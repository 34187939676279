import axios from "axios";
import { getIdToken } from "firebase/auth";
import { getSelectedOrganization } from "../helpers/localStorage";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";
import { showErrorToast } from "../utils/toast";
import { ENV_VARS } from "./env";
import { firebaseAuth } from "./firebase";

export const ERRORS_TO_EXCLUDE = [
	"The dataset should have between 20 and 200 images. Please adjust the number of images to fit within this range.",
	"Failed to upload due to low resolution. Please upload images with a minimum resolution of 1024x1024 pixels.",
	"Failed to upload image",
	"The dataset should have between 10 and 20 images. Please adjust the number of images to fit within this range.",
	"Query doesn't stand with Bria's ethic rules",
	"Transfer organization ownership to delete your account",
];

export async function BriaAxios(customHeaders = {}, signal?: AbortSignal) {
	const currentUser = firebaseAuth.currentUser;
	const authorization = currentUser ? await getIdToken(currentUser) : "";
	const selectedOrg = getSelectedOrganization();
	const instance = axios.create({
		baseURL: ENV_VARS.VITE_PLATFORM_API_URL,
		headers: {
			Authorization: authorization,
			...(iframeStore.isIframe() ? { "Iframe-Id": iframeStore.iframe?.id } : {}),
			"Org-Id": selectedOrg?.organization?.uid,
			"Content-Type": "application/json",
			...customHeaders,
		},
		signal,
	});
	instance.interceptors.response.use(null, (error) => {
		if (axios.isCancel(error)) {
			console.log("Request canceled:", error.message);
		} else if (error.code !== "ERR_CANCELED") {
			const errorMessage = error?.response?.data?.message ?? undefined;
			const expectedError =
				error.response &&
				((error.response.status >= 400 && error.response.status < 500) || error.response.status === 504);

			if (
				!expectedError &&
				!ERRORS_TO_EXCLUDE.includes(errorMessage) &&
				!iframeStore.isPreview() &&
				!(error.response && error.response.status === 403)
			) {
				showErrorToast(undefined, "123");
			}
		}

		return Promise.reject(error);
	});
	return instance;
}

export type BackendError = {
	message: string;
	status: number;
};
