import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../assets/images/icons/CloudUpload.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import BriaAccordion from "../../common/BriaAccordion/BriaAccordion.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import StaticDropdown, { TailoredStyles, TailoredSubStyles } from "../../common/DropDowns/StaticDropdown.tsx";
import ImageUploader from "../../common/ImageUploader/ImageUploader.tsx";
import styles from "./Objects.module.scss";

const Objects = () => {
	const { objectsStore } = useAppStore();
	/* NOTE: Temporatly Hidden - DO NOT DELETE */
	// const [replaceObjectDescription, setReplaceObjectDescription] = useState<null | string>(null);
	const { t } = useTranslation("translation", { keyPrefix: "aiEditor.objects" });
	const isRefineEnabled =
		!objectsStore.isLoading && objectsStore.refine.tailored_style && objectsStore.refine.sub_style;

	const handleTailoredStyleChange = (e: SelectChangeEvent<TailoredStyles>) => {
		objectsStore.setProperty("refine", {
			...objectsStore.refine,
			tailored_style: e.target.value as TailoredStyles,
		});
	};

	const handleSubStyleChange = (e: SelectChangeEvent<TailoredSubStyles>) => {
		objectsStore.setProperty("refine", {
			...objectsStore.refine,
			sub_style: e.target.value as TailoredSubStyles,
		});
	};

	return (
		<Box className={styles.objectsContainer}>
			<Typography className={styles.title}>{t("title")}</Typography>
			<Typography className={styles.description}>{t("description")}</Typography>
			<Box className={styles.separator} />
			{/* NOTE: Temporatly Hidden - DO NOT DELETE */}
			{/* <BriaAccordion accordionTitle={t("removeOrReplace.title")}>
				<Box className={styles.row}>
					<Typography className={styles.accordionDescription}>{t("removeOrReplace.removeObject")}</Typography>
					<BriaButton
						onClick={() => {
							pricingStore.openPricingFlow();
						}}
						className={styles.button}
						buttonType="secondaryMedium"
					>
						{t("buttons.remove")}
					</BriaButton>
				</Box>
				<Box className={styles.separator_20} />
				<Box className={styles.verticalRow}>
					<Typography className={styles.accordionDescription}>
						{t("removeOrReplace.replaceObject")}
					</Typography>
					<BriaInput
						alignment={"flex-start"}
						multiline
						className={styles.textFieldStyle}
						height={"108px"}
						fullWidth={true}
						type="text"
						placeholder={t("removeOrReplace.replaceObjectPlaceholder")}
						value={replaceObjectDescription}
						onChange={(e) => {
							setReplaceObjectDescription(e.target.value);
						}}
					/>
					<BriaButton
						onClick={() => {
							pricingStore.openPricingFlow();
						}}
						className={styles.customButton}
						buttonType="secondaryMedium"
					>
						{t("buttons.replace")}
					</BriaButton>
				</Box>
			</BriaAccordion> */}
			<BriaAccordion accordionTitle={t("refine.title")} defaultExpanded={true}>
				<Box className={styles.verticalRow}>
					<InputLayout label={t("refine.tailoredStyles.label")}>
						<StaticDropdown<TailoredStyles>
							type="tailoredStyles"
							value={objectsStore.refine?.tailored_style}
							onChange={handleTailoredStyleChange}
							placeholder={t("refine.tailoredStyles.placeholder")}
						/>
					</InputLayout>
					{objectsStore.refine?.tailored_style && (
						<InputLayout label={t("refine.tailoredSubStyles.label")}>
							<StaticDropdown<TailoredSubStyles>
								type={`tailoredSubStyles.${objectsStore.refine?.tailored_style}` as any}
								value={objectsStore.refine?.sub_style}
								onChange={handleSubStyleChange}
								placeholder={t("refine.tailoredSubStyles.placeholder")}
							/>
						</InputLayout>
					)}
					{/*<InputLayout label={t("refine.prompt.label")}>*/}
					{/*	<TextField*/}
					{/*		value={objectsStore.refine.prompt}*/}
					{/*		onChange={(e) =>*/}
					{/*			objectsStore.setProperty("refine", {*/}
					{/*				...objectsStore.refine,*/}
					{/*				prompt: e.target.value.trim(),*/}
					{/*			})*/}
					{/*		}*/}
					{/*		placeholder={t("refine.prompt.placeholder")}*/}
					{/*		fullWidth*/}
					{/*		multiline*/}
					{/*		minRows={3}*/}
					{/*		inputProps={{ maxLength: 30 }}*/}
					{/*		InputProps={{*/}
					{/*			classes: {*/}
					{/*				root: styles.refinePromptTextField,*/}
					{/*			},*/}
					{/*		}}*/}
					{/*	/>*/}
					{/*</InputLayout>*/}
					<ImageUploader
						className={styles.imgUploader}
						emptyStateClassName={styles.emptyState}
						titleClassName={styles.uploadTitle}
						icon={<CloudUploadSVG/>}
						inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp" }}
						descriptionClassName={styles.uploadDescription}
						src={objectsStore.refine.originalImage}
						description={t("refine.uploadOriginalImage")}
						onUpload={objectsStore.handleUpload}
						loading={objectsStore.isUploadingOriginal}
						onDelete={objectsStore.handleDelete}
					/>
				</Box>
				<BriaButton
					onClick={objectsStore.refineObject}
					className={styles.customButton}
					buttonType="secondaryMedium"
					disabled={!isRefineEnabled}
				>
					{t("buttons.refineObject")}
				</BriaButton>
			</BriaAccordion>
		</Box>
	);
};

const ObservedComponent = observer(Objects);
export default ObservedComponent;
