import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../assets/images/icons/CloudUpload.tsx";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import upload from "../../../assets/images/svgs/Uppload.svg";
import FileUploader from "../../../components/common/FileUploader/FileUploader";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import ImageUploader from "../../common/ImageUploader/ImageUploader.tsx";
import BriaDropDown from "../BriaDropDown/BriaDropDown.tsx";
import BriaSlider from "../BriaSlider/BriaSlider.tsx";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import styles from "./ImagePromptAdapter.module.scss";

interface IProps {
	label?: string;
	info?: string;
}

const ImagePromptAdapter: React.FC<IProps> = ({ label, info }) => {
	const { t } = useTranslation("translation", { keyPrefix: "imagePromptAdapter" });
	const { sandboxAPIStore } = useAppStore();

	const labeledMarks = [
		{
			value: 0,
			label: "0",
		},
		{
			value: 1,
			label: "1",
		},
	];

	const dropdownOptions = [
		{
			key: "Regular",
			value: "regular",
		},
		{
			key: "Style only",
			value: "style_only",
		},
	];

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
		const IPAdapterPayload = sandboxAPIStore.config.apiConfig?.IPAdapterPayload;
		if (!IPAdapterPayload) {
			return;
		}
		switch (name) {
			case "image_prompt_scale":
				IPAdapterPayload[name] = Number(e.target.value);
				break;
			case "image_prompt_mode":
				IPAdapterPayload[name] = e.target.value;
				break;
		}
	};

	const handleOnDelete = (index: number) => {
		if (sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_urls) {
			sandboxAPIStore.config.apiConfig.IPAdapterPayload.image_prompt_urls.splice(index, 1);
		}
	};

	const handleClearImages = async () => {
		if (sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_urls) {
			sandboxAPIStore.config.apiConfig.IPAdapterPayload.image_prompt_urls = [];
		}
	};

	const uploadIcon = <img src={upload} />;
	const MAX_FILES_LIMIT = 35;

	const renderInput = () => {
		return (
			<InputLayout className={styles.root} label={label} info={info} validateOn={true}>
				<Box className={styles.imagePromptAdapter}>
					<Box className={styles.header}>
						<Typography className={styles.title}>{t("mode")}</Typography>
					</Box>
					<BriaDropDown
						className={styles.dropDown}
						value={sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_mode}
						items={dropdownOptions}
						onChange={(e: any) => {
							handleInputChange(e, "image_prompt_mode");
						}}
					/>

					{sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_urls.length === 0 ? (
						<ImageUploader
							className={styles.imgUploader}
							emptyStateClassName={styles.emptyState}
							titleClassName={styles.uploadTitle}
							icon={<CloudUploadSVG />}
							inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp", multiple: true }}
							descriptionClassName={styles.uploadDescription}
							description={t("uploadYourImages")}
							maxFileSize={sandboxAPIStore.MAX_FILE_SIZE}
							onUpload={sandboxAPIStore.handleUploadImagePromptURLs}
							loading={sandboxAPIStore.isUploadingIPImage}
							maxFilesLimit={MAX_FILES_LIMIT}
						/>
					) : (
						<>
							<Box className={styles.uploadHeader}>
								<Typography className={styles.title}>{t("UploadedImages")}</Typography>
								<Box className={styles.icons}>
									<FileUploader
										buttonText={uploadIcon}
										buttonType="textSmall"
										onUpload={sandboxAPIStore.handleUploadImagePromptURLs}
										inputProps={{ accept: "image/png, image/jpeg, image/jpg", multiple: true }}
										buttonProps={{ className: styles.uploadBtn }}
										maxFilesLimit={MAX_FILES_LIMIT}
										maxFileSize={sandboxAPIStore.MAX_FILE_SIZE}
										disabled={sandboxAPIStore.isUploadingImage || sandboxAPIStore.isLoading}
									/>
									<DeleteIcon className={styles.trash} onClick={handleClearImages} />
								</Box>
							</Box>
							<LoadingPlaceholder
								className={styles.uploadedImages}
								type={"overlay"}
								isLoading={sandboxAPIStore.isUploadingIPImage}
							>
								{sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_urls.map(
									(url, index) => (
										<Box className={styles.imagePreviewWrap}>
											<DeleteIcon
												className={styles.trash}
												onClick={() => handleOnDelete(index)}
											/>
											<img className={styles.imagePreview} src={url} />
										</Box>
									),
								)}
							</LoadingPlaceholder>
						</>
					)}

					<BriaSlider
						value={sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_scale}
						min={0}
						max={1}
						step={0.01}
						marks={labeledMarks}
						valueLabelDisplay={"auto"}
						showMarksPoints
						onChange={(e: any) => {
							handleInputChange(e, "image_prompt_scale");
						}}
					/>
				</Box>
				<Divider />
			</InputLayout>
		);
	};

	return renderInput();
};

const ObservedComponent = observer(ImagePromptAdapter);
export default ObservedComponent;
