import { SvgIcon, SvgIconProps } from "@mui/material";

function DuplicateIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 14 15" {...props}>
			<path
				d="M10.7009 12.205C10.7009 12.9094 10.1299 13.4805 9.42542 13.4805H2.02746C1.32303 13.4805 0.751953 12.9094 0.751953 12.205V4.807C0.751953 4.10257 1.32303 3.53149 2.02746 3.53149H9.42542C10.1299 3.53149 10.7009 4.10257 10.7009 4.807V12.205Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M2.66504 0.979672H11.9763C12.6807 0.979672 13.2518 1.55075 13.2518 2.25518V11.5664"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M5.72656 6.59144V10.418"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M3.81348 8.50781H7.64001"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default DuplicateIcon;
