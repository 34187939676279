import { Box, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import StopIcon from "../../../../../assets/images/icons/StopIcon.tsx";
import BriaSlider from "../../../../../components/common/BriaSlider/BriaSlider.tsx";
import StoppableButton from "../../../../../components/common/StoppableButton/StoppableButton.tsx";
import { APPS } from "../../../../../constants/AppsConstants.ts";
import { MAX_ALLOWED_SELECTED_IMAGES } from "../../../../../constants/ImageToImageConstants.ts";
import { useImageToImageConfig } from "../../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import SwipableMenuButton from "../../../../../layout/SwipableMenu/Button/SwipableMenuButton";
import SwipableMenuLayout from "../../../../../layout/SwipableMenu/Menu/SwipableMenuLayout";
import { ImageEditingConfigsProps } from "../../../../../models/image-to-image";
import { isPlaygroundLoadingImages } from "../../../../../utils/index.ts";
import StyleConfig from "../Features/Style/StyleConfig";
import styles from "../ImageToImageConfig.module.scss";

const FoxConfigs = observer(({ handleGenerateImageToImage }: ImageEditingConfigsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const { isGenerateEnabled } = useImageToImageConfig();
	const hasLoadingImages = isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.IMAGE_TO_IMAGE);

	return (
		<>
			<SwipableMenuLayout className={styles.config}>
				<SwipableMenuButton
					swipeTo={<StyleConfig />}
					subSwipableMenuProps={{ title: t("features.style.title") }}
				>
					<Box className={styles.buttonLabel}>{t("features.style.button")}</Box>
				</SwipableMenuButton>
				<Divider />
				<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
					<BriaSlider
						value={imageToImageStore.config.num_results}
						onChange={(_, value) => imageToImageStore.handleConfigChange("num_results", value as number)}
						min={1}
						step={1}
						max={4}
						marks={Array.from({ length: 4 }, (_, index) => ({
							value: index + 1,
							label: `${index + 1}`,
						}))}
					/>
				</InputLayout>
			</SwipableMenuLayout>
			<StoppableButton
				condition={!!hasLoadingImages}
				onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
				onButtonClick={handleGenerateImageToImage}
				disabled={!isGenerateEnabled()}
				buttonLabel={
					<>
						{imageToImageStore.getGeneratedImagesCount() > MAX_ALLOWED_SELECTED_IMAGES
							? t("tooManySelected")
							: t("button")}
						{imageToImageStore.getGeneratedImagesCount() > 0 &&
							isGenerateEnabled() &&
							` ${imageToImageStore.getGeneratedImagesCount() * imageToImageStore.config.num_results} ${t(
								"results",
							)}`}
					</>
				}
				stopButtonLabel={
					<>
						<StopIcon /> {t("stopGenerating")}
					</>
				}
				fullWidth
				className={`generate-image-to-image ${styles.stopGeneratingBt}`}
			/>
		</>
	);
});

export default FoxConfigs;
