import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon.tsx";
import { EditIcon2 } from "../../../assets/images/icons/EditIcon2.tsx";
import EyeIcon from "../../../assets/images/icons/EyeIcon.tsx";
import PlusIcon from "../../../assets/images/icons/PlusIcon.tsx";
import ActionsDotsDropdown from "../../../components/common/ActionsDotsDropdown/ActionsDotsDropdown.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable.tsx";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip.tsx";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import CopyToClipboardButton from "../../../components/common/CopyToClipboardButton/CopyToClipboardButton.tsx";
import useStaticDropdown, { Status } from "../../../components/common/DropDowns/useStaticDropdown.ts";
import EmptyTable from "../../../components/common/EmptyTable/EmptyTable.tsx";
import ShowAt from "../../../components/common/ShowAt/ShowAt.tsx";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useIframe } from "../../../hooks/useIframe.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { useUserChecks } from "../../../hooks/useUserChecks.tsx";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout.tsx";
import { EventLogTypesEnum } from "../../../models/common.ts";
import { Iframe } from "../../../models/new-iframe.ts";
import { formatDate } from "../../../utils/index.ts";
import { EditIframeLocationState } from "../IframeEditor/NewIframeEditor.tsx";
import iframeStore from "../iframe-store.tsx";
import styles from "./NewIframeConfig.module.scss";

type IframeTableRow = {
	name: string | ReactElement;
	updated: string | ReactElement;
	domains: string[] | ReactElement[];
	status: string | ReactElement;
	actions: ReactElement;
};

const NewIframeConfig = () => {
	const { appStore } = useAppStore();
	const { canUserEditOrganization } = useUserChecks();
	const [loadingRow, setLoadingRow] = useState<{ data: Iframe; column: string }>();
	const [openAllowedDomainsPopup, setOpenAllowedDomainsPopup] = useState(false);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [allowedDomainsInput, setAllowedDomainsInput] = useState("");
	const [allowedDomainsError, setAllowedDomainsError] = useState<string | null>(null);
	const [selectedIframe, setSelectedIframe] = useState<Iframe>();
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.config" });
	const tableHeaderArray = [
		t("table.headers.name"),
		t("table.headers.updated"),
		t("table.headers.domains"),
		t("table.headers.status"),
		t("table.headers.actions"),
	];
	const navigate = useNavigate();
	const [tableData, setTableData] = useState<TableRow<IframeTableRow>[]>([]);
	const [filterBy, setFilterBy] = useState<FilterByOptions>();
	const paginationOptions: PaginationOptions = {
		rowsPerPage: iframeStore.rowsPerPage,
		totalRows: iframeStore.paginatedIframes.total,
		loadNextPage: iframeStore.getOrgIframes,
	};
	const { items: statusDropdownItems } = useStaticDropdown<Status>({ tKeyPrefix: "statuses" });
	const { createIframeUrl } = useIframe();

	useEffect(() => {
		iframeStore
			.getOrgIframes(1)
			.then(() => setTableData(iframeStore.paginatedIframes.items.map((iframe) => createRow(toJS(iframe)))));
	}, []);

	useEffect(() => {
		if (!iframeStore.loadingOrgIframes) {
			const newTableData: TableRow<IframeTableRow>[] = iframeStore.paginatedIframes.items.map((iframe) =>
				createRow(toJS(iframe)),
			);
			setTableData([...newTableData]);
		}
	}, [iframeStore.paginatedIframes.items]);

	const editIframe = (iframeToEdit: Iframe) => {
		const editTemplateState: EditIframeLocationState = {
			iframeToEdit: iframeToEdit,
		};
		navigate(`${RouterConstants.NEW_IFRAMES_EDITOR.editPath}/${iframeToEdit.id}`, {
			state: editTemplateState,
		});
	};

	const openIframe = (iframe: Iframe) => {
		window.open(createIframeUrl(iframe), "_blank");
	};

	const deleteIframe = async (iframeId: string) => {
		await iframeStore.deleteIframe(iframeId);
		setTableData((prevData) => prevData.filter((item) => item.key !== iframeId));
		closeDeletePopup();
	};

	const updateIframeStatus = async (iframe: Iframe, status: Status) => {
		setLoadingRow({ data: iframe, column: "status" });
		await iframeStore.updateIframe({ ...iframe, status });
		setLoadingRow(undefined);
	};

	const createRow = (iframe: Iframe): TableRow<IframeTableRow> => {
		const color = iframe.status === "Deleted" ? "#1A063840" : "";
		const statusContainer = (
			<Box className={styles.statusContainer} sx={{ color }}>
				<div className={`${styles[iframe.status?.toLowerCase()]} ${styles.circle}`} />
				{iframe.status}
			</Box>
		);
		const name = (
			<Box className={clsx(styles.nameContainer)}>
				<Box className={clsx(styles.nameContainer)}>
					<Box>{iframe.name}</Box>
					{iframe.description && <Box className={styles.description}>{iframe.description}</Box>}
				</Box>
			</Box>
		);
		const updated = <Box>{formatDate(iframe.updated_at)}</Box>;
		const status = !iframe.config.allowed_domains?.length ? (
			<BriaButton
				key="add-domain"
				buttonType="primaryMedium"
				className={styles.addDomainBtn}
				startIcon={<PlusIcon fillColor="#fff" />}
				onClick={() => {
					setOpenAllowedDomainsPopup(true);
					setSelectedIframe(iframe);
				}}
			>
				{t("addDomain")}
			</BriaButton>
		) : (
			<Box className={styles.statusContainer}>
				{iframe.status !== "Deleted" && canUserEditOrganization() ? (
					<ActionsDotsDropdown
						label={statusContainer}
						items={statusDropdownItems
							.filter((status) => status.value !== "Deleted")
							.map(({ key, value }) => ({
								text: key,
								onClick: () => updateIframeStatus(iframe, value),
							}))}
					/>
				) : (
					statusContainer
				)}
			</Box>
		);
		const actions = (
			<Box className={styles.actions}>
				<BriaTooltip title={t("table.edit")}>
					<EditIcon2
						className={styles.actionBtn}
						onClick={() => editIframe(iframe)}
						sx={{ width: "18px !important" }}
					/>
				</BriaTooltip>
				<BriaTooltip title={t("table.view")}>
					<EyeIcon
						className={styles.actionBtn}
						onClick={() => openIframe(iframe)}
						sx={{ width: "21px !important" }}
					/>
				</BriaTooltip>
				<BriaTooltip title={t("table.copy")}>
					<CopyToClipboardButton className={styles.actionBtn} textToCopy={createIframeUrl(iframe)} />
				</BriaTooltip>
				{/*TODO: We need to manage the editing capabilities from the DB as a part of the iframe object */}
				<ShowAt condition={!iframe.name.includes(t("table.dontDelete"))}>
					<BriaTooltip title={t("table.delete")}>
						<DeleteIcon className={styles.actionBtn} onClick={() => handleOpenDeletePopup(iframe)} />
					</BriaTooltip>
				</ShowAt>
			</Box>
		);
		const domains = [
			...(iframe.config.allowed_domains || []).map((domain, index) => (
				<Box key={index} className={styles.domain}>
					<Typography textTransform="initial">{domain}</Typography>
					<DeleteIcon className={styles.actionBtn} onClick={() => deleteAllowedDomain(iframe, index)} />
				</Box>
			)),
			<BriaButton
				startIcon={<PlusIcon />}
				onClick={() => {
					setOpenAllowedDomainsPopup(true);
					setSelectedIframe(iframe);
				}}
				className={styles.addBtn}
			>
				{t("table.addDomain")}
			</BriaButton>,
		];

		const newRow: TableRow<IframeTableRow> = {
			key: iframe.id,
			data: {
				name,
				updated,
				domains,
				status,
				actions,
			},
		};

		return newRow;
	};

	const openIframeEditorPage = () => {
		navigate(RouterConstants.NEW_IFRAMES_EDITOR.createPath);
	};

	const updateAllowedDomains = async () => {
		try {
			if (selectedIframe) {
				const domains: string[] = allowedDomainsInput.split(",").map((domain) => domain.trim());
				const isValidDomains = domains.every(isValidDomain);
				if (isValidDomains) {
					const updatedIframe: Iframe = {
						...selectedIframe,
						config: {
							...selectedIframe.config,
							allowed_domains: [...(selectedIframe?.config.allowed_domains || []), ...domains],
						},
						status: "Active",
					};
					await iframeStore.updateIframe(updatedIframe);
					appStore.logEvent({ eventType: EventLogTypesEnum.ACTIVATED_IFRAME });
					setTableData((prevData) =>
						prevData.map((item) => (item.key === selectedIframe.id ? createRow(updatedIframe) : item)),
					);
					closeAllowedDomainsPopup();
				} else {
					setAllowedDomainsError(t("allowedDomainsPopup.invalidDomains"));
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	const deleteAllowedDomain = async (iframe: Iframe, domainIndex: number) => {
		try {
			const filteredDomains: string[] = iframe?.config.allowed_domains.filter(
				(_domain, index) => index !== domainIndex,
			);
			const updatedIframe: Iframe = {
				...iframe,
				config: {
					...iframe.config,
					allowed_domains: filteredDomains,
				},
				status: filteredDomains.length === 0 ? "Inactive" : "Active",
			};
			await iframeStore.updateIframe(updatedIframe);
			setTableData((prevData) =>
				prevData.map((item) => (item.key === iframe.id ? createRow(updatedIframe) : item)),
			);
		} catch (e) {
			console.error(e);
		}
	};

	function isValidDomain(domain: string) {
		const domainPattern =
			/^(http(s?):\/\/localhost(:[0-9]{1,5})?|((http(s?)):\/\/)?[*a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?$/;
		return domainPattern.test(domain);
	}

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions = {
			...filterBy,
			search: {
				text: e.target.value,
				fields: ["name", "description", "updated_at", "status"],
			},
		};
		setFilterBy(updatedFilterBy);
		await iframeStore.getOrgIframes(1, updatedFilterBy);
	};

	const closeAllowedDomainsPopup = () => {
		setOpenAllowedDomainsPopup(false);
		setAllowedDomainsInput("");
		setSelectedIframe(undefined);
	};

	const handleOpenDeletePopup = (iframe: Iframe) => {
		setOpenDeletePopup(true);
		setSelectedIframe(iframe);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setSelectedIframe(undefined);
	};

	return (
		<>
			<TableLayout
				title={t("table.title")}
				description={t("table.description")}
				primaryButtonText={t("table.primaryBtn")}
				primaryButtonClick={openIframeEditorPage}
				secondaryButtonText={t("table.secondaryBtn")}
				secondaryButtonClick={() => window.open(LinkConstants.IFRAME_v2_DOCS, "_blank")}
				headerArray={tableHeaderArray}
				tableData={tableData}
				emptyStateComponent={<EmptyTable message={t("table.emptyState")} />}
				loading={iframeStore.loadingOrgIframes}
				enableSearch
				searchBoxPlaceholder={t("table.searchPlaceholder")}
				handleSearchChange={handleSearchChange}
				filterBy={filterBy}
				paginationOptions={paginationOptions}
				loadingRow={loadingRow}
			/>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={t("deletePopup.confirmText")}
				open={openDeletePopup}
				onClick={() => selectedIframe && deleteIframe(selectedIframe.id)}
			/>
			<ConfirmationPopup
				onClose={closeAllowedDomainsPopup}
				title={t("allowedDomainsPopup.title")}
				description={t("allowedDomainsPopup.description")}
				confirmButtonText={t("allowedDomainsPopup.confirmText")}
				open={openAllowedDomainsPopup}
				onClick={updateAllowedDomains}
				errorMessage={allowedDomainsError}
				disableConfirm={!allowedDomainsInput || allowedDomainsError !== null}
			>
				<BriaInput
					alignment={"flex-start"}
					multiline
					className={styles.textFieldStyle}
					height={"108px"}
					type="text"
					placeholder={t("allowedDomainsPopup.textFieldPlaceholder")}
					value={allowedDomainsInput}
					onChange={(e) => {
						setAllowedDomainsInput(e.target.value);
						setAllowedDomainsError(null);
					}}
				/>
			</ConfirmationPopup>
		</>
	);
};

export default observer(NewIframeConfig);
