import { fabric } from "fabric";
import React, { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import useScenesUtils from "../../../CustomUtils/ScenesUtils.tsx";
import useDesignEditorUtils from "../../../CustomUtils/UseDesignEditor.tsx";
import { FabricCanvas } from "../../../core";
import useDesignEditorContext from "../../../hooks/useDesignEditorContext.ts";
import { useEditor } from "../../../hooks/useEditor.tsx";
import { ITextCustomization } from "../../../interfaces/DesignEditor.ts";
import { Image as EditorImage } from "../../../models/image.ts";
import {ILayer, IScene, IShadow, IStaticImage, IStaticText, LayerType} from "../../../types";
import { CampaignCustomOptions, LayerImage, LayerText } from "../../../types/campaign.ts";
import {
	BackgroundOption,
	BackgroundOptionsEnum,
	BackgroundRoute,
	IBackgroundObject,
	IBoundingBox,
	ISmartImageSize,
	ImageSize,
	ObjectsEnum,
} from "../components/Panels/panelItems";

/**
 * Asynchronously retrieves the dimensions of an image from a given URL.
 *
 * @param imageUrl - The URL of the image.
 * @returns A promise that resolves with the width and height of the image.
 */
export const getImageDimensionsByImageUrl = async (imageUrl: string): Promise<IBoundingBox> => {
	return new Promise((resolve, reject) => {
		const img = new Image();

		img.onload = () => {
			resolve({
				width: img.naturalWidth,
				height: img.naturalHeight,
			} as IBoundingBox);
		};

		img.onerror = (error) => {
			reject(error);
		};

		img.src = imageUrl;
	});
};

/**
 * Calculates the scale factor needed to fit an image within a given rectangle.
 *
 * @param imageUrl - The URL of the image.
 * @param rect - The rectangle object to fit the image into.
 * @param imageDimensions - Optional pre-fetched dimensions of the image (width and height).
 * @param rectangleDimensions - Optional dimensions of the rectangle (width and height).
 * @returns The scale factor to fit the image within the rectangle.
 */
export const getImageToRectScaleFactor = async (
	imageUrl: string,
	rect: any,
	imageDimensions: { width: number; height: number } | null = null,
	rectangleDimensions: { width: number; height: number } | null = null,
) => {
	if (!imageDimensions) {
		imageDimensions = await getImageDimensionsByImageUrl(imageUrl);
	}
	if (!rectangleDimensions) {
		rectangleDimensions = { width: rect.width * rect.scaleX, height: rect.height * rect.scaleY };
	}

	const rectAspectRatio = rectangleDimensions.width / rectangleDimensions.height;
	const imgAspectRatio = imageDimensions.width / imageDimensions.height;

	let scaleFactor;
	if (imgAspectRatio > rectAspectRatio) {
		scaleFactor = rectangleDimensions.width / imageDimensions.width;
	} else {
		scaleFactor = rectangleDimensions.height / imageDimensions.height;
	}

	return scaleFactor;
};

/**
 * Finds and returns a fabric group on the canvas that contains an element with a specific ID.
 *
 * @param canvas - The fabric canvas instance to search.
 * @param elementId - The ID of the element to find within a group.
 * @returns The fabric group containing the element with the specified ID, or null if not found.
 */
export const findGroupByElementId = (canvas: FabricCanvas, elementId: string): fabric.Object | null => {
	let foundGroup = null;
	canvas.forEachObject(function (canvasElement) {
		if (canvasElement instanceof fabric.Group) {
			canvasElement._objects?.forEach((object) => {
				if (object.id === elementId) {
					foundGroup = canvasElement;
				}
			});
		}
	});
	return foundGroup;
};

/**
 * Creates a rectangle object definition for fabric.js with specified dimensions and styling options.
 *
 * @param id - The ID of the rectangle object.
 * @param width - The width of the rectangle.
 * @param height - The height of the rectangle.
 * @param left - Optional. The left position of the rectangle. Default is 0.
 * @param top - Optional. The top position of the rectangle. Default is 0.
 * @param scaleX
 * @param scaleY
 * @returns Partial fabric.js object definition for a rectangle.
 */
export const createRectangleObject = (
	id: string,
	width: number,
	height: number,
	left: number = 0,
	top: number = 0,
	scaleX: number = 1,
	scaleY: number = 1,
): Partial<any> => {
	return {
		left: left,
		top: top,
		width: width,
		height: height,
		originX: "left",
		originY: "top",
		scaleX: scaleX,
		scaleY: scaleY,
		type: "StaticPath",
		path: [["M", width, 0], ["L", 0, 0], ["L", 0, height], ["L", width, height], ["L", width, 0], ["Z"]],
		fill: id === ObjectsEnum.OuterRectangle ? "rgba(83, 0, 201, 0.02)" : "rgba(83, 0, 201, 0.03)",
		stroke: "#B3B3B9",
		strokeWidth: 2,
		strokeDashArray: [10, 10],
		shadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635011325399_603749.png",
		id: id,
	};
};

/**
 * Clones a FileList object by creating a new DataTransfer object and adding each file from the original FileList.
 *
 * @param files - The original FileList to clone.
 * @returns FileList - A new FileList object containing cloned files.
 */
export const cloneFileList = (files: FileList): FileList => {
	const dataTransfer = new DataTransfer();
	Array.from(files).forEach((file) => dataTransfer.items.add(file));
	return dataTransfer.files;
};

export const useSmartImageUtils = () => {
	const editor = useEditor();
	const { applyWizardImageChangeOnLayerV1 } = useDesignEditorUtils();
	const { designEditorStore, imagesStore, campaignStore } = useAppStore();
	const { scenes, setScenes, setCurrentScene, currentScene } = useDesignEditorContext();

	/**
	 * Waits for the canvas to render all objects by using a promise.
	 *
	 * @returns A promise that resolves after the canvas has rendered all objects.
	 */
	function waitForRender(time?: number) {
		return new Promise((resolve) => {
			editor && editor.canvas.canvas.renderAll();
			setTimeout(resolve, time ?? 1200);
		});
	}

	/**
	 * Changes the current page in the editor, updates the scenes list, and sets the current scene.
	 *
	 * @param page - The page object to switch to.
	 */
	const changePage = React.useCallback(
		async (scene: IScene) => {
			return new Promise<void>((resolve, reject) => {
				if (editor) {
					const updatedScene = editor.scene.exportToJSON();
					setCurrentScene(scene);
					editor.renderer
						.render(updatedScene)
						.then((preview: string) => {
							const updatedPages = scenes.map((_scene) => {
								if (_scene.id === updatedScene.id) {
									return { ...updatedScene, preview };
								}
								return _scene;
							}) as any[];

							setScenes(updatedPages);
							editor?.canvas.canvas.disableEvents();
							editor?.canvas.canvas.discardActiveObject();
							editor?.canvas.canvas.enableEvents();
							resolve();
						})
						.catch((error) => {
							console.error("Error rendering the scene: ", error);
							reject(error);
						});
				} else {
					reject(new Error("Editor not initialized"));
				}
			});
		},
		[editor, scenes, currentScene],
	);

	/**
	 * Adds a rectangle object to the editor if it does not already exist.
	 *
	 * @param _currentScene
	 * @param rectangle - The rectangle object to check for existence. If null, a new rectangle will be added.
	 * @param options - The options used to create the new rectangle object.
	 * @returns A promise that resolves when the rectangle is added or if no action is needed.
	 */
	const addRectangleIfNotExists = async (_currentScene: IScene, rectangle: ILayer | null, options: any) => {
		if (_currentScene && !rectangle) {
			await addObjectToScene(_currentScene, options);

			const refObject = await findObjectFromScene(
				_currentScene,
				options.id === ObjectsEnum.OuterRectangle ? ObjectsEnum.InitialFrame : ObjectsEnum.OuterRectangle,
			);

			const addedObject = await findObjectFromScene(_currentScene, options.id);
			if (addedObject && refObject) {
				addedObject.left = (refObject.left ?? 0) + ((refObject.width ?? 1) - (addedObject.width ?? 1)) / 2;
				addedObject.top = (refObject.top ?? 0) + ((refObject.height ?? 1) - (addedObject.height ?? 1)) / 2;
			}
		}
	};
	const initializeSmartImage = async (_currentScene: IScene | null): Promise<void> => {
		if (!_currentScene) {
			return;
		}

		let foundOriginalGroup = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage, true);
		if (foundOriginalGroup?.type?.toLowerCase() !== "group") {
			foundOriginalGroup = undefined;
		}
		const initialFrame = await findObjectFromScene(_currentScene, ObjectsEnum.InitialFrame);
		const outerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.OuterRectangle);
		const innerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);

		await addRectangleIfNotExists(
			_currentScene,
			outerRectangle as ILayer,
			createRectangleObject(
				ObjectsEnum.OuterRectangle,
				(initialFrame?.width ?? 2) - 2,
				(initialFrame?.height ?? 2) - 2,
			),
		);
		await addRectangleIfNotExists(
			_currentScene,
			innerRectangle as ILayer,
			createRectangleObject(
				ObjectsEnum.InnerRectangle,
				(initialFrame?.width ?? 1) / 2,
				(initialFrame?.height ?? 1) / 2,
			),
		);
	};

	const resetRectangleInAGroup = async (
		_currentScene: IScene,
		objectId: string,
		originalDimensions: { width: number; height: number; left: number; top: number } | undefined = undefined,
	): Promise<void> => {
		if (!editor) return;
		let object = await findObjectFromScene(_currentScene, objectId);
		let objectGroup = await findObjectFromScene(_currentScene, objectId, true);
		if (objectGroup?.type?.toLowerCase() !== "group") {
			objectGroup = undefined;
		}

		let originalScene = undefined;
		let originalInnerRectangle = designEditorStore.originalInnerRectangle;
		let originalOuterRectangle = designEditorStore.originalOuterRectangle;
		if (campaignStore.selectedWizardTemplate) {
			originalScene = campaignStore.selectedWizardTemplate.ads_json?.scenes?.find(
				(scene: IScene) => scene.id === _currentScene.id,
			);
		} else if (campaignStore.selectedTemplate) {
			if (campaignStore.scenesAfterResizing && campaignStore.scenesAfterResizing[_currentScene.id]) {
				originalScene = campaignStore.scenesAfterResizing[_currentScene.id];
			} else {
				originalScene = campaignStore.selectedTemplate.ads_json?.scenes?.find(
					(scene: IScene) => scene.id === _currentScene.id,
				);
			}
		}

		if (originalScene) {
			originalInnerRectangle = (await findObjectFromScene(originalScene, objectId, true)) as ILayer;
			originalOuterRectangle = (await findObjectFromScene(originalScene, objectId, true)) as ILayer;
		}

		if (objectGroup) {
			let newWidth = originalDimensions
				? originalDimensions.width
				: (objectGroup.width ?? 1) * (objectGroup.scaleX ?? 1);
			let newHeight = originalDimensions
				? originalDimensions.height
				: (objectGroup.height ?? 1) * (objectGroup.scaleY ?? 1);
			let newLeft = originalDimensions?.left ? originalDimensions.left : objectGroup.left;
			let newTop = originalDimensions?.top ? originalDimensions.top : objectGroup.top;
			let newScaleX = objectGroup.scaleX;
			let newScaleY = objectGroup.scaleY;

			if (objectId === ObjectsEnum.InnerRectangle) {
				if (originalInnerRectangle?.width && originalInnerRectangle?.height) {
					newWidth = originalInnerRectangle.width;
					newHeight = originalInnerRectangle.height;
					newLeft = originalInnerRectangle.left ?? 0;
					newTop = originalInnerRectangle.top ?? 0;
					newScaleX = originalInnerRectangle.scaleX;
					newScaleY = originalInnerRectangle.scaleY;
				}
			}
			if (objectId === ObjectsEnum.OuterRectangle) {
				if (originalOuterRectangle?.width && originalOuterRectangle?.height) {
					newWidth = originalOuterRectangle.width;
					newHeight = originalOuterRectangle.height;
					newLeft = originalOuterRectangle.left ?? 0;
					newTop = originalOuterRectangle.top ?? 0;
					newScaleX = originalOuterRectangle.scaleX;
					newScaleY = originalOuterRectangle.scaleY;
				}
			}
			objectGroup && (await removeObjectFromScene(_currentScene, objectGroup.id));
			const objectOptions = createRectangleObject(objectId, newWidth, newHeight, 0, 0);
			await addObjectToScene(_currentScene, objectOptions);
			object = await findObjectFromScene(_currentScene, objectId);

			if (object) {
				object.left = newLeft;
				object.top = newTop;
				object.scaleX = newScaleX;
				object.scaleY = newScaleY;
			}
		}
	};

	/**
	 * Synchronize missing "SmartImage" and "OriginalImage" objects with the current scene.
	 *
	 * This method checks if the user removed the "SmartImage" or "OriginalImage" objects (e.g., by using "Continue without object")
	 * and then navigated back to a previous scene. It ensures that if these objects exist in the original scene
	 * but are missing in the current scene, they are restored at the appropriate index.
	 *
	 * @param _currentScene - The current scene being edited.
	 */
	const syncMissingSmartImageAndOriginalImageWithOriginalScene = async (_currentScene: IScene) => {
		const originalScene = campaignStore.selectedWizardTemplate?.ads_json?.scenes?.find(
			(scene: IScene) => scene.id === _currentScene.id,
		);

		if (originalScene) {
			// Check for SmartImage and OriginalImage in the original scene
			const originalSmartImage = await findObjectFromScene(originalScene, ObjectsEnum.SmartImage, true);
			const originalOriginalImage = await findObjectFromScene(originalScene, ObjectsEnum.OriginalImage, true);

			if (originalSmartImage || originalOriginalImage) {
				// Check if the current scene already has these objects
				const currentSmartImage = await findObjectFromScene(_currentScene, ObjectsEnum.SmartImage, true);
				const currentOriginalImage = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage, true);
				const currentInnerRectangle = await findObjectFromScene(
					_currentScene,
					ObjectsEnum.InnerRectangle,
					false,
				);
				const currentOuterRectangle = await findObjectFromScene(
					_currentScene,
					ObjectsEnum.OuterRectangle,
					false,
				);

				if (!currentSmartImage && originalSmartImage && !currentOuterRectangle) {
					// Insert SmartImage at the appropriate index in the current scene
					_currentScene.layers.splice(
						originalScene.layers.findIndex((layer: IScene) => layer.id === originalSmartImage.id),
						0,
						originalSmartImage,
					);
				}

				if (!currentOriginalImage && originalOriginalImage && !currentInnerRectangle) {
					// Insert OriginalImage at the appropriate index in the current scene
					_currentScene.layers.splice(
						originalScene.layers.findIndex((layer: IScene) => layer.id === originalOriginalImage.id),
						0,
						originalOriginalImage,
					);
				}
			}
		}
	};

	/**
	 * Calculate the new dimensions of an object based on the scale factor and image dimensions.
	 *
	 * @param currentObjectWidth - The current width of the object.
	 * @param currentObjectHeight - The current height of the object.
	 * @param imageDimensions - The new dimensions (width and height) of the image.
	 * @returns An object containing the new width and height.
	 */
	const calculateNewObjectDimensions = (
		currentObjectWidth: number,
		currentObjectHeight: number,
		imageDimensions: IBoundingBox | ImageSize,
	): ImageSize => {
		const scaleFactor =
			Math.min(currentObjectWidth / imageDimensions.width, currentObjectHeight / imageDimensions.height) || 1;

		/*
		 * scaleFactor < 1: image is bigger than the container
		 * scaleFactor > 1: Container is bigger than the image
		 * */
		const width = scaleFactor < 1 ? imageDimensions.width * scaleFactor : imageDimensions.width;
		const height = scaleFactor < 1 ? imageDimensions.height * scaleFactor : imageDimensions.height;

		return { width, height };
	};

	/**
	 * Change the aspect ratio of a specified object on the canvas based on new image dimensions.
	 *
	 * @param _currentScene
	 * @param objectId - The unique identifier of the object whose aspect ratio needs to be changed.
	 * @param imageDimensions - The new dimensions (width and height) of the image to adjust the aspect ratio to.
	 * @returns A promise that resolves when the aspect ratio change is complete.
	 */
	const changeObjectAspectRatio = async (
		_currentScene: IScene,
		objectId: string,
		imageDimensions: IBoundingBox | undefined,
	): Promise<undefined> => {
		if (!editor || !imageDimensions) return;
		await resetRectangleInAGroup(_currentScene, objectId);
		const innerRectangleGroup = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle, true);
		const originalImageGroup = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage, true);

		const objectWidth = (innerRectangleGroup?.width ?? 1) * (innerRectangleGroup?.scaleX ?? 1);
		const objectHeight = (innerRectangleGroup?.height ?? 1) * (innerRectangleGroup?.scaleY ?? 1);
		const { width: newObjectWidth, height: newObjectHeight } = calculateNewObjectDimensions(
			objectWidth,
			objectHeight,
			imageDimensions,
		);

		const objectLeft =
			(innerRectangleGroup?.left ?? 0) +
			((innerRectangleGroup?.width ?? 1) * (innerRectangleGroup?.scaleX ?? 1)) / 2 -
			newObjectWidth / 2;
		const objectTop =
			(innerRectangleGroup?.top ?? 0) +
			((innerRectangleGroup?.height ?? 1) * (innerRectangleGroup?.scaleY ?? 1)) / 2 -
			newObjectHeight / 2;

		const options = createRectangleObject(
			ObjectsEnum.InnerRectangle,
			newObjectWidth,
			newObjectHeight,
			objectLeft,
			objectTop,
		);

		originalImageGroup && (await removeObjectFromScene(_currentScene, originalImageGroup.id));
		innerRectangleGroup && (await removeObjectFromScene(_currentScene, innerRectangleGroup.id));
		await addObjectToScene(_currentScene, options);
	};

	/**
	 * Replaces an image on the canvas with new image options, positioning it within a specified frame or container.
	 *
	 * @param _currentScene
	 * @param imageContainerId - The ID of the image container object on the canvas.
	 * @param imageOptions - Options for the new image to be added.
	 * @param foundGroup - Optional. Existing group to remove from the canvas before adding the new image.
	 */
	const replaceCanvasImage = async (
		_currentScene: IScene,
		imageContainerId: ObjectsEnum,
		imageOptions: any,
		foundGroup?: any,
	) => {
		if (!editor) return;
		const imageContainer = await findObjectFromScene(_currentScene, imageContainerId);

		// Smart image case
		if (foundGroup && imageContainer) {
			foundGroup && (await removeObjectFromScene(_currentScene, foundGroup.id));
			imageContainer.left = foundGroup.left;
			imageContainer.top = foundGroup.top;
			await addObjectToScene(_currentScene, imageContainer);
		}

		// Add the new image to the objects
		const imageFabricObject = await editor.objects.importAsFabricObject(imageOptions);
		const imageContainerFabricObject = await editor.objects.importAsFabricObject(imageContainer as Partial<ILayer>);

		if (imageFabricObject && imageContainerFabricObject) {
			// Align image with imageContainer
			imageFabricObject.left = (imageContainerFabricObject.left ?? 0) - (imageOptions.offsetX ?? 0);
			imageFabricObject.top = (imageContainerFabricObject.top ?? 0) - (imageOptions.offsetY ?? 0);

			// Create a new group with image and imageContainer
			const group = new fabric.Group([imageFabricObject, imageContainerFabricObject], {
				left: imageFabricObject.left,
				top: imageFabricObject.top,
				originX: "left",
				originY: "top",
			});
			(group as any).id = uuidv4();
			const groupAsLayer = await editor.objects.exportFabricObjectAsLayer(group);
			imageContainer && (await removeObjectFromScene(_currentScene, imageContainer.id));
			await addObjectToScene(_currentScene, groupAsLayer);
		}
	};

	/**
	 * Creates a change event from an image URL or clones an existing event.
	 *
	 * @param event - The original change event from an input element.
	 * @param imageUrl - Optional URL of an image to create a mock file for the event.
	 * @returns A promise that resolves to a new change event with the image file or cloned files.
	 */
	const getChangeEventFromImageUrl = async (event: ChangeEvent<HTMLInputElement>, imageUrl?: string) => {
		let tempEvent: ChangeEvent<HTMLInputElement>;
		if (imageUrl) {
			const response = await fetch(imageUrl);
			const fileBlob = await response.blob();
			const mockFile = new File([fileBlob], "background-image.jpg", { type: fileBlob.type });

			const dataTransfer = new DataTransfer();
			dataTransfer.items.add(mockFile);

			tempEvent = {
				...event,
				target: {
					...event.target,
					files: dataTransfer.files,
				},
			} as ChangeEvent<HTMLInputElement>;
		} else {
			tempEvent = {
				...event,
				target: {
					...event.target,
					files: cloneFileList(event.target.files ?? new DataTransfer().files),
				},
			} as ChangeEvent<HTMLInputElement>;
		}

		return tempEvent;
	};

	/**
	 * Draws the uploaded image on the editor canvas, adjusting its aspect ratio to fit within a specified rectangle.
	 *
	 * @param image - Image object that contains url, size, and a bounding box around the main object
	 * @param externalScene
	 * @returns A promise that resolves when the image is drawn and scaled correctly.
	 */
	const drawOriginalImage = async (image: IBackgroundObject, externalScene?: IScene) => {
		if (editor) {
			const _currentScene = externalScene ?? editor.scene.exportToJSON();
			await changeObjectAspectRatio(_currentScene, ObjectsEnum.InnerRectangle, image.boundingBox);
			const innerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);

			const imageRectWidth =
				(innerRectangle?.width ?? 1) / ((image.boundingBox?.width ?? 1) / (image.size?.width ?? 1));
			const imageRectHeight =
				(innerRectangle?.height ?? 1) / ((image.boundingBox?.height ?? 1) / (image.size?.height ?? 1));
			// This scaleFactor represents the image to the virtual rect factor
			const scaleFactor = await getImageToRectScaleFactor(image.imageUrl, null, null, {
				width: imageRectWidth,
				height: imageRectHeight,
			});

			const offsetX = (image.boundingBox?.left ?? 1) * scaleFactor;
			const offsetY = (image.boundingBox?.top ?? 1) * scaleFactor;

			const options = {
				id: ObjectsEnum.OriginalImage,
				type: "StaticImage",
				src: image.imageUrl,
				left: innerRectangle?.left ?? 0,
				top: innerRectangle?.top ?? 0,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
				offsetX: offsetX,
				offsetY: offsetY,
			};

			await replaceCanvasImage(_currentScene, ObjectsEnum.InnerRectangle, options);
		}
	};

	/**
	 * Draws the smart image on the editor canvas, adjusting its aspect ratio to fit within a specified rectangle.
	 *
	 * @param image - The image object containing the URL and metadata of the smart image.
	 * @param externalScene
	 * @returns A promise that resolves when the smart image is drawn and scaled correctly.
	 */
	const drawSmartImage = async (image: any, externalScene?: IScene) => {
		if (editor) {
			const _currentScene = externalScene ?? editor.scene.exportToJSON();
			const outerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.OuterRectangle);
			const smartImage = await findObjectFromScene(_currentScene, ObjectsEnum.SmartImage, true);

			const scaleFactor = await getImageToRectScaleFactor(image.url, outerRectangle);
			const options = {
				id: ObjectsEnum.SmartImage,
				type: "StaticImage",
				src: image.url,
				left: outerRectangle?.left ?? 0,
				top: outerRectangle?.top ?? 0,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
				metadata: image.input_params,
			};

			if (smartImage) {
				const foundGroup = smartImage.type === "Group" ? smartImage : undefined;
				if (foundGroup) {
					await replaceCanvasImage(_currentScene, ObjectsEnum.OuterRectangle, options, foundGroup);
				}
			} else {
				await replaceCanvasImage(_currentScene, ObjectsEnum.OuterRectangle, options);
			}
		}
	};

	/**
	 * Uploads and registers background images, updates the smart image background options,
	 * and draws the original image on the editor canvas.
	 *
	 * @param event - The change event from an input element used to upload images.
	 * @param imageUrl - Optional URL of an image to be used as a background.
	 * @param externalScene
	 * @returns A promise that resolves when the images are uploaded, registered, and drawn.
	 */
	const uploadAndRegisterBackgroundImages = async (
		event: ChangeEvent<HTMLInputElement>,
		imageUrl?: string,
		externalScene?: IScene,
	) => {
		if (!event?.target?.files && !imageUrl) {
			return;
		}
		imagesStore.setProperty("isUploadingSmartImageMainObject", true);
		const tempEvent: ChangeEvent<HTMLInputElement> = await getChangeEventFromImageUrl(event, imageUrl);
		const originalImage = await imagesStore.registerImageAndGetUrl(!imageUrl ? event : tempEvent);

		if (
			originalImage &&
			typeof originalImage.imageUrl === "string" &&
			typeof originalImage.visualHash === "string" &&
			originalImage.imageUrl
		) {
			const backgroundRemovedResult = await imagesStore.removeImageBackground(originalImage.imageUrl);
			imagesStore.smartImageBackgroundOptions = Object.values(BackgroundOptionsEnum).map(
				(option) =>
					({
						title: option,
						imageUrl:
							option === BackgroundOptionsEnum.REPLACE_BACKGROUND && backgroundRemovedResult.imageUrl
								? backgroundRemovedResult.imageUrl
								: originalImage.imageUrl,
						visualHash: originalImage.visualHash as string,
					}) as BackgroundOption,
			);

			const originalImageObject = {
				...backgroundRemovedResult,
				imageUrl: originalImage.imageUrl,
			} as IBackgroundObject;

			imagesStore.handleSmartImageChange("originalImage", originalImageObject);
			await drawOriginalImage(originalImageObject, externalScene);
		} else {
			console.error("originalImage or fittedWithoutBackgroundImage does not have the required properties.");
		}
		imagesStore.setProperty("isUploadingSmartImageMainObject", false);
	};

	/**
	 * Gets the dimensions of a specified object relative to a reference object.
	 *
	 * @param _currentScene
	 * @param referenceObjectId - The ID of the reference object.
	 * @param object - The fabric object for which the dimensions are to be calculated.
	 * @returns An object containing the relative left, top, width, and height of the specified object.
	 */
	const getObjectDimensionsRelatively = async (_currentScene: IScene, referenceObjectId: string, object: ILayer) => {
		let referenceObject = await findObjectFromScene(_currentScene, referenceObjectId);

		if (!referenceObject) {
			console.error("object not found.");
			return null;
		}

		// Deal with the groups instead of the elements
		let outerGroup = await findObjectFromScene(_currentScene, referenceObjectId, true);
		if (outerGroup?.type?.toLowerCase() !== "group") {
			outerGroup = undefined;
		}
		if (outerGroup) {
			referenceObject = outerGroup;
		}

		const left = (object.left ?? 0) - (referenceObject.left ?? 0);
		const top = (object.top ?? 0) - (referenceObject.top ?? 0);
		const width = (object.width ?? 0) * (object.scaleX ?? 1);
		const height = (object.height ?? 0) * (object.scaleY ?? 1);

		return {
			left: left,
			top: top,
			width: width,
			height: height,
		};
	};

	/**
	 * Calculates the smart image size based on the dimensions and positions of the outer and inner rectangles.
	 *
	 * @returns The smart image size containing canvas size, original image size, and original image location, or null if calculations fail.
	 * @param _currentScene
	 */
	const getSmartImageSize = async (_currentScene: IScene) => {
		let outerRect = await findObjectFromScene(_currentScene, ObjectsEnum.OuterRectangle);
		let innerRect = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage);
		let foundOuterGroup = await findObjectFromScene(_currentScene, ObjectsEnum.OuterRectangle, true);
		let foundInnerGroup = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage, true);

		if (foundOuterGroup?.type?.toLowerCase() !== "group") {
			foundOuterGroup = undefined;
		}
		if (foundInnerGroup?.type?.toLowerCase() !== "group") {
			foundInnerGroup = undefined;
		}

		if (foundInnerGroup) {
			innerRect = foundInnerGroup;
		}

		if (foundOuterGroup) {
			outerRect = foundOuterGroup;
		}

		if (outerRect && innerRect) {
			const originalImageLocation = await getObjectDimensionsRelatively(
				_currentScene,
				ObjectsEnum.OuterRectangle,
				innerRect as ILayer,
			);

			if (originalImageLocation) {
				const smartImageSize: ISmartImageSize = {
					canvasSize: [
						(outerRect.width ?? 1) * (outerRect.scaleX ?? 1),
						(outerRect.height ?? 1) * (outerRect.scaleY ?? 1),
					],
					originalImageSize: [
						(innerRect.width ?? 1) * (innerRect.scaleX ?? 1),
						(innerRect.height ?? 1) * (innerRect.scaleY ?? 1),
					],
					originalImageLocation: [originalImageLocation.left, originalImageLocation.top],
				};
				return smartImageSize;
			}
		}
		return null;
	};

	const findObjectFromScene = async (
		scene: IScene,
		objectId: string,
		getTheGroup?: boolean,
	): Promise<Partial<ILayer> | undefined> => {
		if (!editor) return;
		for (const layer of scene.layers) {
			// Check if the object is a group
			if (layer.type?.toLowerCase() === "group") {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				for (const groupObject of layer.objects || []) {
					if (groupObject.id === objectId) {
						return getTheGroup ? layer : groupObject;
					}
				}
			} else {
				// If it's not a group, check the object directly
				if (layer.id === objectId) {
					return layer;
				}
			}
		}
	};

	const removeObjectFromScene = async (scene: IScene, objectId?: string): Promise<void> => {
		if (!objectId) return;

		for (const layer of scene.layers) {
			const index = scene.layers.indexOf(layer);
			if (layer.type?.toLowerCase() === "group") {
				if (layer.id === objectId) {
					if (index !== -1) {
						scene.layers.splice(index, 1);
					}
					break;
				} else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					const groupObjects = layer.objects || [];
					for (let i = 0; i < groupObjects.length; i++) {
						const groupObject = groupObjects[i];
						if (groupObject.id === objectId) {
							if (index !== -1) {
								scene.layers.splice(index, 1);
							}
							break;
						}
					}
				}
			} else {
				// If it's not a group, check the object directly
				if (layer.id === objectId) {
					if (index !== -1) {
						scene.layers.splice(index, 1);
					}
					break;
				}
			}
		}
		// await editor?.scene.importFromJSON(scene);
	};

	const addObjectToScene = async (scene: IScene, options: any): Promise<IScene | undefined> => {
		if (!editor) {
			return;
		}
		// Importing and exporting just to format the object
		const itemAsFabricObject = await editor.objects.importAsFabricObject(options);
		const itemLayer = (await editor.objects.exportFabricObjectAsLayer(itemAsFabricObject)) as Partial<ILayer>;

		const insertLayerAfterId = (id: string) => {
			const index = scene.layers.findIndex((layer) => layer.id === id);
			if (index !== -1) {
				scene.layers.splice(index + 1, 0, itemLayer);
			}
		};

		if (options.type.toLowerCase() === "group") {
			for (const object of options.objects || []) {
				if (object.id === ObjectsEnum.InnerRectangle) {
					insertLayerAfterId(ObjectsEnum.InitialFrame);
					return;
				} else if (object.id === ObjectsEnum.OuterRectangle) {
					const innerRectGroup = await findObjectFromScene(scene, ObjectsEnum.InnerRectangle, true);
					insertLayerAfterId(innerRectGroup?.id ?? ObjectsEnum.InitialFrame);
					return;
				}
			}
			insertLayerAfterId(ObjectsEnum.InitialFrame);
		} else {
			const outerRectGroup = await findObjectFromScene(scene, ObjectsEnum.OuterRectangle, true);
			switch (options.id) {
				case ObjectsEnum.OuterRectangle:
					insertLayerAfterId(ObjectsEnum.InitialFrame);
					break;
				case ObjectsEnum.InnerRectangle:
					insertLayerAfterId(outerRectGroup?.id ?? ObjectsEnum.InitialFrame);
					break;
				default:
					scene.layers.push(itemLayer);
					break;
			}
		}
	};

	const findGroupByObjectIdFromScene = async (scene: IScene, objectId: string): Promise<fabric.Group | null> => {
		for (const layer of scene.layers as fabric.Object[]) {
			// Check if the object is a group
			if (layer.type?.toLowerCase() === "group") {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				for (const groupObject of layer.objects || []) {
					if (groupObject.id === objectId) {
						return layer as fabric.Group;
					}
				}
			}
		}
		return null;
	};

	const findField = (itemsList: LayerText[] | LayerImage[], identifier?: string, isById: boolean = true) => {
		const index = itemsList.findIndex((item) => (isById ? item.id === identifier : item.type === identifier));
		if (index === -1) {
			return undefined;
		}
		return itemsList[index];
	};

	const extractTextConfigs = (textElement: LayerText) => {
		if (!textElement.text_configs) return {};
		const shadow: IShadow = {} as IShadow
		shadow.enabled = !!textElement.text_configs.shadow;
		shadow.blur = textElement.text_configs?.shadow?.blur ?? 0;
		shadow.color = textElement.text_configs?.shadow?.color ?? "";
		shadow.offsetX = textElement.text_configs?.shadow?.offset?.x ?? 0;
		shadow.offsetY = textElement.text_configs?.shadow?.offset?.y ?? 0;
		return {
			linethrough: textElement.text_configs?.strikethrough ?? false,
			shadow: shadow,
			backgroundColor: textElement.text_configs?.highlight_color ?? "",
		};
	}

	const updateLayerStaticContent = async (
		layer: Partial<ILayer>,
		options: CampaignCustomOptions,
	): Promise<Partial<ILayer> | undefined> => {
		if (!options) return layer;
		const hasTextType = options.texts?.some((text) => text.type);
		const hasImageType = options.images?.some((image) => image.type);
		switch (layer.type) {
			case LayerType.BACKGROUND:
				return { ...layer, fill: options.backgroundColor };

			case LayerType.STATIC_TEXT:
				const textElement =
					hasTextType && options.texts?.some((text) => text.type === (layer as IStaticText).textType)
						? findField(options.texts ?? [], (layer as IStaticText).textType, false)
						: findField(options.texts ?? [], (layer as IStaticText).id);
				if (!textElement) {
					return undefined;
				} else if (textElement.content) {
					return { ...layer, text: textElement.content, ...extractTextConfigs(textElement) };
				}
				break;

			case LayerType.STATIC_IMAGE:
				const imageElement =
					hasImageType && options.images?.some((image) => image.type === (layer as IStaticImage).imageType)
						? findField(options.images ?? [], (layer as IStaticImage).imageType, false)
						: findField(options.images ?? [], (layer as IStaticImage).id);
				if (!imageElement) {
					return undefined;
				}
				if (imageElement.content) {
					return await applyWizardImageChangeOnLayerV1(layer, imageElement.content);
				}
				break;

			default:
				return layer;
		}

		return layer;
	};

	return {
		changePage,
		drawSmartImage,
		drawOriginalImage,
		waitForRender,
		getSmartImageSize,
		initializeSmartImage,
		resetRectangleInAGroup,
		syncMissingSmartImageAndOriginalImageWithOriginalScene,
		findObjectFromScene,
		removeObjectFromScene,
		findGroupByObjectIdFromScene,
		uploadAndRegisterBackgroundImages,
		getChangeEventFromImageUrl,
		updateLayerStaticContent,
	};
};

export const useSmartImageGeneration = () => {
	const editor = useEditor();
	const { updateScenes } = useScenesUtils();
	const { imagesStore, designEditorStore, brandsDefinitionStore } = useAppStore();
	const { applyBrandConfigrationOnScene, applyWizardImagesChangesOnScene } = useDesignEditorUtils();
	const {
		drawOriginalImage,
		drawSmartImage,
		getSmartImageSize,
		findObjectFromScene,
		removeObjectFromScene,
		updateLayerStaticContent,
	} = useSmartImageUtils();
	const { scenes, setScenes, setCurrentScene, currentScene } = useDesignEditorContext();

	/**
	 * Changes the current page in the editor, updates the scenes list, and sets the current scene.
	 *
	 * @param sceneId - The page Id to switch to.
	 */
	const changePageUsingSceneId = React.useCallback(
		async (sceneId: any) => {
			if (editor) {
				const updatedTemplate = editor.scene.exportToJSON();
				const updatedPreview = await editor.renderer.render(updatedTemplate);

				const updatedScenes = scenes.map((scene) => {
					if (scene.id === updatedTemplate.id) {
						return { ...updatedTemplate, preview: updatedPreview };
					}
					return scene;
				}) as IScene[];
				const scene = updatedScenes.find((scene) => scene.id === sceneId);

				setScenes(updatedScenes);
				scene && setCurrentScene(scene);
			}
		},
		[editor, scenes, currentScene],
	);

	const extractTextCustomizations = (scene: IScene): Record<string, ITextCustomization> | undefined => {
		const textCustomizations: Record<string, ITextCustomization> = {};

		const layers = scene?.layers ?? [];
		layers.forEach((layer) => {
			if (layer.type === "StaticText" && layer.textType) {
				const { fill: color, fontSize, fontFamily } = layer as IStaticText;
				textCustomizations[layer.textType] = { color, fontSize, fontFamily };
			}
		});

		return Object.keys(textCustomizations).length > 0 ? textCustomizations : undefined;
	};

	const processImageResult = async (scene: IScene, image: EditorImage) => {
		if (!editor || !image) {
			return scene;
		}

		await drawSmartImage(image, scene);
		const updatedPreview = (await editor?.renderer.render(scene)) as string;
		return { ...scene, preview: updatedPreview } as IScene;
	};

	/**
	 * Updates the scene layers with the provided background color, texts, and images if they exist.
	 * - For background layers, updates the fill color.
	 * - For text layers, replaces the text with the new content based on provided text IDs.
	 * - For image layers, updates the image source based on provided image IDs.
	 *
	 * @param {Scene} scene - The scene object containing layers to be updated.
	 * @param options
	 * @returns {Promise<void>} - A promise that resolves when all layers have been processed.
	 */
	async function updateScenesStaticContent(scene: IScene, options?: CampaignCustomOptions): Promise<void> {
		if (options) {
			for (let i = scene.layers.length - 1; i >= 0; i--) {
				const layer = scene.layers[i];
				const updatedLayer = await updateLayerStaticContent(layer, options);
				if (updatedLayer === undefined) {
					scene.layers.splice(i, 1);
				} else {
					scene.layers[i] = updatedLayer;
				}
			}
		}
	}

	/**
	 * Processes scenes to apply a smart image and update each scene accordingly.
	 *
	 * @param smartImage - The smart image object to be applied to the scenes.
	 * @param scenes
	 * @param options
	 * @returns A promise that resolves when all scenes have been processed.
	 */
	const processScenes = async (smartImage?: ILayer, scenes?: IScene[], options?: CampaignCustomOptions) => {
		if (!editor) {
			return;
		}

		const batchSize = 4;
		let localScenes: IScene[] = await updateScenes();
		if (!localScenes && !scenes) {
			return;
		} else {
			localScenes = scenes ?? localScenes;
		}

		const newScenes = [localScenes[0]];
		const textCustomizations = extractTextCustomizations(localScenes[0]);

		// Helper function to process a scene
		const processScene = async (index: number) => {
			const scene = brandsDefinitionStore.hasBrandReset
				? localScenes[index]
				: await applyBrandConfigrationOnScene(
						brandsDefinitionStore.selectedBrand,
						localScenes[index],
						index,
						textCustomizations,
				  );
			if (designEditorStore.removeSmartImageFromAllScenes || options?.skip) {
				await removeObjectFromScene(scene, ObjectsEnum.OuterRectangle);
				await removeObjectFromScene(scene, ObjectsEnum.InnerRectangle);
				await removeObjectFromScene(scene, ObjectsEnum.SmartImage);
				await removeObjectFromScene(scene, ObjectsEnum.OriginalImage);
			}
			await updateScenesStaticContent(scene, options);
			await applyWizardImagesChangesOnScene(scene);
			const sceneInnerRectangle = await findObjectFromScene(scene, ObjectsEnum.InnerRectangle);
			const sceneOuterRectangle = await findObjectFromScene(scene, ObjectsEnum.OuterRectangle);

			// If scene has a smart image
			if (smartImage && sceneInnerRectangle && sceneOuterRectangle) {
				const originalImage = smartImage.metadata?.originalImage as any;
				await drawOriginalImage(originalImage, scene);

				const smartImageSize = await getSmartImageSize(scene);
				if (smartImageSize) {
					const operation =
						smartImage.metadata?.backgroundOption === BackgroundOptionsEnum.REPLACE_BACKGROUND &&
						(smartImage.metadata?.colorCode as string).trim() !== ""
							? BackgroundRoute.Replace
							: BackgroundRoute.Expand;

					const smartImageForm = smartImage.metadata as any;
					try {
						const image = await imagesStore.generateOrExpandImageBackground(
							operation,
							smartImageSize,
							smartImageForm,
						);

						await designEditorStore.setProperty(
							"numberOfProcessedAdvertisements",
							designEditorStore.numberOfProcessedAdvertisements + 1,
						);

						const updatedScene = await processImageResult(scene, image);
						newScenes.push(updatedScene);
					} catch (error) {
						console.error(`Error processing scene at index ${index}: ${scene.name}`, error);
						// Move to the next scene without blocking
						newScenes.push(scene);
					}

					return;
				}
			} else {
				const updatedPreview = (await editor?.renderer.render(scene)) as string;
				const updatedScene = { ...scene, preview: updatedPreview } as IScene;
				newScenes.push(updatedScene);
				await designEditorStore.setProperty(
					"numberOfProcessedAdvertisements",
					designEditorStore.numberOfProcessedAdvertisements + 1,
				);
				return;
			}

			await designEditorStore.setProperty(
				"numberOfProcessedAdvertisements",
				designEditorStore.numberOfProcessedAdvertisements + 1,
			);
			newScenes.push(scene);
		};

		// Process scenes in batches of batchSize
		for (let i = 1; i < localScenes.length; i += batchSize) {
			const batchPromises: Promise<void>[] = [];
			for (let j = i; j < i + batchSize && j < localScenes.length; j++) {
				batchPromises.push(processScene(j));
			}
			await Promise.all(batchPromises);
		}

		// Update the scenes and set the current scene after all batches are processed
		setScenes(newScenes);
		setCurrentScene(newScenes[0]);
		return [...newScenes];
	};

	/**
	 * Generates campaign smart images by processing scenes with a smart image if available.
	 *
	 * @returns A promise that resolves when the campaign smart images have been generated.
	 */
	const generateCampaignSmartImages = async (scenes?: IScene[], options?: CampaignCustomOptions) => {
		if (!editor) return;
		const _currentScene = editor.scene.exportToJSON();
		const fcSmartImage = await findObjectFromScene(_currentScene, ObjectsEnum.SmartImage);
		return await processScenes(fcSmartImage ? (fcSmartImage as ILayer) : undefined, scenes, options);
	};

	return {
		generateCampaignSmartImages,
		changePageUsingSceneId,
	};
};
