import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputBase, MenuItem, Popover } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useDesignEditorUtils from "../../../../../../CustomUtils/UseDesignEditor";
import { FONT_SIZES } from "../../../../../../constants/editor";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText } from "../../../../../../types";
import styles from "./FixedTextBoxFontSize.module.scss";

const FixedTextBoxFontSize = () => {
	const editor = useEditor();
	const activeObject = useActiveObject() as IStaticText;
	const [maximumFontSize, setMaximumFontSize] = useState<number | undefined>(
		activeObject?.maximumFontSize ?? activeObject.fontSize,
	);
	const [anchorEl, setAnchorEl] = useState(null);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const { fitTextIntoFixedTextBox } = useDesignEditorUtils();
	useEffect(() => {
		if (activeObject && activeObject.type === "StaticText") {
			// @ts-ignore
			setMaximumFontSize(activeObject.maximumFontSize ?? activeObject.fontSize);
		}
	}, [activeObject]);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const onSizeChange = (size: number) => {
		editor?.objects.update({ maximumFontSize: Number(size) });
		setMaximumFontSize(size);
		fitTextIntoFixedTextBox(activeObject as fabric.Object);
		if (inputRef.current) {
			inputRef.current.focus();
		}
		handleClose();
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		if (value === "") {
			setMaximumFontSize(NaN);
		} else {
			const newSize = Number(value);
			if (!isNaN(newSize)) {
				editor?.objects.update({ maximumFontSize: Number(newSize) });
				setMaximumFontSize(newSize);
				fitTextIntoFixedTextBox(activeObject as fabric.Object);
			}
		}
	};

	return (
		<div className={styles.textFontSizeContainer}>
			{/* Input with arrow icon inside */}
			<div className={styles.inputWrapper} onClick={handleClick}>
				<InputBase
					inputRef={inputRef}
					value={maximumFontSize !== undefined && !isNaN(maximumFontSize) ? Math.floor(maximumFontSize) : ""}
					onChange={handleInputChange}
					className={styles.input}
					type="number"
					endAdornment={
						<ExpandMoreIcon
							sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "22px" }}
							className={styles.expandIcon}
						/>
					}
				/>
			</div>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<div className={styles.popoverContent}>
					{FONT_SIZES.map((size, index) => (
						<MenuItem
							key={index}
							onClick={() => onSizeChange(size)}
							className={`${styles.menuItem} ${maximumFontSize === size ? styles.selected : ""}`}
						>
							{size}
						</MenuItem>
					))}
				</div>
			</Popover>
		</div>
	);
};

export default FixedTextBoxFontSize;
