import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RequestAccessContainer from "../../../../../components/TailoredGeneration/RequestAccessContainer/RequestAccessContainer";
import BriaAccordion from "../../../../../components/common/BriaAccordion/BriaAccordion.tsx";
import BriaButton, { ButtonTypes } from "../../../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput.tsx";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import CopyToClipboardButton from "../../../../../components/common/CopyToClipboardButton/CopyToClipboardButton.tsx";
import ImageViewer from "../../../../../components/common/Image/Image";
import LinkConstants from "../../../../../constants/LinkConstants.ts";
import RouterConstants from "../../../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import { EventLogTypesEnum } from "../../../../../models/common.ts";
import { TgProject } from "../../../Projects/store/tg-projects-store.tsx";
import { TgModel } from "../../store/tg-model-store.tsx";
import FigmaInstallationPopup from "../FigmaInstallationPopup/FigmaInstallationPopup.tsx";
import styles from "./ModelAccessPopup.module.scss";

type ModelAccessPopupProps = {
	project: TgProject;
	model: TgModel;
	disabled?: boolean;
	buttonType?: ButtonTypes;
};

const ModelAccessPopup = ({ project, model, disabled = false, buttonType = "textMedium" }: ModelAccessPopupProps) => {
	const { appStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.popups.modelAccessPopup" });
	const [openGetAccessPopup, setOpenGetAccessPopup] = useState(false);
	const [openFigmaInstallationPopup, setOpenFigmaInstallationPopup] = useState(false);
	const accessMethods = t("accessMethods", { returnObjects: true });
	const TG_TRAINING_API_ENDPOINT = `https://engine.prod.bria-api.com/v1/text-to-image/tailored/${model.id}`;

	const openBriaAPIDocs = () => {
		window.open(LinkConstants.BRIA_API_TG_GENERATE_IMAGES, "_blank");
	};

	const navigateToIframes = () => {
		window.open(RouterConstants.NEW_IFRAMES_CONFIG.fullPath, "_blank");
	};

	const downloadFigmaPlugin = async () => {
		await appStore.downloadFigmaPlugin();
	};

	const openComfyUi = () => {
		window.open(LinkConstants.BRIA_COMFYUI_API, "_blank");
	};

	const openFigmaInstallationGuide = () => {
		setOpenFigmaInstallationPopup(true);
	};

	const getAccordionContent = (method: string) => {
		switch (method) {
			case "api":
				return (
					<>
						<BriaInput
							className={styles.readOnlyInput}
							disabled={true}
							aria-readonly={true}
							value={TG_TRAINING_API_ENDPOINT}
							size="small"
						/>
						<Box className={styles.ctas}>
							<BriaButton buttonType="textMedium" onClick={openBriaAPIDocs}>
								{t(`accessMethods.${method}.secondaryBtn`)}
							</BriaButton>
							<CopyToClipboardButton
								className={styles.primaryCopy}
								buttonText={t(`accessMethods.${method}.primaryBtn`)}
								buttonType={"primaryMedium"}
								hideIcon={true}
								textToCopy={TG_TRAINING_API_ENDPOINT}
							/>
						</Box>
					</>
				);
			case "iframe":
				return (
					<Box className={styles.ctas}>
						<BriaButton buttonType="textMedium" onClick={navigateToIframes}>
							{t(`accessMethods.${method}.primaryBtn`)}
						</BriaButton>
					</Box>
				);
			case "figma":
				return (
					<Box className={styles.ctas}>
						<BriaButton buttonType="textMedium" onClick={openFigmaInstallationGuide}>
							{t(`accessMethods.${method}.secondaryBtn`)}
						</BriaButton>
						<BriaButton
							buttonType="primaryMedium"
							onClick={downloadFigmaPlugin}
							loading={appStore.isDownloadingFigmaPlugin}
							disabled={appStore.isDownloadingFigmaPlugin}
						>
							{t(`accessMethods.${method}.primaryBtn`)}
						</BriaButton>
					</Box>
				);
			case "comfyUi":
				return (
					<>
						<BriaInput
							className={styles.readOnlyInput}
							disabled={true}
							aria-readonly={true}
							value={model.id}
							size="small"
						/>
						<Box className={styles.ctas}>
							<CopyToClipboardButton
								className={styles.secondaryCopy}
								buttonText={t(`accessMethods.${method}.secondaryBtn`)}
								buttonType={"textMedium"}
								hideIcon={true}
								textToCopy={String(model.id)}
							/>
							<BriaButton buttonType="primaryMedium" onClick={openComfyUi}>
								{t(`accessMethods.${method}.primaryBtn`)}
							</BriaButton>
						</Box>
					</>
				);
		}
	};

	const getEventLogType = (method: string) => {
		switch (method) {
			case "figma":
				return EventLogTypesEnum.REQUEST_FOR_TG_FIGMA;
			case "photoshop":
				return EventLogTypesEnum.REQUEST_FOR_TG_PHOTOSHOP;
			case "model":
				return EventLogTypesEnum.REQUEST_FOR_TG_MODEL_SOURCE;
			case "copilot":
				return EventLogTypesEnum.REQUEST_FOR_TG_COPILOT;
			case "powerPoint":
				return EventLogTypesEnum.REQUEST_FOR_TG_POWERPOINT;
			default:
				return EventLogTypesEnum.REQUEST_FOR_TAILORED_MODELS;
		}
	};

	return (
		<>
			<BriaButton
				className={styles.getAccessBtn}
				disabled={disabled}
				buttonType={buttonType}
				onClick={() => setOpenGetAccessPopup(true)}
			>
				{t("getAccess")}
			</BriaButton>
			<ConfirmationPopup
				open={openGetAccessPopup}
				title={t("header")}
				maxWidth="616px"
				paperClassName={styles.paperWrap}
				description={
					<Box className={styles.popupContent}>
						<Box className={styles.subHeader}>{t("subHeader")}</Box>

						{Object.keys(accessMethods).map((method) => (
							<Box className={styles.accordionWraper}>
								{t(`accessMethods.${method}.contactUs`) === "true" && (
									<RequestAccessContainer
										className={styles.contactUsBtn}
										requestTitle={t(`contactUsPopup.title`)}
										requestMessage={t(`contactUsPopup.message`)}
										requestFeature={t(`accessMethods.${method}.feature`)}
										eventLog={{ eventType: getEventLogType(method) }}
										buttonMessage={t("contactUs")}
										buttonType={"secondarySmall"}
										showCrown={false}
										modelId={String(model.id)}
										modelName={model.name}
										projectId={String(project.id)}
										projectName={project.project_name}
									/>
								)}
								<BriaAccordion
									className={styles.accordionContainer}
									titleClass={styles.accordionTitle}
									accordionTitle={
										<Box className={styles.titleWrap}>
											<ImageViewer name={`images/svgs/TgAccessMethods/${method}_popup.svg`} />
											{t(`accessMethods.${method}.title`)}
										</Box>
									}
									disabled={t(`accessMethods.${method}.contactUs`) === "true"}
								>
									<Typography className={styles.accordionDescription}>
										{t(`accessMethods.${method}.description`)}
									</Typography>

									<Box className={styles.accordion}>{getAccordionContent(method)}</Box>
								</BriaAccordion>
							</Box>
						))}
					</Box>
				}
				hideActions
				onClose={() => setOpenGetAccessPopup(false)}
			/>

			<FigmaInstallationPopup
				open={openFigmaInstallationPopup}
				onClose={() => setOpenFigmaInstallationPopup(false)}
				onClick={downloadFigmaPlugin}
				loading={appStore.isDownloadingFigmaPlugin}
				disabled={appStore.isDownloadingFigmaPlugin}
			/>
		</>
	);
};

export default observer(ModelAccessPopup);
