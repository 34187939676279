const DiscordIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.25 6.24967C9.16667 5.41634 10.8333 5.41634 13.75 6.24967M5.83333 13.7497C8.75 14.583 11.25 14.583 14.1667 13.7497M12.9167 14.1663C12.9167 14.9997 14.1667 16.6663 14.5833 16.6663C15.8333 16.6663 16.9442 15.2772 17.5 14.1663C18.0558 12.7772 17.9167 9.30551 16.25 4.58301C15.0358 3.73717 13.75 3.46634 12.5 3.33301L11.6667 5.41634M7.08343 14.1663C7.08343 14.9997 5.95343 16.6663 5.55676 16.6663C4.36593 16.6663 3.30843 15.2772 2.77926 14.1663C2.25009 12.7772 2.38259 9.30551 3.96926 4.58301C5.12593 3.73717 6.28759 3.46634 7.50009 3.33301L8.33343 5.41634M8.33333 9.99967C8.33333 10.4599 7.96024 10.833 7.5 10.833C7.03976 10.833 6.66667 10.4599 6.66667 9.99967C6.66667 9.53944 7.03976 9.16634 7.5 9.16634C7.96024 9.16634 8.33333 9.53944 8.33333 9.99967ZM13.3333 9.99967C13.3333 10.4599 12.9602 10.833 12.5 10.833C12.0398 10.833 11.6667 10.4599 11.6667 9.99967C11.6667 9.53944 12.0398 9.16634 12.5 9.16634C12.9602 9.16634 13.3333 9.53944 13.3333 9.99967Z"
			stroke="var(--secondary-color)"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default DiscordIcon;
