import { Box, Divider, Popover, Slider, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../../../../assets/images/icons/CloseIcon";
import BriaInput from "../../../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../../../hooks/useStores";
import InputLayout from "../../../../../../../layout/InputLayout/InputLayout";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import {
	IShadow,
	IStaticCircle,
	IStaticRect,
	IStaticTriangle,
	IStaticVector,
	LayerType,
} from "../../../../../../types";
import ColorBoxPicker from "./ColorBoxPicker/ColorBoxPicker";
import styles from "./FabricShadow.module.scss";
type Props = {
	open: boolean;
	handleClose?: () => void;
	anchorEl?: HTMLButtonElement;
};

const FabricShadow = ({ open = false, handleClose, anchorEl }: Props) => {
	const { campaignStore } = useAppStore();
	const activeObject = useActiveObject() as Required<IStaticRect | IStaticCircle | IStaticTriangle | IStaticVector>;
	const [shadow, setShadow] = useState<IShadow>(activeObject?.shadow);
	const [blurChanged, setBlurChanged] = useState<boolean>(false);

	const { EditAllAds } = useDesignEditorUtils();
	const editor = useEditor();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs" });
	const isFabricShape =
		activeObject?.type === LayerType.STATIC_RECT ||
		activeObject?.type === LayerType.STATIC_CIRCLE ||
		activeObject?.type === LayerType.STATIC_TRIANGLE;

	useEffect(() => {
		let watcher = async () => {
			if (
				(activeObject && activeObject.type === LayerType.STATIC_TEXT) ||
				activeObject?.type === LayerType.STATIC_VECTOR ||
				isFabricShape
			) {
				setShadow(activeObject.shadow);
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);

	const handleShadowBlurChange = (value: number[] | number) => {
		handleShadowUpdate("blur", typeof value === "number" ? value : value[0]);
	};

	const handleShadowOffsetXChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleShadowUpdate("offsetX", e.target.value ? Number(e.target.value) : undefined);
	};

	const handleShadowOffsetYChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleShadowUpdate("offsetY", e.target.value ? Number(e.target.value) : undefined);
	};

	const handleShadowColorChange = (color: string) => {
		handleShadowUpdate("color", color);
	};

	const handleShadowUpdate = (property: keyof fabric.Shadow, value: number | number[] | string | undefined) => {
		if (editor) {
			const updatedShadow = {
				...activeObject?.shadow,
				enabled: true,
				[property]: value,
			};
			setShadow(updatedShadow);

			editor.objects.update(
				{
					shadow: updatedShadow,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(activeObject?.type, activeObject.shapeType, FeatureType.SHADOW, updatedShadow);
		}
	};

	return (
		<Popover
			disableRestoreFocus
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			slotProps={{
				paper: {
					className: styles.popover,
				},
			}}
		>
			<Box className={styles.allShadowOptionsControls}>
				<CloseIcon onClick={handleClose} className={styles.closeIcon} />
				<>
					<Box>
						<Typography className={styles.blurLabel}>{t("blur")}</Typography>
						<Box className={styles.sliderInput}>
							<Slider
								min={0}
								max={100}
								value={shadow?.blur}
								onChange={(_event, newValue) => {
									handleShadowBlurChange(Array.isArray(newValue) ? newValue : [newValue]);
								}}
								className={styles.slider}
							/>

							<BriaInput
								type="number"
								value={shadow?.blur}
								height="19px"
								className={styles.input}
								inputProps={{ className: styles.inputBase }}
								onChange={(e) => {
									handleShadowBlurChange(Number(e.target.value) < 100 ? Number(e.target.value) : 100);
									setBlurChanged(!blurChanged);
								}}
							/>
						</Box>
						<Divider
							className={clsx({
								[styles.divider]: true,
							})}
						/>
						<Typography className={styles.blurColorLabel}>{t("blurcolor")}</Typography>
						<ColorBoxPicker
							withPadding={false}
							color={shadow?.color}
							onColorChange={(color) => {
								handleShadowColorChange(color);
							}}
						/>
						<Divider
							className={clsx({
								[styles.divider]: true,
							})}
						/>
						<Typography className={styles.blurColorLabel}>{t("offset")}</Typography>
						<InputLayout showLabel={false} className={styles.inputLayout}>
							<Box className={styles.offset}>
								<Box className={styles.offsetContainer}>
									<Typography>{t("offsetXLabel")}</Typography>
									<BriaInput
										value={shadow?.offsetX}
										onChange={handleShadowOffsetXChange}
										type="number"
										customEndAdornmentText="px"
									/>
								</Box>
								<Box className={styles.offsetContainer}>
									<Typography>{t("offsetYLabel")}</Typography>
									<BriaInput
										value={shadow?.offsetY}
										onChange={handleShadowOffsetYChange}
										type="number"
										customEndAdornmentText="px"
									/>
								</Box>
							</Box>
						</InputLayout>
					</Box>
				</>
			</Box>
		</Popover>
	);
};

export default FabricShadow;
