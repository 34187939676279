import { Box, FormControlLabel, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import InfoIcon from "../../../assets/images/icons/InfoIcon";
import BriaTooltip from "../BriaTooltip/BriaTooltip";
import styles from "./CheckboxInput.module.scss";

interface IProps {
	label?: string;
	labelClassName?: string;
	info?: string;
	className?: string;
	checkbox: React.ReactElement<any, any>;
	validateOn?: boolean;
	validationMessage?: string;
}

const CheckboxInput = ({
	label,
	labelClassName,
	info,
	validateOn = false,
	validationMessage = "",
	className,
	checkbox,
}: IProps) => {
	return (
		<Box className={`${className} ${styles.container}`}>
			<FormControlLabel
				className={`${labelClassName ? styles[labelClassName] : ""} ${styles.checkboxLabel}`}
				label={label}
				control={checkbox}
			/>
			{info && (
				<BriaTooltip
					title={info}
					className={styles.info}
					componentsProps={{ tooltip: { className: styles.tooltip } }}
					displayOn="click"
				>
					<InfoIcon className={styles.infoIcon} />
				</BriaTooltip>
			)}
			{validateOn && <Typography className={`${styles.inputError}`}>{validationMessage}</Typography>}
		</Box>
	);
};

export default observer(CheckboxInput);
