import BrandCharacters from "../assets/images/Brand_Characters.png";
import GameAssets from "../assets/images/Game_Assets.png";
import IconPackages from "../assets/images/Icon_Packages.png";
import IntegrateEverywhere from "../assets/images/Integrate_Everywhere.png";
import MonetizeTrainingGeneration from "../assets/images/Monetize_Training_Generation.png";
import RouterConstants from "./RouterConstants";

export interface ITailoredModelsFeaturesConstants {
	title: string;
	link?: any;
	image?: string;
}

export const TailoredModelsFeatures: ITailoredModelsFeaturesConstants[] = [
	{
		title: "brandCharacters",
		link: RouterConstants.TG_MODELS.fullPath,
		image: BrandCharacters,
	},
	{
		title: "gameAssets",
		link: RouterConstants.TG_MODELS.fullPath,
		image: GameAssets,
	},
	{
		title: "iconPackages",
		link: RouterConstants.TG_MODELS.fullPath,
		image: IconPackages,
	},
	{
		title: "integrateEverywhere",
		link: RouterConstants.TG_MODELS.fullPath,
		image: IntegrateEverywhere,
	},
	{
		title: "monetizeTrainingGeneration",
		link: RouterConstants.TG_MODELS.fullPath,
		image: MonetizeTrainingGeneration,
	},
];
