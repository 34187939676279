import { Close } from "@mui/icons-material";
import { IconButton, debounce } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React, { useCallback, useState } from "react";
import SearchIcon from "../../../assets/images/icons/SearchIcon";
import styles from "./DebouncedInput.module.scss";

export type DebouncedInputProps = {
	debounceDuration?: number;
	searchIcon?: boolean;
	height?: string;
	placeHolder?: string;
} & TextFieldProps;

const DebouncedInput = ({
	debounceDuration = 700,
	searchIcon = false,
	height = "40px",
	value,
	onChange,
	placeHolder,
	InputProps,
	...rest
}: DebouncedInputProps) => {
	const [localValue, setLocalValue] = useState(value || "");

	const eraseSearch = () => {
		setLocalValue("");
		onChange?.({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
	};

	const handleDebouncedChange = useCallback(
		debounce((e) => onChange?.(e), debounceDuration),
		[],
	);
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLocalValue(e.target.value);
		handleDebouncedChange(e);
	};

	return (
		<TextField
			placeholder={placeHolder ? placeHolder : ""}
			value={localValue}
			onChange={handleChange}
			InputProps={{
				style: {
					height,
					paddingLeft: "8px",
					fontSize: "14px",
				},
				startAdornment: searchIcon && <SearchIcon className={styles.searchIconStyle} />,
				endAdornment: localValue && (
					<IconButton disableRipple size="small" onClick={eraseSearch}>
						<Close sx={{ fontSize: "16px" }} />
					</IconButton>
				),
				...InputProps,
			}}
			{...rest}
		/>
	);
};

export default DebouncedInput;
