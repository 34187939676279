import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSummary } from "@mui/material";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import { ReactNode } from "react";

type BriaAccordionProps = {
	accordionTitle: ReactNode;
	titleClass?: string;
	primaryButton?: ReactNode;
} & AccordionProps;

const BriaAccordion = ({ accordionTitle, titleClass, children, ...rest }: BriaAccordionProps) => {
	return (
		<Accordion {...rest} disableGutters elevation={0} square>
			<AccordionSummary className={titleClass} expandIcon={<ExpandMoreIcon />}>
				{accordionTitle}
			</AccordionSummary>
			{children}
		</Accordion>
	);
};

export default BriaAccordion;
