import Brands from "./Brands/Brands";
import CanvasColor from "./CanvasColor/CanvasColor";
import CanvasFill from "./CanvasFill";
import Customize from "./Customize";
import Elements from "./Elements";
import FontSelector from "./FontSelector/FontSelector";
import Graphics from "./Graphics/Graphics";
import Images from "./Images/Images.tsx";
import InsertText from "./InsertText/InsertText.tsx";
import Layers from "./Layers/Layers.tsx";
import PathFill from "./PathFill";
import Resize from "./Resize/Resize.tsx";
import ShapeFillColor from "./ShapeFillColor/ShapeFillColor";
import StrokeColor from "./StrokeColor/StrokeColor";
import Templates from "./Templates/Templates";
import Text from "./Text/Text";
import TextEffects from "./TextEffects";
import TextFill from "./TextFill/TextFill";
import TextHighlighter from "./TextHighlighter/TextHighlighter";
import TextProperties from "./TextProperties";
import Uploads from "./Uploads";
import WizardImages from "./WizardImages/WizardImages";

export default {
	StaticText: TextProperties,
	Text: Text,
	Customize: Customize,
	FontSelector: FontSelector,
	Templates: Templates,
	Uploads: Uploads,
	Images: Images,
	Elements: Elements,
	Graphics: Graphics,
	Layers: Layers,
	Campaigns: Templates,
	TextFill: TextFill,
	TextHighlighter: TextHighlighter,
	PathFill: PathFill,
	CanvasFill: CanvasFill,
	TextEffects: TextEffects,
	Brands: Brands,
	Resize: Resize,
	CanvasColor: CanvasColor,
	ShapeFillColor: ShapeFillColor,
	InsertText: InsertText,
	WizardImages: WizardImages,
	StrokeColor: StrokeColor,
};

export interface BackgroundOption {
	title: BackgroundOptionsEnum;
	imageUrl: string;
	visualHash: string;
}

export interface IBackgroundObject {
	imageUrl: string;
	boundingBox: IBoundingBox;
	size: ImageSize;
}

export enum ObjectsEnum {
	InitialFrame = "background",
	OuterRectangle = "outerRectangle",
	InnerRectangle = "innerRectangle",
	SmartImage = "smartImage",
	OriginalImage = "originalImage",
	FRAME = "frame",
}

export enum BackgroundOptionsEnum {
	EXPAND_BACKGROUND = "Expand Background",
	REPLACE_BACKGROUND = "Replace Background",
}

export enum BackgroundPanelEnum {
	GeneratePanel = "generate",
	SolidPanel = "solid",
}

export enum SecondaryTabTypeEnum {
	SMART_IMAGE = "SMART_IMAGE",
	SHARED_IMAGES = "SHARED_IMAGES",
	USER_IMAGES = "USER_IMAGES",
	UNSPLASH = "UNSPLASH",
	LOGOS = "LOGOS",
}

export enum BackgroundRoute {
	Expand = "/expand",
	Replace = "/replaceV1",
}

export enum ImageContainerSize {
	Fill = "fill",
	Original = "original",
}

export const defaultSmartForm = {
	seed: 0,
	sid: "",
	originalImage: {
		imageUrl: "",
		boundingBox: { left: 0, top: 0, width: 1, height: 1 } as IBoundingBox,
		size: { width: 1, height: 1 } as ImageSize,
	},
	backgroundOption: BackgroundOptionsEnum.EXPAND_BACKGROUND,
	backgroundDescription: "",
	colorCode: "",
};

export type ImageType = {
	imageUrl: string | undefined;
	visualHash: string | undefined;
};

export type ISmartImageParams = {
	seed: number;
	sid: string;
	originalImage: IBackgroundObject;
	backgroundOption: BackgroundOptionsEnum;
	backgroundDescription: string;
	colorCode: string;
};

export type ISmartImageSize = {
	canvasSize: number[];
	originalImageSize: number[];
	originalImageLocation: number[];
};

export type IBoundingBox = {
	left: number;
	top: number;
	width: number;
	height: number;
};

export type ImageSize = {
	width: number;
	height: number;
};
