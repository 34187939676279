import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../../../hooks/useStores";
import EditorLayout from "../../../../layout/EditorLayout/EditorLayout";
import CreateTgModelDataset from "../../Projects/Viewer/Models/Create/Dataset/CreateTgModelDataset";
import CreateTgModelPopup from "../Popups/CreateModel/CreateTgModelPopup";
import styles from "./CreateTgModel.module.scss";
import SelectTgDatasetStep from "./Dataset/SelectTgDatasetStep";
import TgTrainingVersionStep from "./TrainingVersion/TgTrainingVersionStep.tsx";

const CreateTgModel = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.create" });
	const { tgStore } = useAppStore();
	const { tgModelStore, tgDatasetStore } = tgStore;
	const [currentPanel, setCurrentPanel] = useState(2);
	const [openCreateModelPopup, setOpenCreateModelPopup] = useState(false);
	const stillUploadingImages = tgDatasetStore.datasetForm.images.filter((img) => img.uploading);
	const uploadedImages = tgDatasetStore.datasetForm.images.filter((img) => !img.uploading && !img.error);
	const loadingDatasetSave =
		tgDatasetStore.loadingUpdateDataset ||
		tgDatasetStore.loadingDuplicateDataset ||
		tgDatasetStore.loadingUploadImages ||
		tgDatasetStore.loadingRegenerate;

	useEffect(() => {
		return () => {
			tgModelStore.isDatasetFromExisting = false;
		};
	}, []);

	const handleNext = () => {
		setCurrentPanel((prev) => prev + 1);
	};

	return (
		<>
			<EditorLayout
				childrenClassName={styles.wrapper}
				name={tgModelStore.modelForm?.name || t("title")}
				loadingSave={loadingDatasetSave}
				progress={(uploadedImages.length / tgDatasetStore.datasetForm.images.length) * 100}
				primaryButton={
					currentPanel === 4 && (
						<>
							{stillUploadingImages.length ? (
								<>
									<Typography className={styles.uploadLoader}>{t("uploadLoader")}</Typography>
									<Typography className={styles.uploadLoaderCount}>
										{`${uploadedImages.length}/${tgDatasetStore.datasetForm.images.length}`}
									</Typography>
								</>
							) : (
								<BriaButton
									buttonType="primaryMedium"
									onClick={() => setOpenCreateModelPopup(true)}
									disabled={tgDatasetStore.loadingDuplicateDataset || !uploadedImages.length}
								>
									{t("primaryButton")}
								</BriaButton>
							)}
						</>
					)
				}
				primaryButtonClick={() => setOpenCreateModelPopup(true)}
			>
				{currentPanel === 1 ? (
					<>{/* <TgTrainingTypeStep {...{ handleNext }} /> */}</>
				) : currentPanel === 2 ? (
					<TgTrainingVersionStep {...{ handleNext }} />
				) : currentPanel === 3 ? (
					<SelectTgDatasetStep {...{ handleNext }} />
				) : (
					currentPanel === 4 && <CreateTgModelDataset />
				)}
			</EditorLayout>
			<CreateTgModelPopup open={openCreateModelPopup} onClose={() => setOpenCreateModelPopup(false)} />
		</>
	);
};

export default observer(CreateTgModel);
