import { makeAutoObservable, runInAction } from "mobx";
import { BackendError } from "../../../../config/axios";
import QueryService from "../../../../utils/QueryService";
import { ITgStore } from "../../store/new-tg-stores";

export interface ITgPlaygroundStore {
	playgroundForm: TgPlaygroundForm;
	formErrors: { generate?: BackendError };
	playgroundImages: TgPlaygroundImage[];

	loadingGenerate: boolean;

	handleFormChange: <K extends keyof TgPlaygroundForm>(key: K, value: TgPlaygroundForm[K]) => void;
	generate: (modelId: string) => Promise<TgPlaygroundImage[]>;
	resetPlayground: () => void;
}

export class TgPlaygroundStore implements ITgPlaygroundStore {
	private queryService: QueryService = new QueryService("/new-tailored-generation/models");
	tgStore: ITgStore;

	playgroundForm: TgPlaygroundForm = defaultTgPlaygroundForm;
	formErrors: { generate?: BackendError } = {};
	playgroundImages: TgPlaygroundImage[] = [];

	loadingGenerate: boolean = false;

	constructor(tgStore: ITgStore) {
		makeAutoObservable(this);
		this.tgStore = tgStore;
	}

	handleFormChange = <K extends keyof TgPlaygroundForm>(key: K, value: TgPlaygroundForm[K]) => {
		this.playgroundForm = { ...this.playgroundForm, [key]: value };
	};

	generate = async (modelId: string) => {
		try {
			this.loadingGenerate = true;

			const images: TgPlaygroundImage[] = await this.queryService.post(
				`/${modelId}/generate`,
				this.playgroundForm,
			);

			runInAction(() => {
				this.playgroundImages = images.map((img) => ({ ...img, loading: true }));
				this.loadingGenerate = false;
			});

			return images;
		} catch (err: any) {
			this.loadingGenerate = false;
			this.formErrors.generate = {
				message: err.response.data.message,
				status: err.response.status,
			};
			return Promise.reject(err);
		}
	};

	resetPlayground = () => {
		this.playgroundForm = defaultTgPlaygroundForm;
		this.playgroundImages = [];
	};
}

export type TgPlaygroundForm = {
	prompt: string;
	model_influence: number;
	num_steps: number;
	fast?: boolean;
	include_generation_prefix?: boolean;
};

export type TgPlaygroundImage = {
	url: string;
	seed: number;
	loading?: boolean;
};

export const defaultTgPlaygroundForm: TgPlaygroundForm = {
	prompt: "",
	model_influence: 1,
	num_steps: NaN,
	include_generation_prefix: false,
};
