import { fabric } from "fabric";
import { groupBy } from "lodash";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../hooks/useStores";
import { BrandLogo } from "../../models/brandLogo";
import { BrandText } from "../../models/brandText";
import { HorizontalEnum, LogoTypeEnum, VerticalEnum } from "../../models/common";
import { Font } from "../../models/font";
import useImageUtils from "../../utils/useImageUtils.tsx";
import { DesignEditorContext } from "../contexts/DesignEditor";
import useDesignEditorPages from "../hooks/useDesignEditorScenes";
import { useEditor } from "../hooks/useEditor";
import { IDesign, ITextCustomization } from "../interfaces/DesignEditor.ts";
import { FontItem } from "../interfaces/common";
import { BrandDefinition } from "../models/brandDefinition";
import { Image } from "../models/image.ts";
import {
	IBackground,
	ILayer,
	IScene,
	IStaticCircle,
	IStaticImage,
	IStaticRect,
	IStaticText,
	IStaticTriangle,
	IStaticVector,
	LayerType,
} from "../types";
import { loadFonts } from "../utils/fonts";
import { OBJECT_TYPES } from "../views/DesignEditor/components/ContextMenu/ContextMenusData.tsx";
import { ImageSize, ObjectsEnum } from "../views/DesignEditor/components/Panels/panelItems/index.ts";
import { getImageDimensionsByImageUrl, getImageToRectScaleFactor } from "../views/DesignEditor/utils/smartImageUtils";

type DesignEditorUtils = {
	EditAllAds: (layerType: string, type: string, featureToEdit: string, value: any) => void;
	editBrandConfigrationsInAllAds: (
		activeObject: any,
		property: string,
		newValue: string | number | undefined,
	) => void;
	applyBrandConfigration: (brand: BrandDefinition, scenes: IScene[]) => void;
	applyBrandConfigrationOnScene: (
		brand: BrandDefinition,
		scene: IScene,
		sceneIndex: number,
		textCustomization?: Record<string, ITextCustomization>,
	) => Promise<IScene>;
	applyWizardImagesChangesOnScene: (
		scene: IScene,
		imageUrl?: string,
		currentActiveImage?: Partial<ILayer>,
	) => Promise<void>;
	makeDownloadTemplate: () => void;
	updateAllScenes: () => Promise<void>;
	addImageObjectToCanvas: (image: Image) => void;
	getRgbAndOpacityFromRgba: (color: string) => { opacity: number; rgb: string } | undefined;
	updateColorOpacity: (color: string, opacity: number) => string;
	resetWizardImages: () => void;
	applyWizardImageChangeOnLayerV1: (destinationLayer: Partial<ILayer>, imageUrl: string) => Promise<any>;
	fitTextIntoFixedTextBox: (object: fabric.Object) => void;
};

const useDesignEditorUtils = (): DesignEditorUtils => {
	const scenes = useDesignEditorPages();
	const editor = useEditor();
	const { campaignStore, brandsDefinitionStore, designEditorStore } = useAppStore();
	const { setScenes, currentDesign, setCurrentScene } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.dropDownActions" });
	const { convertS3UriToHttpsUrl, isS3Uri, convertS3UriToHttpsCloudFrontUrl } = useImageUtils();
	const makeDownloadTemplate = () => {
		const makeDownload = (data: IDesign) => {
			const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
			const virtualAnchorLink = document.createElement("a");
			virtualAnchorLink.href = dataStr;
			virtualAnchorLink.download = "template.json";
			virtualAnchorLink.click();
		};
		const currentScene = editor && editor.scene.exportToJSON();
		if (currentScene) {
			const updatedScenes = scenes.map((scn) => {
				if (scn.id === currentScene.id) {
					return {
						id: currentScene.id,
						layers: currentScene.layers,
						name: currentScene.name,
					};
				}
				return {
					id: scn.id,
					layers: scn.layers,
					name: scn.name,
				};
			});
			if (currentDesign) {
				const graphicTemplate: IDesign = {
					id: currentDesign.id,
					name: currentDesign.name,
					frame: currentDesign.frame,
					scenes: updatedScenes,
					metadata: {},
					preview: "",
				};
				makeDownload(graphicTemplate);
			} else {
				console.log("NO CURRENT DESIGN");
			}
		}
	};
	const EditAllAds = async (layerType: string, type: string, featureToEdit: string, value: any) => {
		if (campaignStore.isEditingAllAds) {
			editor?.canvas.canvas.getObjects().forEach((object: fabric.Object) => {
				if (
					object.type === LayerType.STATIC_TEXT &&
					type == object.textType &&
					featureToEdit &&
					type !== t("configureHeadingLevel")
				) {
					EditTextElementsinAllAds(object, featureToEdit, value, true);
				} else if (
					object.type === LayerType.STATIC_VECTOR &&
					type &&
					type == object.shapeType &&
					featureToEdit &&
					type !== t("configureShapeLevel")
				) {
					EditShapeElementsinAllAds(object, featureToEdit, value);
				} else if (
					(object.type === LayerType.STATIC_CIRCLE ||
						object.type === LayerType.STATIC_RECT ||
						object.type === LayerType.STATIC_TRIANGLE) &&
					type &&
					type == object.shapeType &&
					featureToEdit &&
					type !== t("configureBasicShapeLevel")
				) {
					EditFabricShapeElementsinAllAds(object, featureToEdit, value);
				} else if (
					object.type === LayerType.STATIC_IMAGE &&
					type &&
					type == object.imageType &&
					featureToEdit &&
					type !== t("configureImageLevel")
				) {
					EditImageElementsinAllAds(object, featureToEdit, value);
				}
			});

			scenes.map((scene) => {
				scene.layers.forEach(async (layer) => {
					if (layer.type === layerType && featureToEdit) {
						switch (layerType) {
							case LayerType.STATIC_TEXT:
								if (type && type === layer.textType && type !== t("configureHeadingLevel")) {
									EditTextElementsinAllAds(layer as IStaticText, featureToEdit, value);
								}
								break;
							case LayerType.BACKGROUND:
								EditCanvasElementsinAllAds(layer as IBackground, featureToEdit, value);
								break;
							case LayerType.STATIC_VECTOR:
								if (type && type === layer.shapeType && type !== t("configureShapeLevel")) {
									EditShapeElementsinAllAds(layer as IStaticVector, featureToEdit, value);
								}
								break;
							case LayerType.STATIC_RECT || LayerType.STATIC_CIRCLE || LayerType.STATIC_TRIANGLE:
								if (type && type === layer.shapeType && type !== t("configureBasicShapeLevel")) {
									EditFabricShapeElementsinAllAds(
										layer as IStaticCircle | IStaticRect | IStaticTriangle,
										featureToEdit,
										value,
									);
								}
								break;
							case LayerType.STATIC_IMAGE:
								if (type && type === layer.imageType && type !== t("configureImageLevel")) {
									EditImageElementsinAllAds(layer as IStaticImage, featureToEdit, value);
								}
								break;
							default:
								break;
						}
					}
				});
			});
			editor?.history.save();
			await updateAllScenes();
		}
	};

	const EditTextElementsinAllAds = async (
		object: fabric.Object | IStaticText,
		featureToEdit: string,
		value: any,
		current = false,
		addBrandId = false,
	) => {
		if (!addBrandId) {
			object.brandId = undefined;
		}
		switch (featureToEdit) {
			case FeatureType.COLOR:
				object.fill = value;
				if (current) editor?.objects?.update?.({ fill: value }, object.id, false);
				if (addBrandId) {
					object.brandId = brandsDefinitionStore.selectedBrand.id;
				}
				break;
			case FeatureType.UNDERLINE:
				if (current)
					editor?.objects.update(
						{
							underline: value,
						},
						object.id,
						false,
					);
				object.underline = value;
				break;
			case FeatureType.LINETHROUGH:
				if (current)
					editor?.objects.update(
						{
							linethrough: value,
						},
						object.id,
						false,
					);
				object.linethrough = value;
				break;
			case FeatureType.TEXTHIGHLIGHTER:
				if (current)
					editor?.objects.update(
						{
							textBackgroundColor: value,
						},
						object.id,
						false,
					);
				object.textBackgroundColor = value;
				break;
			case FeatureType.BOLD:
				if (current)
					editor?.objects.update(
						{
							fontWeight: value,
						},
						undefined,
						false,
					);
				object.fontWeight = value;
				break;
			case FeatureType.ITALIC:
				if (current)
					editor?.objects.update(
						{
							fontStyle: value,
						},
						undefined,
						false,
					);
				object.fontStyle = value;
				break;
			case FeatureType.TEXT_ALIGN:
				if (current) editor?.objects.update({ textAlign: value }, undefined, false);
				object.textAlign = value;
				break;
			case FeatureType.CHAR_SPACING:
				object.charSpacing = value;
				if (current)
					editor?.objects.update(
						{
							[featureToEdit]: value,
						},
						undefined,
						false,
					);
				break;
			case FeatureType.LINE_HEIGHT:
				object.lineHeight = value;
				if (current)
					editor?.objects.update(
						{
							[featureToEdit]: value,
						},
						undefined,
						false,
					);
				break;
			case FeatureType.LETTER_CASE:
				if (value === "uppercase") {
					object.text = object.text.toUpperCase();
				} else {
					object.text = object.text.toLowerCase();
				}
				break;
			case FeatureType.TEXT:
				object.text = value;
				if (current)
					editor?.objects.update(
						{
							text: value,
						},
						undefined,
						false,
					);
				if (
					object?.type === LayerType.STATIC_TEXT &&
					object.fixedSize &&
					object.fixedWidthValue &&
					object.fixedHeightValue
				) {
					fitTextIntoFixedTextBox(object as fabric.Object);
				}
				break;
			case FeatureType.FONTSIZE:
				if (
					object?.type === LayerType.STATIC_TEXT &&
					object.fixedSize &&
					object.fixedWidthValue &&
					object.fixedHeightValue
				) {
					object.fontSize = value;
					if (current)
						editor?.objects.update(
							{
								fontSize: value,
							},
							undefined,
							false,
						);
				}
				break;
			case FeatureType.FONT:
				object.fontFamily = value.name;
				object.fontURL = value.url;
				if (current)
					editor?.objects.update(
						{
							fontFamily: value.name,
							fontURL: value.url,
						},
						undefined,
						false,
					);
				break;
			case FeatureType.BRAND_COLOR:
				object.colorNumber = value;
				break;
			case FeatureType.BRAND_FONT:
				object.fontType = value;
				break;
			case FeatureType.FIXEDTEXTBOX:
				if (current)
					editor?.objects.update(
						{
							fixedSize: value,
						},
						object.id,
						false,
					);
				object.fixedSize = value;
				break;
			case FeatureType.FIXEDSIZES:
				if (current)
					editor?.objects.update(
						{
							fixedWidthValue: value.fixedWidth,
							fixedHeightValue: value.fixedHeight,
							width: value.fixedWidth,
							height: value.fixedHeight,
						},
						object.id,
						false,
					);
				object.fixedWidthValue = value.fixedWidth;
				object.fixedHeightValue = value.fixedHeight;
				object.width = value.fixedWidth;
				object.height = value.fixedHeight;
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const EditCanvasElementsinAllAds = async (
		object: fabric.Object | IBackground,
		featureToEdit: string,
		value: any,
		addBrandId = false,
	) => {
		switch (featureToEdit) {
			case FeatureType.COLOR:
				object.fill = value;
				object.brandId = undefined;
				if (addBrandId) object.brandId = brandsDefinitionStore.selectedBrand.id;
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const EditShapeElementsinAllAds = async (
		object: fabric.Object | IStaticVector,
		featureToEdit: string,
		value: any,
		addBrandId = false,
	) => {
		switch (featureToEdit) {
			case FeatureType.COLOR:
				const objects = object._objects?.[0]?._objects;
				const objectColors = groupBy(objects, FeatureType.FILL);
				campaignStore.vectorColors = {
					...campaignStore.vectorColors,
					colors: Object.keys(objectColors),
					colorMap: object.colorMap,
				};

				Object.keys(campaignStore.vectorColors.colorMap).map((color, _index) => {
					campaignStore.prevColor = color;
				});
				object?.updateLayerColor?.(campaignStore.prevColor, value);
				object.fill = value;
				if (object.strokeOnly) object.stroke = value;
				object.colorMap[campaignStore.prevColor] = value;
				object.brandId = undefined;
				if (addBrandId) {
					object.brandId = brandsDefinitionStore.selectedBrand.id;
				}
				break;
			case FeatureType.BRAND_COLOR:
				object.colorNumber = value;
				break;
			case FeatureType.SHADOW:
				if (!value.enabled) {
					object.shadow = undefined;
				} else {
					object.shadow = value;
				}
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const EditFabricShapeElementsinAllAds = async (
		object: fabric.Object | IStaticCircle | IStaticRect | IStaticTriangle,
		featureToEdit: string,
		value: any,
		addBrandId = false,
	) => {
		switch (featureToEdit) {
			case FeatureType.COLOR:
				object.fill = value;
				object.brandId = undefined;
				if (addBrandId) {
					object.brandId = brandsDefinitionStore.selectedBrand.id;
				}
				break;
			case FeatureType.BRAND_COLOR:
				object.colorNumber = value;
				break;
			case FeatureType.STROKE_WIDTH:
				object.strokeWidth = value;
				break;
			case FeatureType.RADIUS:
				object.rx = value;
				object.ry = value;
				break;
			case FeatureType.STROKE_DASH_ARRAY:
				object.strokeDashArray = value;
				break;
			case FeatureType.STROKE_COLOR_NUMBER:
				object.strokeColorNumber = value;
				break;
			case FeatureType.STROKE:
				object.stroke = value;
				break;
			case FeatureType.SHADOW:
				if (!value.enabled) {
					object.shadow = undefined;
				} else {
					object.shadow = value;
				}
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const EditImageElementsinAllAds = async (
		object: fabric.Object | IStaticImage,
		featureToEdit: string,
		value: any,
	) => {
		switch (featureToEdit) {
			case FeatureType.FLIPX:
				object.flipX = value;
				break;
			case FeatureType.FLIPY:
				object.flipY = value;
				break;
			case FeatureType.BRAND_LOGO:
				object.logoType = value;
				break;
			case FeatureType.HORIZONTAL_ALIGNMENT:
				object.horizontalAlignment = value;
				break;
			case FeatureType.VERTICAL_ALIGNMENT:
				object.verticalAlignment = value;
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const updateAllScenes = async () => {
		const new_scenes: IScene[] = [];
		for (const scene of scenes) {
			const updatedPreview = (await editor?.renderer.render(scene)) as string;
			const updatedScene = { ...scene, preview: updatedPreview };
			new_scenes.push(updatedScene);
		}
		scenes.forEach((scene, index) => {
			scene.preview = new_scenes[index].preview;
		});
		// Commenting it out for the border popover issue, will revisit it in case any issue happned
		// setScenes(new_scenes);
	};

	const applyBrandConfigration = async (brand: BrandDefinition, scenes: IScene[]) => {
		if (campaignStore.isEditingAllAds) {
			const new_scenes: IScene[] = [];
			for (let sceneIndex = 0; sceneIndex < scenes.length; sceneIndex++) {
				const updatedScene = await applyBrandConfigrationOnScene(brand, scenes[sceneIndex], sceneIndex);
				new_scenes.push(updatedScene);
			}
			setScenes(new_scenes);
			setCurrentScene(new_scenes[0]);
		} else {
			const currentSce = editor?.scene.exportToJSON();
			let currentSceneIdx = 0;
			for (let sceneIndex = 0; sceneIndex < scenes.length; sceneIndex++) {
				if (currentSce?.id === scenes[sceneIndex].id) currentSceneIdx = sceneIndex;
			}
			if (currentSce) {
				scenes[currentSceneIdx] = await applyBrandConfigrationOnScene(
					brand,
					scenes[currentSceneIdx],
					currentSceneIdx,
				);
				setScenes(scenes);
				setCurrentScene(scenes[currentSceneIdx]);
			}
		}
	};

	const applyBrandConfigrationOnScene = async (
		brand: BrandDefinition,
		scene: IScene,
		sceneIndex: number,
		textCustomization?: Record<string, ITextCustomization>,
	) => {
		const selectedBrand = brand;
		let fonts: { [key: string]: Font | undefined } = {};
		if (!selectedBrand) {
			return scene;
		}

		const colors = selectedBrand.color_pallete?.colors || [];
		if (selectedBrand?.brand_texts) {
			fonts = selectedBrand.brand_texts.reduce(
				(fontOptions: { [key: string]: Font | undefined }, font: BrandText) => {
					fontOptions[font?.type] = font.font;
					return fontOptions;
				},
				{},
			);
		}

		const logos: { [key in LogoTypeEnum]: string } =
			selectedBrand?.logos?.reduce(
				(logosOptions: { [key in LogoTypeEnum]: string }, logo: BrandLogo) => {
					logosOptions[logo?.type] = logo.src;
					return logosOptions;
				},
				{} as { [key in LogoTypeEnum]: string },
			) || defaultLogos;

		for (let i = 0; i < scene.layers.length; i++) {
			const layer = scene.layers[i];
			if (layer.type === LayerType.STATIC_IMAGE) {
				const updatedLayer = await applyImageConfig(layer as IStaticImage, logos, sceneIndex);
				if (updatedLayer) {
					scene.layers[i] = updatedLayer;
				}
			} else if (layer.type === LayerType.STATIC_TEXT) {
				await applyTextConfig(layer as IStaticText, fonts, colors, false, textCustomization);
			} else if (layer.type === LayerType.STATIC_VECTOR) {
				applyVectorConfig(layer as IStaticVector, colors);
			} else if (
				layer.type === LayerType.STATIC_CIRCLE ||
				layer.type === LayerType.STATIC_TRIANGLE ||
				layer.type === LayerType.STATIC_RECT
			) {
				applyFabricShapesConfig(layer as IStaticCircle | IStaticRect | IStaticTriangle, colors);
			} else if (layer.type === LayerType.BACKGROUND && !designEditorStore.canvasColorAppliedOnWizard) {
				applyBackgroundConfig(layer as IBackground, colors);
			}
		}

		const updatedPreview = (await editor?.renderer.render(scene)) as string;
		return { ...scene, preview: updatedPreview };
	};

	const applyTextConfig = async (
		object: IStaticText,
		fonts: { [key: string]: Font | undefined },
		colors: any,
		current = false,
		textCustomization?: Record<string, ITextCustomization>,
	) => {
		if (object.textType && textCustomization?.[object.textType]) {
			// Customization from the campaign wizard takes priority over the brand's text definition
			const { color, fontFamily } = textCustomization[object.textType];
			EditTextElementsinAllAds(object, FeatureType.COLOR, color, current, true);
			Object.assign(object, {
				fontFamily,
				fill: color,
			});
		} else {
			const { colorNumber, fontType } = object;
			if (colorNumber && colorNumber >= 1 && colorNumber <= 6 && colors[colorNumber - 1]) {
				EditTextElementsinAllAds(object, FeatureType.COLOR, colors[colorNumber - 1], current, true);
			}

			if (fontType && fonts && fonts[fontType]) {
				const fontOption = fonts[fontType];
				const font = {
					name: fontOption?.name,
					url: fontOption?.src && convertS3UriToHttpsUrl(fontOption?.src),
				};
				object.fontFamily = font.name;
				object.fontURL = font.url;
				await loadFonts([font as FontItem]);
				object.brandId = brandsDefinitionStore.selectedBrand.id;
				if (current) {
					editor?.objects.update(
						{
							fontFamily: font.name,
							fontURL: font.url,
						},
						object.id,
						false,
					);
				}
			}
		}
	};

	const applyVectorConfig = (object: IStaticVector, colors: string[]) => {
		const { colorNumber } = object;
		if (colorNumber && colorNumber >= 1 && colorNumber <= 6 && colors[colorNumber - 1]) {
			EditShapeElementsinAllAds(object, FeatureType.COLOR, colors[colorNumber - 1], true);
		}
	};

	const applyFabricShapesConfig = (object: IStaticCircle | IStaticRect | IStaticTriangle, colors: string[]) => {
		const { colorNumber, strokeColorNumber } = object;
		if (colorNumber && colorNumber >= 1 && colorNumber <= 6 && colors[colorNumber - 1]) {
			EditFabricShapeElementsinAllAds(object, FeatureType.COLOR, colors[colorNumber - 1], true);
		}

		if (strokeColorNumber && strokeColorNumber >= 1 && strokeColorNumber <= 6 && colors[strokeColorNumber - 1]) {
			EditFabricShapeElementsinAllAds(object, FeatureType.STROKE, colors[strokeColorNumber - 1], true);
		}
	};

	const applyBackgroundConfig = (object: IBackground, colors: string[]) => {
		const { colorNumber } = object;
		if (colorNumber && colorNumber >= 1 && colorNumber <= 6 && colors[colorNumber - 1]) {
			EditCanvasElementsinAllAds(object, FeatureType.COLOR, colors[colorNumber - 1], true);
		}
	};

	const applyImageConfig = async (
		object: IStaticImage,
		logos: { [key in LogoTypeEnum]: string },
		sceneIndex: number,
	) => {
		const { logoType } = object;
		const currentObject = object;
		if (logoType && logos && logos[logoType as LogoTypeEnum]) {
			const originalLogoObjects = campaignStore.getOriginalLogoObjects(sceneIndex) || [];
			const storedObject = originalLogoObjects.find((fabricObject) => fabricObject.id === object.id);

			if (campaignStore.isFirstTimeBrandApplied) {
				// First-time brand application logic
				if (!storedObject) {
					originalLogoObjects.push(object);
					campaignStore.storeOriginalLogoObjects(sceneIndex, originalLogoObjects);
				} else {
					object = storedObject;
				}
			} else {
				if (!storedObject) {
					originalLogoObjects.push(object);
					campaignStore.storeOriginalLogoObjects(sceneIndex, originalLogoObjects);
				} else {
					object = storedObject;
				}
			}
			const src = logos[logoType as LogoTypeEnum]; // new source
			const logoUrl = convertS3UriToHttpsCloudFrontUrl(src);

			const originalImageDimensions = await getImageDimensionsByImageUrl(logoUrl);
			const scaleFactor = await getImageToRectScaleFactor(logoUrl, object);

			const newImageOptions = {
				id: object.id,
				type: LayerType.STATIC_IMAGE,
				src: logoUrl,
				left: object?.left,
				top: object?.top,
				width: originalImageDimensions.width,
				height: originalImageDimensions.height,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
				imageType: currentObject.imageType,
				logoType: currentObject.logoType,
				horizontalAlignment: currentObject.horizontalAlignment,
				verticalAlignment: currentObject.verticalAlignment,
				brandId: brandsDefinitionStore.selectedBrand.id,
			};

			// Add the new image to the editor canvas only if it's the current scene
			if (editor?.canvas.canvas.getObjects().includes(object as fabric.Object)) {
				editor?.canvas.canvas.remove(object as fabric.Object);
				await editor?.objects.add(newImageOptions);
				editor?.objects.update(newImageOptions);
			}

			// Update the Logo Alignment
			const { top, left } = updateStaticImageAlignment(
				object,
				currentObject,
				originalImageDimensions,
				scaleFactor,
			);
			newImageOptions.top = top;
			newImageOptions.left = left;

			return newImageOptions;
		}
		return object;
	};

	const applyWizardImageChangeOnLayer = async (
		object: Partial<ILayer>,
		imageUrl?: string,
		currentActiveImage?: Partial<ILayer>,
	) => {
		const currentObject = object;
		const originalScene = designEditorStore.originalSceneInWizard;
		const storedLayer = originalScene?.layers.find((layer) => layer.id === object.id);
		const imagesTypeURLMapping = designEditorStore.imageTypeToNewUrlMap;

		// Check the imageType and fetch the corresponding URL
		let newImageUrl =
			currentObject &&
			currentObject.imageType &&
			imagesTypeURLMapping &&
			imagesTypeURLMapping[currentObject.imageType];
		if (!newImageUrl && imageUrl && object.id === currentActiveImage?.id) {
			newImageUrl = imageUrl;
		}

		if (newImageUrl) {
			// Use storedLayer if it exists, otherwise use currentObject
			const referenceObject = currentObject || storedLayer;

			const originalImageDimensions = await getImageDimensionsByImageUrl(newImageUrl);
			const scaleFactor = await getImageToRectScaleFactor(newImageUrl, referenceObject);

			const newImageOptions = {
				id: object.id,
				type: "StaticImage",
				src: newImageUrl,
				left: referenceObject.left,
				top: referenceObject.top,
				width: originalImageDimensions.width,
				height: originalImageDimensions.height,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
				imageType: currentObject.imageType,
				logoType: currentObject.logoType,
				horizontalAlignment: currentObject.horizontalAlignment,
				verticalAlignment: currentObject.verticalAlignment,
				brandId: brandsDefinitionStore.selectedBrand.id,
			};

			// Update the Logo Alignment
			const { top, left } = updateStaticImageAlignment(
				object,
				currentObject,
				originalImageDimensions,
				scaleFactor,
				true,
			);
			newImageOptions.top = top;
			newImageOptions.left = left;

			// Return the updated image layer for other scenes
			return newImageOptions;
		}

		return object;
	};

	const applyWizardImageChangeOnLayerV1 = async (destinationLayer: Partial<ILayer>, imageUrl: string) => {
		if (imageUrl) {
			const referenceObject = destinationLayer;
			const originalImageDimensions = await getImageDimensionsByImageUrl(imageUrl);
			const scaleFactor = await getImageToRectScaleFactor(imageUrl, referenceObject);

			const newImageOptions = {
				id: destinationLayer.id,
				type: "StaticImage",
				src: imageUrl,
				left: referenceObject.left,
				top: referenceObject.top,
				width: originalImageDimensions.width,
				height: originalImageDimensions.height,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
				imageType: destinationLayer.imageType,
				logoType: destinationLayer.logoType,
				horizontalAlignment: destinationLayer.horizontalAlignment,
				verticalAlignment: destinationLayer.verticalAlignment,
				brandId: brandsDefinitionStore.selectedBrand.id,
			};

			const { top, left } = updateStaticImageAlignment(
				destinationLayer,
				destinationLayer,
				originalImageDimensions,
				scaleFactor,
				true,
			);
			newImageOptions.top = top;
			newImageOptions.left = left;

			return newImageOptions;
		}

		return destinationLayer;
	};

	const addImageObjectToCanvas = useCallback(
		async (image: Image) => {
			let url = image.url;
			if (isS3Uri(url)) {
				url = convertS3UriToHttpsCloudFrontUrl(image.url);
			}
			const objects = editor?.objects;
			const referenceObject = objects?.findOneById(ObjectsEnum.InitialFrame);
			const scaleFactor = await getImageToRectScaleFactor(url, referenceObject);
			const originalImageDimensions = await getImageDimensionsByImageUrl(url);

			const options = {
				type: "StaticImage",
				src: url,
				left: referenceObject.left,
				top: referenceObject.top,
				width: originalImageDimensions.width,
				height: originalImageDimensions.height,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
			};
			await editor?.objects.add(options);
		},
		[editor],
	);
	/**
	 * Calculates the new left and new top for the new brand logo based on the original logo.
	 *
	 * @param object - The Original staticImage Object.
	 * @param currentObject - The Current staticImage Object.
	 * @param originalImageDimensions - pre-fetched dimensions of the new logo (width and height).
	 * @param scaleFactor - the calculated scale Factor needed to fit the new logo  within the original logo.
	 * @returns The top and left for the new  Logo.
	 */

	const updateStaticImageAlignment = (
		object: IStaticImage | Partial<ILayer>,
		currentObject: IStaticImage | Partial<ILayer>,
		originalImageDimensions: ImageSize,
		scaleFactor: number,
		centerImage?: boolean,
	) => {
		const objectWidth = (object.width ?? 0) * (object.scaleX ?? 1);
		const objectHeight = (object.height ?? 0) * (object.scaleY ?? 1);
		const replaceLogoWidth = originalImageDimensions.width * scaleFactor;
		const replaceLogoHeight = originalImageDimensions.height * scaleFactor;

		const newLeft = currentObject.horizontalAlignment
			? calculateAlignment(
					currentObject.horizontalAlignment as HorizontalEnum,
					objectWidth,
					replaceLogoWidth,
					object.left ?? 0,
			  )
			: centerImage
			? calculateAlignment(HorizontalEnum.CENTER, objectWidth, replaceLogoWidth, object.left ?? 0)
			: object.left ?? 0;

		const newTop = currentObject.verticalAlignment
			? calculateAlignment(
					currentObject.verticalAlignment as VerticalEnum,
					objectHeight,
					replaceLogoHeight,
					object.top ?? 0,
			  )
			: centerImage
			? calculateAlignment(VerticalEnum.CENTER, objectHeight, replaceLogoHeight, object.top ?? 0)
			: object.top ?? 0;

		return {
			top: newTop,
			left: newLeft,
		};
	};

	/**
	 * Calculates new Alignment.
	 *
	 * @param alignment - Alignment Type.
	 * @param objectSize - The  Object width or object height based on Alignment Type.
	 * @param replaceSize - the new logo width or heightbased on Alignment Type.
	 * @param objectPos - the original object top or left based on Alignment Type .
	 * @returns The top or left for the new  Logo based on Alignment Type .
	 */

	const calculateAlignment = (
		alignment: HorizontalEnum | VerticalEnum,
		objectSize: number,
		replaceSize: number,
		objectPos: number,
	) => {
		switch (alignment) {
			case HorizontalEnum.RIGHT:
			case VerticalEnum.BOTTOM:
				return objectPos + (objectSize - replaceSize);
			case HorizontalEnum.LEFT:
			case VerticalEnum.TOP:
				return objectPos;
			case HorizontalEnum.CENTER:
			case VerticalEnum.CENTER:
				return objectPos + (objectSize / 2 - replaceSize / 2);
			default:
				return objectPos;
		}
	};

	/**
	 *parse RGBA color to Get RGB and opacity.
	 *
	 * @param color - Color in RGBA format.
	 * @returns The opacity and  RGB .
	 */

	const getRgbAndOpacityFromRgba = (color: string) => {
		// Regular expression to match rgba color format
		const rgbaRegex = /^rgba\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\s*\)$/;

		// Check if the color matches the rgba format
		const match = rgbaRegex.exec(color);

		if (match) {
			// Extract the opacity value (4th capture group in the regex)
			const opacity = parseFloat(match[4]);
			const red = parseInt(match[1], 10);
			const green = parseInt(match[2], 10);
			const blue = parseInt(match[3], 10);

			// Return the RGB values as a string in the format "rgb(r, g, b)"
			return { opacity: opacity, rgb: `rgb(${red}, ${green}, ${blue})` };
		}

		// Return undefined if the color is not in rgba format
		return undefined;
	};

	/**
	 *update given color opacity to prepare it for the opensource color picker.
	 *
	 * @param color - Color in RGBA or rgb format.
	 * @returns The updated color with opacity in  RGBA format .
	 */

	const updateColorOpacity = (color: string, opacity: number) => {
		const rgbRegex = /^rgb\(\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*\)$/i;
		const rgbaRegex = /^rgba\(\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*,\s*([\d.]+)\s*\)$/i;

		let match;
		if (opacity) {
			if ((match = color.match(rgbRegex))) {
				// Convert rgb to rgba with the new opacity
				const [, r, g, b] = match;
				return `rgba(${r}, ${g}, ${b}, ${opacity})`;
			} else if ((match = color.match(rgbaRegex))) {
				// Update opacity in the existing rgba
				const [, r, g, b] = match;
				return `rgba(${r}, ${g}, ${b}, ${opacity})`;
			} else {
				return color;
			}
		}
		return color;
	};

	const editBrandConfigrationsInAllAds = (
		activeObject: any,
		property: string,
		newValue: string | number | undefined,
	) => {
		const updateProperty = (
			featureType: FeatureType,
			layerType: LayerType,
			typeKey: keyof typeof activeObject,
			newValue: string | number | undefined,
		) => {
			const keyValue = activeObject[typeKey] as string | undefined;
			if (keyValue) {
				EditAllAds(layerType, keyValue, featureType, newValue);
			}
		};
		const isStaticText =
			activeObject?.name === OBJECT_TYPES.STATIC_TEXT || activeObject?.type === OBJECT_TYPES.STATIC_TEXT;
		const isStaticImage =
			activeObject?.name === OBJECT_TYPES.STATIC_IMAGE || activeObject?.type === OBJECT_TYPES.STATIC_IMAGE;
		const isFabricShape =
			activeObject?.type === LayerType.STATIC_RECT ||
			activeObject?.type === LayerType.STATIC_CIRCLE ||
			activeObject?.type === LayerType.STATIC_TRIANGLE;
		if (isStaticText && activeObject.textType) {
			if (property === Property.COLOR_NUMBER) {
				updateProperty(
					FeatureType.BRAND_COLOR,
					LayerType.STATIC_TEXT,
					ActiveObjectBrandAttributes.TEXT_TYPE,
					newValue,
				);
			} else if (property === Property.FONT_TYPE) {
				updateProperty(
					FeatureType.BRAND_FONT,
					LayerType.STATIC_TEXT,
					ActiveObjectBrandAttributes.TEXT_TYPE,
					newValue,
				);
			}
		} else if (isStaticImage && activeObject.imageType) {
			if (property === Property.LOGO_TYPE) {
				updateProperty(
					FeatureType.BRAND_LOGO,
					LayerType.STATIC_IMAGE,
					ActiveObjectBrandAttributes.IMAGE_TYPE,
					newValue,
				);
			} else if (property === Property.HORIZONTAL_ALIGNMENT) {
				updateProperty(
					FeatureType.HORIZONTAL_ALIGNMENT,
					LayerType.STATIC_IMAGE,
					ActiveObjectBrandAttributes.IMAGE_TYPE,
					newValue,
				);
			} else if (property === Property.VERTICAL_ALIGNMENT) {
				updateProperty(
					FeatureType.VERTICAL_ALIGNMENT,
					LayerType.STATIC_IMAGE,
					ActiveObjectBrandAttributes.IMAGE_TYPE,
					newValue,
				);
			}
		} else if (isFabricShape && activeObject.shapeType) {
			if (property === Property.COLOR_NUMBER) {
				updateProperty(
					FeatureType.BRAND_COLOR,
					activeObject?.type,
					ActiveObjectBrandAttributes.SHAPE_TYPE,
					newValue,
				);
			} else if (property === Property.STOKE_COLOR_NUMBER) {
				updateProperty(
					FeatureType.STROKE_COLOR_NUMBER,
					activeObject?.type,
					ActiveObjectBrandAttributes.SHAPE_TYPE,
					newValue,
				);
			}
		} else if (activeObject.shapeType) {
			updateProperty(
				FeatureType.BRAND_COLOR,
				LayerType.STATIC_VECTOR,
				ActiveObjectBrandAttributes.SHAPE_TYPE,
				newValue,
			);
		}
	};

	const applyWizardImagesChangesOnScene = async (
		scene: IScene,
		imageUrl?: string,
		currentActiveImage?: Partial<ILayer>,
	) => {
		for (let i = 0; i < scene.layers.length; i++) {
			const layer = scene.layers[i];
			if (layer.type === LayerType.STATIC_IMAGE) {
				const updatedLayer = await applyWizardImageChangeOnLayer(layer, imageUrl, currentActiveImage);
				if (updatedLayer) {
					scene.layers[i] = updatedLayer;
				}
			}
		}
	};

	const resetWizardImages = async () => {
		if (!designEditorStore.originalSceneInWizard) {
			return;
		}
		designEditorStore.setProperty("isResettingWizardImages", true);
		const _currentScene = editor?.scene.exportToJSON();
		if (_currentScene) {
			await resetWizardImagesChangesOnScene(_currentScene);
			designEditorStore.setProperty("isSceneFullyLoaded", false);
			setCurrentScene(_currentScene);
		}

		designEditorStore.setProperty("backupOfReplacedStaticImagesLayers", []);
		designEditorStore.setProperty("imageTypeToNewUrlMap", {});
		designEditorStore.setProperty("isResettingWizardImages", false);
	};

	const resetWizardImagesChangesOnScene = async (scene: IScene) => {
		for (let i = 0; i < scene.layers.length; i++) {
			const layer = scene.layers[i];
			const foundOriginalLayer = designEditorStore.backupOfReplacedStaticImagesLayers.find(
				(l: any) => l.id === layer?.id,
			);
			if (layer.id && layer.type === LayerType.STATIC_IMAGE && foundOriginalLayer) {
				scene.layers[i] = foundOriginalLayer;
			}
		}
	};

	// Utility to calculate text width for a given line
	function calculateTextWidth(object: fabric.Object, text: string, fontSize: number) {
		const context = document.createElement("canvas").getContext("2d");
		if (context) context.font = `${fontSize}px ${object.fontFamily || "sans-serif"}`;
		return context?.measureText(text).width || 0;
	}

	// Measure the total height of the text
	function calculateTextHeight(textBox: fabric.Object, fontSize: number) {
		const sanitizedText = textBox.text.replace(/\n/g, " ");
		const textbox = new fabric.Textbox(sanitizedText, {
			width: textBox.width,
			fontSize: fontSize,
			fontFamily: textBox.fontFamily,
			textAlign: textBox.textAlign,
			lineHeight: textBox.lineHeight,
			fontWeight: textBox.fontWeight,
			padding: textBox.padding,
		});
		return textbox.height || 1;
	}

	// Utility to calculate the maximum font size that fits the longest word within the allowed width
	function getMaxFontSizeForLongestWord(textElement: fabric.Object, initialFontSize: number) {
		const maxAllowedWidth = textElement.fixedWidthValue;
		const cleanedText = textElement.text.replace(/\n/g, " ");
		const words = cleanedText.split(" ");
		let adjustedFontSize = undefined;
		let currentFontSize = initialFontSize;

		for (const word of words) {
			if (maxAllowedWidth) {
				while (
					calculateTextWidth(textElement, word, currentFontSize) > maxAllowedWidth &&
					currentFontSize > 1
				) {
					currentFontSize -= 1;
				}
				if (currentFontSize !== initialFontSize) {
					adjustedFontSize = currentFontSize;
				}
			}
		}

		return { finalFontSize: currentFontSize, adjustedFontSize };
	}

	const fitTextIntoFixedTextBox = (textBox: fabric.Object) => {
		const maxWidth = textBox.fixedWidthValue;
		const maxHeight = textBox.fixedHeightValue;
		let maxFontSize = textBox.maximumFontSize;

		let fontSize = textBox.fontSize || 100;
		if (maxFontSize && fontSize > maxFontSize) {
			fontSize = maxFontSize;
		}

		let totalHeight = calculateTextHeight(textBox, fontSize);

		// Adjust font size to fit within height (shrink if overflowing)
		if (totalHeight && maxHeight && fontSize) {
			while (totalHeight > maxHeight && fontSize > 1) {
				fontSize -= 0.1; // Shrink in larger steps initially
				totalHeight = calculateTextHeight(textBox, fontSize);
			}
		}

		let { finalFontSize, adjustedFontSize } = getMaxFontSizeForLongestWord(textBox, fontSize);
		fontSize = finalFontSize;
		textBox.fontSize = fontSize;

		// Adjust font size to fill space (grow if there's extra space)
		let index = 0;
		maxFontSize = adjustedFontSize ?? maxFontSize;
		if (totalHeight && maxHeight && maxFontSize && fontSize) {
			while (totalHeight < maxHeight && fontSize < maxFontSize) {
				index = index + 1;
				const testFontSize = fontSize + 1;
				const testHeight = calculateTextHeight(textBox, testFontSize);
				if (testHeight > maxHeight) break;
				fontSize = testFontSize;
				totalHeight = testHeight;
			}
		}
		let res = getMaxFontSizeForLongestWord(textBox, fontSize);
		fontSize = res.finalFontSize;
		textBox.fontSize = fontSize;
		editor?.canvas.canvas.renderAll();
		if (typeof textBox.set === "function") {
			textBox.set("height", maxHeight);
			textBox.set("width", maxWidth);
		} else {
			textBox.height = maxHeight;
			textBox.width = maxWidth;
		}
	};
	return {
		EditAllAds,
		fitTextIntoFixedTextBox,
		editBrandConfigrationsInAllAds,
		applyBrandConfigration,
		applyBrandConfigrationOnScene,
		makeDownloadTemplate,
		updateAllScenes,
		addImageObjectToCanvas,
		getRgbAndOpacityFromRgba,
		updateColorOpacity,
		applyWizardImagesChangesOnScene,
		resetWizardImages,
		applyWizardImageChangeOnLayerV1,
	};
};

export default useDesignEditorUtils;

export enum FeatureType {
	COLOR = "color",
	UNDERLINE = "underline",
	LINETHROUGH = "lineThrough",
	TEXTHIGHLIGHTER = "textHighlighter",
	BOLD = "bold",
	ITALIC = "italic",
	TEXT_ALIGN = "textAlign",
	CHAR_SPACING = "charSpacing",
	LINE_HEIGHT = "lineHeight",
	LETTER_CASE = "letterCase",
	FLIPX = "flipX",
	FLIPY = "flipY",
	FILL = "fill",
	TEXT = "text",
	FONT = "font",
	BRAND_COLOR = "brandColor",
	BRAND_FONT = "brandFont",
	BRAND_LOGO = "brandLogo",
	HORIZONTAL_ALIGNMENT = "horizontalAlignment",
	VERTICAL_ALIGNMENT = "verticalAlignment",
	STROKE_WIDTH = "strokeWidth",
	STROKE_DASH_ARRAY = "strokeDashArray",
	RADIUS = "Radius",
	STROKE_COLOR_NUMBER = "strokeColorNumber",
	STROKE = "stroke",
	SHADOW = "shadow",
	FIXEDTEXTBOX = "fixedTextBox",
	FIXEDSIZES = "fixedSizes",
	FONTSIZE = "fontSize",
}
export enum Property {
	COLOR_NUMBER = "colorNumber",
	FONT_TYPE = "fontType",
	LOGO_TYPE = "logoType",
	HORIZONTAL_ALIGNMENT = "horizontalAlignment",
	VERTICAL_ALIGNMENT = "verticalAlignment",
	STOKE_COLOR_NUMBER = "strokeColorNumber",
}

export enum ActiveObjectBrandAttributes {
	SHAPE_TYPE = "shapeType",
	IMAGE_TYPE = "imageType",
	TEXT_TYPE = "textType",
}

const defaultLogos: { [key in LogoTypeEnum]: string } = {
	LOGO_1: "",
	LOGO_2: "",
	LOGO_3: "",
	LOGO_4: "",
	LOGO_5: "",
	LOGO_6: "",
};
