import { Box, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PromotionPopupImage from "../../../assets/images/promotionPopup.png";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useOrgNavigation from "../../../hooks/useOrgNavigation.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import styles from "./PromotionPopup.module.scss";

interface IProps {
	open: boolean;
	onClose: () => void;
}

export default function PromotionPopup({ open, onClose }: IProps) {
	const { t } = useTranslation("translation", { keyPrefix: "TGUpgradPopup" });
	const { orgNavigationHandler } = useOrgNavigation();

	return (
		<Dialog
			classes={{
				paper: styles.paper,
			}}
			onClose={onClose}
			open={open}
			maxWidth={false}
		>
			<Box className={styles.container}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("header")}</Typography>
					<Box className={styles.descriptionContainer}>
						<Typography className={styles.description}>{t("capabilitiesList")}</Typography>
					</Box>
					<ul className={styles.capabilitiesList}>
						<li dangerouslySetInnerHTML={{ __html: t("listItem1") }} />
						<li dangerouslySetInnerHTML={{ __html: t("listItem2") }} />
						<li dangerouslySetInnerHTML={{ __html: t("listItem3") }} />
						<li dangerouslySetInnerHTML={{ __html: t("listItem4") }} />
						<li dangerouslySetInnerHTML={{ __html: t("listItem5") }} />
					</ul>
					<Typography className={styles.description}>{t("startExploring")}</Typography>
					<Box className={styles.buttonsContainer}>
						<Box className={styles.buttons}>
							<BriaButton
								onClick={() => orgNavigationHandler(RouterConstants.NEW_TAILORED_GENERATION.fullPath, onClose)}
								buttonType="primaryMedium"
							>
								{t("startTraining")}
							</BriaButton>
							<BriaButton onClick={onClose} buttonType="tertiaryMedium">
								{t("close")}
							</BriaButton>
						</Box>
					</Box>
				</Box>
				<Box className={styles.imageContainer}>
					<img alt="Tailored Generation Upgraded!" src={PromotionPopupImage} />
				</Box>
			</Box>
		</Dialog>
	);
}
