import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { BackendError } from "../../../../config/axios";
import QueryService from "../../../../utils/QueryService";
import { AsyncProps } from "../../../../utils/useImageUtils";
import { TgModel } from "../../Models/store/tg-model-store";
import { ITgStore } from "../../store/new-tg-stores";

export interface ITgDatasetStore {
	datasetForm: TgDataset;
	formErrors: { upload?: string };
	isFormDuplicateTriggered: boolean;

	loadingDataset: boolean;
	loadingCreateDataset: boolean;
	loadingUpdateDataset: boolean;
	loadingDeleteDataset: boolean;
	loadingDuplicateDataset: boolean;
	loadingUploadImages: boolean;
	loadingRegenerate: boolean;
	loadingUpdateImage: boolean;

	handleFormChange: <K extends keyof TgDataset>(key: K, value: TgDataset[K]) => void;
	getDataset: (id: number) => Promise<TgDataset>;
	createDataset: (datasetToCreate: TgDataset) => Promise<TgDataset>;
	updateDataset: (id: number, updatedDataset: TgDataset) => Promise<TgDataset>;
	deleteDataset: (id: number) => Promise<void>;
	duplicateDataset: (id: number) => Promise<TgDataset>;

	uploadImage: (datasetId: number, file: File, imageId?: number) => Promise<TgDatasetImage | void>;
	uploadImages: (datasetId: number, files: File[]) => Promise<void>;
	updateImageCaption: (datasetId: number, imageId: number, newCaption: string) => Promise<TgDatasetImage>;
	deleteImage: (datasetId: number, imageId: number) => Promise<void>;
	regenerateAllCaptions: (datasetId: number) => Promise<void>;
	regenerateImageCaption: (datasetId: number, imageId: number) => Promise<void>;
}

export class TgDatasetStore implements ITgDatasetStore {
	private queryService: QueryService = new QueryService("/new-tailored-generation/datasets");
	tgStore: ITgStore;

	datasetForm: TgDataset = defaultDataset;
	formErrors: { upload?: string } = {};
	isFormDuplicateTriggered: boolean = false;

	loadingProjectDatasets: boolean = false;
	loadingDataset: boolean = false;
	loadingCreateDataset: boolean = false;
	loadingUpdateDataset: boolean = false;
	loadingDeleteDataset: boolean = false;
	loadingDuplicateDataset: boolean = false;
	loadingUploadImages: boolean = false;
	loadingRegenerate: boolean = false;
	loadingUpdateImage: boolean = false;

	constructor(tgStore: ITgStore) {
		makeAutoObservable(this);
		this.tgStore = tgStore;
	}

	handleFormChange = <K extends keyof TgDataset>(key: K, value: TgDataset[K]) => {
		this.datasetForm = { ...this.datasetForm, [key]: value };
	};

	getDataset = async (id: number): Promise<TgDataset> => {
		try {
			this.loadingDataset = true;
			const dataset = await this.queryService.get(`/${id}`);

			runInAction(() => {
				this.loadingDataset = false;
			});

			return dataset;
		} catch (err: any) {
			this.loadingDataset = false;
			return Promise.reject(err);
		}
	};

	createDataset = async (datasetToCreate: TgDataset): Promise<TgDataset> => {
		try {
			this.loadingCreateDataset = true;
			const newDataset = await this.queryService.post(`/`, datasetToCreate);

			runInAction(() => {
				this.loadingCreateDataset = false;
			});

			return newDataset;
		} catch (err: any) {
			this.loadingCreateDataset = false;
			return Promise.reject(err);
		}
	};

	updateDataset = async (id: number, datasetToUpdate: TgDataset): Promise<TgDataset> => {
		try {
			this.loadingUpdateDataset = true;
			const updatedDataset = await this.queryService.put(`/${id}`, datasetToUpdate);

			runInAction(() => {
				this.tgStore.tgProjectStore.paginatedDatasets.items =
					this.tgStore.tgProjectStore.paginatedDatasets?.items.map((dataset) =>
						dataset.id === id ? updatedDataset : dataset,
					);
				this.loadingUpdateDataset = false;
			});
			return updatedDataset;
		} catch (err: any) {
			this.loadingUpdateDataset = false;
			return Promise.reject(err);
		}
	};

	deleteDataset = async (id: number) => {
		try {
			this.loadingDeleteDataset = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.tgStore.tgProjectStore.paginatedDatasets = {
					items: this.tgStore.tgProjectStore.paginatedDatasets.items.filter((dataset) => dataset.id !== id),
					total: this.tgStore.tgProjectStore.paginatedDatasets.total - 1,
				};
				this.loadingDeleteDataset = false;
			});
		} catch (err: any) {
			this.loadingDeleteDataset = false;
			return Promise.reject(err);
		}
	};

	duplicateDataset = async (id: number): Promise<TgDataset> => {
		try {
			this.loadingDuplicateDataset = true;
			const newDataset = await this.queryService.post(`/${id}/duplicate`);

			runInAction(() => {
				this.loadingDuplicateDataset = false;
			});

			return newDataset;
		} catch (err: any) {
			this.loadingDuplicateDataset = false;
			return Promise.reject(err);
		}
	};

	uploadImage = async (dataset_id: number, file: File, imageId?: number): Promise<TgDatasetImage | void> => {
		const updateExistingImage = (updatedImage: TgDatasetImage) => {
			this.datasetForm.images = this.datasetForm.images.map((img) => (img.id === imageId ? updatedImage : img));
		};

		const formData = new FormData();
		formData.append("file", file);

		const skeletonImage: TgDatasetImage = {
			...defaultDatasetImage,
			id: imageId ?? (uuidv4() as unknown as number),
			thumbnail_url: URL.createObjectURL(file),
			caption_source: "automatic",
			file: file,
			uploading: true,
		};

		if (imageId) {
			updateExistingImage(skeletonImage);
		} else {
			this.datasetForm.images = [...this.datasetForm.images, skeletonImage];
		}

		imageId = imageId ?? skeletonImage.id;

		try {
			const newImage: TgDatasetImage = await this.queryService.post(`/${dataset_id}/upload_image`, formData, {
				"Content-Type": "multipart/form-data",
			});

			runInAction(() => {
				updateExistingImage(newImage);
			});

			return newImage;
		} catch (err: any) {
			updateExistingImage({
				...skeletonImage,
				uploading: false,
				error: {
					message: err.response.data.message,
					status: err.response.status,
				},
			});
		}
	};

	uploadImages = async (dataset_id: number, files: File[]): Promise<void> => {
		if (files.length) {
			this.loadingUploadImages = true;

			const skeletonImages: TgDatasetImage[] = files.map((file) => ({
				...defaultDatasetImage,
				id: uuidv4() as unknown as number,
				thumbnail_url: URL.createObjectURL(file),
				caption_source: "automatic",
				file: file,
				uploading: true,
			}));

			this.datasetForm.images = [...this.datasetForm.images, ...skeletonImages];

			for (const skeletonImage of skeletonImages) {
				skeletonImage.file && (await this.uploadImage(dataset_id, skeletonImage.file, skeletonImage.id));
			}
			this.loadingUploadImages = false;
		}
	};

	updateImageCaption = async (datasetId: number, imageId: number, newCaption: string) => {
		try {
			this.loadingUpdateImage = true;
			const updatedImage = await this.queryService.put(`/${datasetId}/images/${imageId}`, {
				caption: newCaption,
			});

			runInAction(() => {
				this.datasetForm.images = this.datasetForm.images.map((img) =>
					img.id === imageId ? updatedImage : img,
				);
				this.loadingUpdateImage = false;
			});

			return updatedImage;
		} catch (err: any) {
			this.loadingUpdateImage = false;
			return Promise.reject(err);
		}
	};

	deleteImage = async (datasetId: number, imageId: number) => {
		try {
			this.loadingUpdateImage = true;

			await this.queryService.delete(`/${datasetId}/images/${imageId}`);

			runInAction(() => {
				this.datasetForm.images = this.datasetForm.images.filter((img) => img.id !== imageId);
				this.loadingUpdateImage = false;
			});
		} catch (err: any) {
			this.loadingUpdateImage = false;
			return Promise.reject(err);
		}
	};

	regenerateAllCaptions = async (datasetId: number) => {
		this.loadingRegenerate = true;

		try {
			await this.queryService.put(`/${datasetId}/images/regenerate`);

			const checkCaptionsReady = async (): Promise<TgDataset | undefined> => {
				const dataset = await this.getDataset(datasetId);
				return dataset?.captions_update_status === "completed" ? dataset : undefined;
			};

			const pullUntilAvailable = async ({ maxAttempts = 200, sleepSecs = 1 }: AsyncProps = {}) => {
				for (let tries = 0; tries < maxAttempts; tries++) {
					const newDataset = await checkCaptionsReady();
					if (newDataset) return newDataset;
					await new Promise((resolve) => setTimeout(resolve, sleepSecs * 1000));
				}
				throw new Error("Caption update did not complete within the maximum attempts");
			};

			try {
				const newDataset = await pullUntilAvailable();

				runInAction(() => {
					this.datasetForm = newDataset;
					this.loadingRegenerate = false;
				});
			} catch (err) {
				console.error(`Failed to regenerate captions for dataset ID ${datasetId}:`, err);
			}
		} catch (err: any) {
			this.loadingRegenerate = false;
			return Promise.reject(err);
		}
	};

	regenerateImageCaption = async (datasetId: number, imageId: number) => {
		try {
			this.loadingUpdateImage = true;

			const updatedImage: TgDatasetImage = await this.queryService.put(
				`/${datasetId}/images/${imageId}/regenerate`,
			);

			runInAction(() => {
				this.datasetForm.images = this.datasetForm.images.map((img) =>
					img.id === imageId ? updatedImage : img,
				);
				this.loadingUpdateImage = false;
			});
		} catch (err: any) {
			this.loadingUpdateImage = false;
			return Promise.reject(err);
		}
	};
}

export type TgDataset = {
	id: number;
	name: string;
	description: string;
	caption_prefix: string;
	captions_update_status?: TgDatasetImageCaptionStatus;
	status: TgDatasetStatus;
	images: TgDatasetImage[];
	models?: TgModel[];
	project_id: number;
	created_at: string;
	updated_at: string;
};

export type TgDatasetStatus = "Draft" | "Completed" | "Deleted";

export type TgDatasetImage = {
	id: number;
	caption: string;
	caption_source: TgDatasetImageCaption;
	image_name: string;
	image_url: string;
	thumbnail_url: string;
	dataset_id: number;
	created_at: string;
	updated_at: string;
	file?: File;
	uploading?: boolean;
	error?: BackendError;
};
export type TgDatasetImageCaption = "automatic" | "manual";
export type TgDatasetImageCaptionStatus = "in_progress" | "completed";

export const defaultDataset: TgDataset = {
	id: NaN,
	name: "",
	description: "",
	caption_prefix: "",
	images: [],
	status: "Draft",
	project_id: NaN,
	created_at: "",
	updated_at: "",
};

const defaultDatasetImage: TgDatasetImage = {
	id: NaN,
	caption: "",
	caption_source: "automatic",
	image_name: "",
	image_url: "",
	thumbnail_url: "",
	created_at: "",
	updated_at: "",
	dataset_id: NaN,
};
