import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../../../assets/images/icons/CloudUpload.tsx";
import BriaUpgradeOverlay from "../../../../../components/common/BriaUpgradeOverlay/BriaUpgradeOverlay.tsx";
import ColorPicker from "../../../../../components/common/ColorPicker/ColorPicker";
import ImageUploader from "../../../../../components/common/ImageUploader/ImageUploader.tsx";
import TextColorPicker from "../../../../../components/common/TextColorPicker/TextColorPicker";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { OrgFeatures } from "../../../../../models/billing.ts";
import QueryService from "../../../../../utils/QueryService.ts";
import iframeStore from "../../../iframe-store.tsx";
import styles from "./IframeCustomStyleConfig.module.scss";

const IframeCustomStyleConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.customStyle" });
	const fileQueryService: QueryService = new QueryService("/upload-image");
	const [isUploadingLogo, setIsUploadingLogo] = useState(false);

	const handleCssVarChange = async (cssVar: string, value: string) => {
		handleFormChange("config", {
			...iframeForm.config,
			custom_style: {
				...iframeForm.config.custom_style,
				cssVars: {
					...iframeForm.config.custom_style?.cssVars,
					[cssVar]: value,
				},
			},
		});
	};

	const handleLogoImageChange = async (imageFile?: File) => {
		let imageUrl = undefined;
		if (imageFile) {
			try {
				setIsUploadingLogo(true);
				const formData = new FormData();
				formData.append("file", imageFile, imageFile.name);
				formData.append("max_size", "100");
				imageUrl = await fileQueryService.post("/s3/file", formData, {
					"Content-Type": "multipart/form-data",
				});
			} finally {
				setIsUploadingLogo(false);
			}
		}
		handleFormChange("config", {
			...iframeForm.config,
			custom_style: {
				...iframeForm.config.custom_style,
				logo: {
					...iframeForm.config.custom_style?.logo,
					image: imageUrl,
				},
			},
		});
	};

	return (
		<Box className={styles.container}>
			<InputLayout label={t("primaryColor.label")}>
				<BriaUpgradeOverlay feature={OrgFeatures.IFRAME_WHITE_LABEL}>
					<Box className={styles.colorWrapper}>
						<ColorPicker
							circleWidth="33px"
							color={iframeForm?.config.custom_style?.cssVars?.["primary_main_color"]}
							onColorChange={(color: string) => handleCssVarChange("primary_main_color", color)}
						/>
						<TextColorPicker
							color={iframeForm?.config.custom_style?.cssVars?.["primary_main_color"]}
							onColorChange={(color: string) => handleCssVarChange("primary_main_color", color)}
						/>
					</Box>
				</BriaUpgradeOverlay>
			</InputLayout>
			<InputLayout label={t("logo.label")}>
				<BriaUpgradeOverlay feature={OrgFeatures.IFRAME_WHITE_LABEL}>
					<ImageUploader
						description={t("logo.uploadLogo")}
						icon={iframeForm?.config.custom_style?.logo?.image ? "" : <CloudUploadSVG />}
						horizontalLayout
						largeIcon
						src={iframeForm?.config.custom_style?.logo?.image}
						onUpload={(e) => handleLogoImageChange(e.target.files?.[0])}
						onDelete={() => handleLogoImageChange()}
						loading={isUploadingLogo}
					></ImageUploader>
				</BriaUpgradeOverlay>
			</InputLayout>
		</Box>
	);
};

export default observer(IframeCustomStyleConfig);
