import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AutomotiveBG from "../../assets/images/pngs/Automotive/automotive-bg.png";
import BriaButton from "../../components/common/BriaButton/BriaButton.tsx";
import RouterConstants from "../../constants/RouterConstants.ts";
import useSecureNavigate from "../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import styles from "./Automotive.module.scss";

const Automotive = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp" });
	const { uiStore } = useAppStore();
	const navigate = useSecureNavigate();

	useEffect(() => {
		uiStore.showSideBar();
	}, []);

	return (
		<Box className={styles.container}>
			<Box className={styles.contentWrapper}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("landingTitle")}</Typography>
					<Typography className={styles.subTitle}>{t("landingSubtitle")}</Typography>
					<Box className={styles.actions}>
						<BriaButton
							buttonType="primaryMedium"
							onClick={() => navigate(RouterConstants.AUTOMOTIVE_EDITOR.fullPath)}
						>
							{t("startNewProject")}
						</BriaButton>
						{/* <BriaButton buttonType="secondaryMedium">{t("manageProjects")}</BriaButton> */}
					</Box>
				</Box>
			</Box>

			<Box className={styles.bgImages}>
				<img src={AutomotiveBG} />
			</Box>
		</Box>
	);
};

export default observer(Automotive);
