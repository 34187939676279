import { Box, Divider } from "@mui/material";
import { ChevronDown } from "baseui/icon";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../../../../../../components/common/BriaTooltip/BriaTooltip";
import { useAppStore } from "../../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../../CustomUtils/UseDesignEditor";
import bold from "../../../../../assets/svgs/bold.svg";
import highlight from "../../../../../assets/svgs/highlight.svg";
import Italic from "../../../../../assets/svgs/italic.svg";
import strikethrough from "../../../../../assets/svgs/strikethrough.svg";
import fixTextBox from "../../../../../assets/svgs/textFixedSize.svg";
import underline from "../../../../../assets/svgs/underline.svg";
import SmartImageHeader from "../../../../../components/common/SmartImageHeader/SmartImageHeader.tsx";
import { PanelType } from "../../../../../constants/app-options";
import { PopUpSteps } from "../../../../../constants/mock-data.ts";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import useAppContext from "../../../../../hooks/useAppContext";
import { useEditor } from "../../../../../hooks/useEditor";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen";
import { LayerType, type IStaticText } from "../../../../../types";
import { getTextProperties } from "../../../utils/text";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import FabricElementsShadow from "../../Panels/panelItems/Common/FabricElementsShadow/FabricElementsShadow.tsx";
import BrandSettings from "../BrandSettings/BrandSettings.tsx";
import FixedTextBoxSizeCheckBox from "./FixedTextBoxSizeCheckBox/FixedTextBoxSizeCheckBox.tsx";
import FixedTextElementPopover from "./FixedTextElementPopover/FixedTextElementPopover.tsx";
import TextAlign from "./TextAlign/TextAlign";
import TextFontSize from "./TextFontSize/TextFontSize";
import TextLetterCase from "./TextLetterCase/TextLetterCase";
import TextSpacing from "./TextSpacing/TextSpacing";
import styles from "./TextToolBar.module.scss";

interface TextState {
	color: string;
	bold: boolean;
	italic: boolean;
	underline: boolean;
	family: string;
	styleOptions: StyleOptions;
	linethrough: boolean;
}

interface StyleOptions {
	hasItalic: boolean;
	hasBold: boolean;
	options: any[];
}

const TextToolBar = () => {
	const initialOptions: TextState = {
		family: "Lato-Black",
		bold: false,
		italic: false,
		linethrough: false,
		underline: false,
		color: "#1A0638",
		styleOptions: {
			hasBold: true,
			hasItalic: true,
			options: [],
		},
	};
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const [state, setState] = useState<TextState>(initialOptions);
	const activeObject = useActiveObject() as Required<IStaticText>;
	const { setActivePanel } = useAppContext();
	const editor = useEditor();
	const { isPopupView } = useContext(DesignEditorContext);
	const { brandFontStore, designEditorStore, campaignStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.textControllers" });
	const { EditAllAds } = useDesignEditorUtils();
	const TEXT_INDEX = PopUpSteps.indexOf("text");
	useEffect(() => {
		if (activeObject && activeObject.type === "StaticText") {
			if (brandFontStore?.brandOrgFonts?.length == 0) {
				brandFontStore.getAllOrgBrandFonts(true).then(() => {
					const textProperties = getTextProperties(activeObject, brandFontStore?.brandOrgFonts);
					setState({ ...state, ...textProperties });
				});
			} else {
				const textProperties = getTextProperties(activeObject, brandFontStore?.brandOrgFonts);
				setState({ ...state, ...textProperties });
			}
		}
	}, [activeObject]);

	useEffect(() => {
		const watcher = async () => {
			if (activeObject && activeObject.type === "StaticText") {
				const textProperties = getTextProperties(activeObject, brandFontStore?.brandOrgFonts);
				setState({ ...state, ...textProperties });
				campaignStore.setProperty("fixedTextBoxChecked", activeObject?.fixedSize);
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);

	const makeBold = useCallback(() => {
		let fontWeight;
		const currentFontWeight =
			typeof activeObject.fontWeight === "number"
				? activeObject.fontWeight
				: activeObject.fontWeight === "bold"
				? 700
				: 400;
		const isCurrentlyBold = state.bold || currentFontWeight >= 600;

		if (isCurrentlyBold) {
			fontWeight = currentFontWeight >= 600 ? "normal" : currentFontWeight - 300;
			editor?.objects.update(
				{
					fontWeight: fontWeight,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			setState({ ...state, bold: false });
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.BOLD, fontWeight);
		} else {
			fontWeight = currentFontWeight < 600 ? 600 : "bold";
			if (!campaignStore.isEditingAllAds)
				editor?.objects.update(
					{
						fontWeight: fontWeight,
					},
					undefined,
					!campaignStore.isEditingAllAds,
				);
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.BOLD, fontWeight);
			setState({ ...state, bold: true });
		}
	}, [editor, state]);

	const makeItalic = useCallback(async () => {
		let fontStyle = "normal";
		if (state.italic) {
			editor?.objects.update(
				{
					fontStyle: fontStyle,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.ITALIC, fontStyle);
			setState({ ...state, italic: false });
		} else {
			fontStyle = "italic";
			if (!campaignStore.isEditingAllAds)
				editor?.objects.update(
					{
						fontStyle: fontStyle,
					},
					undefined,
					!campaignStore.isEditingAllAds,
				);
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.ITALIC, fontStyle);
			setState({ ...state, italic: true });
		}
	}, [editor, state]);

	const makeUnderline = useCallback(() => {
		if (!campaignStore.isEditingAllAds)
			editor?.objects.update(
				{
					underline: !state.underline,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
		EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.UNDERLINE, !state.underline);
		setState({ ...state, underline: !state.underline });
	}, [editor, state]);

	const makeStrikethrough = useCallback(() => {
		if (!campaignStore.isEditingAllAds)
			editor?.objects.update(
				{
					linethrough: !state.linethrough,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
		EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.LINETHROUGH, !state.linethrough);
		setState({ ...state, linethrough: !state.linethrough });
	}, [editor, state]);

	const handleOpenFixTextBoxDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
		campaignStore.fixTextBoxAnchorEl = event.currentTarget;
	};
	const handleFixTextBoxAnchorElClose = () => {
		campaignStore.fixTextBoxAnchorEl = undefined;
	};

	return (
		<Box
			className={clsx({
				[styles.container]: true,
				[styles.hide]: isPopupView && designEditorStore.activeStep !== TEXT_INDEX,
			})}
		>
			<Box
				className={clsx({
					[styles.textControls]: true,
				})}
			>
				<Box className={styles.textControlsWrapper}>
					<Box
						className={styles.fontSelector}
						onClick={() => {
							setIsSidebarOpen(true);
							setActivePanel(PanelType.FONTSELECTOR);
						}}
					>
						{state?.family?.length > 12 ? state?.family?.slice(0, 12) + "..." : state?.family}
						<Box className={styles.fontSelectorDropdownIcon}>
							<ChevronDown color=" rgba(0, 0, 0, 0.54);" size={24} />
						</Box>
					</Box>
					{activeObject?.fixedSize ? (
						<BriaTooltip title={t("fontSizeSelectionDisabled")} placement="bottom">
							<>{!isPopupView && <TextFontSize />}</>
						</BriaTooltip>
					) : (
						<>{!isPopupView && <TextFontSize />}</>
					)}
					<Divider className={styles.divider} orientation={"vertical"} />

					<Box className={styles.textControlsIcons}>
						<Box
							style={{
								backgroundColor: state.color,
								background: state.color,
								opacity: activeObject?.opacity,
							}}
							onClick={() => {
								setIsSidebarOpen(true);
								setActivePanel(PanelType.TEXTFILL);
							}}
							className={styles.colorBox}
						></Box>
						<BriaTooltip title={t("highlighterColor")} placement="bottom">
							<Box
								onClick={() => {
									setIsSidebarOpen(true);
									setActivePanel(PanelType.TEXTHIGHLIGHTER);
									if (activeObject.textBackgroundColor) {
										editor?.objects.update(
											{
												textBackgroundColor: undefined,
												brandId: undefined,
											},
											undefined,
											!campaignStore.isEditingAllAds,
										);
									}
								}}
								className={styles.textControl}
							>
								<img src={highlight} alt="" />
							</Box>
						</BriaTooltip>
						<Divider className={styles.divider} orientation={"vertical"} />
						<BriaTooltip title={t("bold")} placement="bottom">
							<BriaButton
								className={styles.iconbutton}
								disableRipple
								disabled={state.styleOptions.hasBold}
								onClick={makeBold}
							>
								<img
									className={styles.textControl}
									style={{ ...(!state.bold && { color: "rgb(169,169,169)" }) }}
									src={bold}
									alt=""
								/>
							</BriaButton>
						</BriaTooltip>
						<BriaTooltip title={t("Italic")} placement="bottom">
							<BriaButton
								className={styles.iconbutton}
								disableRipple
								disabled={state.styleOptions.hasItalic}
								onClick={makeItalic}
							>
								<img
									className={styles.textControl}
									style={{ ...(!state.italic && { color: "rgb(169,169,169)" }) }}
									src={Italic}
									alt=""
								/>
							</BriaButton>
						</BriaTooltip>
						<BriaTooltip title={t("Underline")} placement="bottom">
							<BriaButton
								className={styles.iconbutton}
								disableRipple
								disabled={state.styleOptions.hasItalic}
								onClick={makeUnderline}
							>
								<img
									className={styles.textControl}
									style={{ ...(!state.underline && { color: "rgb(169,169,169)" }) }}
									onClick={makeUnderline}
									src={underline}
									alt=""
								/>
							</BriaButton>
						</BriaTooltip>
						<BriaTooltip title={t("lineThrough")} placement="bottom">
							<BriaButton
								className={styles.iconbutton}
								disableRipple
								disabled={state.styleOptions.hasItalic}
								onClick={makeStrikethrough}
							>
								<img
									className={styles.textControl}
									style={{ ...(!state.linethrough && { color: "rgb(169,169,169)" }) }}
									src={strikethrough}
									alt=""
								/>
							</BriaButton>
						</BriaTooltip>
						<FabricElementsShadow showTextShadowIcon={true} />
						<TextLetterCase />
						{!isPopupView && (
							<>
								<Divider className={styles.divider} orientation={"vertical"} /> <TextAlign />
								<Divider className={styles.divider} orientation={"vertical"} /> <TextSpacing />
							</>
						)}
						{!isPopupView && (
							<>
								<Divider className={styles.divider} orientation={"vertical"} />
								{campaignStore.fixedTextBoxChecked && (
									<BriaTooltip title={t("fixTextBox")} placement="bottom">
										<BriaButton
											buttonType="textMedium"
											onClick={handleOpenFixTextBoxDialog}
											className={clsx({
												[styles.fixedTextButtonWrapper]: true,
												[styles.selected]: !!campaignStore.fixTextBoxAnchorEl,
											})}
										>
											<img src={fixTextBox} alt="" />
										</BriaButton>
									</BriaTooltip>
								)}
								<FixedTextBoxSizeCheckBox />
							</>
						)}
					</Box>

					<FixedTextElementPopover
						open={!!campaignStore.fixTextBoxAnchorEl}
						handleClose={handleFixTextBoxAnchorElClose}
						anchorEl={campaignStore.fixTextBoxAnchorEl}
					/>
				</Box>
				{!isPopupView && <EditAllAdsSwitch />}
			</Box>

			<BrandSettings activeObject={activeObject} showBrandColorDropdown={true} showBrandFontDropdown={true} />
			{!isPopupView && <SmartImageHeader />}
		</Box>
	);
};

const ObservedComponent = observer(TextToolBar);
export default ObservedComponent;
