import { Bedtime, WbSunny } from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../context/ThemeContext.tsx";
import { ThemeMode } from "../../../constants/themes.ts";
import BriaButton from "../BriaButton/BriaButton.tsx";
import styles from "./ThemeModeDropdown.module.scss";

const ThemeModeDropdown = () => {
	const { mode, changeTheme } = useTheme();
	const { t } = useTranslation("translation", { keyPrefix: "themeModeDropdown" });
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<BriaButton
				onClick={(event) => {
					handleClick(event);
				}}
				aria-label={t("accountOfCurrentUser")}
				className={styles.modeButton}
				disableRipple
			>
				{mode === ThemeMode.Light ? <WbSunny /> : <Bedtime />}
			</BriaButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				className={styles.menuWrapper}
			>
				<MenuItem
					onClick={() => {
						changeTheme(ThemeMode.Light);
						handleClose();
					}}
					className={clsx(styles.menuItem, {
						[styles.active]: mode === ThemeMode.Light,
					})}
				>
					<WbSunny />
					<Typography variant="inherit">{t("light")}</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						changeTheme(ThemeMode.Dark);
						handleClose();
					}}
					className={clsx(styles.menuItem, {
						[styles.active]: mode === ThemeMode.Dark,
					})}
				>
					<Bedtime />
					<Typography variant="inherit">{t("dark")}</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export default observer(ThemeModeDropdown);
