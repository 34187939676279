import { Box, Popover, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../../../../../assets/images/icons/CloseIcon";
import { EditIcon2 } from "../../../../../../../../assets/images/icons/EditIcon2";
import BriaButton from "../../../../../../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../../../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaIconButton from "../../../../../../../../components/common/BriaIconButton/BriaIconButton";
import BriaInput from "../../../../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../../../../hooks/useStores";
import InputLayout from "../../../../../../../../layout/InputLayout/InputLayout";
import { TgModel, defaultModel } from "../../../../../../Models/store/tg-model-store";
import styles from "./TgModelNameColumn.module.scss";

type TgModelNameColumnProps = {
	model: TgModel;
};

const TgModelNameColumn = ({ model }: TgModelNameColumnProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.dashboard.table.name" });
	const { tgStore } = useAppStore();
	const { tgModelStore } = tgStore;
	const [anchorEl, setAnchorEl] = useState<SVGElement | HTMLButtonElement>();

	useEffect(() => {
		tgModelStore.modelForm = anchorEl ? model : defaultModel;
	}, [anchorEl]);

	const handleOpenDialog = (event: React.MouseEvent<SVGElement | HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	const updateModel = async () => {
		if (tgModelStore.modelForm.name !== model.name || tgModelStore.modelForm.description !== model.description) {
			await tgModelStore.updateModel(model.id, tgModelStore.modelForm);
		}
		handleClose();
	};

	return (
		<>
			<Box className={styles.nameContainer}>
				<Box className={styles.name}>
					<Box>{model.name}</Box>
					<BriaIconButton className={styles.iconButton} title={t("edit")} onClick={handleOpenDialog}>
						<EditIcon2 />
					</BriaIconButton>
				</Box>
				<Box className={styles.description}>{model.description}</Box>
			</Box>
			<Popover
				open={!!anchorEl}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				slotProps={{ paper: { className: styles.popover } }}
			>
				<Box className={styles.contentContainer}>
					<CloseIcon onClick={handleClose} className={styles.closeIcon} />
					<Typography className={styles.title}>{t("popup.title")}</Typography>
					<InputLayout label={t("popup.name.label")}>
						<BriaInput
							placeholder={t("popup.name.placeholder")}
							value={tgModelStore.modelForm.name}
							onChange={(e) => tgModelStore.handleFormChange("name", e.target.value)}
							height="45px"
						/>
					</InputLayout>
					<InputLayout label={t("popup.description.label")}>
						<BriaInput
							placeholder={t("popup.description.placeholder")}
							value={tgModelStore.modelForm.description}
							onChange={(e) => tgModelStore.handleFormChange("description", e.target.value)}
							InputProps={{ className: styles.descriptionInput }}
							inputProps={{ maxLength: 110 }}
						/>
					</InputLayout>
					<BriaButtonGroup className={styles.buttons}>
						<BriaButton className={styles.confirmBtn} buttonType="tertiaryMedium" onClick={handleClose}>
							{t("popup.cancelBtn")}
						</BriaButton>
						<BriaButton
							className={styles.confirmBtn}
							buttonType="primaryMedium"
							onClick={updateModel}
							loading={tgModelStore.loadingUpdateModel}
						>
							{t("popup.confirmBtn")}
						</BriaButton>
					</BriaButtonGroup>
				</Box>
			</Popover>
		</>
	);
};

export default observer(TgModelNameColumn);
