import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import styles from "./BestPracticesPopup.module.scss";

type listType = {
	text: string;
	images?: string[];
};

type useCaseType = {
	title: string;
	description?: string;
	list?: listType[];
	images?: string[];
};

export type IBestPracticeSectionProps = {
	id: string;
	title?: string;
	subTitle?: string;
	description?: string;
	list?: string[];
	useCases?: useCaseType[];
	className?: string;
};

export const BestPracticeSection = ({
	id,
	title,
	subTitle,
	description,
	list,
	useCases,
	className,
}: IBestPracticeSectionProps) => {
	return (
		<Box className={className} id={id}>
			{title && <Typography className={styles.title}>{title}</Typography>}
			{description && <Typography className={styles.descr} dangerouslySetInnerHTML={{ __html: description }} />}
			{subTitle && <Typography className={styles.subTitle}>{subTitle}</Typography>}
			{list && (
				<ul className={styles.list}>
					{list.map((item, index) => {
						return <li className={styles.item} key={index} dangerouslySetInnerHTML={{ __html: item }} />;
					})}
				</ul>
			)}
			{useCases && (
				<Box className={styles.useCasesContainer}>
					{useCases.map((useCase, index) => {
						return (
							<>
								<Box className={styles.useCase} key={index}>
									<Box className={styles.texts}>
										<Typography
											className={styles.useCaseTitle}
											dangerouslySetInnerHTML={{ __html: useCase.title }}
										/>
										{useCase.description && (
											<Typography
												className={styles.descr}
												dangerouslySetInnerHTML={{ __html: useCase.description }}
											/>
										)}
										{useCase.list && (
											<ul className={styles.useCaseList}>
												{useCase.list.map((item, index) => (
													<li className={styles.item} key={index}>
														<span dangerouslySetInnerHTML={{ __html: item.text }} />
														{item.images && (
															<Box className={styles.images}>
																{item.images.map((image, index) => (
																	<Box>
																		<img
																			className={styles.img}
																			key={index}
																			src={image}
																			alt=""
																		/>
																	</Box>
																))}
															</Box>
														)}
													</li>
												))}
											</ul>
										)}
									</Box>

									{useCase.images && (
										<Box className={styles.images}>
											{useCase.images.map((image: string, index: number) => (
												<Box>
													<img className={styles.img} key={index} src={image} alt="" />
												</Box>
											))}
										</Box>
									)}
								</Box>
								{index !== useCases.length - 1 && <Divider className={styles.divider} />}
							</>
						);
					})}
				</Box>
			)}
		</Box>
	);
};

const ObservedComponent = observer(BestPracticeSection);
export default ObservedComponent;
