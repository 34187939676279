import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function EmailIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 69 57"  {...props}>
			<g clip-path="url(#clip0_4245_4452)">
				<path
					d="M59.7019 13.2475C59.3611 12.8285 58.7451 12.7657 58.3261 13.1051L48.3183 21.2467L44.3222 16.5684C43.9716 16.1578 43.3542 16.1089 42.9436 16.4595C42.5329 16.81 42.4841 17.4273 42.8346 17.8378L47.4509 23.2423C47.7959 23.6459 48.3993 23.7004 48.8114 23.3652L59.5609 14.6217C59.762 14.4583 59.8905 14.2209 59.917 13.9639C59.9436 13.7056 59.8667 13.4472 59.7019 13.2461V13.2475Z"
					fill="white"
				/>
				<path
					d="M0.34625 23.9914L22.2461 42.5314C24.4837 44.4363 27.7758 44.4349 30.0121 42.5287L41.9069 32.4417C42.1778 32.2168 42.3077 31.8649 42.2463 31.5186C42.1848 31.1722 41.9418 30.8846 41.6094 30.7673C41.2769 30.6499 40.9082 30.7198 40.6414 30.9502L28.7466 41.0372C27.2381 42.3234 25.0186 42.3248 23.5087 41.04L1.60892 22.4999C1.19688 22.1522 0.580906 22.2039 0.231715 22.6158C-0.117475 23.0278 -0.0657947 23.6437 0.344853 23.9928L0.34625 23.9914Z"
					fill="white"
				/>
				<path
					d="M51.2611 0.500001C41.8665 0.497208 34.1005 7.8219 33.5544 17.1981H6.56478C2.94018 17.2022 0.004193 20.1391 2.72117e-06 23.7616V49.9364C-0.00139404 51.467 0.534962 52.9501 1.51549 54.1259C1.59091 54.2726 1.70265 54.3969 1.83954 54.4876C3.07427 55.7738 4.78112 56.5014 6.56478 56.5H45.6741C47.455 56.5 49.1604 55.7752 50.3937 54.4918C50.4468 54.4569 50.4943 54.4178 50.539 54.3745C50.6116 54.3019 50.6731 54.2195 50.7206 54.1287C51.7025 52.9529 52.2403 51.4684 52.2375 49.9364V35.9448C61.5664 35.4351 68.9986 27.6886 68.9986 18.2357C68.9888 8.44474 61.0538 0.511173 51.2611 0.500001ZM50.2834 49.9364C50.2834 50.7073 50.0906 51.467 49.7191 52.1443L37.9682 40.1902C37.5882 39.8131 36.9751 39.8103 36.5937 40.186C36.2124 40.5616 36.2027 41.1747 36.5742 41.5601L48.4341 53.6246C47.6379 54.2223 46.67 54.5449 45.6741 54.5449H6.56478C5.56749 54.5449 4.59674 54.2209 3.79919 53.6204L15.5948 41.5574C15.8449 41.3088 15.9412 40.9443 15.8477 40.6049C15.7541 40.2656 15.4859 40.0016 15.1437 39.9165C14.8015 39.8313 14.4397 39.936 14.1981 40.1902L2.51697 52.1359C2.14822 51.4614 1.95547 50.7045 1.95686 49.935V23.7602C1.95966 21.2158 4.02128 19.1546 6.56618 19.1518H33.5474C34.0167 28.2025 41.2338 35.4434 50.2848 35.9434V49.935L50.2834 49.9364ZM51.2611 34.0162C42.5439 34.0162 35.4777 26.9513 35.4777 18.2357C35.4777 9.52005 42.5439 2.45511 51.2611 2.45511C59.9783 2.45511 67.0445 9.52005 67.0445 18.2357C67.0445 26.9513 59.9783 34.0162 51.2611 34.0162Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4245_4452">
					<rect width="69" height="56" fill="white" transform="translate(0 0.5)" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
}

export default EmailIcon;