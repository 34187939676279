const CheckIcon = () => (
	<svg width="19" height="16" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.92899 0.350421C6.06288 0.216526 6.27997 0.216526 6.41386 0.350421C6.54776 0.484315 6.54776 0.7014 6.41386 0.835294L2.29958 4.94958C2.16569 5.08347 1.9486 5.08347 1.81471 4.94958L0.100421 3.23529C-0.0334735 3.1014 -0.0334735 2.88431 0.100421 2.75042C0.234315 2.61653 0.4514 2.61653 0.585294 2.75042L2.05714 4.22227L5.92899 0.350421Z"
			fill="var(--primary-main-color)"
		/>
	</svg>
);

export default CheckIcon;
