import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "rsuite";
import TextToImagePopup from "../../../../../../../components/TextToImage/TextToImagePopup/TextToImagePopup";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../../../../../../hooks/useStores";
import useDesignEditorUtils from "../../../../../../CustomUtils/UseDesignEditor";
import StepperNavigation from "../../../../../../StepperNavigation/StepperNavigation";
import { PopUpSteps } from "../../../../../../constants/mock-data";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor";
import styles from "./WizardImages.module.scss";

const WizardImages = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.wizardImages" });
	const { resetWizardImages } = useDesignEditorUtils();
	const { designEditorStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const WIZARD_IMAGE_INDEX = PopUpSteps.indexOf("WizardImages");

	return (
		<Box className={styles.container}>
			<Typography className={styles.title}> {t("replaceImages")} </Typography>
			<Typography className={styles.description}>{t("descriptions")}</Typography>
			<Divider className={styles.divider} />
			<BriaButton
				className={styles.restAllImagesButton}
				buttonType="textMedium"
				loading={designEditorStore.isResettingWizardImages}
				disabled={designEditorStore.isResettingWizardImages || !designEditorStore.isSceneFullyLoaded}
				onClick={resetWizardImages}
			>
				{t("restAllImages")}
			</BriaButton>
			{isPopupView && designEditorStore.activeStep === WIZARD_IMAGE_INDEX && <StepperNavigation />}
			<TextToImagePopup />
		</Box>
	);
};

const ObservedComponent = observer(WizardImages);
export default ObservedComponent;
