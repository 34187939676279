import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../../assets/images/icons/CloseIcon";
import BriaIconButton from "../../../../../components/common/BriaIconButton/BriaIconButton";
import {
	CUSTOM_SIZE_CONFIGS,
	GENERATE_BY_IMAGE_CONFIGS,
	GENERATE_BY_TEXT_CONFIGS,
	SOLID_COLOR_CONFIG,
} from "../../../../../constants/ImageToImageConstants";
import { useAppStore } from "../../../../../hooks/useStores";
import { ConfigItemProps } from "../../../../../models/image-to-image";
import styles from "./ConfigDisplay.module.scss";

const ConfigItem: React.FC<ConfigItemProps> = ({
	configKey,
	onDelete,
	label,
	value,
	onClick,
	children,
	useExactLetterCase,
}) => {
	const [deleteValue, setDeleteValue] = useState<any>(false);
	const { imageToImageStore } = useAppStore();

	useEffect(() => {
		if (configKey === "solid") {
			setDeleteValue(SOLID_COLOR_CONFIG);
		} else if (configKey === "generateByText") {
			setDeleteValue(GENERATE_BY_TEXT_CONFIGS);
		} else if (configKey === "generateByImage") {
			setDeleteValue(GENERATE_BY_IMAGE_CONFIGS);
		} else if (configKey === "aspect_ratio") {
			const updatedValue = (imageToImageStore.config.size?.aspect_ratio?.value as string[])?.filter(
				(item) => item !== value,
			);

			setDeleteValue({
				selected: updatedValue.length > 0,
				value: updatedValue,
			});
		} else if (configKey === "increase_resolution") {
			const updatedValue = (imageToImageStore.config.size?.increase_resolution?.value as string[])?.filter(
				(item) => item !== value,
			);
			setDeleteValue({
				selected: updatedValue.length > 0,
				value: updatedValue,
			});
		} else if (configKey === "custom_size") {
			setDeleteValue(CUSTOM_SIZE_CONFIGS);
		} else if (configKey === "medium_destination") {
			const updatedValue =
				imageToImageStore.config.size?.medium_destination?.destinations?.filter(
					(destination) => !(destination.width === value.width && destination.height === value.height),
				) || [];

			setDeleteValue({
				selected: updatedValue.length > 0,
				platform: imageToImageStore.config.size?.medium_destination?.platform,
				destinations: updatedValue,
			});
		} else if (configKey === "placements") {
			const updatedValue =
				imageToImageStore.config.foreground_placement?.placements?.values?.filter((item) => item !== value) ||
				[];

			setDeleteValue({
				selected: updatedValue.length > 0,
				values: updatedValue,
			});
		} else if (configKey === "prompt") {
			setDeleteValue("");
		} else if (configKey === "tailored_model_id") {
			setDeleteValue(undefined);
		}
	}, [
		configKey,
		value,
		imageToImageStore.config.size?.aspect_ratio?.value,
		imageToImageStore.config.size?.increase_resolution?.value,
		imageToImageStore.config.size?.medium_destination?.platform,
		imageToImageStore.config.size?.medium_destination?.destinations,
		imageToImageStore.config.foreground_placement?.placements,
	]);

	const handleOnClick = async () => {
		await imageToImageStore.handleActiveConfigChange(configKey);
		onClick?.();
	};

	return (
		<Box className={styles.configItem} key={configKey} onClick={handleOnClick}>
			<Typography
				className={clsx(styles.label, {
					[styles.initialTransform]: configKey === "prompt" || configKey === "generateByText",
					[styles.exactLetterCase]: useExactLetterCase,
				})}
			>
				{configKey === "generateByImage" && (
					<div className={styles.thumbContainer}>
						<img className={styles.thumb} src={value} />
					</div>
				)}
				{children || label}
			</Typography>
			<BriaIconButton
				onClick={(event) => {
					event?.stopPropagation();
					onDelete(configKey, deleteValue);
				}}
				className={styles.deleteBtn}
			>
				<CloseIcon />
			</BriaIconButton>
		</Box>
	);
};

export default ConfigItem;
