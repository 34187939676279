import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../hooks/useStores";
import { PanelType } from "../constants/app-options";
import { PopUpSteps } from "../constants/mock-data";
import useAppContext from "../hooks/useAppContext";
import useDesignEditorContext from "../hooks/useDesignEditorContext";
import { useEditor } from "../hooks/useEditor";
import { ObjectsEnum } from "../views/DesignEditor/components/Panels/panelItems";
import { useSmartImageUtils } from "../views/DesignEditor/utils/smartImageUtils";
import styles from "./StepperNavigation.module.scss";
enum StepperActions {
	Back = "back",
	Next = "next",
}

const StepperNavigation = () => {
	const { designEditorStore, brandsDefinitionStore, campaignStore, brandFontStore, imagesStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.popup" });
	const editor = useEditor();
	const { activePanel } = useAppContext();
	const { resetRectangleInAGroup } = useSmartImageUtils();
	const { setCurrentScene } = useDesignEditorContext();
	const BRANDS_INDEX = PopUpSteps.indexOf("brands");
	const CANVAS_COLOR_INDEX = PopUpSteps.indexOf("CanvasColor");
	const isCanvasColorIndex = designEditorStore.activeStep === CANVAS_COLOR_INDEX;
	const isActivePanelTextFill = activePanel === PanelType.TEXTFILL;
	const isActivePanelFontSelector = activePanel === PanelType.FONTSELECTOR;
	const SMART_IMAGE_INDEX = PopUpSteps.indexOf("images");
	const isSmartImageIndex = designEditorStore.activeStep === SMART_IMAGE_INDEX;

	const TEMPLATES_INDEX = PopUpSteps.indexOf("templates");
	const isLastStep = designEditorStore.activeStep + 1 === designEditorStore.editorStepperSteps.length;
	const isFirstStep = designEditorStore.activeStep === TEMPLATES_INDEX;
	const isSmartImageStepAndHasNoSmartImage =
		designEditorStore.activeStep === SMART_IMAGE_INDEX && !designEditorStore.hasSmartImages;
	const isButtonDisabled =
		designEditorStore.isTemplateEmptyState ||
		brandsDefinitionStore.isApplyingBrandConfigurations ||
		brandsDefinitionStore.isResettingBrandConfigurations ||
		designEditorStore.isLoading ||
		campaignStore.isLoadingTemplates ||
		campaignStore.isLoadingPlacements ||
		brandFontStore.isLoading ||
		imagesStore.isGeneratingImages ||
		designEditorStore.isGenerateAdsLoading ||
		designEditorStore.isLoadingSmartImageStep ||
		imagesStore.isUploadingSmartImageMainObject ||
		(designEditorStore.activeStep === BRANDS_INDEX && brandsDefinitionStore.isLoading) ||
		imagesStore.isLoadingWizardImages ||
		designEditorStore.isResettingWizardImages ||
		!designEditorStore.isSceneFullyLoaded;
	const shouldAddPaddingLeft =
		isSmartImageIndex || isCanvasColorIndex || isActivePanelFontSelector || isActivePanelTextFill;

	const shouldAdjustPosition = isCanvasColorIndex || isActivePanelTextFill || isActivePanelFontSelector || isLastStep;

	const pressStepperAction = (action: StepperActions) => {
		let newStep = designEditorStore.activeStep;
		const disabledSteps = designEditorStore.editorStepperDisabledSteps || [];

		if (action === StepperActions.Next) {
			do {
				newStep++;
			} while (disabledSteps.includes(newStep));
		} else if (action === StepperActions.Back) {
			do {
				newStep--;
			} while (disabledSteps.includes(newStep));
		}

		// Ensure the new step is within valid bounds
		const maxSteps = designEditorStore.editorStepperSteps.length - 1;
		if (newStep < 0 || newStep > maxSteps) {
			return;
		}

		setActiveStep(newStep);
	};

	const setActiveStep = (newStep: number) => {
		designEditorStore.setProperty("newStep", newStep);
	};

	return (
		<Box
			className={clsx(styles.stepperActions, {
				[styles.bottom]: isFirstStep || BRANDS_INDEX,
				[styles.zeroPaddingbottom]: isLastStep,
				[styles.paddingLeft]: shouldAddPaddingLeft,
				[styles.position]: shouldAdjustPosition,
			})}
		>
			{!isFirstStep && (
				<BriaButton
					onClick={async () => {
						pressStepperAction(StepperActions.Back);
						if (designEditorStore.activeStep === SMART_IMAGE_INDEX) {
							if (editor) {
								designEditorStore.setProperty("isSceneFullyLoaded", false);
								const _currentScene = editor.scene.exportToJSON();
								await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
								await resetRectangleInAGroup(_currentScene, ObjectsEnum.InnerRectangle);
								const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
								const updatedScene = {
									..._currentScene,
									preview: updatedPreview,
									duration: 1000,
								};
								setCurrentScene(updatedScene);
							}
						}
					}}
					className={styles.button}
					buttonType="textMedium"
					disabled={isFirstStep || isButtonDisabled}
				>
					{t("buttons.previous")}
				</BriaButton>
			)}
			{!isLastStep && (
				<BriaButton
					onClick={() => {
						pressStepperAction(StepperActions.Next);
					}}
					className={styles.button}
					buttonType="primaryMedium"
					disabled={isLastStep || isButtonDisabled || isSmartImageStepAndHasNoSmartImage}
					loading={designEditorStore.isLoadingSmartImageStep}
				>
					{t("buttons.next")}
				</BriaButton>
			)}
		</Box>
	);
};

const ObservedComponent = observer(StepperNavigation);
export default ObservedComponent;
