const CloudUpload = () => (
	<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.83354 15.5C4.79377 15.5 3.79659 15.1049 3.06136 14.4017C2.32613 13.6984 1.91309 12.7446 1.91309 11.75C1.91309 10.7554 2.32613 9.80161 3.06136 9.09835C3.79659 8.39509 4.79377 8 5.83354 8C6.07911 6.90598 6.79751 5.94457 7.8307 5.32726C8.34228 5.0216 8.91575 4.80962 9.51835 4.70343C10.121 4.59723 10.7409 4.5989 11.3428 4.70833C11.9447 4.81777 12.5167 5.03283 13.0263 5.34123C13.5358 5.64964 13.9729 6.04535 14.3125 6.50577C14.6521 6.9662 14.8877 7.48232 15.0057 8.02466C15.1237 8.56701 15.1218 9.12496 15.0002 9.66667H15.8335C16.6071 9.66667 17.349 9.97396 17.8959 10.5209C18.4429 11.0679 18.7502 11.8098 18.7502 12.5833C18.7502 13.3569 18.4429 14.0987 17.8959 14.6457C17.349 15.1927 16.6071 15.5 15.8335 15.5H15.0002M7.50024 13L10.0002 10.5M10.0002 10.5L12.5002 13M10.0002 10.5V18"
			stroke="var(--secondary-color)"
			strokeOpacity="0.5"
			strokeWidth="1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default CloudUpload;
