import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EmailIcon from "../../../assets/images/icons/EmailIcon";
import styles from "./ResetPasswordConfirmation.module.scss";

interface IProps {
	email: string;
}

const ResetPasswordConfirmation: React.FC<IProps> = ({ email }) => {
	const { t } = useTranslation();

	return (
		<Box className={styles.resetPasswordConfirmation}>
			<Box className={styles.emailSent}>
				<Typography className={styles.mainTitle}>{t("emailSent")}</Typography>
			</Box>

			<EmailIcon className={styles.icon} width={60}/>

			<Box textAlign="center" padding="10px">
				<Typography className={styles.description}>
					{t("weSentAPasswordResetLinkTo")}
					<span className={styles.boldFont}>{email}</span>
				</Typography>
			</Box>
		</Box>
	);
};

export default ResetPasswordConfirmation;
