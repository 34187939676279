export const isValidEmail = (email: string) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(email);
};

export const hasNonEmptyValues = (obj: any): boolean =>
	Object.values(obj).some((value) =>
		value !== null && typeof value === "object" ? hasNonEmptyValues(value) : !!value,
	);

export type ruleSetType = {
	required?: boolean;
	min?: number;
	max?: number;
	custom?: string;
	customErrorMessage?: string;
	onlyNumbers?: boolean;
	onlyPositiveNumbers?: boolean;
};

const customValidations: Record<string, (value: any, payload?: Record<string, any>) => boolean> = {
	isValidHexColor: (value: string) => {
		if (value === "") return true;
		const hexColorRegex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
		return hexColorRegex.test(value);
	},
	promptOrImageRef: (value: string, payload) => {
		if (!payload) return false;
		if (
			payload["name"] === "bg_prompt" &&
			((!!value && !!payload["ref_image_file"]) || (!value && !payload["ref_image_file"]))
		) {
			return false;
		}
		if (
			payload["name"] === "ref_image_file" &&
			((!!value && !!payload["bg_prompt"]) || (!value && !payload["bg_prompt"]))
		) {
			return false;
		}

		return true;
	},
};

export const validateField = (ruleSet: ruleSetType, value: unknown, payload?: Record<string, any>): string | null => {
	if (ruleSet.required && (value === null || value === undefined || value === "")) {
		return "This field is required.";
	}

	if (ruleSet.min !== undefined && typeof value === "number" && value < ruleSet.min) {
		return `Value must be at least ${ruleSet.min}.`;
	}

	if (ruleSet.max !== undefined && typeof value === "number" && value > ruleSet.max) {
		return `Value must be no more than ${ruleSet.max}.`;
	}

	if (ruleSet.onlyNumbers) {
		if ((typeof value === "string" && value.trim() === "") || value === null) {
			return null;
		}

		const isNumber = /^-?\d+(\.\d+)?$/.test(String(value));
		if (!isNumber) {
			return "Value must be a valid number.";
		}
	}

	if (ruleSet.onlyPositiveNumbers) {
		const isPositiveOrZeroNumber = /^\d+(\.\d+)?$/.test(String(value));
		if (!isPositiveOrZeroNumber || Number(value) < 0) {
			return "Value must be a positive number or zero.";
		}
	}

	if (ruleSet.custom && customValidations[ruleSet.custom] && !customValidations[ruleSet.custom](value, payload)) {
		return ruleSet.customErrorMessage || "Invalid value.";
	}

	return null; // No Errors
};

export const validateVideoDuration = async (file: File, maxDuration: number): Promise<void> => {
	return new Promise((resolve, reject) => {
		const videoElement = document.createElement("video");
		videoElement.preload = "metadata";

		videoElement.onloadedmetadata = () => {
			URL.revokeObjectURL(videoElement.src);
			const duration = videoElement.duration;
			if (duration >= maxDuration) {
				reject(new Error(`Please upload a video no longer than ${maxDuration} seconds.`));
			} else {
				resolve();
			}
		};

		videoElement.onerror = () => {
			reject(new Error("Invalid video file or unable to retrieve metadata."));
		};

		videoElement.src = URL.createObjectURL(file);
	});
};
