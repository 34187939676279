import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../assets/images/icons/CrownIcon";
import { getSelectedOrganization } from "../../../helpers/localStorage";
import { useAppStore } from "../../../hooks/useStores";
import { IEventLog } from "../../../models/eventsLog.ts";
import BriaButton, { ButtonTypes } from "../../common/BriaButton/BriaButton";
import ConfirmationPopup from "../../common/ConfirmationPopup/ConfirmationPopup";
import RequestAccessForm from "../../common/RequestAccessForm/RequestAccessForm";
import styles from "./RequestAccessContainer.module.scss";

export type RequestAccessContainerProps = {
	children?: ReactNode;
	requestFeature: string;
	eventLog?: IEventLog;
	buttonType?: ButtonTypes;
	showCrown?: boolean;
	crownColor?: string;
	buttonMessage: string;
	buttonLoading?: boolean;
	requestTitle?: string;
	requestMessage?: string;
	submissionMessage?: string;
	className?: string;
	paperClass?: string;
	condition?: boolean;
	modelId?: string;
	modelName?: string;
	projectId?: string;
	projectName?: string;
};

const RequestAccessContainer = ({
	children,
	requestFeature,
	eventLog,
	buttonType = "primary",
	showCrown = true,
	crownColor = "white",
	buttonMessage,
	buttonLoading = false,
	className,
	paperClass,
	submissionMessage,
	requestTitle,
	requestMessage,
	condition = true,
	modelId,
	modelName,
	projectId,
	projectName,
}: RequestAccessContainerProps) => {
	const [openRequest, setOpenRequest] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [message, setMessage] = useState("");
	const [openSuccessEmail, setOpenSuccessEmail] = useState(false);
	const { t } = useTranslation("translation", { keyPrefix: "requestAccessContainer" });
	const { authStore, appStore } = useAppStore();

	return (
		<Box className={clsx(styles.container, className)}>
			{children && condition ? (
				<>{children}</>
			) : (
				<BriaButton
					className={clsx({
						[styles.primaryButton]: true,
						[styles.requestAccessButtonContainer]: true,
						[styles.secondaryMedium]: buttonType === "secondaryMedium",
						[styles.textMedium]: buttonType === "textMedium",
					})}
					loading={buttonLoading}
					disabled={buttonLoading}
					buttonType={buttonType}
					onClick={() => setOpenRequest(true)}
				>
					{showCrown && <CrownIcon stroke={crownColor} />}
					{buttonMessage}
				</BriaButton>
			)}

			<ConfirmationPopup
				onClose={() => setOpenRequest(false)}
				title={requestTitle ? requestTitle : t("requestAccess.title")}
				description={requestMessage ? requestMessage : t("requestAccess.description")}
				confirmButtonText={t("requestAccess.primaryButton")}
				firstButtonText={t("requestAccess.secondaryButton")}
				hideCancel={true}
				open={openRequest}
				onClick={async () => {
					const userEmail = authStore?.user?.email || "";
					const userName = authStore?.user?.userName || "";
					const orgName = getSelectedOrganization()?.organization.name || "";
					const response = await appStore.sendRequestAccess(
						userName,
						userEmail,
						orgName,
						message,
						phoneNumber,
						requestFeature,
						modelId,
						modelName,
						projectId,
						projectName,
					);
					if (response) {
						eventLog &&
							appStore.logEvent({
								properties: {
									user_name: userName,
									user_email: userEmail,
									org_name: orgName,
									phone_number: phoneNumber,
									...(modelId ? { model_id: modelId } : {}),
									...(modelName ? { model_name: modelName } : {}),
									...(projectId ? { project_id: projectId } : {}),
									...(projectName ? { project_name: projectName } : {}),
									message,
									...eventLog?.properties,
								},
								...eventLog,
							});
						setOpenSuccessEmail(true);
					}
					setOpenRequest(false);
				}}
				paperClassName={clsx(styles.requestAccessCustomPaperClass, paperClass)}
				loading={appStore.isSendRequestAccessLoading}
			>
				<RequestAccessForm
					setMessage={setMessage}
					message={message}
					setPhoneNumber={setPhoneNumber}
					phoneNumber={phoneNumber}
				/>
			</ConfirmationPopup>

			<ConfirmationPopup
				onClose={() => setOpenSuccessEmail(false)}
				title={t("successRequestAccess.title")}
				description={submissionMessage ? submissionMessage : t("successRequestAccess.description")}
				confirmButtonText={t("successRequestAccess.primaryButton")}
				open={openSuccessEmail}
				onClick={async () => setOpenSuccessEmail(false)}
				hideCancel={true}
				paperClassName={styles.customPaperClass}
			/>
		</Box>
	);
};

const ObservedComponent = observer(RequestAccessContainer);
export default ObservedComponent;
