import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../components/common/BriaButton/BriaButton.tsx";
import CustomDialog from "../components/common/CustomDialog/CustomDialog.tsx";
import HorizontalLinearStepper from "../components/common/HorizontalLinearStepper/HorizontalLinearStepper.tsx";
import LoadingPlaceholder from "../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { useAppStore } from "../hooks/useStores.tsx";
import BriaDesignEditor from "./BriaDesignEditor.tsx";
import useCampaignHeaderUtils from "./CustomUtils/CampaignHeaderUtils.tsx";
import StepperNavigation from "./StepperNavigation/StepperNavigation.tsx";
import { PopUpSteps } from "./constants/mock-data.ts";
import styles from "./styles/DesignEditorPopup.module.scss";

const BriaDesignEditorPopup = () => {
	const { saveNewCampaign, isLoading } = useCampaignHeaderUtils();
	const { designEditorStore, campaignStore, imagesStore, playgroundStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.popup" });
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const CAMPAIGN_INDEX = PopUpSteps.indexOf("campaigns");
	const isLastStep = designEditorStore.activeStep + 1 === designEditorStore.editorStepperSteps.length;

	const handleCloseClick = () => {
		designEditorStore.setProperty("designEditorPopup", false);
		imagesStore.clearSmartImageForm();
		playgroundStore.setTextToGenerate("");
	};

	const setActiveStep = (newStep: number) => {
		designEditorStore.setProperty("newStep", newStep);
	};

	const renderPopupActionsHeader = () => (
		<Box className={styles.popupActionsHeader}>
			<Box className={styles.row1}>
				<Typography className={styles.title}>{t("title")}</Typography>
				<CloseIcon onClick={handleCloseClick} className={styles.closeIcon} />
			</Box>
			<Box className={styles.separator} />
			<Box className={styles.row2}>
				<HorizontalLinearStepper
					steps={designEditorStore.editorStepperSteps.map((step) => step.title)}
					activeStep={designEditorStore.activeStep}
					readonly
					setActiveStep={setActiveStep}
					disabledStep={designEditorStore.editorStepperDisabledSteps}
					disableAll={designEditorStore.activeStep === CAMPAIGN_INDEX}
				/>
				<Box className={styles.buttons}>
					{designEditorStore.isLoadingTemplate ? (
						<Box className={styles.loadingContainer}>
							<Typography>{t("buttons.templateLoading")}</Typography>
							<LoadingPlaceholder className={styles.loadingPlaceholder} isLoading={true} size={15} />
						</Box>
					) : (
						<>
							{!designEditorStore.hideCampaignResultContent ? (
								<BriaButton
									onClick={async () => {
										campaignStore.setProperty("isSavingToMyCampaign", true);
										const newTemplateId = await saveNewCampaign({
											templateName: designEditorStore.campaignName,
										});
										newTemplateId && designEditorStore.setProperty("designEditorPopup", false);
									}}
									className={styles.saveToMyCampaignButton}
									buttonType="primary"
									disabled={designEditorStore.campaignUpdatedTemplate === undefined || isLoading}
									loading={isLoading}
								>
									{t("buttons.saveToMyCampaign")}
								</BriaButton>
							) : (
								<>
									<>{isLastStep && <StepperNavigation />}</>
								</>
							)}
						</>
					)}
				</Box>
			</Box>
			<Box className={styles.separator} />
		</Box>
	);

	return (
		<CustomDialog
			open={designEditorStore.designEditorPopup}
			onClose={() => {
				handleCloseClick();
			}}
			containerClassName={styles.container}
		>
			{renderPopupActionsHeader()}
			<BriaDesignEditor isPopupView={true} />
			<Snackbar
				autoHideDuration={2000}
				open={!!successMessage}
				onClose={() => setSuccessMessage(null)}
				className={styles.saveSuccessToast}
			>
				<Alert severity="success">{successMessage}</Alert>
			</Snackbar>
		</CustomDialog>
	);
};

const ObservedComponent = observer(BriaDesignEditorPopup);
export default ObservedComponent;
