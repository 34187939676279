import {
	AuthProvider,
	User as FirebaseUser,
	SAMLAuthProvider,
	applyActionCode,
	checkActionCode,
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInAnonymously,
	signInWithEmailAndPassword,
	signInWithPopup,
} from "firebase/auth";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { firebaseAuth } from "../config/firebase.ts";
import { USER_ORGANIZATION_ROLES, USER_SETTINGS } from "../constants/UserConstants.ts";
import { createFirebaseUser, getUserWithUID } from "../helpers/firebase.ts";
import { IframePostMessageTypes } from "../models/new-iframe.ts";
import { UserOrganizationFlat } from "../models/organization.ts";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";
import useErrorToast from "./useErrorToast.tsx";
import useSecureNavigate from "./useSecureNavigate.tsx";
import { useAppStore } from "./useStores.tsx";

export const useAuthService = () => {
	const errorToast = useErrorToast();
	const navigate = useSecureNavigate();
	const { t } = useTranslation();
	const EXCLUSION_EXCEPTIONS_LIST = ["auth/popup-closed-by-user", "auth/cancelled-popup-request"];
	const [loading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<boolean | string>(false);
	const { authStore, analyticsStore } = useAppStore();

	const loginAsGuest = async () => {
		return signInAnonymously(firebaseAuth);
	};

	const loginWithIframeSso = async (providerId: string) => {
		return await authenticateWithPopup(new SAMLAuthProvider(providerId), [
			{
				org_uid: iframeStore.iframe.organization.id,
				role: USER_ORGANIZATION_ROLES.USER,
				status: "active",
			},
		]);
	};

	const loginWithEmail = async (
		email: string,
		password: string,
		continueUrl?: string,
		state?: any,
		onSuccessCallback?: () => void,
	) => {
		setIsLoading(true);
		try {
			await signInWithEmailAndPassword(firebaseAuth, email, password);
			onSuccessCallback?.();
			if (continueUrl) {
				navigate(continueUrl, { state: state });
			}
		} catch (error: any) {
			setErrorMessage(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	async function createFirebaseUserAndUpdateStore(
		userToRegister: FirebaseUser,
		getInfo: boolean,
		username?: string,
		company?: string,
		role?: string,
		userOrganizations?: UserOrganizationFlat[],
	) {
		await createFirebaseUser(userToRegister, getInfo, username, company, role, userOrganizations);

		// Retry getting user if it doesn't exist immediately
		let updatedUser = await getUserWithUID(userToRegister.uid);
		if (!updatedUser) {
			await new Promise((resolve) => setTimeout(resolve, 500));
			updatedUser = await getUserWithUID(userToRegister.uid);
		}

		await authStore.setUser(updatedUser);
		await authStore.setPostRegistrationConfigs();
		analyticsStore.logRegistrationEvent();
		return updatedUser;
	}

	const authenticateWithPopup = async (provider: AuthProvider, userOrganizations?: UserOrganizationFlat[]) => {
		try {
			const result = await signInWithPopup(firebaseAuth, provider);
			const userToRegister = result.user;

			if (!userToRegister) {
				errorToast.showError();
				return Promise.reject(t("somethingWentWrong"));
			}

			const userObject = await getUserWithUID(userToRegister.uid);

			if (userObject) {
				// Login
				return Promise.resolve(userObject?.getInfo ?? true);
			} else {
				// Signup
				try {
					const updatedUser = await createFirebaseUserAndUpdateStore(
						userToRegister,
						false,
						undefined,
						undefined,
						undefined,
						userOrganizations,
					);
					return Promise.resolve(updatedUser?.getInfo ?? true);
				} catch (error) {
					errorToast.showError(t("couldntRegisterFirebaseUser"));
					return Promise.reject(error);
				}
			}
		} catch (error: any) {
			console.log("authenticateWithRedirect error", error);
			if (iframeStore.isIframe()) {
				iframeStore.showIframeAuthErrorPage = true;
				iframeStore.sendPostMessage(IframePostMessageTypes.AuthFailed);
			}
			if (error.code === "auth/invalid-email") {
				errorToast.showError(t("emailIsInvalid"));
				if (!iframeStore.isIframe()) {
					return Promise.reject(t("emailIsInvalid"));
				}
			} else if (error.code === "auth/account-exists-with-different-credential") {
				errorToast.showError(t("userExistsWithDifferentCredentials"));
				if (!iframeStore.isIframe()) {
					return Promise.reject(t("userExistsWithDifferentCredentials"));
				}
			} else if (error.code === "auth/popup-blocked") {
				if (!iframeStore.isIframe()) {
					errorToast.showError(t("popupBlocked"));
					return Promise.reject(t("popupBlocked"));
				}
			} else if (!EXCLUSION_EXCEPTIONS_LIST.includes(error.code)) {
				errorToast.showError();
				if (!iframeStore.isIframe()) {
					return Promise.reject(error);
				}
			}
			authStore.registrationSource = undefined;
		}
	};

	const registerWithEmail = async (
		username: string,
		email: string,
		password: string,
		company?: string,
		role?: string,
	) => {
		try {
			setIsLoading(true);
			const result = await createUserWithEmailAndPassword(firebaseAuth, email, password);
			const userToRegister = await result.user;
			if (!userToRegister) {
				errorToast.showError();
				return Promise.reject(t("somethingWentWrong"));
			}
			const updatedUser = await createFirebaseUserAndUpdateStore(userToRegister, true, username, company, role);
			authStore.user?.updateSettings(USER_SETTINGS.hidePromotionPopup, true);
			setIsLoading(false);
			return Promise.resolve(updatedUser?.getInfo ?? true);
		} catch (error: any) {
			setIsLoading(false);
			setErrorMessage(error.message);
			authStore.registrationSource = undefined;
			return Promise.reject(error);
		}
	};

	const getActionCodeSettings = (continueUrl?: string, state?: any) => {
		if (continueUrl) {
			const newUrl = new URL(`${location.origin}${continueUrl}`);
			if (state && state.prompt) {
				newUrl.searchParams.set("prompt", encodeURIComponent(state.prompt));
			}
			continueUrl = newUrl.toString();
		}
		return continueUrl
			? {
					url: continueUrl,
			  }
			: undefined;
	};

	const resetPassword = async (email: string, continueUrl?: string, state?: any) => {
		setIsLoading(true);
		return sendPasswordResetEmail(firebaseAuth, email, getActionCodeSettings(continueUrl, state))
			.then(() => {
				setErrorMessage(false);
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const confirmPasswordResetFunction = (code: string, newPassword: string) => {
		setIsLoading(true);
		return confirmPasswordReset(firebaseAuth, code, newPassword)
			.then((res) => res)
			.catch((error) => {
				setErrorMessage(error.code);
				// var errorCode = error.code;
				// var errorMessage = error.message;
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const sendEmailVerificationFunction = async (continueUrl?: string, state?: any) => {
		return await sendEmailVerification(firebaseAuth.currentUser!, getActionCodeSettings(continueUrl, state));
	};

	const verifyUser = async (code: string) => {
		await checkActionCode(firebaseAuth, code);
		await applyActionCode(firebaseAuth, code);
	};

	return {
		loginAsGuest,
		loginWithEmail,
		loginWithIframeSso,
		registerWithEmail,
		authenticateWithPopup,
		resetPassword,
		confirmPasswordResetFunction,
		sendEmailVerificationFunction,
		verifyUser,
		loading,
		errorMessage,
		setErrorMessage,
	};
};
