import { Box, FormControlLabel, Typography } from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ObjectsEnum } from "..";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton";
import BriaCheckbox from "../../../../../../../components/common/BriaCheckbox/BriaCheckbox";
import { DropDownItem } from "../../../../../../../components/common/BriaDropDown/BriaDropDown";
import BriaInput from "../../../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../../../hooks/useStores";
import InputLayout from "../../../../../../../layout/InputLayout/InputLayout";
import useScenesUtils from "../../../../../../CustomUtils/ScenesUtils";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import useDesignEditorContext from "../../../../../../hooks/useDesignEditorContext";
import { useEditor } from "../../../../../../hooks/useEditor";
import { ILayer, IScene } from "../../../../../../types";
import { useSmartImageUtils } from "../../../../utils/smartImageUtils";
import MediaComponent from "../Templates/MediaComponent/MediaComponent";
import styles from "./Resize.module.scss";

const MAX_FRAME_WIDTH = 13000;
const MAX_FRAME_HEIGHT = 5000;

const Resize: React.FC = () => {
	const { t } = useTranslation("translation");
	const { t: t1 } = useTranslation("translation", { keyPrefix: "editor.tabs.resize" });
	const editor = useEditor();
	const { currentDesign, setCurrentDesign, currentScene, setCurrentScene, setScenes } = useDesignEditorContext();
	const { campaignStore } = useAppStore();
	const [selectedPlacement, setSelectedPlacement] = useState<string | string[]>("");
	const [heightValue, setHeightValue] = useState<number | undefined>(currentScene?.frame.height);
	const [widthValue, setWidthValue] = useState<number | undefined>(currentScene?.frame.width);
	const [constrainProportionsChecked, setConstrainProportionsChecked] = useState<boolean>(false);
	const { updateScenes } = useScenesUtils();
	const { waitForRender } = useSmartImageUtils();
	const placements = campaignStore.placements.items.map((plac) => ({
		key: plac.name,
		value: plac.name,
		width: plac.width,
		height: plac.height,
	}));

	const items: DropDownItem[] = [...placements];

	useEffect(() => {
		if (!campaignStore.isResizingCanvas) {
			setWidthValue(currentScene?.frame.width);
			setHeightValue(currentScene?.frame.height);
		}
	}, [currentScene]);

	useEffect(() => {
		if (widthValue && heightValue) {
			campaignStore.orginalCanvasAspectRatio = widthValue / heightValue;
		}
		return () => {
			campaignStore.originalScenesBeforeResizing = null;
		};
	}, []);

	const onChangePlacementDropdown = (value: string | string[]) => {
		setSelectedPlacement(value);
		const selectedPlacmentValue = placements.find((placement) => placement.value === value);
		if (selectedPlacmentValue) {
			applyResize(selectedPlacmentValue.width, selectedPlacmentValue.height, value, false);
			setWidthValue(selectedPlacmentValue.width);
			setHeightValue(selectedPlacmentValue.height);
		}
	};

	/**
	 * Applies resizing to the current scene, ensuring all elements are scaled relative to the original
	 * frame size (the initial dimensions before any resizing).
	 *
	 * On the first resize call, this function checks if the original frame dimensions are stored in
	 * `campaignStore.sceneToUpdateBeforeResize`. If not, it saves the initial frame dimensions to use
	 * as a reference for all subsequent resizing actions.
	 *
	 * If the current frame dimensions do not match the initial frame dimensions, the frame is reset
	 * to the original dimensions before applying the new resize. This ensures all elements are resized
	 * relative to the original dimensions, rather than incrementally based on previous resizes.
	 *
	 * @param widthValue - The target width for the resized frame.
	 * @param heightValue - The target height for the resized frame.
	 */
	const applyResize = async (
		widthValue: number,
		heightValue: number,
		placementValue?: string | string[],
		customSize?: boolean,
	) => {
		if (editor) {
			campaignStore.isResizingCanvas = true;
			const width = widthValue;
			const height = heightValue;
			const SceneName =
				!customSize && typeof placementValue === "string"
					? placementValue
					: t1("key", { width: width, height: height });
			const scenesBeforeResize = await updateScenes();
			if (!campaignStore.originalScenesBeforeResizing && scenesBeforeResize) {
				campaignStore.originalScenesBeforeResizing = _.cloneDeep(scenesBeforeResize);
			}
			const sceneToUpdateBeforeResize = campaignStore.originalScenesBeforeResizing?.find(
				(scene: IScene) => scene.id === currentScene?.id,
			);
			if (sceneToUpdateBeforeResize) {
				sceneToUpdateBeforeResize.name = SceneName;
				sceneToUpdateBeforeResize && setCurrentScene(sceneToUpdateBeforeResize);
			}

			await waitForRender(2000);
			const oldWidth = editor.frame.frame.width;
			const oldHeight = editor.frame.frame.height;

			// Calculate scale factor based on the dominant dimension to maintain aspect ratio
			const scale = Math.min(width / oldWidth, height / oldHeight);

			editor.frame.resize({ width, height });
			setCurrentDesign({
				...currentDesign,
				frame: { width, height },
			});
			const scenesAfterResize = await updateScenes();
			const sceneToUpdateAfterResize = scenesAfterResize.find((scene) => scene.id === currentScene?.id);
			// Ensure original scene state is saved once and remains unmodified
			if (sceneToUpdateAfterResize && sceneToUpdateBeforeResize) {
				for (let layer of sceneToUpdateAfterResize.layers) {
					if (
						layer.id !== ObjectsEnum.InitialFrame &&
						layer.id !== ObjectsEnum.FRAME &&
						(layer.left || layer.left === 0) &&
						(layer.top || layer.top === 0)
					) {
						const matchingLayer = sceneToUpdateBeforeResize.layers.find(
							(l: Partial<ILayer>) => l.id === layer.id,
						);

						if (
							matchingLayer &&
							(matchingLayer.left || matchingLayer.left === 0) &&
							(matchingLayer.top || matchingLayer.top === 0) &&
							layer.scaleX &&
							layer.scaleY
						) {
							// Apply the uniform scale factor to both position and size
							layer.left = matchingLayer.left * scale;
							layer.top = matchingLayer.top * scale;
							layer.scaleX = layer.scaleX * scale;
							layer.scaleY = layer.scaleY * scale;
						}
					}
				}
				if (!campaignStore.scenesAfterResizing) {
					campaignStore.setProperty("scenesAfterResizing", {});
				}

				const updatedPreview = (await editor?.renderer.render(sceneToUpdateAfterResize)) as string;
				const updatedScenes = scenesAfterResize.map((scene: IScene) => {
					if (scene.id === sceneToUpdateAfterResize.id) {
						return { ...sceneToUpdateAfterResize, preview: updatedPreview };
					}
					return scene;
				}) as IScene[];
				const updatedScene = { ...sceneToUpdateAfterResize, preview: updatedPreview, duration: 1000 };
				setScenes(updatedScenes);
				const updatedSceneId = updatedScene.id;
				if (campaignStore.scenesAfterResizing) campaignStore.scenesAfterResizing[updatedSceneId] = updatedScene;

				updatedScene.name = SceneName;
				setCurrentScene(updatedScene);
			}

			editor.scene.name =
				!customSize && typeof placementValue === "string"
					? placementValue
					: t1("key", { width: width, height: height });
			editor.history.save();
			campaignStore.isResizingCanvas = false;
		}
	};

	const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		if (inputValue.trim() === "") {
			setWidthValue(undefined);
			return;
		}
		const numericWidthValue = parseFloat(inputValue);
		if (isNaN(numericWidthValue) || numericWidthValue <= 0 || numericWidthValue > MAX_FRAME_WIDTH) {
			setWidthValue(widthValue);
			return;
		}
		if (constrainProportionsChecked && heightValue && campaignStore.orginalCanvasAspectRatio) {
			const newHeightValue = numericWidthValue / campaignStore.orginalCanvasAspectRatio;
			setHeightValue(newHeightValue);
		}

		setWidthValue(numericWidthValue);
	};

	const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		if (inputValue.trim() === "") {
			setHeightValue(undefined);
			return;
		}
		const numericHeightValue = parseFloat(inputValue);
		if (isNaN(numericHeightValue) || numericHeightValue <= 0 || numericHeightValue > MAX_FRAME_HEIGHT) {
			setHeightValue(heightValue);
			return;
		}
		setHeightValue(numericHeightValue);
	};

	return (
		<Box className={styles.container}>
			<MainTabHeader title={t("editor.panels.panelsList.resizeCanvas")} />
			<Box className={clsx(styles.tabs, styles.fullHeight)}>
				<Box>
					<MediaComponent
						selectedPlacements={selectedPlacement}
						onChangePlacementDropdown={onChangePlacementDropdown}
						items={items}
						loading={campaignStore.isLoadingPlacements}
						disabled={campaignStore.isResizingCanvas}
						multiple={false}
						selectMultipleCheckbox={false}
					/>
					<Typography className={styles.or}>{t("editor.tabs.resize.or")}</Typography>
					<InputLayout label={t1("customSize")} className={styles.input}>
						<Box className={styles.resolution}>
							<Box className={styles.resolutionContainer}>
								<Typography>{t("editor.tabs.resize.widthLabel")}</Typography>
								<BriaInput
									value={widthValue}
									onChange={handleWidthChange}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
							<Box className={styles.resolutionContainer}>
								<Typography>{t("editor.tabs.resize.heightLabel")}</Typography>
								<BriaInput
									value={heightValue}
									onChange={handleHeightChange}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
						</Box>
					</InputLayout>
					<FormControlLabel
						control={
							<BriaCheckbox
								checked={constrainProportionsChecked}
								onChange={(e) => {
									setConstrainProportionsChecked(e.target.checked);
									if (e.target.checked && widthValue && heightValue) {
										campaignStore.orginalCanvasAspectRatio = widthValue / heightValue;
									}
								}}
							/>
						}
						label={t1("ConstrainProportions")}
					/>
					<Box className={styles.resizeButtonContainer}>
						<BriaButton
							disabled={!widthValue || !heightValue || campaignStore.isResizingCanvas}
							onClick={async () => {
								if (widthValue && heightValue)
									await applyResize(widthValue, heightValue, undefined, true);
							}}
							className={styles.resizeButton}
							buttonType="primary"
						>
							{t("resize")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Resize;
