import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import InfoIcon from "../../../../../assets/images/icons/InfoIcon";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip";
import styles from "./ConfigButtonLabel.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
	label?: string;
	labelClassName?: string;
	labelSize?: string;
	info?: string;
	className?: string;
	isPrimary?: boolean;
}

const ConfigButtonLabel = ({ label, labelClassName, labelSize, info, className, isPrimary }: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground" });
	return (
		<Box className={clsx(styles.container, className)}>
			<Box className={clsx(styles.textLabel, labelClassName)}>
				<Typography
					className={clsx(styles.inputLabel, {
						[styles.largeLabel]: labelSize === "large",
					})}
				>
					{label || <span>&nbsp;</span>}
				</Typography>
				{isPrimary && <Box className={styles.primaryLabel}><Typography>{t("primary")}</Typography></Box>}
				{info && (
					<BriaTooltip
						title={info}
						className={styles.info}
						componentsProps={{ tooltip: { className: styles.tooltip } }}
						displayOn="click"
					>
						<InfoIcon className={styles.infoIcon} />
					</BriaTooltip>
				)}
			</Box>
		</Box>
	);
};

export default observer(ConfigButtonLabel);
