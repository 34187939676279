import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PopupWithSidebar from "../../../../../components/Popups/PopupWithSidebar/PopupWithSidebar.tsx";
import BestPracticeSection from "./BestPracticeSection";
import { getBestPracticesData } from "./BestPracticesData.tsx";
import styles from "./BestPracticesPopup.module.scss";

export type BestPracticesPopupProps = {
	open: boolean;
	onClose: () => void;
};

const BestPracticesPopup = ({ open, onClose }: BestPracticesPopupProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.popups.bestPractices" });
	const data = getBestPracticesData(t);

	const handleCloseDialog = () => {
		localStorage.removeItem("guidelineActiveTap");
		onClose();
	};

	return (
		<PopupWithSidebar
			sideMenuButtons={data.sideMenuButtons}
			selectedLink={localStorage.getItem("guidelineActiveTap")}
			handleCloseDialog={handleCloseDialog}
			open={open}
		>
			<Box className={styles.root}>
				<Typography className={styles.header}>{t("header")}</Typography>

				<BestPracticeSection
					id={t("taps.overview.id")}
					title={t("content.overviewSection.title")}
					description={t("content.overviewSection.descr")}
					className={styles.overviewSection}
				/>
				<Divider className={styles.sectionDivider} />

				<BestPracticeSection
					id={t("taps.datasetSize.id")}
					title={t("content.datasetSizeSection.title")}
					list={[t("content.datasetSizeSection.listItem1"), t("content.datasetSizeSection.listItem2")]}
					className={styles.datasetSizeSection}
				/>
				<Divider className={styles.sectionDivider} />

				<BestPracticeSection
					id={t("taps.diversityAndFocus.id")}
					title={t("content.diversitySection.title")}
					useCases={data.diversityUseCases}
					className={styles.diversitySection}
				/>
				<Divider className={styles.sectionDivider} />

				<BestPracticeSection
					title={t("content.imageQualitySection.title")}
					description={t("content.imageQualitySection.descr")}
					useCases={data.imageQualityUseCases}
					id={t("taps.imageQuality.id")}
				/>
				<Divider className={styles.sectionDivider} />

				<BestPracticeSection
					title={t("content.assetsSection.title")}
					description={t("content.assetsSection.descr")}
					subTitle={t("content.assetsSection.subTitle")}
					useCases={data.assetsUseCases}
					id={t("taps.assetsBackgrounds.id")}
				/>
				<Divider className={styles.sectionDivider} />

				<BestPracticeSection
					title={t("content.captionsSection.title")}
					description={t("content.captionsSection.descr")}
					useCases={data.captionsUseCases}
					id={t("taps.captionsAndPrefix.id")}
				/>
			</Box>
		</PopupWithSidebar>
	);
};

const ObservedComponent = observer(BestPracticesPopup);
export default ObservedComponent;
