import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import BriaDesignEditorPopup from "../../DesignEditor/BriaDesignEditorPopup.tsx";
import AIEditorPopup from "../../components/AIEditor/AIEditorPopup/AIEditorPopup.tsx";
import BriaUploadPopup from "../../components/Popups/BriaUploadPopup/BriaUploadPopup.tsx";
import ImageUploadPopup from "../../components/Popups/ImageUploadPopup/ImageUploadPopup.tsx";
import PricingFlow from "../../components/Popups/Pricing/PricingFlow.tsx";
import SubscriptionLinkGeneratorPopup from "../../components/Popups/SubscriptionLinkGeneratorPopup/SubscriptionLinkGeneratorPopup.tsx";
import ExportImagePopup from "../../components/common/ExportImagePopup/ExportImagePopup.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import AutomotiveHeader from "../HeaderLayout/AutomotiveHeader/AutomotiveHeader.tsx";
import styles from "./AutomotiveLayout.module.scss";

const AutomotiveLayout = () => {
	const { aiEditorStore, authStore, uiStore, designEditorStore } = useAppStore();

	return (
		<Box className={styles.hubLayout}>
			<AutomotiveHeader />
			<AIEditorPopup />
			<ImageUploadPopup
				open={aiEditorStore.openImageUploadPopup}
				loading={aiEditorStore.isUploadingImage}
				onUpload={aiEditorStore.handleUploadImageToEngine}
				handleCloseClick={aiEditorStore.handleCloseImageUploadPopup}
			/>
			{uiStore.SubscriptionLinkGeneratorPopup && authStore.isLoggedIn && authStore.user?.isSuperAdmin() && (
				<SubscriptionLinkGeneratorPopup />
			)}
			<PricingFlow />
			<Outlet />
			<BriaUploadPopup />
			{designEditorStore.designEditorPopup && <BriaDesignEditorPopup />}
			{uiStore.ExportImagePopup && <ExportImagePopup open={uiStore.ExportImagePopup} />}
		</Box>
	);
};

export default observer(AutomotiveLayout);
