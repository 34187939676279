import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RequestAccessContainer from "../../../components/TailoredGeneration/RequestAccessContainer/RequestAccessContainer";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../components/common/BriaButtonGroup/BriaButtonGroup";
import ImageViewer from "../../../components/common/Image/Image";
import LinkConstants from "../../../constants/LinkConstants";
import RouterConstants from "../../../constants/RouterConstants";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { EventLogTypesEnum } from "../../../models/common";
import FigmaInstallationPopup from "../Models/Popups/FigmaInstallationPopup/FigmaInstallationPopup.tsx";
import TgRequestAccessButton from "./RequestAccess/TgRequestAccessButton";
import styles from "./TgAccessMethods.module.scss";

const TgAccessMethods = () => {
	const { appStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "newTg.accessMethods" });
	const [openFigmaInstallationPopup, setOpenFigmaInstallationPopup] = useState(false);
	const accessMethods = t("methods", { returnObjects: true });

	const navigateToApiTokens = () => {
		window.open(RouterConstants.AUTH_KEYS.fullPath, "_blank");
	};

	const openDocs = () => {
		window.open(LinkConstants.BRIA_API_TG_GENERATE_IMAGES, "_blank");
	};

	const navigateToIframes = () => {
		window.open(RouterConstants.NEW_IFRAMES_CONFIG.fullPath, "_blank");
	};

	const openComfyUi = () => {
		window.open(LinkConstants.BRIA_COMFYUI_API, "_blank");
	};

	const downloadFigmaPlugin = async () => {
		await appStore.downloadFigmaPlugin();
	};

	const getCardButtons = (method: string) => {
		switch (method) {
			case "api":
				return (
					<TgRequestAccessButton>
						<BriaButtonGroup className={styles.cardButtons}>
							<BriaButton buttonType="textSmall" onClick={navigateToApiTokens}>
								{t(`methods.${method}.primaryBtn`)}
							</BriaButton>
							<BriaButton buttonType="textSmall" onClick={openDocs}>
								{t(`methods.${method}.secondaryBtn`)}
							</BriaButton>
						</BriaButtonGroup>
					</TgRequestAccessButton>
				);
			case "figma":
				return (
					<TgRequestAccessButton>
						<BriaButtonGroup className={styles.cardButtons}>
							<BriaButton buttonType="textSmall" onClick={openFigmaInstallationGuide}>
								{t(`methods.${method}.secondaryBtn`)}
							</BriaButton>
							<BriaButton
								buttonType="textSmall"
								onClick={downloadFigmaPlugin}
								loading={appStore.isDownloadingFigmaPlugin}
								disabled={appStore.isDownloadingFigmaPlugin}
							>
								{t(`methods.${method}.primaryBtn`)}
							</BriaButton>
						</BriaButtonGroup>
					</TgRequestAccessButton>
				);
			case "model":
				return (
					<TgRequestAccessButton>
						<RequestAccessContainer
							requestTitle={t(`contactUsPopup.title`)}
							requestMessage={`${t(`contactUsPopup.message`)}`}
							requestFeature={`Tailored Generation - ${t(`methods.${method}.header`)}`}
							eventLog={{ eventType: EventLogTypesEnum.REQUEST_FOR_TG_MODEL_SOURCE }}
							buttonType="textSmall"
							buttonMessage={t(`methods.${method}.primaryBtn`)}
							showCrown={false}
							paperClass={styles.contactUsPopup}
						/>
					</TgRequestAccessButton>
				);
			case "photoshop":
				return (
					<TgRequestAccessButton>
						<RequestAccessContainer
							requestTitle={t(`contactUsPopup.title`)}
							requestMessage={`${t(`contactUsPopup.message`)}`}
							requestFeature={`Tailored Generation - ${t(`methods.${method}.header`)}`}
							eventLog={{ eventType: EventLogTypesEnum.REQUEST_FOR_TG_PHOTOSHOP }}
							buttonType="textSmall"
							buttonMessage={t(`methods.${method}.primaryBtn`)}
							showCrown={false}
							paperClass={styles.contactUsPopup}
						/>
					</TgRequestAccessButton>
				);
			case "iframe":
				return (
					<TgRequestAccessButton>
						<BriaButton className={styles.cardButtons} buttonType="textSmall" onClick={navigateToIframes}>
							{t(`methods.${method}.primaryBtn`)}
						</BriaButton>
					</TgRequestAccessButton>
				);
			case "comfyUi":
				return (
					<TgRequestAccessButton>
						<BriaButton className={styles.cardButtons} buttonType="textSmall" onClick={openComfyUi}>
							{t(`methods.${method}.primaryBtn`)}
						</BriaButton>
					</TgRequestAccessButton>
				);
			case "copilot":
				return (
					<TgRequestAccessButton>
						<RequestAccessContainer
							requestTitle={t(`contactUsPopup.title`)}
							requestMessage={`${t(`contactUsPopup.message`)}`}
							requestFeature={`Tailored Generation - ${t(`methods.${method}.header`)}`}
							eventLog={{ eventType: EventLogTypesEnum.REQUEST_FOR_TG_COPILOT }}
							buttonType="textSmall"
							buttonMessage={t(`methods.${method}.primaryBtn`)}
							showCrown={false}
							paperClass={styles.contactUsPopup}
						/>
					</TgRequestAccessButton>
				);
			case "powerPoint":
				return (
					<TgRequestAccessButton>
						<RequestAccessContainer
							requestTitle={t(`contactUsPopup.title`)}
							requestMessage={`${t(`contactUsPopup.message`)}`}
							requestFeature={`Tailored Generation - ${t(`methods.${method}.header`)}`}
							eventLog={{ eventType: EventLogTypesEnum.REQUEST_FOR_TG_POWERPOINT }}
							buttonType="textSmall"
							buttonMessage={t(`methods.${method}.primaryBtn`)}
							showCrown={false}
							paperClass={styles.contactUsPopup}
						/>
					</TgRequestAccessButton>
				);
		}
	};

	const openFigmaInstallationGuide = () => {
		setOpenFigmaInstallationPopup(true);
	};

	return (
		<Box className={styles.container}>
			<Typography className={styles.header}>{t("header")}</Typography>
			<Typography className={styles.description}>{t("description")}</Typography>
			<Box className={styles.cards}>
				{Object.keys(accessMethods).map((method) => (
					<Box className={styles.card}>
						<Box className={styles.imageBackground}>
							<ImageViewer
								className={styles.methodImage}
								name={`images/svgs/TgAccessMethods/${method}.svg`}
							/>
						</Box>
						<Box className={styles.cardContent}>
							<Box className={styles.cardTexts}>
								<Typography className={styles.cardHeader}>{t(`methods.${method}.header`)}</Typography>
								<Typography className={styles.cardDescription}>
									{t(`methods.${method}.description`)}
								</Typography>
							</Box>
							{getCardButtons(method)}
						</Box>
					</Box>
				))}
			</Box>
			<FigmaInstallationPopup
				open={openFigmaInstallationPopup}
				onClose={() => setOpenFigmaInstallationPopup(false)}
				onClick={downloadFigmaPlugin}
				loading={appStore.isDownloadingFigmaPlugin}
				disabled={appStore.isDownloadingFigmaPlugin}
			/>
		</Box>
	);
};

export default observer(TgAccessMethods);
