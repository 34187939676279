import { makeAutoObservable, runInAction } from "mobx";
import { PaginatedItems } from "../../models/common";
import { ITableUsageRecord, IUsageData, IUsageSummary } from "../../models/usage";
import QueryService from "../../utils/QueryService";

export interface IAccountStore {
	isLoading: boolean;
	isLoadingSummaryReport: boolean;
	isLoadingAllUsageData: boolean;
	isError: boolean;
	rowsPerPage: number;
	usageSummary: IUsageSummary;
	usageData: IUsageData[];
	allUsageData: IUsageData;
	tabularUsageData: PaginatedItems<ITableUsageRecord>;

	loadUsageData(
		startDate: string,
		endDate: string,
		apiKey: string,
		selectedApiRoute: string[],
		usageType: string,
		selectedTailoredModels: number[],
	): Promise<void>;

	loadUsageSummary(
		startDate: string,
		endDate: string,
		apiKey: string,
		usageType: string,
		selectedApiRoute?: string[],
		selectedTailoredModels?: number[],
	): Promise<void>;

	clearStoreData(): void;

	loadAllUsageData(
		startDate: string,
		endDate: string,
		apiKey: string,
		selectedApiRoute: string[],
		usageType: string,
		selectedTailoredModels: number[],
	): Promise<void>;
}

export default class AccountStore implements IAccountStore {
	private queryService: QueryService = new QueryService("/accounts");
	private usageDataAbortController: AbortController = new AbortController();
	private usageSummaryAbortController: AbortController = new AbortController();
	isLoading: boolean = false;
	isLoadingSummaryReport: boolean = false;
	isLoadingAllUsageData: boolean = false;
	isError: boolean = false;
	rowsPerPage: number = 20;
	usageSummary: IUsageSummary = { monthlyUsage: 0, totalUsage: 0 };
	usageData: IUsageData[] = [];
	allUsageData: IUsageData = {
		name: "",
	};
	tabularUsageData: PaginatedItems<ITableUsageRecord> = { total: 0, items: [] };

	constructor() {
		makeAutoObservable(this);
	}

	loadUsageData = async (
		startDate: string,
		endDate: string,
		apiKey: string,
		selectedApiRoute: string[] = [],
		usageType: string,
		selectedTailoredModels: number[] = [],
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;
			if (this.usageDataAbortController) {
				this.usageDataAbortController.abort();
			}

			this.usageDataAbortController = new AbortController();

			const data: IUsageData[] = await this.queryService.post(
				"/",
				{
					startDate: startDate,
					endDate: endDate,
					apiKey: apiKey,
					apiRoutes: selectedApiRoute,
					usageType: usageType.toLowerCase(),
					tailoredModels: selectedTailoredModels,
				},
				undefined,
				{ signal: this.usageDataAbortController.signal },
			);

			runInAction(() => {
				this.usageData = data;
				// Convert data form from chart view to a tabular view
				const groupedData = data.reduce((listOfObjects: any, obj) => {
					for (const key in obj) {
						if (key !== "name") {
							if (!listOfObjects[key]) {
								listOfObjects[key] = [];
							}
							listOfObjects[key].push(obj[key]);
						}
					}
					return listOfObjects;
				}, {});

				const finalResult = Object.entries(groupedData).map(([key, value]) => ({ [key]: value }));
				this.tabularUsageData = {
					items: finalResult,
					total: finalResult.keys.length,
				};
				this.isError = false;
				this.isLoading = false;
			});
		} catch (e: any) {
			if (e?.code !== "ERR_CANCELED") {
				runInAction(() => {
					this.isLoading = false;
					this.isError = true;
				});
			}
		}
	};

	loadUsageSummary = async (
		startDate: string,
		endDate: string,
		apiKey: string,
		usageType: string,
		selectedApiRoute: string[],
		selectedTailoredModels: number[],
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingSummaryReport = true;

			if (this.usageSummaryAbortController) {
				this.usageSummaryAbortController.abort();
			}

			this.usageSummaryAbortController = new AbortController();

			const data: IUsageSummary = await this.queryService.post(
				"/summary",
				{
					startDate,
					endDate,
					apiKey,
					usageType: usageType.toLowerCase(),
					apiRoutes: selectedApiRoute,
					tailoredModels: selectedTailoredModels,
				},
				undefined,
				{ signal: this.usageSummaryAbortController.signal },
			);

			runInAction(() => {
				this.usageSummary = data;
				this.isError = false;
				this.isLoadingSummaryReport = false;
			});
		} catch (e: any) {
			if (e?.code !== "ERR_CANCELED") {
				runInAction(() => {
					this.isLoadingSummaryReport = false;
					this.isError = true;
				});
			}
		}
	};

	clearStoreData = () => {
		runInAction(() => {
			this.usageSummary = { monthlyUsage: 0, totalUsage: 0 };
			this.usageData = [];
			this.tabularUsageData = { total: 0, items: [] };
		});
	};

	loadAllUsageData = async (
		startDate: string,
		endDate: string,
		apiKey: string,
		selectedApiRoute: string[] = [],
		usageType: string,
		selectedTailoredModels: number[] = [],
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingAllUsageData = true;

			const data: IUsageData[] = await this.queryService.post("/", {
				startDate: startDate,
				endDate: endDate,
				apiKey: apiKey,
				apiRoutes: selectedApiRoute,
				usageType: usageType.toLowerCase(),
				tailoredModels: selectedTailoredModels,
			});

			runInAction(() => {
				this.allUsageData = data[0];
				this.isLoadingAllUsageData = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
				this.isLoadingAllUsageData = false;
			});
		}
	};
}
