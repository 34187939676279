import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import mobile from "../../assets/images/pngs/mobile.png";
import Logo from "../../assets/images/svgs/Bria-logo.svg";
import BriaButton from "../../components/common/BriaButton/BriaButton.tsx";
import LinkConstants from "../../constants/LinkConstants.ts";
import styles from "./MobileNotSupportedPage.module.scss";

const MobileNotSupportedPage = () => {
	const { t } = useTranslation("translation", { keyPrefix: "mobileNotSupported" });
	return (
		<Box className={styles.container}>
			<Box className={styles.logo}>
				<img src={Logo} />
			</Box>
			<Box className={styles.content}>
				<Typography className={styles.title}>{t("title")}</Typography>
				<Typography className={styles.subTitle}>{t("subTitle")}</Typography>
				<Box className={styles.img}>
					<img src={mobile} />
				</Box>
			</Box>
			<Box className={styles.cta}>
				<BriaButton
					buttonType={"primaryMedium"}
					className={styles.button}
					onClick={() => window.location.replace(LinkConstants.BRIA_WEBSITE)}
				>
					{t("button")}
				</BriaButton>
			</Box>
		</Box>
	);
};

export default MobileNotSupportedPage;
