const ThumbUp = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.87695 11.3739V16.3727C8.87695 16.5384 8.81111 16.6974 8.69389 16.8145C8.57668 16.9317 8.41771 16.9976 8.25195 16.9976H7.00195C6.83619 16.9976 6.67722 16.9317 6.56001 16.8145C6.4428 16.6974 6.37695 16.5384 6.37695 16.3727V11.9988C6.37695 11.8331 6.4428 11.6741 6.56001 11.5569C6.67722 11.4398 6.83619 11.3739 7.00195 11.3739H8.87695ZM8.87695 11.3739C9.53999 11.3739 10.1759 11.1106 10.6447 10.6419C11.1136 10.1732 11.377 9.53742 11.377 8.87454V8.24969C11.377 7.91826 11.5086 7.60039 11.7431 7.36603C11.9775 7.13166 12.2954 7 12.627 7C12.9585 7 13.2764 7.13166 13.5108 7.36603C13.7453 7.60039 13.877 7.91826 13.877 8.24969V11.3739H15.752C16.0835 11.3739 16.4014 11.5056 16.6358 11.74C16.8703 11.9743 17.002 12.2922 17.002 12.6236L16.377 15.7479C16.2871 16.1312 16.1166 16.4603 15.8911 16.6857C15.6657 16.9111 15.3975 17.0206 15.127 16.9976H10.752C10.2547 16.9976 9.77776 16.8001 9.42613 16.4485C9.0745 16.097 8.87695 15.6202 8.87695 15.123"
			stroke="var(--primary-text-color)"
			strokeOpacity="0.85"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ThumbUp;
