import { SvgIcon, SvgIconProps } from "@mui/material";

function DatabaseIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 18" {...props}>
			<path
				d="M14.6683 4C14.6683 5.38071 11.6835 6.5 8.00163 6.5C4.31973 6.5 1.33496 5.38071 1.33496 4M14.6683 4C14.6683 2.61929 11.6835 1.5 8.00163 1.5C4.31973 1.5 1.33496 2.61929 1.33496 4M14.6683 4V9M1.33496 4V9M1.33496 9C1.33496 9.66304 2.03734 10.2989 3.28758 10.7678C4.53782 11.2366 6.23352 11.5 8.00163 11.5C9.76974 11.5 11.4654 11.2366 12.7157 10.7678C13.9659 10.2989 14.6683 9.66304 14.6683 9M1.33496 9V14C1.33496 14.663 2.03734 15.2989 3.28758 15.7678C4.53782 16.2366 6.23352 16.5 8.00163 16.5C9.76974 16.5 11.4654 16.2366 12.7157 15.7678C13.9659 15.2989 14.6683 14.663 14.6683 14V9"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default DatabaseIcon;
