import { debounce } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import RouterConstants from "../constants/RouterConstants.ts";
import useSecureNavigate from "../hooks/useSecureNavigate.tsx";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";

function MobileChecker() {
	const navigate = useSecureNavigate();

	function handleResize() {
		if (isMobile && window.innerWidth < 768 && !iframeStore.isIframe()) {
			navigate(RouterConstants.MOBILE_NOT_SUPPORTED_PAGE.path);
		} else if (window.location.pathname.includes(RouterConstants.MOBILE_NOT_SUPPORTED_PAGE.path)) {
			navigate(RouterConstants.CONSOLE.path);
		}
	}

	const debouncedResizeHandler = debounce(handleResize, 100);

	useEffect(() => {
		if (!iframeStore.isIframe()) {
			window.addEventListener("resize", debouncedResizeHandler);
			// call handler right away so state gets updated with initial window size
			handleResize();
		}
		return () => window.removeEventListener("resize", debouncedResizeHandler);
	}, []);

	return null;
}

export default observer(MobileChecker);
