import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import ImageViewer from "../../../components/common/Image/Image";
import LoadingPlaceholder from "../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../../constants/RouterConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../hooks/useStores";
import styles from "./TgLobby.module.scss";

const TgLobby = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.lobby" });
	const { t: methodsT } = useTranslation("translation", { keyPrefix: "newTg.accessMethods" });
	const navigate = useSecureNavigate();
	const { tgStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const integrateMethods = methodsT("methods", { returnObjects: true });
	const featuresList = t("features.images", { returnObjects: true });

	const createFirstProject = () => {
		navigate(RouterConstants.TG_PROJECTS.createPath);
	};

	return (
		<Box className={clsx(styles.emptyProjects, { [styles.onEmpty]: !tgProjectStore.loadingOrgProjects })}>
			<Typography className={styles.header}>{t("header")}</Typography>
			<LoadingPlaceholder isLoading={tgProjectStore.loadingOrgProjects} className={styles.loadingPlaceholder}>
				<Box className={styles.content}>
					<Box className={styles.trainSection}>
						<Typography className={styles.sectionTitle}>{t("train.title")}</Typography>
						<Typography className={styles.sectionSubTitle}>{t("train.subTitle")}</Typography>
						<BriaButton
							buttonType="primaryMedium"
							className={styles.button}
							requiresApiAccess={true}
							onClick={createFirstProject}
						>
							{t("primaryButton")}
						</BriaButton>
					</Box>
					<Box className={styles.integrateSection}>
						<Typography className={styles.sectionTitle}>{t("integrate.title")}</Typography>
						<Typography className={styles.sectionSubTitle}>{t("integrate.subTitle")}</Typography>
						<Box className={styles.integrateMethods}>
							{Object.keys(integrateMethods).map((method) => (
								<Box className={styles.integrateMethod}>
									<ImageViewer
										className={styles.integrateImage}
										name={`images/svgs/TgAccessMethods/${method}.svg`}
									/>
									<Typography>{methodsT(`methods.${method}.header`)}</Typography>
								</Box>
							))}
						</Box>
					</Box>
					<Box className={styles.featuresSection}>
						<Typography className={styles.featuresTitle}>{t("features.title")}</Typography>
						<Box className={styles.featuresImages}>
							{Object.keys(featuresList).map((feature) => (
								<Box className={styles.featureItem}>
									<ImageViewer
										className={styles.featureImage}
										name={`images/pngs/TgModelFeatures/${feature}.png`}
									/>
									<Typography className={styles.featureTitle}>
										{t(`features.images.${feature}`)}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
			</LoadingPlaceholder>
		</Box>
	);
};

export default observer(TgLobby);
