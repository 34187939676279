import { Alert, Box, useTheme } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "../../../assets/images/icons/CopyIcon";
import BriaButton, { ButtonTypes } from "../BriaButton/BriaButton";
import BriaTooltip from "../BriaTooltip/BriaTooltip";
import styles from "./CopyToClipboardButton.module.scss";

export type CopyToClipboardButtonProps = {
	textToCopy?: string;
	className?: string;
	afterCopyAction?: () => void;
	buttonText?: string;
	tooltipText?: string;
	iconPosition?: "start" | "end";
	buttonType?: ButtonTypes;
	hideIcon?: boolean;
};

const CopyToClipboardButton = ({
	textToCopy = "",
	className,
	afterCopyAction,
	buttonText,
	tooltipText,
	iconPosition = "start",
	buttonType = "textSmall",
	hideIcon = false,
}: CopyToClipboardButtonProps) => {
	const { t } = useTranslation("translation");
	const [isSnackbarOpen, setSnackbarOpen] = useState(false);
	const theme = useTheme();

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(textToCopy);
		setSnackbarOpen(true);
		afterCopyAction?.();
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	return (
		<>
			<Box className={className} onClick={handleCopyToClipboard}>
				{!buttonText ? (
					<BriaTooltip title={tooltipText ?? t("Copy")}>
						<Box className={styles.iconButton}>
							<CopyIcon />
						</Box>
					</BriaTooltip>
				) : (
					<BriaButton
						className={styles.buttonText}
						buttonType={buttonType}
						startIcon={
							!hideIcon &&
							iconPosition === "start" && <CopyIcon strokeColor={theme.palette.primary.light} />
						}
						endIcon={
							!hideIcon &&
							iconPosition === "end" && <CopyIcon strokeColor={theme.palette.primary.light} />
						}
					>
						{buttonText}
					</BriaButton>
				)}
			</Box>
			<Snackbar open={isSnackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}>
				<Alert onClose={handleCloseSnackbar} severity="success">
					{t("copyToClipboardSuccess")}
				</Alert>
			</Snackbar>
		</>
	);
};

export default CopyToClipboardButton;
