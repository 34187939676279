import styles from "./BriaBadge.module.scss";

interface BriaBadgeProps {
	label: string;
	color?: string;
	className?: string;
	children?: React.ReactNode;
}

const BriaBadge: React.FC<BriaBadgeProps> = ({ label, color, className, children }) => {
	return (
		<>
			{children}
			<span className={`${className} ${styles.label}`} style={{ backgroundColor: color }}>
				{label}
			</span>
		</>
	);
};

export default BriaBadge;
