import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable";
import RouterConstants from "../../../constants/RouterConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../hooks/useStores";
import DataDashboardLayout from "../../../layout/DataDashboard/DataDashboardLayout";
import TgProjectActionsPopover from "./Dashboard/ActionsPopover/TgProjectActionsPopover";
import TgProjectsEmpty from "./EmptyState/TgProjectsEmpty";
import styles from "./TgProjects.module.scss";
import { ViewTgProjectLocationState } from "./Viewer/TgProjectViewer";
import { TgProject } from "./store/tg-projects-store";

const TgProjects = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.dashboard" });
	const navigate = useSecureNavigate();
	const { tgStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const paginationOptions: PaginationOptions<TgProject> = {
		rowsPerPage: tgProjectStore.rowsPerPage,
		totalRows: tgProjectStore.paginatedProjects.total,
		loadNextPage: tgProjectStore.getOrgProjects,
	};
	const [filterBy, setFilterBy] = useState<FilterByOptions<TgProject>>();

	const openProject = (projectToView: TgProject) => {
		const viewProjectState: ViewTgProjectLocationState = { projectToView: toJS(projectToView) };
		navigate(`${RouterConstants.TG_PROJECTS.fullPath}/${projectToView.id}/${RouterConstants.NEW_TG_MODELS.path}`, {
			state: viewProjectState,
		});
	};

	const openCreateProject = () => {
		navigate(RouterConstants.TG_PROJECTS.createPath);
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions<TgProject> = {
			...filterBy,
			search: {
				text: e.target.value,
				fields: ["project_name", "project_description", "ip_medium", "ip_type"],
			},
		};
		setFilterBy(updatedFilterBy);
		await tgProjectStore.getOrgProjects(1, updatedFilterBy);
	};

	return (
		<DataDashboardLayout
			className={styles.layout}
			primaryButton={
				<BriaButton buttonType="primaryMedium" onClick={openCreateProject}>
					{t("primaryButton")}
				</BriaButton>
			}
			title={t("title")}
			description={t("description")}
			enableSearch
			handleSearchChange={handleSearchChange}
			searchBoxPlaceholder={t("search.placeholder")}
			paginationOptions={paginationOptions}
			filterBy={filterBy}
			loading={tgProjectStore.loadingOrgProjects}
		>
			{!tgProjectStore.paginatedProjects.items.length ? (
				<TgProjectsEmpty />
			) : (
				<Box className={styles.cards}>
					{tgProjectStore.paginatedProjects.items.map((project) => (
						<Box className={styles.card}>
							<TgProjectActionsPopover {...{ project }} />
							<Box className={styles.cardDetails}>
								<Typography className={styles.cardName}>{project.project_name}</Typography>
								<Typography className={styles.cardDesc}>{project.project_description}</Typography>
								<Box className={styles.cardFields}>
									<Box className={styles.cardField}>
										<Typography className={styles.fieldKey}>{t("card.info.ip_medium")}</Typography>
										<Typography className={styles.fieldValue}>
											{i18next.t(`newTg.projects.editor.tgIpMediums.${project.ip_medium}`)}
										</Typography>
									</Box>
									<Box className={styles.cardField}>
										<Typography className={styles.fieldKey}>{t("card.info.ip_type")}</Typography>
										<Typography className={styles.fieldValue}>
											{i18next.t(`newTg.projects.editor.ipType.types.${project.ip_type}.name`)}
										</Typography>
									</Box>
								</Box>
							</Box>
							<BriaButton
								className={styles.cardButton}
								buttonType="secondaryMedium"
								onClick={() => openProject(project)}
							>
								{t("card.actions.primaryButton.nonEmpty")}
							</BriaButton>
						</Box>
					))}
				</Box>
			)}
		</DataDashboardLayout>
	);
};

export default observer(TgProjects);
