import { Mark } from "@mui/base/useSlider";
import type { Header, PostData } from "har-format";
import { MutableRefObject } from "react";
import { DropDownItem } from "../components/common/BriaDropDown/BriaDropDown.tsx";
import { ruleSetType } from "../utils/validators";
import { PlaygroundImage } from "./image-to-image";

export enum SandboxAPIType {
	ImageGenerations = "ImageGenerations",
	ImageModifications = "ImageModifications",
	ECommerceSuite = "ECommerceSuite",
	VideoEditing = "VideoEditing",
}

export enum ImageGenerationsSubAPIType {
	GenerateImage = "text-to-image",
	GenerateVector = "text-to-vector",
	reimagine = "reimagine",
	promptEnhancement = "prompt-enhancement",
}

export enum ImageModificationsSubAPIType {
	RemoveBackground = "remove-background",
	BlurBackground = "blur-background",
	GenerateBackground = "generate-background",
	ExpandImage = "expand-image",
	IncreaseResolution = "increase-resolution",
	DelayerImage = "delayer-image",
	CropOutForeground = "crop-out-foreground",
	GetMasks = "get-masks",
	Eraser = "eraser",
	GenFill = "gen-fill",
}

export enum eCommerceSuiteSubAPIType {
	ProductCutout = "product-cutout",
	ProductPackshot = "product-packshot",
	ProductShadow = "product-shadow",
	LifestyleProductShotByText = "lifestyle-product-shot-by-text",
	LifestyleProductShotByImage = "lifestyle-product-shot-by-image",
}

export enum VideoEditingSubAPIType {
	RemoveVideoBackground = "remove-video-background",
}

export enum modelTypes {
	Base = "base",
	Fast = "fast",
	Hd = "hd",
	Tailored = "tailored",
}

export type SandboxSubAPITypes =
	| ImageGenerationsSubAPIType
	| ImageModificationsSubAPIType
	| eCommerceSuiteSubAPIType
	| VideoEditingSubAPIType;
export const APIS_REQUIRE_ORG: SandboxSubAPITypes[] = [ImageModificationsSubAPIType.IncreaseResolution];

export interface APIParameter {
	name: string;
	type: string;
	label: string;
	placeholder?: string | string[];
	info?: string;
	required?: boolean;
	defaultValue?: any;
	min?: number;
	max?: number;
	step?: number;
	dropdownOptions?: DropDownItem<any>[];
	hidden?: boolean;
	numberOfInputs?: number;
	customEndAdornmentText?: string;
	className?: string;
	selectMultipleCheckbox?: boolean;
	multiple?: boolean;
	valueLabelDisplay?: "on" | "off" | "auto";
	height?: string;
	minRows?: number;
	multiline?: boolean;
	marks?: boolean | Mark[];
	action?: string;
	validationMessage?: string | null;
	validationSchema?: ruleSetType;
	variant?: string;
	labeledMarks?: { value: number; label?: string }[];
	guidanceMethods?: { id: string }[];
	showFoundationModels?: string;
}

export type guidanceMethods = {
	guidance_method: string | null;
	guidance_method_image_file: string | null;
	guidance_method_aspect_ratio?: number;
	guidance_method_scale: number;
};

export type IPAdapter = {
	image_prompt_urls: string[];
	image_prompt_scale: number;
	image_prompt_mode: string;
};

interface APIConfig {
	id: string;
	apiName: string;
	endpoint: string;
	docLink: string;
	method: string;
	contentType?: string;
	nonIsolated?: boolean;
	requiresOrg?: boolean;
	parameters: APIParameter[];
	pathParameter?: Record<string, any>;
	guidanceMethods?: { id: string }[];
	guidanceMethodsPayload?: guidanceMethods[];
	IPAdapterPayload?: IPAdapter;
}

export interface ApiRequestData {
	method: string;
	url: string;
	headers: Header[];
	postData?: PostData;
}

export type SandboxAPIConfigType = {
	apiConfig: APIConfig | null;
	selectedAPI: SandboxAPIType;
	selectedSubAPI?: SandboxSubAPITypes;
	original_image: PlaygroundImage;
	num_results?: number;
	apiRequestData?: ApiRequestData;
};

export type sandboxResultImage = {
	result_url: string;
	seed?: number;
	description?: string;
};

export type IBrushSettings = {
	lineJoin: CanvasLineJoin;
	lineCap: CanvasLineCap;
	strokeStyle: string;
	lineWidth: number;
	globalCompositeOperation: GlobalCompositeOperation;
	globalAlpha: number;
	brushColorOpacity: number;
	reset?: boolean;
	isMaskApplied?: boolean;
};

export type BrushCanvasRefs = {
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	canvasOverlayRef: MutableRefObject<HTMLCanvasElement | null>;
};
