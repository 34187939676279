import { TextOptions } from "../../DesignEditor/interfaces/editor";

export const propertiesToInclude = ["id", "selectable"];

export const defaultTextOptions: TextOptions = {
	underline: false,
	textAlign: "left",
	charSpacing: 0,
	fill: "#000000",
	fontFamily: "Open Sans",
	fontSize: 12,
	lineHeight: 12,
	isGroup: false,
	isMultiple: false,
	styles: [],
	font: {},
	activeStyle: {},
	fontWeight: 500,
	textType: "",
	fontStyle: "",
	fontType: "",
	fontURL: "",
};

export const DEFAULT_SCENE_PROCESSING_TIME = 15;
export const defaultShadow = {
	blur: 4,
	offsetX: 0,
	offsetY: 0,
	enabled: true,
	color: "#1A0638",
};
