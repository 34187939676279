import { Box, Popover, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../../../../assets/images/icons/CloseIcon";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton";
import BriaInput from "../../../../../../../components/common/BriaInput/BriaInput";
import InputLayout from "../../../../../../../layout/InputLayout/InputLayout";
import useDesignEditorUtils from "../../../../../../CustomUtils/UseDesignEditor";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import FixedTextBoxFontSize from "../FixedTextBoxFontSize/FixedTextBoxFontSize";
import styles from "./FixedTextElementPopover.module.scss";
type Props = {
	open: boolean;
	handleClose?: () => void;
	anchorEl?: HTMLButtonElement;
};

const FixedTextElementPopover = ({ open = false, handleClose, anchorEl }: Props) => {
	const activeObject = useActiveObject() as Required<fabric.Object>;
	const { fitTextIntoFixedTextBox } = useDesignEditorUtils();
	const [fixedHeightValue, setFixedHeightValue] = useState<number | undefined>(
		activeObject?.fixedHeightValue ? Math.round(activeObject?.fixedHeightValue) : Math.round(activeObject?.height),
	);
	const [fixedWidthValue, setFixedWidthValue] = useState<number | undefined>(
		activeObject?.fixedWidthValue ? Math.round(activeObject?.fixedWidthValue) : Math.round(activeObject?.width),
	);
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.textControllers" });

	useEffect(() => {
		setFixedHeightValue(
			activeObject?.fixedHeightValue
				? Math.round(activeObject?.fixedHeightValue)
				: Math.round(activeObject?.height),
		);
		setFixedWidthValue(
			activeObject?.fixedWidthValue ? Math.round(activeObject?.fixedWidthValue) : Math.round(activeObject?.width),
		);
	}, [open]);

	const handleFixedWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFixedWidthValue(e.target.value ? Math.round(Number(e.target.value)) : undefined);
	};

	const handleFixedHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFixedHeightValue(e.target.value ? Math.round(Number(e.target.value)) : undefined);
	};
	const handleResizeTextElement = () => {
		if (activeObject) {
			activeObject.fixedHeightValue = fixedHeightValue ?? undefined;
			activeObject.fixedWidthValue = fixedWidthValue ?? undefined;
			if (fixedWidthValue) activeObject.set("width", fixedWidthValue);
			if (fixedHeightValue) activeObject.set("height", fixedHeightValue);
			activeObject.setCoords();
			handleClose && handleClose();
			fitTextIntoFixedTextBox(activeObject);
			// TODO:It's not needed for now, may be we need to add it in future
			// EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.FIXEDSIZES, {
			// 	fixedWidth: fixedWidthValue,
			// 	fixedHeight: fixedHeightValue,
			// });
		}
	};

	return (
		<Popover
			disableRestoreFocus
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			slotProps={{
				paper: {
					className: styles.popover,
				},
			}}
		>
			<Box className={styles.resizeTextElementContainer}>
				<>
					<CloseIcon onClick={handleClose} className={styles.closeIcon} />
					<Box>
						<Typography className={styles.textBoxSizeTitle}>{t("textBoxSize")}</Typography>
						<InputLayout showLabel={false} className={styles.inputLayout}>
							<Box className={styles.resolution}>
								<Box className={styles.resolutionContainer}>
									<Typography>{t("widthLabel")}</Typography>
									<BriaInput
										rootClass={styles.rootClass}
										value={fixedWidthValue && Math.round(fixedWidthValue)}
										onChange={handleFixedWidthChange}
										type="number"
										customEndAdornmentText="px"
									/>
								</Box>
								<Box className={styles.resolutionContainer}>
									<Typography>{t("heightLabel")}</Typography>
									<BriaInput
										rootClass={styles.rootClass}
										value={fixedHeightValue && Math.round(fixedHeightValue)}
										onChange={handleFixedHeightChange}
										type="number"
										customEndAdornmentText="px"
									/>
								</Box>
							</Box>
						</InputLayout>
						<Box className={styles.fixedFontSizeSection}>
							<Typography className={styles.maxFontLabel}>{t("maximumFontSize")}</Typography>
							<FixedTextBoxFontSize />
						</Box>
						<Box className={styles.resizeButtonContainer}>
							<BriaButton
								disabled={!fixedHeightValue || !fixedWidthValue}
								onClick={handleResizeTextElement}
								className={styles.resizeButton}
								buttonType="primary"
							>
								{t("resize")}
							</BriaButton>
						</Box>
					</Box>
				</>
			</Box>
		</Popover>
	);
};

const ObservedComponent = observer(FixedTextElementPopover);
export default ObservedComponent;
