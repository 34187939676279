import { Options } from "browser-image-compression";

export const IMAGE_COMPRESSION_CONFIG: Options = {
	maxSizeMB: 3,
	maxWidthOrHeight: 2048,
	useWebWorker: true,
	preserveExif: true,
};

export const SANDBOX_IMAGE_COMPRESSION_CONFIG: Options = {
	maxSizeMB: 12,
	maxWidthOrHeight: 4096,
	useWebWorker: true,
	preserveExif: true,
};