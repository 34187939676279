import Papa from "papaparse";
import { DropDownItem } from "../../../components/common/BriaDropDown/BriaDropDown";
import { ITableUsageRecord, IUsageData, LineChartDefaultValues, UsagePeriod, UsageType } from "../../../models/usage";

interface CreateModelLookupParams {
	models: { id?: string; name: string }[];
}

interface ConvertUsageDataParams {
	usageData: IUsageData[];
	modelLookup: Record<string, string>;
}

interface HandleDownloadAsCsvClickParams {
	accountStore: any;
	tailoredGenerationStore: any;
	t: (key: string) => string;
}

interface HandleSelectApiRoutesParams {
	value: string[];
	t: (key: string) => string;
	filteredApiRoutesDropDownItems: DropDownItem[];
	selectedApiRoute: string[];
	setSelectedApiRoute: (newSelection: string[]) => void;
	activeApiRoutes: string[];
}

interface UpdateApiRoutesItemsParams {
	t: (key: string) => string;
	defaultApiRoutesDropDownItems: DropDownItem[];
	tailoredGenerationStore: { models: { name: string }[] };
	setSelectedApiRoute: (items: string[]) => void;
	setApiRoutesDropDownItems: (items: DropDownItem[]) => void;
	setFilteredApiRoutesDropDownItems: (items: DropDownItem[]) => void;
}

export const DROPDOWN_HEIGHT = "32px";

export const DROPDOWN_WIDTH = "230px";

export const DROPDOWN_MAX_HEIGHT = "640px";

export const ROUTES_MAPPER = {
	gen_fill: "gen_fill",
	prompt_enhancer: "prompt_enhancer",
	"product/shadow": "product/shadow",
	"product/lifestyle_shot_by_text": "product/lifestyle_shot_by_text",
	"product/lifestyle_shot_by_image": "product/lifestyle_shot_by_image",
	erase_foreground: "erase_foreground",
	crop: "crop",
	"text-to-vector/base/23": "text-to-vector/base/23",
	"text-to-vector/fast/23": "text-to-vector/fast/23",
	"text-to-image/base/22": "text-to-image/base/22",
	"text-to-image/base/23": "text-to-image/base/23",
	"text-to-image/fast/22": "text-to-image/fast/22",
	"text-to-image/fast/23": "text-to-image/fast/23",
	"text-to-image/hd": "text-to-image/hd",
	replace_bg: "replace_bg",
	style: "style",
	image_expansion: "image_expansion",
	campaign_generator: "campaign_generator",
	increase_resolution: "increase_resolution",
	image_to_psd: "image_to_psd",
	"video/background/remove": "video/background/remove",
	"background/remove": "background/remove",
	remove_bg: "remove_bg",
	"background/blur": "background/blur",
	"objects/remove": "objects/remove",
	search_similar_images: "search_similar_images",
	reimagine: "reimagine",
	eraser: "eraser",
	caption: "caption",
	product_placement: "product_placement",
	"product/cutout": "product/cutout",
	"product/packshot": "product/packshot",
	face: "face",
};

export const updateApiRoutesItems = async ({
	t,
	defaultApiRoutesDropDownItems,
	tailoredGenerationStore,
	setSelectedApiRoute,
	setApiRoutesDropDownItems,
	setFilteredApiRoutesDropDownItems,
}: UpdateApiRoutesItemsParams) => {
	try {
		const tailoredModels = tailoredGenerationStore.models.map((item) => ({
			key: item.name,
			value: item.name,
		}));

		const apiRoutesItems = [
			{
				key: t("selectAll"),
				value: t("selectAll"),
			},
			...defaultApiRoutesDropDownItems.sort((a, b) => (a.value > b.value ? 1 : -1)),
			...tailoredModels.sort((a, b) => (a.value > b.value ? 1 : -1)),
		];

		const selectedItems = apiRoutesItems.map((item) => item.key);
		setSelectedApiRoute(selectedItems);
		setApiRoutesDropDownItems(apiRoutesItems);
		setFilteredApiRoutesDropDownItems(apiRoutesItems);
	} catch (error) {
		console.error(error);
	}
};

// Handle CSV download
export const handleDownloadAsCsvClick = async ({
	accountStore,
	tailoredGenerationStore,
	t,
}: HandleDownloadAsCsvClickParams) => {
	const { data, header } = computeTableData(accountStore, tailoredGenerationStore, t);

	// Convert the data to a 2D array for CSV generation
	const csvData: any[][] = data.map((record: any) => record.data);

	// Add headers to data
	csvData.unshift(header);

	// Convert data to CSV format
	const csv = Papa.unparse(csvData);
	const blob = new Blob([csv], { type: "text/csv" });
	const url = URL.createObjectURL(blob);

	// Create a link element and trigger download
	const aTag = document.createElement("a");
	aTag.href = url;
	aTag.download = `bria_usage_report.csv`;
	document.body.appendChild(aTag);
	aTag.click();

	// Cleanup
	document.body.removeChild(aTag);
	URL.revokeObjectURL(url);
};

// Filter API routes based on search input
export const filterApiRoutes = (apiRoutesDropDownItems: DropDownItem[], value: string): DropDownItem[] => {
	if (value && value.length > 0) {
		return apiRoutesDropDownItems.filter((item) => item.value.toLowerCase().includes(value.toLowerCase()));
	} else {
		return apiRoutesDropDownItems;
	}
};

// Compute table data for display
export const computeTableData = (accountStore: any, tailoredGenerationStore: any, t: (key: string) => string) => {
	const totals: number[] = [];

	const data = accountStore.tabularUsageData.items.map((record: ITableUsageRecord) => {
		const firstKey = Object.keys(record)[0];
		const dataArray = [...record[firstKey]];

		if (firstKey !== dataArray[0]) {
			dataArray.unshift(firstKey);
		}

		dataArray.forEach((value: number, index: number) => {
			if (index > 0) {
				if (totals[index] === undefined) {
					totals[index] = 0;
				}
				totals[index] += value;
			}
		});

		const recordTitle = tailoredGenerationStore.models.find((item: any) => item.id?.toString() === firstKey)?.name;
		if (recordTitle) {
			dataArray[0] = recordTitle;
		}

		return {
			key: recordTitle || firstKey,
			data: dataArray,
		};
	});

	const summaryRow: (string | number)[] = [t("summary"), ...totals.slice(1)];
	data.unshift({
		key: t("summary"),
		data: summaryRow,
	});

	const tableHeader = accountStore.usageData.map((obj: any) => obj.name);
	tableHeader.unshift("");

	return {
		data,
		header: tableHeader,
	};
};

export const handleSelectApiRoutes = ({
	value,
	t,
	filteredApiRoutesDropDownItems,
	selectedApiRoute,
	setSelectedApiRoute,
	activeApiRoutes,
}: HandleSelectApiRoutesParams) => {
	const selectAll = t("selectAll");
	const filteredApiRoutes = filteredApiRoutesDropDownItems
		.filter((item) => item.key !== selectAll)
		.map((item) => item.key);

	const valueWithoutSelectAll = value.filter((item) => item !== selectAll);

	const sortedValueWithoutSelectAll = [...valueWithoutSelectAll].sort();
	const sortedFilteredApiRoutes = [...filteredApiRoutes].sort();
	const isListsEquals = sortedValueWithoutSelectAll.every((value, index) => value === sortedFilteredApiRoutes[index]);

	if (Array.isArray(value)) {
		// Case 1: "Select All" is selected, and one individual item is unselected
		if (selectedApiRoute.includes(selectAll) && valueWithoutSelectAll.length === filteredApiRoutes.length - 1) {
			setSelectedApiRoute(valueWithoutSelectAll.filter((item) => activeApiRoutes.includes(item)));
		}
		// Case 2: "Select All" is selected, but not all items are selected, or
		// all items are selected but "Select All" is not included
		else if (
			(value.includes(selectAll) && !isListsEquals) ||
			(isListsEquals && !selectedApiRoute.includes(selectAll))
		) {
			setSelectedApiRoute(filteredApiRoutesDropDownItems.map((item) => item.key));
		}
		// Case 3: All items are selected, and "Select All" is included
		else if (isListsEquals && selectedApiRoute.includes(selectAll)) {
			setSelectedApiRoute([]);
		}
		// Case 4: Individual items are selected/unselected when "Select All" is not selected
		else {
			setSelectedApiRoute(value.filter((item) => activeApiRoutes.includes(item)));
		}
	}
};

export const handleHoverRange = (date: Date): [Date, Date] => {
	return [date, date];
};

export const createModelLookup = ({ models }: CreateModelLookupParams): Record<string, string> => {
	return models.reduce(
		(acc, model) => {
			if (model.id !== undefined) acc[model.id.toString()] = model.name;
			return acc;
		},
		{} as Record<string, string>,
	);
};

export const convertUsageData = ({ usageData, modelLookup }: ConvertUsageDataParams): IUsageData[] => {
	return usageData.map((item) => {
		const convertedObj: IUsageData = { name: item.name }; // Ensure name property exists

		Object.entries(item).forEach(([key, value]) => {
			if (key !== "name") {
				const modelName = modelLookup[key] || key;
				convertedObj[modelName] = value;
			}
		});

		return convertedObj;
	});
};

export const getApiRoutesAndTailoredModels = (list: string[], tailoredGenerationStore: any) => {
	const apiRoutes: string[] = [];
	const tailoredModels: string[] = [];

	list.forEach((item) => {
		if (Object.keys(LineChartDefaultValues[0]).includes(item)) {
			apiRoutes.push(item);
		} else {
			tailoredModels.push(item);
		}
	});

	const tailoredModelsIds = tailoredGenerationStore.models
		.filter((item: any) => tailoredModels.includes(item.name))
		.map((item: any) => item.id);

	return {
		apiRoutes,
		tailoredModelsIds,
	};
};

export const usagePeriodDropDownItems: DropDownItem[] = Object.entries(UsagePeriod).map(([key, value]) => ({
	key: key,
	value: value as string,
}));

export const usageTypeDropDownItems: DropDownItem[] = Object.entries(UsageType).map(([, value]) => ({
	key: value,
	value: value as string,
}));

export const defaultApiRoutesDropDownItems: DropDownItem[] = Object.entries(LineChartDefaultValues[0])
	.filter(([key]) => key !== "name")
	.map(([key]) => ({
		key: key,
		value: key as string,
	}));
