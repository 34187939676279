import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../assets/images/icons/InfoIcon";
import BriaTooltip from "../../components/common/BriaTooltip/BriaTooltip";
import styles from "./InputLayout.module.scss";

interface IProps {
	label?: string | React.ReactNode;
	labelClassName?: string;
	labelIcon?: any;
	labelSize?: string;
	info?: string;
	className?: string;
	children: React.ReactNode;
	showLabel?: boolean;
	validateOn?: boolean;
	validationMessage?: string | null;
	optional?: boolean;
}

const InputLayout = ({
	label,
	labelClassName,
	labelIcon,
	labelSize,
	info,
	showLabel = true,
	validateOn = false,
	validationMessage = "",
	className,
	children,
	optional = false,
}: IProps) => {
	const { t } = useTranslation();

	return (
		<Box className={clsx(styles.container, className)}>
			{showLabel && (
				<Box
					className={clsx(
						{
							[styles.textLabel]: !optional,
							[styles.textLabelOptionalStyle]: optional,
						},
						labelClassName,
					)}
				>
					<Typography
						className={clsx(styles.inputLabel, {
							[styles.largeLabel]: labelSize === "large",
						})}
					>
						{label || <span>&nbsp;</span>}
					</Typography>
					{info && (
						<BriaTooltip
							title={info}
							className={styles.info}
							componentsProps={{ tooltip: { className: styles.tooltip } }}
							displayOn="click"
						>
							<InfoIcon className={styles.infoIcon} />
						</BriaTooltip>
					)}
					{labelIcon && <img className={styles.labelIcon} src={labelIcon} />}
					{optional && <Typography className={styles.optionalLabel}>{t("optional")}</Typography>}
				</Box>
			)}
			{children}
			{validateOn && <Typography className={styles.inputError}>{validationMessage}</Typography>}
		</Box>
	);
};

export default observer(InputLayout);
