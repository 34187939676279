import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputBase, MenuItem, Popover } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FONT_SIZES } from "../../../../../../constants/editor";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText } from "../../../../../../types";
import styles from "./TextFontSize.module.scss";

const TextFontSize = () => {
	const editor = useEditor();
	const activeObject = useActiveObject() as IStaticText;
	const [textFontSize, setTextFontSize] = useState(activeObject?.fontSize ?? 12);
	const [anchorEl, setAnchorEl] = useState(null);
	const inputRef = useRef<HTMLInputElement | null>(null);
	useEffect(() => {
		if (activeObject && activeObject.type === "StaticText") {
			// @ts-ignore
			setTextFontSize(activeObject.fontSize);
		}
	}, [activeObject]);
	const handleClick = (event: any) => {
		if (!activeObject?.fixedSize) {
			setAnchorEl(event.currentTarget);
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const onSizeChange = (size: number) => {
		editor?.objects.update({ fontSize: Number(size) });
		setTextFontSize(size);
		if (inputRef.current) {
			inputRef.current.focus();
		}
		handleClose();
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		if (value === "") {
			setTextFontSize(NaN);
		} else {
			const newSize = Number(value);
			if (!isNaN(newSize)) {
				editor?.objects.update({ fontSize: Number(newSize) });
				setTextFontSize(newSize);
			}
		}
	};

	return (
		<div className={styles.textFontSizeContainer}>
			{/* Input with arrow icon inside */}
			<div className={styles.inputWrapper} onClick={handleClick}>
				<InputBase
					disabled={activeObject?.fixedSize}
					inputRef={inputRef}
					value={isNaN(textFontSize) ? "" : Math.floor(textFontSize)}
					onChange={handleInputChange}
					className={styles.input}
					type="number"
					endAdornment={
						<ExpandMoreIcon
							sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "22px" }}
							className={styles.expandIcon}
						/>
					}
				/>
			</div>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				sx={{
					"& .MuiPopover-paper": {
						top: "88px !important",
						left: "551px !important",
						position: "absolute",
					},
				}}
			>
				<div className={styles.popoverContent}>
					{FONT_SIZES.map((size, index) => (
						<MenuItem
							key={index}
							onClick={() => onSizeChange(size)}
							className={`${styles.menuItem} ${textFontSize === size ? styles.selected : ""}`}
						>
							{size}
						</MenuItem>
					))}
				</div>
			</Popover>
		</div>
	);
};

export default TextFontSize;
