import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../assets/images/icons/CloudUpload.tsx";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import { useAppStore } from "../../../hooks/useStores.tsx";
import useImageUtils from "../../../utils/useImageUtils";
import ImageUploader from "../../common/ImageUploader/ImageUploader.tsx";
import BriaDropDown from "../BriaDropDown/BriaDropDown.tsx";
import BriaSlider from "../BriaSlider/BriaSlider.tsx";
import styles from "./GuidanceMethods.module.scss";

interface IProps {
	id: string;
	index: number;
	onDelete: () => void;
}

const GuidanceMethod: React.FC<IProps> = ({ index, onDelete }) => {
	const { t } = useTranslation("translation", { keyPrefix: "guidanceMethods" });
	const { getAspectRatioFromBase64 } = useImageUtils();
	const [uploadError, setUploadError] = useState<boolean>(false);
	const { sandboxAPIStore } = useAppStore();

	const labeledMarks = [
		{
			value: 0,
			label: "0",
		},
		{
			value: 1,
			label: "1",
		},
	];

	const dropdownOptions = [
		{
			key: "Controlnet canny",
			value: "controlnet_canny",
		},
		{
			key: "Controlnet depth",
			value: "controlnet_depth",
		},
		{
			key: "Controlnet recoloring",
			value: "controlnet_recoloring",
		},
		{
			key: "Controlnet color grid",
			value: "controlnet_color_grid",
		},
	];

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number, name: string) => {
		const guidanceMethodsPayload = sandboxAPIStore.config.apiConfig?.guidanceMethodsPayload;
		if (!guidanceMethodsPayload?.[index]) {
			return;
		}
		if (name === "guidance_method") {
			guidanceMethodsPayload[index][name] = e.target.value;
		} else if (name === "guidance_method_scale") {
			guidanceMethodsPayload[index][name] = Number(e.target.value);
		}
	};

	const handleOnUpload = async (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const prevImageAspectRatio = sandboxAPIStore.config.apiConfig?.guidanceMethodsPayload?.find(
			(item) => item.guidance_method_image_file !== null,
		)?.guidance_method_aspect_ratio;

		const file = e.target.files?.[0];
		if (!file) return;

		if (prevImageAspectRatio) {
			try {
				const reader = new FileReader();
				reader.onload = async (event: ProgressEvent<FileReader>) => {
					const base64NewImage = event.target?.result as string;

					const newImageAspectRatio = await getAspectRatioFromBase64(base64NewImage);

					if (prevImageAspectRatio !== null && newImageAspectRatio !== prevImageAspectRatio) {
						setUploadError(true);
					} else {
						setUploadError(false);
						await sandboxAPIStore.handleUploadGuidanceMethodFile(file, index);
					}
				};

				reader.readAsDataURL(file);
			} catch (error) {
				setUploadError(false);
				console.error("Error loading images:", error);
			}
		} else {
			await sandboxAPIStore.handleUploadGuidanceMethodFile(file, index);
		}
	};

	const renderInput = () => {
		return (
			<Box className={styles.guidanceMethod}>
				<Box className={styles.header}>
					<Typography className={styles.methodNum}>
						{t("methodNum")} {index + 1}
					</Typography>
					<DeleteIcon
						className={styles.trash}
						onClick={() => {
							onDelete();
						}}
					/>
				</Box>

				<BriaDropDown
					className={styles.dropDown}
					value={sandboxAPIStore.config.apiConfig?.guidanceMethodsPayload?.[index]?.[`guidance_method`]}
					items={dropdownOptions}
					onChange={(e: any) => {
						handleInputChange(e, index, `guidance_method`);
					}}
				/>

				<ImageUploader
					className={styles.imgUploader}
					emptyStateClassName={styles.emptyState}
					titleClassName={styles.uploadTitle}
					icon={<CloudUploadSVG />}
					inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp" }}
					descriptionClassName={styles.uploadDescription}
					description={t("uploadYourImage")}
					maxFileSize={12 * 1024 * 1024}
					onUpload={(e) => handleOnUpload(e, index)}
					onDelete={() => sandboxAPIStore.handleDeleteGuidanceMethodFile(index)}
					showPreview={!uploadError}
				/>
				{uploadError && (
					<Typography className={styles.uploadError}>
						<Trans i18nKey={t("uploadError")} components={{ strong: <strong /> }} />
					</Typography>
				)}

				<BriaSlider
					value={sandboxAPIStore.config.apiConfig?.guidanceMethodsPayload?.[index]?.[`guidance_method_scale`]}
					min={0}
					max={1}
					step={0.01}
					marks={labeledMarks}
					valueLabelDisplay={"auto"}
					showMarksPoints
					onChange={(e: any) => {
						handleInputChange(e, index, `guidance_method_scale`);
					}}
				/>
			</Box>
		);
	};

	return renderInput();
};

const ObservedComponent = observer(GuidanceMethod);
export default ObservedComponent;
