import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import RouterConstants from "../../constants/RouterConstants";
import { useAppStore } from "../../hooks/useStores";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";
import TgLobby from "./Lobby/TgLobby";

const NewTailoredGeneration = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg" });
	const { tgStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const subTabs = [RouterConstants.TG_PROJECTS.path, RouterConstants.TG_ACCESS_METHODS.path];
	const [projectsCount, setProjectsCount] = useState(0);

	useEffect(() => {
		tgProjectStore.getOrgProjects(1).then((paginatedProjects) => setProjectsCount(paginatedProjects.total));
	}, []);

	const showLobby = !projectsCount;

	return (
		<>
			{showLobby ? (
				<TgLobby />
			) : (
				<TabsLayout title={t("header")} subTabs={subTabs}>
					<Outlet />
				</TabsLayout>
			)}
		</>
	);
};

export default observer(NewTailoredGeneration);
