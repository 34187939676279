import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import RouterConstants from "../../../../../../constants/RouterConstants";
import { useAppStore } from "../../../../../../hooks/useStores";
import CreateTgModel from "../../../../Models/Create/CreateTgModel";
import { ViewTgProjectLocationState } from "../../TgProjectViewer";

export const CreateTgProjectModel = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.viewer.models.create" });
	const { tgStore, uiStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const { projectId } = useParams();

	useEffect(() => {
		uiStore.hideSideBar();

		uiStore.showBackButton(
			`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_MODELS.path}`,
			undefined,
			t("backButton", { projectName: tgProjectStore.projectToView.project_name || "Project" }),
		);

		if (!tgProjectStore.projectToView.id && projectId) {
			tgProjectStore.getProject(Number(projectId)).then((project) => (tgProjectStore.projectToView = project));
		}

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
		};
	}, []);

	useEffect(() => {
		if (tgProjectStore.projectToView.id) {
			const viewProjectState: ViewTgProjectLocationState = { projectToView: toJS(tgProjectStore.projectToView) };

			uiStore.showBackButton(
				`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_MODELS.path}`,
				{ state: viewProjectState },
				t(`backButton`, { projectName: tgProjectStore.projectToView.project_name }),
			);
		}
	}, [tgProjectStore.projectToView.id]);

	return <CreateTgModel />;
};

export default observer(CreateTgProjectModel);
