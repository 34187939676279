const BulkModeIcon = () => (
	<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask id="path-1-inside-1" fill="white">
		<rect x="0.5" y="0.67" width="6.67" height="16.67" rx="1" />
	</mask>
	<rect
		x="0.5"
		y="0.67"
		width="6.67"
		height="16.67"
		rx="1"
		stroke="var(--primary-text-color)"
		strokeWidth="2.5"
		mask="url(#path-1-inside-1)"
	/>
	<mask id="path-2-inside-2" fill="white">
		<rect x="8.83" y="0.67" width="6.67" height="7.5" rx="1" />
	</mask>
	<rect
		x="8.83"
		y="0.67"
		width="6.67"
		height="7.5"
		rx="1"
		stroke="var(--primary-text-color)"
		strokeWidth="2.5"
		mask="url(#path-2-inside-2)"
	/>
	<mask id="path-3-inside-3" fill="white">
		<rect x="8.83" y="9.83" width="6.67" height="7.5" rx="1" />
	</mask>
	<rect
		x="8.83"
		y="9.83"
		width="6.67"
		height="7.5"
		rx="1"
		stroke="var(--primary-text-color)"
		strokeWidth="2.5"
		mask="url(#path-3-inside-3)"
	/>
</svg>

);

export default BulkModeIcon;
