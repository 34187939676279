import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./TgProjectsEmpty.module.scss";

const TgProjectsEmpty = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.dashboard.emptyState" });
	return (
		<Box className={styles.header}>
			<Typography className={styles.title}>{t("title")}</Typography>
			<Typography className={styles.description}>{t("description")}</Typography>
		</Box>
	);
};

export default observer(TgProjectsEmpty);
