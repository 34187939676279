export const getCroppedImg = async (
	imageSrc: string,
	pixelCrop: { x: number; y: number; width: number; height: number },
): Promise<string> => {
	const createImage = (src: string): Promise<HTMLImageElement> => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.crossOrigin = "anonymous";
			img.src = src;
			img.onload = () => resolve(img);
			img.onerror = (err) => reject(err);
		});
	};

	const image = await createImage(imageSrc);

	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	if (!ctx) {
		throw new Error("Failed to create canvas context");
	}

	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	ctx.fillStyle = "rgba(0, 0, 0, 0)";
	ctx.fillRect(0, 0, canvas.width, canvas.height);

	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height,
	);

	return new Promise((resolve, reject) => {
		try {
			const base64Image = canvas.toDataURL("image/png");
			resolve(base64Image);
		} catch (error) {
			reject(new Error("Failed to generate Base64 image"));
		}
	});
};