import { Box, Fade, LinearProgress, Typography } from "@mui/material";
import clsx from "clsx";
import React, { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import CacheIcon from "../../assets/images/icons/CacheIcon";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaInput from "../../components/common/BriaInput/BriaInput";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import styles from "./EditorLayout.module.scss";

type Props = {
	name: string;
	placeholder?: string;
	onNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onNameBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	errorName?: boolean;
	primaryButton?: string | ReactNode;
	primaryButtonClick?: () => void;
	loadingPrimaryButton?: boolean;
	disablePrimary?: boolean;
	secondaryButton?: string | ReactNode;
	secondaryButtonClick?: () => void;
	loadingSecondaryButton?: boolean;
	disableSecondary?: boolean;
	children?: ReactNode;
	className?: string;
	headerClassName?: string;
	childrenClassName?: string;
	loading?: boolean;
	loadingSave?: boolean;
	progress?: number;
};

const EditorLayout: React.FC<Props> = ({
	name,
	placeholder,
	onNameChange,
	onNameBlur,
	errorName,
	primaryButton,
	primaryButtonClick,
	loadingPrimaryButton = false,
	disablePrimary = false,
	secondaryButton,
	secondaryButtonClick,
	loadingSecondaryButton = false,
	disableSecondary = false,
	children,
	className,
	headerClassName,
	childrenClassName,
	loading = false,
	loadingSave = false,
	progress,
}: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "editorLayout" });
	const nameRef = useRef(name);
	const displayProgress = !!progress && progress < 100;

	const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (e.target.value !== nameRef.current) {
			onNameBlur?.(e);
		}
	};

	return (
		<LoadingPlaceholder isLoading={loading} className={styles.loadingPlaceholder}>
			<Box className={`${className} ${styles.container}`}>
				<Box
					className={`${headerClassName} ${clsx([
						styles.headerWrapper,
						!displayProgress && styles.headerWrapperWithBorder,
					])}`}
				>
					<Box className={styles.headerName}>
						{onNameChange || onNameBlur ? (
							<BriaInput
								value={name}
								placeholder={placeholder}
								onChange={onNameChange}
								onBlur={handleNameBlur}
								InputProps={{
									className: styles.titleStyle,
									disableUnderline: true,
								}}
								variant="standard"
								fullWidth
								error={errorName}
								helperText={errorName && "Invalid or existing name"}
								FormHelperTextProps={{
									style: {
										height: "4px",
										paddingLeft: "4px",
									},
								}}
								autoFocus
							/>
						) : (
							<Typography className={styles.titleStyle}>{name}</Typography>
						)}
						<Fade in={loadingSave}>
							<Typography className={styles.loadingSave}>
								<CacheIcon />
								{t("loadingSave")}
							</Typography>
						</Fade>
					</Box>
					<BriaButtonGroup>
						{typeof secondaryButton === "string" ? (
							<BriaButton
								onClick={secondaryButtonClick}
								buttonType="textMedium"
								disabled={disableSecondary}
								loading={loadingSecondaryButton}
							>
								{secondaryButton}
							</BriaButton>
						) : (
							secondaryButton
						)}
						{typeof primaryButton === "string" ? (
							<BriaButton
								onClick={primaryButtonClick}
								buttonType="primaryMedium"
								disabled={disablePrimary}
								loading={loadingPrimaryButton}
							>
								{primaryButton}
							</BriaButton>
						) : (
							primaryButton
						)}
					</BriaButtonGroup>
				</Box>
				{displayProgress && <LinearProgress variant="determinate" value={progress} />}
				<Box className={`${childrenClassName} ${styles.children}`}>{children}</Box>
			</Box>
		</LoadingPlaceholder>
	);
};

export default EditorLayout;
