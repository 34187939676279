import { Box, SelectChangeEvent, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import BriaDropDown from "../../../../../../components/common/BriaDropDown/BriaDropDown";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput";
import useStaticDropdown from "../../../../../../components/common/DropDowns/useStaticDropdown";
import { IMAGES_RESOLUTIONS_LIST } from "../../../../../../constants/AppsConstants";
import { useImageToImageConfig } from "../../../../../../hooks/useImageToImageConfig";
import { useSizeConfig } from "../../../../../../hooks/useSizeConfig";
import { useAppStore } from "../../../../../../hooks/useStores";
import InputLayout from "../../../../../../layout/InputLayout/InputLayout";
import {
	ImageEditingConfigComponentProps,
	ImageEditingViewMode,
	Platform,
	ResponsePlatformDestination,
} from "../../../../../../models/image-to-image";
import Config from "../../Config/Config";
import styles from "./SizeConfig.module.scss";

const SizeConfig = ({ onBackButtonClick }: ImageEditingConfigComponentProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features.size" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const {
		updateSelectedSizeConfigs,
		canApplyAspectRatio,
		canAppleCustomSize,
		canAppleMediumDestination,
		canApplyIncreaseResolution,
	} = useImageToImageConfig();
	const {
		cropConfig,
		platformsList,
		selectedAspectRatios,
		selectedDestinations,
		selectedResolutions,
		selectedPlatform,
		exceededResolutions,
		handleConfigChange,
		handleAspectRatioSelection,
		handlePlatformSelection,
		handleResolutionSelection,
		updateSelectedCustomSize,
	} = useSizeConfig();
	const { items } = useStaticDropdown({ tKeyPrefix: "aspectRatio" });
	const AspectRatiosList = items;
	const resolutionsList = IMAGES_RESOLUTIONS_LIST.filter((item) => item.key !== "X1");
	const [activeConfig, setActiveConfig] = useState<string>(imageToImageStore.activeConfig || "");

	useEffect(() => {
		if (playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE) {
			onBackButtonClick?.();
		}
	}, [playgroundStore.imageEditingViewMode]);

	const handleMediumDestinationChange = (e: SelectChangeEvent<string>) => {
		imageToImageStore.handleConfigChange("size", {
			...imageToImageStore.config.size,
			medium_destination: {
				...imageToImageStore.config.size?.medium_destination,
				platform: { ...imageToImageStore.config.size?.medium_destination?.platform, key: e.target.value },
			},
		});
		updateSelectedSizeConfigs();
	};

	return (
		<Box className={styles.sizeConfigContainer}>
			{/* Increase Resolution */}
			<Config
				title={t("increaseResolution")}
				checked={imageToImageStore.config.size?.increase_resolution?.selected || selectedResolutions.length > 0}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleConfigChange("increase_resolution", {
						...imageToImageStore.config.size?.increase_resolution,
						selected: target.checked,
						value: target.checked ? selectedResolutions : [],
					});
					setActiveConfig(t("increaseResolution"));
				}}
				isActive={activeConfig === t("increaseResolution") || canApplyIncreaseResolution()}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.configContent}>
					<Box className={styles.aspectRatioList}>
						{resolutionsList?.map((resolution, index) => {
							const value = resolution.value as string;
							const isSelected = selectedResolutions.includes(value);
							return (
								<BriaButton
									key={index}
									onClick={() => handleResolutionSelection(value)}
									className={clsx(styles.aspectRatioBtn, {
										[styles.selected]: isSelected,
									})}
									disabled={exceededResolutions.includes(value) || imageToImageStore.isGeneratingImages}
								>
									{value}
								</BriaButton>
							);
						})}
					</Box>
				</Box>
			</Config>

			{/* Aspect Ratio */}
			<Config
				title={t("aspectRatio")}
				checked={imageToImageStore.config.size?.aspect_ratio?.selected || selectedAspectRatios.length > 0}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleConfigChange("aspect_ratio", {
						...imageToImageStore.config.size?.aspect_ratio,
						selected: target.checked,
						value: target.checked ? selectedAspectRatios : [],
					});
					setActiveConfig(t("aspectRatio"));
				}}
				isActive={activeConfig === t("aspectRatio") || canApplyAspectRatio()}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.configContent}>
					<Box className={styles.aspectRatioList}>
						{AspectRatiosList?.map((aspectRatio, index) => {
							const value = aspectRatio.value as string;
							const isSelected = selectedAspectRatios.includes(value);
							return (
								<BriaButton
									key={index}
									onClick={() => handleAspectRatioSelection(value)}
									className={clsx(styles.aspectRatioBtn, {
										[styles.selected]: isSelected,
									})}
									sx={{ aspectRatio: value.replace(":", "/") }}
								>
									{value}
								</BriaButton>
							);
						})}
					</Box>
				</Box>
			</Config>

			{/* Custom Size */}
			<Config
				title={t("customSize")}
				checked={imageToImageStore.config.size?.custom_size?.selected}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					const custom_size = imageToImageStore.config.size?.custom_size;
					handleConfigChange("custom_size", {
						...custom_size,
						selected: target.checked,
						width: target.checked ? custom_size?.width : 0,
						height: target.checked ? custom_size?.height : 0,
					});
					setActiveConfig(t("customSize"));
				}}
				isActive={activeConfig === t("customSize") || canAppleCustomSize()}
				setIsActive={setActiveConfig}
			>
				<Box className={clsx(styles.configContent, styles.dimensions)}>
					<InputLayout label={t("width")} className={styles.inputLayout}>
						<BriaInput
							value={imageToImageStore.config.size?.custom_size?.width}
							onChange={async (e) => {
								const target = e.target as HTMLInputElement;
								await handleConfigChange("custom_size", {
									...imageToImageStore.config.size?.custom_size,
									width: parseInt(target.value),
								});
								updateSelectedCustomSize();
							}}
							type="number"
							customEndAdornmentText="px"
							height="40px"
						/>
					</InputLayout>
					<InputLayout label={t("height")} className={styles.inputLayout}>
						<BriaInput
							value={imageToImageStore.config.size?.custom_size?.height}
							onChange={async (e) => {
								const target = e.target as HTMLInputElement;
								await handleConfigChange("custom_size", {
									...imageToImageStore.config.size?.custom_size,
									height: parseInt(target.value),
								});
								updateSelectedCustomSize();
							}}
							type="number"
							customEndAdornmentText="px"
							height="40px"
						/>
					</InputLayout>
				</Box>
			</Config>

			{/* Medium Destination */}
			<Config
				title={t("mediumDestination")}
				checked={imageToImageStore.config.size?.medium_destination?.selected || selectedDestinations.length > 0}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleConfigChange("medium_destination", {
						...imageToImageStore.config.size?.medium_destination,
						selected: target.checked,
						destinations: target.checked ? selectedDestinations : [],
					});
					target.checked && setActiveConfig(t("mediumDestination"));
				}}
				isActive={activeConfig === t("mediumDestination") || canAppleMediumDestination()}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.configContent}>
					<BriaDropDown
						value={selectedPlatform}
						onChange={handleMediumDestinationChange}
						placeholder={t("dropDownPlaceholder")}
						items={platformsList}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
					/>
					{selectedPlatform && (
						<Box className={styles.platformItems}>
							{cropConfig.map((platform: Platform) => {
								return platform.get?.(selectedPlatform)?.map((item: ResponsePlatformDestination) => {
									const isSelected = selectedDestinations.find(
										(destination) =>
											destination.width === item.width && destination.height === item.height,
									);

									return (
										<BriaButton
											key={`${selectedPlatform}-${item.name}`}
											onClick={() =>
												handlePlatformSelection({
													name: `${selectedPlatform} ${item.name}`,
													width: item.width,
													height: item.height,
												})
											}
											className={clsx(styles.platformItem, {
												[styles.selected]: isSelected,
											})}
										>
											<Box
												className={styles.platformOption}
												sx={{ width: item.resize.width ?? 0, height: item.resize.height ?? 0 }}
											></Box>
											<Box>
												<Typography className={styles.label}>{item.name}</Typography>
												<Typography className={styles.label}>
													{item.width} x {item.height}
												</Typography>
											</Box>
										</BriaButton>
									);
								});
							})}
						</Box>
					)}
				</Box>
			</Config>
		</Box>
	);
};

export default observer(SizeConfig);
