import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Key, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import EmailButtonIcon from "../../assets/images/icons/EmailButtonIcon.tsx";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import Table, { FilterByOptions, PaginationOptions } from "../../components/common/BriaTable/BriaTable";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import SearchInput from "../../components/common/SearchInputField/SearchInputField.tsx";
import LinkConstants from "../../constants/LinkConstants";
import { useAppStore } from "../../hooks/useStores.tsx";
import styles from "./TableLayout.module.scss";

export type ButtonIcons = "plus" | "email";

export interface TableRow<T> {
	key?: Key;
	data: T;
	rowStyle?: object;
}

interface IProps<T> {
	title?: string;
	description?: string;
	disablePrimaryButton?: boolean;
	primaryButtonRequiresApiAccess?: boolean;
	primaryButtonLoading?: boolean;
	primaryButtonIconType?: ButtonIcons;
	primaryButtonText?: string | ReactNode;
	primaryButtonClick?: () => void;
	hideSecondaryButton?: boolean;
	secondaryButtonIconType?: ButtonIcons;
	secondaryButtonText?: string | ReactNode;
	secondaryButtonClick?: () => void;
	disableBottomButton?: boolean;
	hideBottomButton?: boolean;
	bottomButtonIconType?: ButtonIcons;
	bottomButtonText?: string;
	bottomButtonClick?: () => void;
	headerArray: string[];
	tableData?: TableRow<T>[];
	emptyStateComponent?: ReactNode;
	enableSearch?: boolean;
	handleSearchChange?: (value: any) => void;
	searchBoxPlaceholder?: string;
	filterBy?: FilterByOptions;
	paginationOptions?: PaginationOptions;
	children?: ReactNode;
	loading?: boolean;
	loadingRow?: { data: any; column: string };
	hideHeader?: boolean;
}

const TableLayout = <T,>({
	title,
	description,
	primaryButtonRequiresApiAccess = false,
	disablePrimaryButton = false,
	primaryButtonLoading = false,
	primaryButtonClick,
	primaryButtonIconType,
	primaryButtonText,
	hideSecondaryButton = false,
	secondaryButtonText,
	secondaryButtonIconType,
	secondaryButtonClick = () => {
		window.open(LinkConstants.BRIA_API, "_blank");
	},
	disableBottomButton = false,
	hideBottomButton = false,
	bottomButtonText,
	bottomButtonIconType,
	bottomButtonClick,
	tableData = [],
	headerArray,
	emptyStateComponent,
	enableSearch,
	handleSearchChange,
	searchBoxPlaceholder = "Search",
	filterBy,
	paginationOptions,
	children,
	loading = false,
	loadingRow,
	hideHeader = false,
}: IProps<T>) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState("");
	const { authStore } = useAppStore();

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
		handleSearchChange?.(event);
	};

	if (!secondaryButtonText) {
		secondaryButtonText = t("apiDocumentation");
	}

	return (
		<Box className={styles.container}>
			{!hideHeader && (
				<Box className={styles.header}>
					<Box>
						<Box style={{ display: title ? "flex" : "block" }} className={styles.titleSearchWrapper}>
							<Typography className={styles.title}>{title}</Typography>
							{(enableSearch || searchValue) && (
								<Box className={styles.searchContainer}>
									<SearchInput
										searchIcon
										value={searchValue}
										onChange={handleSearch}
										classes={{
											root: styles.searchRootStyle,
										}}
										placeholder={searchBoxPlaceholder}
										height={"40px"}
									/>
								</Box>
							)}
						</Box>
						<Typography className={styles.description}>{description}</Typography>
					</Box>
					<Box className={styles.buttonsContainer}>
						{!hideSecondaryButton &&
							(typeof secondaryButtonText === "string" ? (
								<BriaButton
									className={styles.secondaryBtn}
									onClick={secondaryButtonClick}
									buttonType="textMedium"
								>
									{secondaryButtonIconType === "email" ? (
										<EmailButtonIcon className={styles.emailButtonIcon} />
									) : secondaryButtonIconType === "plus" ? (
										<AddIcon />
									) : (
										<></>
									)}
									{secondaryButtonText}
								</BriaButton>
							) : (
								secondaryButtonText
							))}
						{primaryButtonText &&
							(typeof primaryButtonText === "string" ? (
								<BriaButton
									onClick={primaryButtonClick}
									loading={primaryButtonLoading}
									buttonType="primaryMedium"
									disabled={disablePrimaryButton}
									requiresApiAccess={primaryButtonRequiresApiAccess}
								>
									{primaryButtonIconType === "email" ? (
										<EmailButtonIcon className={styles.emailButtonIcon} />
									) : primaryButtonIconType === "plus" ? (
										<AddIcon />
									) : (
										<></>
									)}
									{primaryButtonText}
								</BriaButton>
							) : (
								primaryButtonText
							))}
					</Box>
				</Box>
			)}
			{children}

			<LoadingPlaceholder className={styles.loading} isLoading={loading} alwaysRenderChildren>
				{tableData.length || !emptyStateComponent ? (
					<>
						<Table
							{...{
								headerArray,
								filterBy,
								paginationOptions,
								loadingRow,
								hideBottomButton,
								disableBottomButton,
								bottomButtonIconType,
								bottomButtonText,
								bottomButtonClick,
							}}
							rows={tableData}
						/>
						{bottomButtonText && !hideBottomButton && (
							<BriaButton
								onClick={bottomButtonClick}
								buttonType="textSmall"
								disabled={disableBottomButton}
								style={{ color: "#5300c9", alignSelf: "end" }}
							>
								{bottomButtonIconType === "email" ? (
									<EmailButtonIcon className={styles.emailButtonIcon} />
								) : bottomButtonIconType === "plus" ? (
									<AddIcon />
								) : (
									<></>
								)}
								{bottomButtonText}
							</BriaButton>
						)}
					</>
				) : (
					<Box
						className={styles.emptyState}
						onClick={() =>
							(!primaryButtonRequiresApiAccess ||
								(!authStore.isLoadingOrgSubscriptions &&
									!authStore.isOrgBlocked &&
									!authStore.orgPassedFreeLimit)) &&
							primaryButtonClick
						}
					>
						{emptyStateComponent}
					</Box>
				)}
			</LoadingPlaceholder>
		</Box>
	);
};

export default observer(TableLayout);
