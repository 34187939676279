import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaImage from "../../../../components/common/Galleries/BriaImage";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../hooks/useStores";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import { EditTgProjectDatasetLocationState } from "../../Projects/Viewer/Datasets/Edit/EditTgProjectDataset";
import { TgDataset, defaultDataset } from "../store/tg-dataset-store";
import styles from "./TgDatasetViewer.module.scss";

export type ViewTgDatasetLocationState = {
	datasetToView?: TgDataset;
};

type TgDatasetViewerProps = {
	embeddedView?: boolean;
};

const TgDatasetViewer = ({ embeddedView }: TgDatasetViewerProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.viewer" });
	const location = useLocation();
	const navigate = useSecureNavigate();
	const { projectId, datasetId } = useParams();
	const { tgStore } = useAppStore();
	const { tgDatasetStore } = tgStore;
	const [dataset, setDataset] = useState<TgDataset>(location.state?.datasetToView ?? defaultDataset);

	useEffect(() => {
		(async () => {
			try {
				if (!location.state?.datasetToView && datasetId) {
					const dataset = await tgDatasetStore.getDataset(Number(datasetId));
					setDataset(dataset);
				}
			} catch (err: any) {
				if (err.response?.status === 404) {
					navigate(RouterConstants.NEW_TAILORED_GENERATION.fullPath);
				}
			}
		})();

		return () => {
			window.history.replaceState({}, "");
		};
	}, []);

	const duplicateDataset = async () => {
		const newDataset = await tgDatasetStore.duplicateDataset(dataset.id);
		const editDatasetState: EditTgProjectDatasetLocationState = {
			datasetToEdit: newDataset,
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_DATASETS.path}/${newDataset.id}/edit`,
			{ state: editDatasetState },
		);
	};

	const exportDataset = () => {};

	return (
		<LoadingPlaceholder className={styles.loadingPlaceholder} isLoading={tgDatasetStore.loadingDataset}>
			<Box className={`${styles.viewerContainer} ${embeddedView ? "" : styles.fullScreenView}`}>
				<Box className={styles.header}>
					<Typography className={styles.name}>{dataset.name}</Typography>
					<BriaButtonGroup>
						<BriaButton className={styles.hidden} buttonType="textMedium" onClick={exportDataset}>
							{t("secondaryButton")}
						</BriaButton>
						<BriaButton
							buttonType="secondaryMedium"
							onClick={duplicateDataset}
							loading={tgDatasetStore.loadingDuplicateDataset}
						>
							{t("primaryButton")}
						</BriaButton>
					</BriaButtonGroup>
				</Box>
				<InputLayout
					className={styles.captionPrefix}
					labelSize="large"
					label={t("prefix.label")}
					info={t("prefix.info")}
				>
					<Typography className={styles.prefixText}>{dataset.caption_prefix}</Typography>
				</InputLayout>
				<Box className={styles.images}>
					{dataset.images.map((image) => (
						<Box className={styles.imageCard}>
							<BriaImage
								className={styles.image}
								url={image.thumbnail_url}
								image={{ url: image.thumbnail_url, id: image.id.toString(), src: image.thumbnail_url }}
								hideFullScreenButton
							/>
							<Box className={styles.imageCaptionWrapper}>
								<Box className={styles.imageHeader}>
									<Typography className={styles.imageCaptionTag}>
										{t(`images.tags.${image.caption_source}`)}
									</Typography>
								</Box>
								<Typography className={styles.imageCaption}>
									<Typography component="span" className={styles.imagePrefix}>
										<Typography component="span" className={styles.imagePrefixTag}>
											{t(`images.tags.prefix`)}
										</Typography>
										<Typography component="span" className={styles.imagePrefixText}>
											{dataset.caption_prefix}
										</Typography>
									</Typography>
									<Typography component="span" className={styles.imageCaptionText}>
										{image.caption}
									</Typography>
								</Typography>
							</Box>
						</Box>
					))}
				</Box>
			</Box>
		</LoadingPlaceholder>
	);
};

export default observer(TgDatasetViewer);
