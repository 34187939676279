import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SubscriptionBackground from "../../../assets/images/svgs/subscription-widget-background.svg";
import SubscriptionSideBackground from "../../../assets/images/svgs/subscription-widget-side-background.svg";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { USER_SETTINGS } from "../../../constants/UserConstants.ts";
import { ORG_BLOCK_REASONS, ORG_SUBSCRIPTION_PLANS, ORG_SUBSCRIPTION_STATUSES } from "../../../constants/billing.ts";
import { getSelectedOrganization } from "../../../helpers/localStorage.ts";
import useOrgNavigation from "../../../hooks/useOrgNavigation.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import BillingService from "../../../services/BillingService.ts";
import Background from "../../common/Background/Background.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import styles from "./SubscriptionWidget.module.scss";

const SubscriptionWidget = () => {
	const { authStore, pricingStore } = useAppStore();
	const billingService = new BillingService();
	const { t } = useTranslation("translation", { keyPrefix: "home.subscriptionWidget" });
	const [showUncopiedApiKeyBanner, setShowUncopiedApiKeyBanner] = useState(false);
	const [loadingCustomerPortal, setLoadingCustomerPortal] = useState(false);

	const { orgNavigationHandler } = useOrgNavigation();

	useEffect(() => {
		const loadData = async () => {
			if (authStore.orgUsage && authStore.orgSubscription) {
				const _hideFreeUserBanner = await authStore.user?.getFreshSettingsCopy(
					USER_SETTINGS.hideFreeUserBanner,
				);
				setShowUncopiedApiKeyBanner(
					authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name && !_hideFreeUserBanner,
				);
			}
		};

		loadData();
	}, [
		authStore.orgSubscription,
		authStore.orgUsage,
		authStore.isLoadingOrgUsage,
		authStore.isLoadingOrgSubscriptions,
	]);

	const getUncopiedApiKeyBanner = () => {
		return (
			<Box className={styles.freeUserWithoutApi}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("freeUserWithoutApi.title")}</Typography>
					<Typography className={styles.description}>{t("freeUserWithoutApi.description")}</Typography>
					<Typography className={styles.info}>
						{t("freeUserWithoutApi.info", { calls: authStore.orgUsage?.apiFreeCallsLimit })}
					</Typography>
					<BriaButton
						onClick={() => orgNavigationHandler(RouterConstants.AUTH_KEYS.fullPath)}
						className={styles.upgradeButton}
						buttonType="secondaryMedium"
					>
						{t("freeUserWithoutApi.getApiKey")}
					</BriaButton>
				</Box>
				<Background
					background={SubscriptionSideBackground}
					width="100%"
					height="290px"
					borderRadius="0px"
					backgroundRepeat="no-repeat"
					backgroundPosition="top right"
				/>
			</Box>
		);
	};

	const ApiWarningWidget = ({
		message,
		buttonText,
		loading = false,
		onClick,
	}: {
		message: string;
		buttonText: string;
		loading?: boolean;
		onClick: () => void;
	}) => (
		<Box className={styles.inActiveApi}>
			<Typography className={styles.inActiveApiTitle}>{message}</Typography>
			<BriaButton onClick={onClick} buttonType="primaryMedium" loading={loading}>
				{buttonText}
			</BriaButton>
		</Box>
	);

	const getUserWithApiBox = (skeleton = false) => {
		return (
			<Box className={styles.freeUserWithApi}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("freeUserWithApi.title")}</Typography>
					{!skeleton &&
						authStore.orgSubscription?.is_blocked &&
						(authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name &&
						authStore.orgSubscription?.block_reason === ORG_BLOCK_REASONS.PASSED_FREE_LIMITS ? (
							<ApiWarningWidget
								message={t("freeUserWithApi.apiIsNotActive")}
								buttonText={t("freeUserWithApi.buttonText")}
								onClick={() => {
									pricingStore.openPricingFlow();
								}}
							/>
						) : (
							<ApiWarningWidget
								message={t("orgIsBlocked.apiIsNotActive")}
								buttonText={t("orgIsBlocked.buttonText")}
								loading={loadingCustomerPortal}
								onClick={() => window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank")}
							/>
						))}
					{!skeleton &&
						authStore.orgSubscription?.status === ORG_SUBSCRIPTION_STATUSES.PAST_DUE &&
						!authStore.orgSubscription?.is_blocked && (
							<ApiWarningWidget
								message={t("subscriptionPastDue.apiIsNotActive")}
								buttonText={t("subscriptionPastDue.buttonText")}
								loading={loadingCustomerPortal}
								onClick={() => {
									setLoadingCustomerPortal(true);
									billingService
										.createCustomerPortalSession(window.location.href)
										.then((session) => {
											window.open(session.url, "_self");
										})
										.finally(() => {
											setLoadingCustomerPortal(false);
										});
								}}
							/>
						)}
					<Box className={styles.grid}>
						<Box className={styles.gridColumn}>
							<Typography className={styles.header}>{t("freeUserWithApi.plan")}</Typography>
							<Typography
								className={clsx({
									[styles.data]: true,
									[styles.loaderStyle]: skeleton,
								})}
							>
								{skeleton ? (
									<CircularProgress color="inherit" size={15} />
								) : (
									<>
										{authStore.orgSubscription?.plan_name ?? "-"}{" "}
										{t("freeUserWithApi.subscription")}
									</>
								)}
							</Typography>
						</Box>
						<Box className={styles.gridColumn}>
							<Typography className={styles.header}>{t("freeUserWithApi.apiUsage")}</Typography>
							<Typography
								className={clsx({
									[styles.data]: true,
									[styles.loaderStyle]: skeleton,
								})}
							>
								{skeleton ? (
									<CircularProgress color="inherit" size={15} />
								) : (
									<>
										{authStore.orgUsage?.apiUsage.toLocaleString()} {t("calls")} (
										{authStore.orgSubscription?.start_date ?? "-"})
									</>
								)}
							</Typography>
						</Box>

						<Box className={styles.gridColumn}>
							{(skeleton ||
								authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name) && (
								<>
									<Typography className={styles.header}>
										{t("freeUserWithApi.apiFreeCallsLimit")}
									</Typography>
									<Typography
										className={clsx({
											[styles.data]: true,
											[styles.loaderStyle]: skeleton,
										})}
									>
										{skeleton ? (
											<CircularProgress color="inherit" size={15} />
										) : (
											<>
												{authStore.orgUsage?.apiFreeCallsLimit.toLocaleString()} {t("calls")}
											</>
										)}
									</Typography>
								</>
							)}
						</Box>
					</Box>
					{(skeleton ||
						(authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name &&
							!authStore.orgSubscription?.is_blocked)) && (
						<BriaButton
							onClick={() => {
								pricingStore.openPricingFlow();
							}}
							disabled={skeleton}
							className={styles.upgradeButton}
							buttonType="secondaryMedium"
						>
							{t("freeUserWithApi.increaseApiLimit")}
						</BriaButton>
					)}
				</Box>
			</Box>
		);
	};

	return (
		<Background
			background={SubscriptionBackground}
			width="100%"
			height="auto"
			borderRadius="9px 0px 0px 0px"
			backgroundRepeat="no-repeat"
			backgroundSize="cover"
			backgroundPosition="unset"
			className={styles.background}
		>
			{authStore.isLoadingOrgUsage || authStore.isLoadingOrgSubscriptions ? (
				<Box className={styles.container}>{getUserWithApiBox(true)}</Box>
			) : (
				<Box className={styles.container}>
					{showUncopiedApiKeyBanner || !getSelectedOrganization()?.organization ? (
						getUncopiedApiKeyBanner()
					) : authStore.orgUsage?.hasApiKeys ? (
						getUserWithApiBox()
					) : (
						<></>
					)}
				</Box>
			)}
		</Background>
	);
};

const ObservedComponent = observer(SubscriptionWidget);
export default ObservedComponent;
