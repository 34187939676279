import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function CacheIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 18 18" {...props}>
			<path
				d="M16.8223 8.00068C16.5777 6.2409 15.7613 4.61034 14.4989 3.36018C13.2365 2.11001 11.598 1.3096 9.83594 1.08224C8.07385 0.85487 6.28589 1.21316 4.74747 2.10193C3.20905 2.99069 2.00551 4.36062 1.32227 6.00068M0.822266 2.00068V6.00068H4.82227M0.822266 10.0007C1.06682 11.7605 1.8832 13.391 3.14563 14.6412C4.40806 15.8914 6.04651 16.6918 7.80859 16.9191C9.57068 17.1465 11.3586 16.7882 12.8971 15.8994C14.4355 15.0107 15.639 13.6408 16.3223 12.0007M16.8223 16.0007V12.0007H12.8223"
				stroke="#5B5B5B"
				strokeOpacity="0.85"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default CacheIcon;
