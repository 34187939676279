import { Theme, ThemeOptions, ThemeProvider, createTheme, darken, lighten, rgbToHex } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useMemo } from "react";
import RouterConstants from "../constants/RouterConstants.ts";
import { useTheme } from "../context/ThemeContext.tsx";
import { ThemeMode } from "../constants/themes.ts";
import { useBriaTheme } from "../hooks/useBriaTheme";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";

type Props = {
	children: ReactNode;
};
const BriaThemeProvider = ({ children }: Props) => {
	const { mode, setMode } = useTheme();

	const { getCssVariableValue, setCssVariableValue } = useBriaTheme();
	const mainColor = getCssVariableValue("--primary-main-color");
	const lighterMainColor = rgbToHex(lighten(mainColor, 0.2));
	const darkerMainColor = rgbToHex(darken(mainColor, 0.3));

	const lightTheme: ThemeOptions = {
		palette: {
			mode: "light",
			common: {
				white: "#FFFFFF",
				black: "#495057",
			},
			primary: {
				main: mainColor,
				light: lighterMainColor,
				dark: darkerMainColor,
			},
			secondary: {
				main: "#D80067",
			},
			text: {
				primary: "#495057",
				secondary: "#ABB5BE",
			},
			error: {
				main: "#DC3545",
			},
			warning: {
				main: "#FFC107",
			},
			success: {
				main: "#28A745",
			},
			background: {
				default: "radial-gradient(60.83% 60.83% at 50% 39.17%, #FFFFFF 21.87%, #EDEDED 100%)",
				paper: "#FFFFFF",
			},
		},
		typography: {
			fontFamily: "Montserrat",
		},
		shape: {
			borderRadius: 10,
		},
		components: {
			MuiInputBase: {
				styleOverrides: {
					input: {
						color: "var(--black-color)",
						"&:-webkit-autofill": {
							WebkitBoxShadow: "0 0 0 100px transparent inset !important",
							WebkitTextFillColor: "inherit",
							transition: "background-color 5000s ease-in-out 0s !important",
						},
						"&:-moz-autofill": {
							boxShadow: "0 0 0 100px transparent inset !important",
							color: "inherit",
							backgroundColor: "transparent !important",
						},
					},
				},
			},
			MuiFormControlLabel: {
				styleOverrides: {
					label: {
						fontSize: "14px",
						color: "#5B5B5B",
					},
				},
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						padding: 0,
						"&:before": {
							display: "none",
						},
					},
				},
			},
			MuiAccordionSummary: {
				styleOverrides: {
					root: {
						minHeight: 0,
						padding: 0,
						marginBottom: 24,
					},
					content: {
						margin: 0,
						fontSize: 14,
						fontWeight: 600,
						color: "#1a0638",
					},
				},
			},
			MuiSlider: {
				styleOverrides: {
					root: {
						height: 2,
					},
					thumb: {
						width: 12,
						height: 12,
						backgroundColor: "white",
					},
					markLabel: {
						fontSize: 12,
						fontWeight: 700,
					},
					mark: {
						display: "none",
					},
					valueLabel: {
						borderRadius: 14,
						backgroundColor: mainColor,
					},
				},
			},
			MuiSwitch: {
				styleOverrides: {
					root: {
						padding: 0,
						height: "fit-content",
						width: "fit-content",
					},
					switchBase: {
						padding: 2,
						"&.Mui-checked": {
							transform: "translateX(17px)",
							color: "#fff",
						},
						"&.Mui-checked+.MuiSwitch-track": {
							opacity: 1,
							backgroundColor: lighterMainColor,
						},
					},
					track: {
						height: 14,
						width: 32,
					},
					thumb: {
						height: 10.5,
						width: 10.87,
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						"&:hover .MuiOutlinedInput-notchedOutline": {
							borderColor: "var(--primary-main-color)",
						},
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: "var(--primary-main-color)",
						},
					},
					notchedOutline: {
						borderColor: "rgba(var(--black-color-rgb), 0.23)",
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundColor: "var(--white-color)",
					},
				},
			},
			MuiSelect: {
				styleOverrides: {
					icon: {
						color: "rgba(var(--black-color-rgb), 0.54)",
					},
					iconOpen: {
						color: "var(--primary-color)",
					},
				},
			},
		},
	};

	const darkTheme: ThemeOptions = {
		palette: {
			mode: "dark",
			common: {
				white: "#000",
				black: "#FFF",
			},
			primary: {
				main: mainColor,
				light: lighterMainColor,
				dark: darkerMainColor,
			},
			secondary: {
				main: "#D80067",
			},
			text: {
				primary: "#495057",
				secondary: "#ABB5BE",
			},
			error: {
				main: "#DC3545",
			},
			warning: {
				main: "#FFC107",
			},
			success: {
				main: "#28A745",
			},
			background: {
				default: "radial-gradient(60.83% 60.83% at 50% 39.17%, #000 21.87%, #EDEDED 100%)",
				paper: "#000",
			},
		},
		typography: {
			fontFamily: "Montserrat",
		},
		shape: {
			borderRadius: 10,
		},
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundColor: "var(--card-background)",
						backgroundImage: "none",
						border: "1px solid var(--border-light-color)",
					},
				},
			},
			MuiSelect: {
				styleOverrides: {
					icon: {
						color: "rgba(var(--black-color-rgb), 0.54)",
					},
					iconOpen: {
						color: "var(--black-color)",
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						"&:hover .MuiOutlinedInput-notchedOutline": {
							borderColor: "var(--primary-main-color)",
						},
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: "var(--primary-main-color)",
						},
					},
					notchedOutline: {
						borderColor: "rgba(var(--black-color-rgb), 0.23)",
					},
				},
			},
			MuiInputBase: {
				styleOverrides: {
					input: {
						color: "var(--black-color)",
						"&:-webkit-autofill": {
							WebkitBoxShadow: "0 0 0 100px transparent inset !important",
							WebkitTextFillColor: "inherit",
							transition: "background-color 5000s ease-in-out 0s !important",
						},
						"&:-moz-autofill": {
							boxShadow: "0 0 0 100px transparent inset !important",
							color: "inherit",
							backgroundColor: "transparent !important",
						},
					},
				},
			},
			MuiFormControlLabel: {
				styleOverrides: {
					label: {
						fontSize: "14px",
						color: "#5B5B5B",
					},
				},
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						padding: 0,
						"&:before": {
							display: "none",
						},
					},
				},
			},
			MuiAccordionSummary: {
				styleOverrides: {
					root: {
						minHeight: 0,
						padding: 0,
						marginBottom: 24,
					},
					content: {
						margin: 0,
						fontSize: 14,
						fontWeight: 600,
						color: "#1a0638",
					},
				},
			},
			MuiSlider: {
				styleOverrides: {
					root: {
						height: 2,
						color: "white",
					},
					thumb: {
						width: 12,
						height: 12,
						backgroundColor: "white",
					},
					markLabel: {
						fontSize: 12,
						fontWeight: 700,
						color: "white",
					},
					mark: {
						display: "none",
					},
					valueLabel: {
						borderRadius: 14,
						backgroundColor: "white",
					},
					track: {
						backgroundColor: "var(--primary-color)",
						borderColor: "var(--primary-color)",
					},
				},
			},
			MuiSwitch: {
				styleOverrides: {
					root: {
						padding: 0,
						height: "fit-content",
						width: "fit-content",
					},
					switchBase: {
						padding: 2,
						"&.Mui-checked": {
							transform: "translateX(17px)",
							color: "#fff",
						},
						"&.Mui-checked+.MuiSwitch-track": {
							opacity: 1,
							backgroundColor: "var(--black-color)",
						},
					},
					track: {
						height: 14,
						width: 32,
					},
					thumb: {
						height: 10.5,
						width: 10.87,
					},
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						color: "var(--black-color)",
						"&.Mui-focused": {
							color: "var(--black-color)",
						},
					},
				},
			},
		},
	};

	useEffect(() => {
		if (location.pathname.startsWith(RouterConstants.AUTOMOTIVE_EDITOR.path)) {
			setMode(ThemeMode.Dark);
		}
	}, [location.pathname]);

	useEffect(() => {
		document.documentElement.className = mode;
	}, [mode]);

	const theme: Theme = useMemo(() => {
		const selectedTheme: ThemeOptions = mode === "dark" ? darkTheme : lightTheme;
		if (iframeStore.iframe?.config.custom_style?.cssVars) {
			setCssVariableValue("--primary-light-color", lighterMainColor);
			setCssVariableValue("--primary-dark-color", darkerMainColor);
		}
		return createTheme(selectedTheme);
	}, [mode, iframeStore.iframe?.config.custom_style?.cssVars]);

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default observer(BriaThemeProvider);
