import { Box } from "@mui/material";
import clsx from "clsx";
import { useContext } from "react";
import Icons from "../../../../../components/Icons/index.ts";
import SmartImageHeader from "../../../../../components/common/SmartImageHeader/SmartImageHeader.tsx";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import useSmartImageHeader from "../../../../../hooks/useSmartImageHeader.tsx";
import styles from "./StaticPath.module.scss";

const StaticPath = () => {
	const { isPopupView } = useContext(DesignEditorContext);
	const { handleDeleteSmartImage } = useSmartImageHeader();

	return (
		<Box
			className={clsx({
				[styles.container]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box className={styles.staticPathControlsContainer}>
				<Box onClick={handleDeleteSmartImage} className={styles.StaticPathControlsWrapper}>
					<Icons.Delete size={20} />
				</Box>
			</Box>
			{!isPopupView && <SmartImageHeader />}
		</Box>
	);
};

export default StaticPath;
