import { Box } from "@mui/material";
import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import { EditIcon2 } from "../../../assets/images/icons/EditIcon2";
import ActionsDotsDropdown from "../../../components/common/ActionsDotsDropdown/ActionsDotsDropdown";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import EmptyTable from "../../../components/common/EmptyTable/EmptyTable";
import RouterConstants from "../../../constants/RouterConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores";
import { useUserChecks } from "../../../hooks/useUserChecks";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout";
import { StatusEnum } from "../../../models/common";
import { Template } from "../../../models/template";
import { formatDate } from "../../../utils";
import { EditTemplateLocationState } from "./Editor/TemplateEditor";
import styles from "./Templates.module.scss";

type TemplateTableRow = {
	id: string | ReactElement;
	name: string | ReactElement;
	layouts: string | ReactElement;
	updated: string | ReactElement;
	status: string | ReactElement;
	actions: ReactElement;
};

const Templates = () => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.templates" });
	const { templatesStore } = useAppStore();
	const navigate = useSecureNavigate();
	const { canUserEditOrganization } = useUserChecks();
	const [tableData, setTableData] = useState<TableRow<TemplateTableRow>[]>();
	const [templateToDelete, setTemplateToDelete] = useState<Template>();
	const [loadingRow, setLoadingRow] = useState<{ data: Template; column: string }>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const headerArray = [
		t("table.headers.id"),
		t("table.headers.name"),
		t("table.headers.layouts"),
		t("table.headers.updated_at"),
		t("table.headers.status"),
		t("table.headers.actions"),
	];
	const [filterBy, setFilterBy] = useState<FilterByOptions>();
	const paginationOptions: PaginationOptions = {
		rowsPerPage: templatesStore.rowsPerPage,
		totalRows: templatesStore.paginatedTemplates.total,
		loadNextPage: templatesStore.loadTemplates,
	};

	useEffect(() => {
		templatesStore.loadTemplates(1).then(() => {
			setTableData(templatesStore.paginatedTemplates.items.map(createRow));
		});
	}, []);

	useEffect(() => {
		if (!templatesStore.isLoading) {
			const newTableData: TableRow<TemplateTableRow>[] = templatesStore.paginatedTemplates.items.map(createRow);
			setTableData([...newTableData]);
		}
	}, [templatesStore.paginatedTemplates.items]);

	useEffect(() => {
		if (tableData) {
			templatesStore.isLoading = false;
		}
	}, [tableData]);

	const editTemplate = async (templateToEdit: Template) => {
		const editTemplateState: EditTemplateLocationState = {
			templateToEdit: toJS(templateToEdit),
		};
		navigate(`${RouterConstants.TEMPLATE_EDITOR.editPath}/${templateToEdit.id}`, {
			state: editTemplateState,
		});
	};

	const openTemplateEditorPage = async () => {
		navigate(RouterConstants.NEW_TEMPLATE_EDITOR.fullPath);
	};

	const deleteTemplate = async () => {
		if (templateToDelete) {
			setLoadingRow({ data: templateToDelete, column: "actions" });
			closeDeletePopup();
			await templatesStore.updateTemplate({ ...templateToDelete, status: StatusEnum.DELETED });
			setLoadingRow(undefined);
		}
	};

	const updateTemplateStatus = async (template: Template, status: StatusEnum) => {
		setLoadingRow({ data: template, column: "status" });
		await templatesStore.updateTemplate({ ...template, status });
		setLoadingRow(undefined);
	};

	const createRow = (template: Template): TableRow<TemplateTableRow> => {
		const color = template.status === StatusEnum.DELETED ? "rgba(var(--primary-text-color-rgb),0.4)" : "";
		const statusContainer = (
			<Box className={styles.statusContainer} sx={{ color }}>
				<div className={`${styles[template.status?.toLowerCase()]} ${styles.circle}`} />
				{template.status}
			</Box>
		);
		return {
			rowStyle: {
				backgroundColor: template.status === StatusEnum.DELETED ? "var(--disabled-table-row)" : "",
			},
			key: template.id,
			data: {
				id: (
					<Box className={styles.idContainer} style={{ color: color }}>
						{template.id}
					</Box>
				),
				name: (
					<Box className={styles.nameStyle} style={{ color: color }}>
						{template.name}
					</Box>
				),
				layouts: <Box style={{ color: color }}>{template.layouts?.length}</Box>,
				updated: <Box style={{ color: color }}>{formatDate(template.updated_at)}</Box>,
				status: (
					<Box className={styles.statusContainer}>
						{template.status !== StatusEnum.DELETED && canUserEditOrganization() ? (
							<ActionsDotsDropdown
								label={statusContainer}
								items={Object.values(StatusEnum)
									.filter((status) => status !== StatusEnum.DELETED)
									.map((status) => ({
										text: status,
										onClick: () => updateTemplateStatus(template, status),
									}))}
							/>
						) : (
							statusContainer
						)}
					</Box>
				),
				actions: (
					<Box className={styles.actionsContainer}>
						{[
							{
								icon: (
									<BriaTooltip title={t("edit")}>
										<EditIcon2 className={styles.actionBtn} sx={{ width: "18px !important" }} />
									</BriaTooltip>
								),
								onClick: () => canUserEditOrganization() && editTemplate(template),
							},
							{
								icon: (
									<BriaTooltip title={t("delete")}>
										<DeleteIcon className={styles.actionBtn} />
									</BriaTooltip>
								),
								onClick: () => canUserEditOrganization() && handleOpenDeletePopup(template),
							},
						].map((action, index) => (
							<Box
								key={index}
								onClick={action.onClick}
								className={clsx({
									[styles.disabledOpacity]:
										!canUserEditOrganization() || template.status === StatusEnum.DELETED,
								})}
							>
								{action.icon}
							</Box>
						))}
					</Box>
				),
			},
		};
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions = {
			...filterBy,
			search: { text: e.target.value, fields: ["id", "name", "updated_at", "status"] },
		};
		setFilterBy(updatedFilterBy);
		await templatesStore.loadTemplates(1, updatedFilterBy);
	};

	const handleOpenDeletePopup = (template: Template) => {
		setOpenDeletePopup(true);
		setTemplateToDelete(template);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setTemplateToDelete(undefined);
	};

	return (
		<>
			<TableLayout
				title={t("table.title")}
				description={t("table.description")}
				primaryButtonText={ct("createNew")}
				primaryButtonClick={openTemplateEditorPage}
				secondaryButtonText={ct("documentation")}
				headerArray={headerArray}
				tableData={tableData}
				enableSearch={true}
				searchBoxPlaceholder={t("table.searchPlaceholder")}
				handleSearchChange={handleSearchChange}
				filterBy={filterBy}
				paginationOptions={paginationOptions}
				emptyStateComponent={<EmptyTable message={t("table.emptyState")} />}
				loading={templatesStore.isLoading}
				loadingRow={loadingRow}
			/>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={ct("delete")}
				open={openDeletePopup}
				onClick={deleteTemplate}
			/>
		</>
	);
};

export default observer(Templates);
