import { SelectChangeEvent } from "@mui/material";
import { get, ref } from "firebase/database";
import { useCallback, useState } from "react";
import { getImageDimensionsByImageUrl } from "../DesignEditor/views/DesignEditor/utils/smartImageUtils";
import { DropDownItem } from "../components/common/BriaDropDown/BriaDropDown";
import { firebaseDatabase } from "../config/firebase";
import { IMAGES_RESOLUTIONS_LIST } from "../constants/AppsConstants";
import { useAppStore } from "../hooks/useStores";
import { ConfigValue, ManualPlacementConfigType, Platform } from "../models/image-to-image";
import { checkImageResolution } from "../utils";
import { generateCropPresetsByPlatform } from "../utils/generateCropPresetsByPlatform";

export const useManualPlacementConfig = () => {
	const { imageToImageStore, playgroundStore } = useAppStore();
	const [selectedConfig, setSelectedConfig] = useState<string>("");
	const [activeConfig, setActiveConfig] = useState<string>("");
	const [selectedAspectRatio, setSelectedAspectRatio] = useState("");
	const [selectedPlatform, setSelectedPlatform] = useState("");
	const [selectedPlatformDimensions, setSelectedPlatformDimensions] = useState<{
		width?: number;
		height?: number;
		name?: string;
	}>();
	const [cropConfig, setCropConfig] = useState<Platform[]>([]);
	const [platformsList, setPlatformsList] = useState<DropDownItem[]>([]);
	const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>();
	const [customWidth, setCustomWidth] = useState<string>();
	const [customHeight, setCustomHeight] = useState<string>();
	const [exceededResolutions, setExceededResolutions] = useState<string[]>([]);

	const selectedImage =
		playgroundStore.singleSelectedImage || playgroundStore.getAvailableImages().find((image) => image.selected);

	const calculateImageDimensions = useCallback(async () => {
		if (selectedImage) {
			const dimensions = await getImageDimensionsByImageUrl(selectedImage.url);

			setImageDimensions(dimensions);

			const config: ManualPlacementConfigType = {
				...dimensions,
				original_image_size: [dimensions.width, dimensions.height],
			};
			imageToImageStore.handleConfigChange("manual_placement", config);
		}
	}, [selectedImage, imageToImageStore, playgroundStore.playgroundResults]);

	const getCropConfig = useCallback(async () => {
		const res = await get(ref(firebaseDatabase, "crop_config/"));
		const data: any = generateCropPresetsByPlatform(res.val());
		setCropConfig(data);

		const platforms: DropDownItem[] = data.flatMap((platform: any) =>
			[...platform.keys()].map((key) => ({ key, value: key })),
		);
		setPlatformsList(platforms);
	}, []);

	const handleConfigChange = useCallback(
		(field: string, value: ConfigValue) => {
			imageToImageStore.handleConfigChange("manual_placement", {
				...imageToImageStore.config.manual_placement,
				[field]: value,
			});
		},
		[imageToImageStore],
	);

	const handleMediumDestinationChange = useCallback((e: SelectChangeEvent<string>) => {
		setSelectedPlatform(e.target.value);
	}, []);

	const handleAspectRatioSelection = useCallback(
		(item: string) => {
			setSelectedAspectRatio(item);

			if (selectedImage && imageDimensions) {
				const { width: originalWidth, height: originalHeight } = imageDimensions;
				let newWidth: number = originalWidth;
				let newHeight: number = originalHeight;

				if (item !== "original") {
					const [aspectWidth, aspectHeight] = item.split(":").map(Number);
					if (aspectWidth && aspectHeight) {
						const originalAspectRatio = originalWidth / originalHeight;
						const selectedAspectRatio = aspectWidth / aspectHeight;

						if (selectedAspectRatio > originalAspectRatio) {
							newHeight = Math.round(newWidth / selectedAspectRatio);
						} else {
							newWidth = Math.round(newHeight * selectedAspectRatio);
						}
					}
				}

				handleConfigChange("width", newWidth);
				handleConfigChange("height", newHeight);
			}
		},
		[selectedImage, imageDimensions, handleConfigChange, playgroundStore.playgroundResults],
	);

	const handlePlatformSelection = useCallback(
		async (item: any) => {
			setSelectedPlatformDimensions(item);
			handleConfigChange("width", item.width);
			handleConfigChange("height", item.height);
		},
		[handleConfigChange],
	);

	const checkResolutions = useCallback(async () => {
		const selectedImage =
			playgroundStore.singleSelectedImage || playgroundStore.getAvailableImages().find((image) => image.selected);
		const resolutionFactors = IMAGES_RESOLUTIONS_LIST.filter((item) => item.key !== "X1").map((res) =>
			parseInt(res.value.replace("X", "")),
		);
		if (selectedImage) {
			const exceedResolutions: string[] = [];
			for (const factor of resolutionFactors) {
				const exceed = await checkImageResolution(selectedImage.url, factor);
				if (exceed) {
					IMAGES_RESOLUTIONS_LIST.forEach((res) => {
						const resFactor = parseInt(res.value.replace("X", ""));
						if (resFactor >= factor) {
							exceedResolutions.push(res.value);
						}
					});
				}
			}
			setExceededResolutions(exceedResolutions);
		}
	}, [selectedImage]);

	const isExpandEnabled = () => {
		const manualPlacement = imageToImageStore.config.manual_placement;

		if (manualPlacement?.cropped_area_pixels && manualPlacement?.width && manualPlacement?.height) {
			const cropped = manualPlacement.cropped_area_pixels;
			const croppedArea = manualPlacement.cropped_area;
			const canvasWidth = manualPlacement.width;
			const canvasHeight = manualPlacement.height;

			const ratio = Math.max(croppedArea.width / cropped.width, croppedArea.height / cropped.height);

			const scaledLocationX = Math.round(cropped.x * ratio);
			const scaledLocationY = Math.round(cropped.y * ratio);
			const scaledImageWidth = Math.round(canvasWidth * ratio);
			const scaledImageHeight = Math.round(canvasHeight * ratio);

			return (
				scaledLocationX < 0 ||
				scaledLocationY < 0 ||
				Math.abs(scaledImageWidth - scaledLocationX) < croppedArea.width ||
				Math.abs(scaledImageHeight - scaledLocationY) < croppedArea.height
			);
		}

		return false;
	};

	const isImageInsideCanvas = () => {
		const manualPlacement = imageToImageStore.config.manual_placement;

		const croppedAreaPixels = manualPlacement?.cropped_area_pixels;
		const canvasWidth = manualPlacement?.width ?? 0;
		const canvasHeight = manualPlacement?.height ?? 0;

		if (!manualPlacement?.canvas_size || !manualPlacement?.original_image_size) {
			return true;
		}
		if (!croppedAreaPixels) return false;

		const { x, y, width, height } = croppedAreaPixels;
		const leftBoundary = x;
		const rightBoundary = x + width;
		const topBoundary = y;
		const bottomBoundary = y + height;

		const isOutside =
			rightBoundary < 0 || leftBoundary > canvasWidth || bottomBoundary < 0 || topBoundary > canvasHeight;

		return !isOutside;
	};

	return {
		selectedConfig,
		setSelectedConfig,
		selectedAspectRatio,
		selectedPlatform,
		selectedPlatformDimensions,
		cropConfig,
		platformsList,
		imageDimensions,
		customWidth,
		setCustomWidth,
		customHeight,
		setCustomHeight,
		handleConfigChange,
		handleMediumDestinationChange,
		handleAspectRatioSelection,
		handlePlatformSelection,
		calculateImageDimensions,
		setSelectedAspectRatio,
		setSelectedPlatformDimensions,
		activeConfig,
		setActiveConfig,
		checkResolutions,
		exceededResolutions,
		isExpandEnabled,
		isImageInsideCanvas,
		getCropConfig,
	};
};
