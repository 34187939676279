import aws from "../assets/images/svgs/AWS.svg";
import azure from "../assets/images/svgs/Azure.svg";
import backgroundGeneration from "../assets/images/svgs/Background Generation.svg";
import campaignGeneration from "../assets/images/svgs/Campaign Generation.svg";
import comfyUI from "../assets/images/svgs/ComfyUI.svg";
import Models from "../assets/images/svgs/DownloadModel.svg";
import iFrameIcon from "../assets/images/svgs/EnableIFrame.svg";
import Group from "../assets/images/svgs/Group 85433.svg";
import huggingFace from "../assets/images/svgs/Hugging_Face.svg";
import imageExpansion from "../assets/images/svgs/Image Expansion.svg";
import imageToPSD from "../assets/images/svgs/Image-to-PSD (Delayering).svg";
import increaseResolution from "../assets/images/svgs/Increase Resolution.svg";
import API from "../assets/images/svgs/IntegrateViaAPI.svg";
import presenterRecasting from "../assets/images/svgs/Presenter Recasting.svg";
import texTtoImage from "../assets/images/svgs/Text-to-Image Generation.svg";
import falAI from "../assets/images/svgs/fal_ai.svg";
import invoke from "../assets/images/svgs/invoke.svg";
import { ICardProps } from "../components/Dashboard/DashoboardCardComponent";
import LinkConstants from "../constants/LinkConstants";
import RouterConstants from "./RouterConstants";

export type linkCard = {
	title: string;
	link: string;
	icon: string;
};

export const cardDataImplementedOptions: ICardProps[] = [
	{
		icon: Models,
		cardTitle: "implementationOptions.cards.modelsTitle",
		cardDescription: "implementationOptions.cards.modelsDescription",
		mainButton: "get_started",
		background: "#5300C9",
		newTab: false,
		href: RouterConstants.FOUNDATION_MODELS.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: API,
		cardTitle: "implementationOptions.cards.APITitle",
		cardDescription: "implementationOptions.cards.APIDescription",
		mainButton: "get_started",
		background: "#5300C9",
		newTab: false,
		href: RouterConstants.IMAGE_GENERATION_APIS.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: iFrameIcon,
		cardTitle: "implementationOptions.cards.iframeTitle",
		cardDescription: "implementationOptions.cards.iframeDescription",
		mainButton: "get_started",
		background: "#5300C9",
		newTab: false,
		href: RouterConstants.NEW_IFRAMES_CONFIG.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	// {
	// 	icon: CloudComputing,
	// 	cardTitle: "implementationOptions.cards.cloudMarketplaceTitle",
	// 	cardDescription: "implementationOptions.cards.cloudMarketplaceDescription",
	// 	mainButton: "get_started",
	// 	background: "#5300C9",
	// 	newTab: false,
	// 	containGetApiKeyButton: false,
	// 	containGetModelButton: false,
	// 	containconfigureIframeButton: false,
	// 	extraCardLinks: [
	// 		{
	// 			icon: AWS,
	// 			title: "implementationOptions.cards.extraLinks.aws.title",
	// 			href: LinkConstants.BRIA_AI_AWS_MARKETPLACE,
	// 		},
	// 		{
	// 			icon: Azure,
	// 			title: "implementationOptions.cards.extraLinks.azure.title",
	// 			href: LinkConstants.BRIA_AI_AZURE_MARKETPLACE,
	// 		},
	// 	],
	// },
];

export const aIImageCreationCapabilitiesCards: ICardProps[] = [
	{
		icon: texTtoImage,
		cardTitle: "aIImageCreationCapabilities.cards.texttoImageGenerationTitle",
		cardDescription: "aIImageCreationCapabilities.cards.texttoImageGenerationDescription",
		mainButton: "Playground",
		background: "#D80067",
		newTab: false,
		href: `/${RouterConstants.APPS.path}/${RouterConstants.TEXT_TO_IMAGE.path}`,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: campaignGeneration,
		cardTitle: "aIImageCreationCapabilities.cards.campaignGenerationTitle",
		cardDescription: "aIImageCreationCapabilities.cards.campaignGenerationDescription",
		mainButton: "Playground",
		background: "#D80067",
		newTab: true,
		href: LinkConstants.CONTACT_US_FORM_LINK,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
		extraCardLinks: [
			{
				title: "implementationOptions.cards.extraLinks.contactUs",
				href: LinkConstants.CONTACT_US_FORM_LINK,
			},
		],
	},
];

export const aiImageCustomizationCapabilitiesCards: ICardProps[] = [
	{
		icon: backgroundGeneration,
		cardTitle: "aIImageCustomizationCapabilities.cards.backgroundGenerationTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.backgroundGenerationDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: false,
		href: RouterConstants.GENERATE_BACKGROUND_APP.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: imageExpansion,
		cardTitle: "aIImageCustomizationCapabilities.cards.imageExpansionTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.imageExpansionDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: false,
		href: RouterConstants.EXPAND_IMAGE_APP.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: presenterRecasting,
		cardTitle: "aIImageCustomizationCapabilities.cards.presenterAppearanceTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.presenterAppearanceDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: true,
		href: LinkConstants.PRESENTER_RECASTING,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: increaseResolution,
		cardTitle: "aIImageCustomizationCapabilities.cards.increaseResolutionTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.increaseResolutionDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: false,
		href: RouterConstants.INCREASE_RESOLUTION_APP.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: Group,
		cardTitle: "aIImageCustomizationCapabilities.cards.objectErasureAndReplacementTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.objectErasureAndReplacementDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: false,
		href: RouterConstants.ERASER_APP.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: imageToPSD,
		cardTitle: "aIImageCustomizationCapabilities.cards.imageToPSDTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.imageToPSDDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: false,
		href: RouterConstants.DELAYER_IMAGE_APP.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
];

export const yourFavoritePlatforms: linkCard[] = [
	{
		icon: aws,
		title: "aws",
		link: LinkConstants.BRIA_AI_AWS_MARKETPLACE,
	},
	{
		icon: azure,
		title: "azure",
		link: LinkConstants.BRIA_AI_AZURE_MARKETPLACE,
	},
	{
		icon: comfyUI,
		title: "ComfyUI",
		link: LinkConstants.BRIA_COMFYUI_API,
	},
	{
		icon: huggingFace,
		title: "HuggingFace",
		link: LinkConstants.BRIA_HUGGING_FACE,
	},
	{
		icon: falAI,
		title: "falAI",
		link: LinkConstants.BRIA_FAL_AI,
	},
	{
		icon: invoke,
		title: "invoke",
		link: LinkConstants.INVOKE_PLATFORM,
	},
];
