import { AxiosRequestConfig } from "axios";
import { BriaAxios } from "../config/axios.tsx";

class QueryService {
	private baseURL: string;

	constructor(baseURL: string) {
		this.baseURL = baseURL;
	}

	async get(url: string, config?: AxiosRequestConfig<any>): Promise<any> {
		const response = await (
			await BriaAxios(config?.headers, config?.signal as AbortSignal)
		).get(`${this.baseURL}${url}`, config);
		return response.data;
	}

	async post(
		url: string,
		data: any = {},
		headers: Record<string, string> = {},
		options: Record<string, any> = {},
	): Promise<any> {
		const config = { ...options, headers: { ...headers } };

		const response = await (await BriaAxios()).post(`${this.baseURL}${url}`, data, config);
		return response.data;
	}

	async put(url: string, data: any = {}): Promise<any> {
		const response = await (await BriaAxios()).put(`${this.baseURL}${url}`, data);
		return response.data;
	}

	async delete(url: string): Promise<any> {
		const response = await (await BriaAxios()).delete(`${this.baseURL}${url}`);
		return response.data;
	}
}

export default QueryService;
