import { useTranslation } from "react-i18next";
import styles from "./AppHeader.module.scss";

import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import LinkConstants from "../../../constants/LinkConstants.ts";

import { observer } from "mobx-react-lite";
import { CrownIcon } from "../../../assets/images/icons/CrownIcon.tsx";
import OrganizationsDropdown from "../../../components/common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import ThemeModeDropdown from "../../../components/common/ThemeModeDropdown/ThemeModeDropdown.tsx";
import UserDropdown from "../../../components/common/UserDropdown/UserDropdown.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { isBriaPublicOrg } from "../../../utils/index.ts";
import HeaderLayout from "../HeaderLayout.tsx";

const AppHeader = () => {
	const { pricingStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "headers.app" });
	const navigate = useSecureNavigate();
	const handleLogoClick = () => {
		navigate(RouterConstants.CONSOLE.path);
	};
	return (
		<HeaderLayout onLogoClick={handleLogoClick}>
			<BriaButton
				onClick={() => {
					pricingStore.openPricingFlow();
				}}
				className={styles.upgradeBtn}
			>
				<CrownIcon />
				{t("upgrade")}
			</BriaButton>
			<BriaButton onClick={() => window.open(LinkConstants.BRIA_API, "_blank")} buttonType="tertiaryMedium">
				{t("docs")}
			</BriaButton>
			<OrganizationsDropdown />
			<UserDropdown />
			{isBriaPublicOrg() && <ThemeModeDropdown />}
		</HeaderLayout>
	);
};

export default observer(AppHeader);
