import { ReactNode, createContext, useContext, useState } from "react";
import { ThemeMode } from "../constants/themes";

export type ThemeModeContextType = {
	mode: ThemeMode;
	changeTheme: (theme: ThemeMode) => void;
	setMode: (newMode: ThemeMode) => void;
};

const ThemeModeContext = createContext<ThemeModeContextType | undefined>(undefined);

export const useTheme = (): ThemeModeContextType => {
	const context = useContext(ThemeModeContext);
	if (!context) {
		throw new Error("useThemeMode must be used within a ThemeModeProvider");
	}
	return context;
};

export const ThemeModeProvider = ({ children }: { children: ReactNode }) => {
	const [mode, setMode] = useState<ThemeMode>(ThemeMode.Light);

	const changeTheme = (theme: ThemeMode) => {
		setMode(theme);
	};

	return <ThemeModeContext.Provider value={{ mode, changeTheme, setMode }}>{children}</ThemeModeContext.Provider>;
};
