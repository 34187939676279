import React from "react";
import classNames from "classnames";
import Logo from "../../../assets/images/svgs/Bria-logo.svg";
import WhiteLogo from "../../../assets/images/svgs/Bria-white-logo.svg";
import { useTheme } from "../../../context/ThemeContext.tsx";
import { ThemeMode } from "../../../constants/themes.ts";

type LogoComponentProps = {
	onLogoClick: () => void;
	customLogo?: string;
	className?: string;
	altText?: string;
};

const BriaLogo: React.FC<LogoComponentProps> = React.memo(
	({ onLogoClick, customLogo, className, altText = "Bria Logo" }) => {
		const { mode } = useTheme();

		// Select appropriate logo based on theme or custom logo
		const logoSrc = customLogo || (mode === ThemeMode.Dark ? WhiteLogo : Logo);

		return (
			<img
				src={logoSrc}
				alt={altText}
				aria-label={altText}
				onClick={onLogoClick}
				style={{ cursor: "pointer" }}
				className={classNames("bria-logo", className)}
			/>
		);
	}
);

export default BriaLogo;
