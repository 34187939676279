import { Box } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FilterByOptions, PaginationOptions } from "../../../../../components/common/BriaTable/BriaTable";
import { useAppStore } from "../../../../../hooks/useStores";
import TableLayout, { TableRow } from "../../../../../layout/TableLayout/TableLayout";
import { formatDate } from "../../../../../utils";
import { TgDataset } from "../../../Datasets/store/tg-dataset-store";
import TgDatasetActions from "./Dashboard/Actions/TgDatasetActions";
import TgProjectDatasetsEmpty from "./Empty/TgProjectDatasetsEmpty";
import styles from "./TgProjectDatasets.module.scss";

type TgDatasetTableRow = {
	name: string | ReactElement;
	created: string | ReactElement;
	status: string | ReactElement;
	actions: ReactElement;
};

const TgProjectDatasets = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.dashboard" });
	const { projectId } = useParams();
	const { tgStore, authStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const [loadingRow, setLoadingRow] = useState<{ data: TgDataset; column: string }>();
	const tableHeaderArray = [
		t("table.headers.name"),
		t("table.headers.created"),
		t("table.headers.status"),
		t("table.headers.actions"),
	];
	// const [filterBy, setFilterBy] = useState<FilterByOptions>();

	const loadNextPage = (page?: number | undefined, filterBy?: FilterByOptions | undefined) =>
		tgProjectStore.getDatasets(Number(projectId), page, filterBy);

	const paginationOptions: PaginationOptions = {
		rowsPerPage: tgProjectStore.rowsPerPage,
		totalRows: tgProjectStore.paginatedDatasets.total,
		loadNextPage: loadNextPage,
	};

	useEffect(() => {
		tgProjectStore.getDatasets(Number(projectId), 1);
	}, [authStore.isLoadingOrgSubscriptions]);

	const createRow = (dataset: TgDataset): TableRow<TgDatasetTableRow> => {
		const color = dataset.status === "Deleted" ? "#1A063840" : "";

		const name = (
			<Box className={styles.nameContainer}>
				<Box className={styles.nameContainer}>
					<Box>{dataset.name}</Box>
					{dataset.description && <Box className={styles.description}>{dataset.description}</Box>}
				</Box>
			</Box>
		);

		const created = <Box>{formatDate(dataset.created_at)}</Box>;

		const status = (
			<Box className={styles.statusContainer} sx={{ color }}>
				<div className={`${styles[dataset.status?.toLowerCase()]} ${styles.circle}`} />
				{t(`table.status.${dataset.status}`)}
			</Box>
		);

		const actions = <TgDatasetActions {...{ dataset, setLoadingRow }} />;

		const newRow: TableRow<TgDatasetTableRow> = {
			key: dataset.id,
			data: {
				name,
				created,
				status,
				actions,
			},
		};

		return newRow;
	};

	// const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const updatedFilterBy: FilterByOptions = {
	// 		...filterBy,
	// 		search: {
	// 			text: e.target.value,
	// 			fields: ["name", "created_at", "status"],
	// 		},
	// 	};
	// 	setFilterBy(updatedFilterBy);
	// 	await tgProjectStore.getDatasets(Number(projectId), 1, updatedFilterBy);
	// };

	const tableData = tgProjectStore.paginatedDatasets.items.map((dataset) => createRow(toJS(dataset)));

	return (
		<TableLayout
			headerArray={tableHeaderArray}
			tableData={tableData}
			emptyStateComponent={<TgProjectDatasetsEmpty />}
			loading={tgProjectStore.loadingProjectDatasets}
			// enableSearch
			// searchBoxPlaceholder={t("table.searchPlaceholder")}
			// handleSearchChange={handleSearchChange}
			// filterBy={filterBy}
			paginationOptions={paginationOptions}
			loadingRow={loadingRow}
			hideHeader
		/>
	);
};

export default observer(TgProjectDatasets);
