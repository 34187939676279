import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ANALYTICS_EVENTS } from "../../../../analytics-store.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { useImageToImageConfig } from "../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../hooks/useStores";
import { isFoxApps } from "../../../../utils";
import FoxConfigs from "./FoxConfigs/FoxConfigs.tsx";
import ImageEditingConfigs from "./ImageEditingConfigs/ImageEditingConfigs.tsx";
import styles from "./ImageToImageConfig.module.scss";
import { useManualPlacementConfig } from "../../../../hooks/useManualPlacementConfig.ts";

const ImageToImageConfig = () => {
	const { imageToImageStore, playgroundStore, analyticsStore } = useAppStore();
	const {
		canExpandBackground,
		canRemoveBackground,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		canBlurBackground,
		canApplyAspectRatio,
		canAppleCustomSize,
		canAppleMediumDestination,
		canGenerateForegroundPlacement,
	} = useImageToImageConfig();

	const { checkResolutions } = useManualPlacementConfig()

	useEffect(() => {
		if (playgroundStore.selectedConfig !== APPS.IMAGE_TO_IMAGE) {
			playgroundStore.handleAppChange(APPS.IMAGE_TO_IMAGE);
		}
	}, [imageToImageStore.isGeneratingImages]);

	const handleGenerateImageToImage = async () => {
		const { logEvent } = analyticsStore;

		await imageToImageStore.generateImageToImage();
		checkResolutions();
		if (!isFoxApps()) {
			logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_GENERATE);

			// Background configs events
			canExpandBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_EXPAND_IMAGE);
			canRemoveBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_REMOVE_BACKGROUND);
			canBlurBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_BLUR_BACKGROUND);
			canApplySolidBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_SOLID_BACKGROUND);
			canGenerateBackgroundByText() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_GENERATE_BACKGROUND_BY_TEXT);

			// Size configs events
			canApplyAspectRatio() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_ASPECT_RATIO);
			canAppleCustomSize() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_CUSTOM_SIZE);
			canAppleMediumDestination() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_MEDIUM_DESTINATION);

			// Foreground placement configs
			canGenerateForegroundPlacement() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_GENERATE_FOREGROUND);
		}
	};

	return (
		<Box className={styles.wrapper}>
			{isFoxApps() ? (
				<FoxConfigs handleGenerateImageToImage={handleGenerateImageToImage} />
			) : (
				<ImageEditingConfigs handleGenerateImageToImage={handleGenerateImageToImage} />
			)}
		</Box>
	);
};

export default observer(ImageToImageConfig);
