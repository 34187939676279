import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import RouterConstants from "../../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../../hooks/useStores";
import TgDatasetEditor from "../../../../Datasets/Editor/TgDatasetEditor";
import { TgDataset, defaultDataset } from "../../../../Datasets/store/tg-dataset-store";

export type EditTgProjectDatasetLocationState = {
	datasetToEdit?: TgDataset;
};

export const EditTgProjectDataset = () => {
	const { datasetId } = useParams();
	const navigate = useSecureNavigate();
	const location = useLocation();
	const { tgStore } = useAppStore();
	const { tgDatasetStore } = tgStore;

	useEffect(() => {
		tgDatasetStore.datasetForm = location.state?.datasetToEdit ?? defaultDataset;

		(async () => {
			try {
				if (!location.state?.datasetToEdit && datasetId) {
					const dataset = await tgDatasetStore.getDataset(Number(datasetId));
					tgDatasetStore.datasetForm = dataset;
				}
			} catch (err: any) {
				if (err.response?.status === 404) {
					navigate(RouterConstants.NEW_TAILORED_GENERATION.fullPath);
				}
			}
		})();
	}, []);

	return <TgDatasetEditor />;
};

export default observer(EditTgProjectDataset);
