import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useAppStore } from "../../../../../../../hooks/useStores";
import TgDatasetEditor from "../../../../../Datasets/Editor/TgDatasetEditor";
import { TgModel } from "../../../../../Models/store/tg-model-store";

export type CreateTgModelLocationState = {
	modelToDuplicate?: TgModel;
};

export const CreateTgModelDataset = () => {
	const { uiStore, tgStore } = useAppStore();
	const { tgDatasetStore, tgModelStore } = tgStore;

	useEffect(() => {
		uiStore.hideSideBar();

		return () => {
			if (!tgModelStore.isDatasetFromExisting && tgDatasetStore.datasetForm.status === "Draft") {
				tgDatasetStore.deleteDataset(tgDatasetStore.datasetForm.id);
			}
		};
	}, []);

	return <TgDatasetEditor />;
};

export default observer(CreateTgModelDataset);
