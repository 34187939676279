const ThumbDown = () => (
	<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.30078 12.6227V7.62395C9.30078 7.45823 9.23493 7.2993 9.11772 7.18212C9.00051 7.06493 8.84154 6.9991 8.67578 6.9991H7.42578C7.26002 6.9991 7.10105 7.06493 6.98384 7.18212C6.86663 7.2993 6.80078 7.45823 6.80078 7.62395L6.80078 11.9979C6.80078 12.1636 6.86663 12.3225 6.98384 12.4397C7.10105 12.5569 7.26002 12.6227 7.42578 12.6227H9.30078ZM9.30078 12.6227C9.96382 12.6227 10.5997 12.8861 11.0685 13.3548C11.5374 13.8235 11.8008 14.4592 11.8008 15.1221V15.747C11.8008 16.0784 11.9325 16.3963 12.1669 16.6306C12.4013 16.865 12.7193 16.9967 13.0508 16.9967C13.3823 16.9967 13.7002 16.865 13.9347 16.6306C14.1691 16.3963 14.3008 16.0784 14.3008 15.747V12.6227H16.1758C16.5073 12.6227 16.8252 12.4911 17.0597 12.2567C17.2941 12.0223 17.4258 11.7045 17.4258 11.373L16.8008 8.2488C16.7109 7.86547 16.5404 7.53632 16.3149 7.31092C16.0895 7.08553 15.8213 6.97609 15.5508 6.9991L11.1758 6.9991C10.6785 6.9991 10.2016 7.1966 9.84996 7.54814C9.49833 7.89969 9.30078 8.37649 9.30078 8.87365"
			stroke="var(--primary-text-color)"
			strokeOpacity="0.85"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ThumbDown;
