import i18next from "i18next";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useParams } from "react-router-dom";
import RequestAccessContainer from "../../../../components/TailoredGeneration/RequestAccessContainer/RequestAccessContainer";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores";
import TabsLayout from "../../../../layout/TabsLayout/TabsLayout";
import { OrgFeatures } from "../../../../models/billing.ts";
import { EventLogTypesEnum } from "../../../../models/common.ts";
import ModelAccessPopup from "../../Models/Popups/ModelAccessPopup/ModelAccessPopup";
import { ViewTgProjectLocationState } from "../../Projects/Viewer/TgProjectViewer.tsx";
import { TgModel, defaultModel } from "../store/tg-model-store";
import styles from "./TgModelViewer.module.scss";

export type ViewTgModelLocationState = {
	modelToView?: TgModel;
};

const TgModelViewer = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.viewer" });
	const navigate = useSecureNavigate();
	const location = useLocation();
	const { projectId, modelId } = useParams();
	const { uiStore, tgStore, authStore } = useAppStore();
	const { tgModelStore, tgProjectStore, tgPlaygroundStore } = tgStore;
	const subTabs = [
		{
			name: RouterConstants.TG_MODEL_PLAYGROUND.path,
			link: RouterConstants.TG_MODEL_PLAYGROUND.path,
		},
		{
			name: RouterConstants.TG_MODEL_DATASET.path,
			link: `${RouterConstants.TG_MODEL_DATASET.path}/${tgModelStore.modelToView?.dataset_id}`,
		},
	];

	if (tgModelStore.modelToView.status !== "Completed") {
		subTabs.splice(0, 1);
	}

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(
			`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_MODELS.path}`,
			undefined,
			t(`backButton`, { projectName: tgProjectStore.projectToView.project_name || "Project" }),
		);
		tgModelStore.modelToView = location.state?.modelToView ?? defaultModel;

		(async () => {
			try {
				if (!location.state?.modelToView && modelId) {
					const model = await tgModelStore.getModel(Number(modelId));
					tgModelStore.modelToView = model;
				}

				if (!tgProjectStore.projectToView.id && projectId) {
					const project = await tgProjectStore.getProject(Number(projectId));
					tgProjectStore.projectToView = project;
				}
			} catch (err: any) {
				if (err.response?.status === 404) {
					navigate(RouterConstants.NEW_TAILORED_GENERATION.fullPath);
				}
			}
		})();

		return () => {
			tgPlaygroundStore.resetPlayground();
			uiStore.showSideBar();
			uiStore.hideBackButton();
			window.history.replaceState({}, "");
		};
	}, []);

	useEffect(() => {
		if (tgProjectStore.projectToView.id) {
			const viewProjectState: ViewTgProjectLocationState = { projectToView: toJS(tgProjectStore.projectToView) };

			uiStore.showBackButton(
				`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_MODELS.path}`,
				{ state: viewProjectState },
				t(`backButton`, { projectName: tgProjectStore.projectToView.project_name }),
			);
		}
	}, [tgProjectStore.projectToView.id]);

	return (
		<TabsLayout
			className={styles.tabsLayout}
			title={
				<LoadingPlaceholder
					className={styles.loadingPlaceholder}
					isLoading={tgModelStore.loadingModel}
					size={32}
				>
					{tgModelStore.modelToView?.name}
				</LoadingPlaceholder>
			}
			primaryButtonLabel={
				<RequestAccessContainer
					className={styles.requestAccessContainer}
					requestFeature={"Tailored Models"}
					eventLog={{ eventType: EventLogTypesEnum.REQUEST_FOR_TAILORED_MODELS }}
					buttonMessage={i18next.t("requestAccessContainer.requestAccessCTA")}
					buttonLoading={authStore.isLoadingOrgSubscriptions}
					condition={authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION)}
					buttonType={"textMedium"}
					crownColor={"#5300c9"}
				>
					<ModelAccessPopup
						buttonType={"primaryMedium"}
						disabled={!(tgModelStore.modelToView.status === "Completed")}
						model={tgModelStore.modelToView}
						project={tgProjectStore.projectToView}
					/>
				</RequestAccessContainer>
			}
			subTabs={subTabs}
			isLoading={tgModelStore.loadingModel}
		>
			<Outlet />
		</TabsLayout>
	);
};

export default observer(TgModelViewer);
