function getImageUrl(name: string): string {
	return new URL(`../../../assets/${name}`, import.meta.url).href;
}

interface IImage {
	name: string;
	className?: string;
	onClick?: () => void;
}

const ImageViewer = ({ name, className, onClick, ...rest }: IImage) => {
	return <img className={className} src={getImageUrl(name)} alt={name} onClick={onClick} {...rest} />;
};

export default ImageViewer;
