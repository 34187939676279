import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import styles from "./BriaFormSection.module.scss";

type BriaFormSection = {
	header?: string;
	subHeader?: string;
	className?: string;
	children?: ReactNode;
};

const BriaFormSection = ({ header, subHeader, className, children }: BriaFormSection) => {
	return (
		<Box className={`${className} ${styles.inputWrapper}`}>
			<Box className={styles.headersWrapper}>
				<Typography className={styles.inputHeader}>{header}</Typography>
				<Typography className={styles.inputSubHeader}>{subHeader}</Typography>
			</Box>
			{children}
		</Box>
	);
};

export default BriaFormSection;
