import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Plus from "../../../../assets/images/svgs/plus.svg";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import BriaButtonGroup from "../../../../components/common/BriaButtonGroup/BriaButtonGroup.tsx";
import FileUploader from "../../../../components/common/FileUploader/FileUploader";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores";
import EditorLayout from "../../../../layout/EditorLayout/EditorLayout";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import BestPracticesPopup from "../../Models/Popups/BestPractices/BestPracticesPopup.tsx";
import { defaultModel } from "../../Models/store/tg-model-store.tsx";
import { TgDataset, defaultDataset } from "../store/tg-dataset-store";
import TgDatasetImageCard from "./ImageCard/TgDatasetImageCard";
import TgPrefixInput from "./Prefix/TgPrefixInput";
import styles from "./TgDatasetEditor.module.scss";

export type EditTgDatasetLocationState = {
	datasetToEdit?: TgDataset;
};

const TgDatasetEditor = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.editor" });
	const navigate = useSecureNavigate();
	const { uiStore, tgStore } = useAppStore();
	const { tgDatasetStore, tgModelStore } = tgStore;
	const { datasetId } = useParams();
	const isEditMode = !!datasetId;
	const [loadingUploadButton, setLoadingUploadButton] = useState(false);
	const [openBestPracticesPopup, setOpenBestPracticesPopup] = useState(false);

	useEffect(() => {
		uiStore.hideSideBar();

		return () => {
			tgDatasetStore.datasetForm = defaultDataset;
			tgModelStore.modelForm = defaultModel;
			tgDatasetStore.isFormDuplicateTriggered = false;
			window.history.replaceState({}, "");
		};
	}, []);

	const triggerOnChangeExisting = async (): Promise<boolean> => {
		if (tgModelStore.isDatasetFromExisting) {
			const duplicatedDataset = await tgDatasetStore.duplicateDataset(tgDatasetStore.datasetForm.id);
			tgDatasetStore.isFormDuplicateTriggered = true;
			tgDatasetStore.datasetForm = duplicatedDataset;
			tgModelStore.modelForm.dataset_id = duplicatedDataset.id;
			tgModelStore.isDatasetFromExisting = false;
			return true;
		} else {
			return false;
		}
	};

	const handleUploadImages = async (e: ChangeEvent<HTMLInputElement>) => {
		const files: File[] | null = Array.from(e.target.files || []);
		e.target.value = "";
		if (files.length) {
			setLoadingUploadButton(true);
			await triggerOnChangeExisting();
			setLoadingUploadButton(false);
			await tgDatasetStore.uploadImages(tgDatasetStore.datasetForm.id, files);
		}
	};

	const handleUpdateName = async (e: React.FocusEvent<HTMLInputElement>) => {
		const datasetName = e.target.value;
		await triggerOnChangeExisting();
		tgDatasetStore.handleFormChange("name", datasetName);
	};

	const secondaryButtonClickHandler = () => {
		setOpenBestPracticesPopup(true);
	};

	const onCloseBestPracticesPopup = () => {
		setOpenBestPracticesPopup(false);
	};

	// const handleAutoCaptions = () => {};

	// const handleDownload = () => {};

	// const handleUpload = () => {};

	const saveDataset = async () => {
		await tgDatasetStore.updateDataset(tgDatasetStore.datasetForm.id, tgDatasetStore.datasetForm);
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${tgDatasetStore.datasetForm.project_id}/${RouterConstants.NEW_TG_DATASETS.path}`,
		);
	};

	return (
		<EditorLayout
			className={styles.wrapper}
			headerClassName={styles.header}
			name={tgDatasetStore.datasetForm.name}
			placeholder={t("title")}
			onNameChange={(e) => tgDatasetStore.handleFormChange("name", e.target.value)}
			onNameBlur={handleUpdateName}
			primaryButton={
				<BriaButtonGroup>
					<FileUploader
						buttonProps={{ startIcon: <img src={Plus} /> }}
						buttonType="textMedium"
						buttonText={t("uploadBtn")}
						onUpload={handleUploadImages}
						maxFilesLimit={200 - tgDatasetStore.datasetForm.images.length}
						inputProps={{ accept: "image/png, image/jpg, image/jpeg, image/webp", multiple: true }}
						loading={loadingUploadButton}
						disabled={tgDatasetStore.loadingDuplicateDataset}
					/>
					{isEditMode && (
						<BriaButton
							buttonType="primaryMedium"
							onClick={saveDataset}
							loading={tgDatasetStore.loadingUpdateDataset}
						>
							{t("primaryBtn")}
						</BriaButton>
					)}
				</BriaButtonGroup>
			}
			secondaryButton={t("secondaryBtn")}
			secondaryButtonClick={secondaryButtonClickHandler}
			loading={
				!tgDatasetStore.datasetForm.id || (tgDatasetStore.loadingDataset && !tgDatasetStore.loadingRegenerate)
			}
			childrenClassName={styles.editorContent}
		>
			<InputLayout className={styles.captionPrefix} label={t("form.prefix.label")} info={t("form.prefix.info")}>
				<TgPrefixInput {...{ triggerOnChangeExisting }} />
			</InputLayout>
			<Box className={styles.imagesHeaderWrapper}>
				<Typography className={styles.imagesHeader}>{t("form.images.label")}</Typography>
				{/* <BriaButtonGroup>
					<Tooltip title={t("form.images.icons.autoCaptions")} placement="top">
						<img className={styles.icon} onClick={handleAutoCaptions} src={autoCaptionsIcon} />
					</Tooltip>
					<Tooltip title={t("form.images.icons.download")} placement="top">
						<img className={styles.icon} onClick={handleDownload} src={downloadIcon} />
					</Tooltip>
					<Tooltip title={t("form.images.icons.upload")} placement="top">
						<img className={styles.icon} onClick={handleUpload} src={uploadIcon} />
					</Tooltip>
				</BriaButtonGroup> */}
			</Box>
			<Box className={styles.images}>
				{tgDatasetStore.datasetForm.images.map((image, index) => (
					<TgDatasetImageCard key={index} {...{ image, triggerOnChangeExisting }} />
				))}
			</Box>
			<BestPracticesPopup open={openBestPracticesPopup} onClose={onCloseBestPracticesPopup} />
		</EditorLayout>
	);
};

export default observer(TgDatasetEditor);
