import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CompareIcon from "../../../assets/images/icons/CompareIcon";
import DownloadIcon from "../../../assets/images/icons/DownloadIcon";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import BriaButton from "../BriaButton/BriaButton";
import BriaTooltip from "../BriaTooltip/BriaTooltip";
import styles from "./FullScreenViewer.module.scss";
import FullScreenImage, { FullScreenImageType } from "./Image/FullScreenImage";

export type FullScreenViewerProps = {
	open: boolean;
	images: FullScreenImageType[];
	currentIndex?: number;
	displayMode?: "arrows" | "scroll";
	onClose: () => void;
	onDownload?: (images: FullScreenImageType[]) => void;
	onCompare?: (images: FullScreenImageType[]) => void;
	hideDownloadIcon?: boolean;
};

const FullScreenViewer = ({
	open,
	images,
	currentIndex = 0,
	displayMode = "arrows",
	onClose,
	onDownload,
	onCompare,
	hideDownloadIcon,
}: FullScreenViewerProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "briaImage.fullScreenView" });
	const [isOpen, setIsOpen] = useState(open);
	const [viewerLoaded, setViewerLoaded] = useState(false);
	const [currentImage, setCurrentImage] = useState(currentIndex);
	const [displayedImages, setDisplayedImages] = useState(images);
	const isCompareMode = !displayedImages.some((image) => !image.hide && !image.openCompare);
	const [zoomedImageIndex, setZoomImageIndex] = useState<number>(-1);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		setCurrentImage(currentIndex);
	}, [currentIndex]);

	useEffect(() => {
		setViewerLoaded(true);
	}, []);

	const handleDownload = () => {
		if (onDownload) {
			onDownload(
				displayedImages.length > 1 && displayMode === "scroll"
					? displayedImages
					: [displayedImages[currentImage]],
			);
		}
	};

	const openAllCompare = () => {
		setDisplayedImages(displayedImages.map((image) => ({ ...image, openCompare: !isCompareMode })));
		onCompare && !isCompareMode && onCompare(displayedImages);
	};

	const onPrevImage = useCallback(
		(e?: any) => {
			e?.stopPropagation();
			const prevIndex = (currentImage - 1 + displayedImages.length) % displayedImages.length;
			setCurrentImage(prevIndex);
		},
		[currentImage, displayedImages.length],
	);

	const onNextImage = useCallback(
		(e?: any) => {
			e?.stopPropagation();
			const nextIndex = (currentImage + 1) % displayedImages.length;
			setCurrentImage(nextIndex);
		},
		[currentImage, displayedImages.length],
	);

	const handleKeyPress = useCallback(
		(e?: any) => {
			if (e.key === "Escape") {
				onClose();
			} else if (displayMode === "arrows") {
				if (e.key === "ArrowLeft") {
					onPrevImage();
				} else if (e.key === "ArrowRight") {
					onNextImage();
				}
			}
		},
		[onClose, onPrevImage, onNextImage],
	);

	useEffect(() => {
		document.addEventListener("keyup", handleKeyPress);
		return () => {
			document.removeEventListener("keyup", handleKeyPress);
		};
	}, [handleKeyPress]);

	const handleClose = () => {
		setIsOpen(false);
		onClose();
	};

	return (
		<>
			{isOpen && (
				<Box className={clsx(styles.overlay, viewerLoaded && styles.fadeIn)} onClick={handleClose}>
					<Box className={styles.fullScreenViewerActions} onClick={(e) => e.stopPropagation()}>
						{displayedImages[currentImage].compareTo && (
							<BriaTooltip title={t("compare")}>
								<IconButton
									className={clsx(styles.actionButton, {
										[styles.active]: isCompareMode,
									})}
									onClick={openAllCompare}
								>
									<CompareIcon />
								</IconButton>
							</BriaTooltip>
						)}
						{!hideDownloadIcon &&
							(!iframeStore.isIframe() || iframeStore.iframe.config.general_config?.enable_download) && (
								<BriaTooltip title={t("download")}>
									<IconButton className={styles.actionButton} onClick={handleDownload}>
										<DownloadIcon className={styles.downloadIcon} />
									</IconButton>
								</BriaTooltip>
							)}
						<BriaButton
							className={clsx(styles.actionButton, styles.closeButton)}
							startIcon={<Close />}
							buttonType="tertiaryMedium"
							onClick={handleClose}
						>
							{t("close")}
						</BriaButton>
					</Box>
					{displayedImages.length > 1 && displayMode === "arrows" ? (
						<>
							<ChevronLeft
								className={`${styles.arrowIcon} ${styles.leftArrowIcon}`}
								onClick={onPrevImage}
							/>
							<ChevronRight
								className={`${styles.arrowIcon} ${styles.rightArrowIcon}`}
								onClick={onNextImage}
							/>
							<Box className={clsx({
								[styles.scrollable]: isCompareMode && displayedImages[currentImage].compareTo
							})}>
								<FullScreenImage
									image={displayedImages[currentImage]}
									index={currentImage}
									{...{ displayMode, displayedImages, setDisplayedImages }}
									onDownload={() => {
										onDownload && onDownload([displayedImages[currentImage]]);
									}}
									onCompare={() => {
										onCompare && onCompare([displayedImages[currentImage]]);
									}}
									hideDownloadIcon={hideDownloadIcon}
								/>
							</Box>
						</>
					) : (
						<Box className={styles.scrollable}>
							{displayedImages.map((image, index) => (
								<FullScreenImage
									key={index}
									{...{ image, index, displayMode, displayedImages, setDisplayedImages }}
									onZoom={(zoomed) => setZoomImageIndex(zoomed ? index : -1)}
									hide={zoomedImageIndex !== -1 && zoomedImageIndex !== index}
									onDownload={() => {
										onDownload && onDownload([image]);
									}}
									onCompare={() => {
										onCompare && onCompare([image]);
									}}
									hideDownloadIcon={hideDownloadIcon}
								/>
							))}
						</Box>
					)}
				</Box>
			)}
		</>
	);
};

export default FullScreenViewer;
