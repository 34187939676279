import { Box, Divider, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SolidGradientColorPicker from "../../../../../../../components/common/SolidGradientColorPicker/SolidGradientColorPicker";
import { useAppStore } from "../../../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import StepperNavigation from "../../../../../../StepperNavigation/StepperNavigation";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import { PopUpSteps } from "../../../../../../constants/mock-data";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor";
import { useEditor } from "../../../../../../hooks/useEditor";
import { BrandDefinition } from "../../../../../../models/brandDefinition";
import { LayerType } from "../../../../../../types";
import styles from "./CanvasColor.module.scss";
export type Iprops = {};

const CanvasColor = ({}: Iprops) => {
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation");
	const { t: tEditorTabs } = useTranslation("translation", { keyPrefix: "editor.tabs" });
	const editor = useEditor();
	const [brands, setBrands] = useState<BrandDefinition[]>([]);
	const { campaignStore, brandsDefinitionStore, designEditorStore } = useAppStore();
	const { EditAllAds, getRgbAndOpacityFromRgba, updateColorOpacity } = useDesignEditorUtils();
	const [color, setColor] = useState(
		editor && editor?.frame?.background?.fill && typeof editor.frame.background.fill == "string"
			? updateColorOpacity(editor.frame.background.fill, editor.frame.background.opacity)
			: "#fff",
	);
	const CANVAS_COLOR_INDEX = PopUpSteps.indexOf("CanvasColor");

	useEffect(() => {
		loadBrands();
	}, []);

	const loadBrands = () => {
		brandsDefinitionStore.loadBrandsDefinition().then(() => {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === "Active"));
		});
	};

	useEffect(() => {
		let watcher = async () => {
			setColor(
				editor && editor?.frame?.background?.fill && typeof editor.frame.background.fill == "string"
					? updateColorOpacity(editor.frame.background.fill, editor.frame.background.opacity)
					: "#fff",
			);
		};
		if (editor) {
			editor.on("canvas:updated", watcher);
		}
		return () => {
			if (editor) {
				editor.off("canvas:updated", watcher);
			}
		};
	}, [editor]);

	const updateBackgroundColor = (color: string, brandColor: boolean = false) => {
		const result = getRgbAndOpacityFromRgba(color);
		setColor(color);
		campaignStore.canvasColorPicker = color;
		if (editor) {
			editor.frame.setBackgroundColor(result?.rgb ?? color);
			if (!brandColor) {
				editor.frame.background.set({ opacity: result?.opacity ?? 1 });
				editor.objects.update({ opacity: result?.opacity ?? 1, brandId: undefined }, undefined, false);
			}
			let newColor = null;
			if (brandColor) {
				newColor = updateColorOpacity(color, editor.frame.background.opacity);
			}
			if (!campaignStore.isEditingAllAds) editor.history.save();
			EditAllAds(LayerType.BACKGROUND, "", FeatureType.COLOR, newColor ?? color);
		}
	};

	return (
		<>
			<Box className={styles.container}>
				<MainTabHeader title={t(`editor.panels.panelsList.canvasColor`)} />
				<Box className={`${styles.tabs} ${styles.fullHeight}`}>
					<Box className={styles.ColorFillContent}>
						<Box className={styles.colorPickerContainer}>
							<SolidGradientColorPicker
								color={color}
								onChange={(color: string) => {
									updateBackgroundColor(color);
								}}
								hideColorTypeBtns={true}
							/>
						</Box>
						<Divider className={styles.mainDivider} />
						<Typography className={styles.mainLabel}>{tEditorTabs("brandColors")}</Typography>
						<Box className={styles.BrandsContainer}>
							{brands.map((brand: BrandDefinition, idx: number) => {
								const colors = brand?.color_pallete?.colors;
								return (
									<>
										<Box className={styles.brandColorsRow}>
											<Typography className={styles.brandNameLabel}>
												{brand.name.length < 10 ? brand.name : brand.name.slice(0, 10) + "..."}
											</Typography>

											{colors?.map((color: string, index: number) => (
												<Box
													onClick={() => {
														updateBackgroundColor(color, true);
													}}
													key={index}
												>
													<Box className={styles.itemContent}>
														<Box
															sx={{ background: color }}
															className={styles.itemPreview}
														></Box>
													</Box>
												</Box>
											))}
										</Box>
										{idx != brands.length - 1 && <Divider className={styles.divider} />}
									</>
								);
							})}
						</Box>
					</Box>
				</Box>
				{isPopupView && designEditorStore.activeStep === CANVAS_COLOR_INDEX && <StepperNavigation />}
			</Box>
		</>
	);
};

export default CanvasColor;
