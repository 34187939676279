import { Box, Link, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DashboardSectionComponent } from "../../components/Dashboard/DashboardSectionComponent";
import SubscriptionWidget from "../../components/Home/SubscriptionWidget/SubscriptionWidget.tsx";
import TailoredGenerationWidget from "../../components/Home/TailoredGenerationWidget/TailoredGenerationWidget.tsx";
import {
	aIImageCreationCapabilitiesCards,
	aiImageCustomizationCapabilitiesCards,
	cardDataImplementedOptions,
	yourFavoritePlatforms,
} from "../../constants/dashboardConstants";
import { useAppStore } from "../../hooks/useStores.tsx";
import styles from "./Home.module.scss";

const Home = () => {
	const { t } = useTranslation("translation", { keyPrefix: "briaProductsAndServices" });
	const { uiStore } = useAppStore();

	useEffect(() => {
		uiStore.showSideBar();
	}, []);

	return (
		<Box className={styles.container}>
			<SubscriptionWidget />
			<Box className={styles.innerContainer}>
				<Box className={styles.firstGradientSeparotor} />
				<DashboardSectionComponent
					title={t("implementationOptions.title")}
					description={t("implementationOptions.cardsHeader")}
					cards={cardDataImplementedOptions}
					className={clsx(styles.cards, styles.consumeOptions)}
				>
					<Typography className={styles.bottomTitle}>{t("implementationOptions.bottomTitle")}</Typography>
					<Typography className={styles.bottomDescription}>
						{t("implementationOptions.bottomDescription")}
					</Typography>
					<Box className={styles.platforms}>
						{yourFavoritePlatforms.map((platform) => (
							<Link className={styles.platform} href={platform.link} target={"_blank"} rel="noopener">
								<img className={styles.icon} src={platform.icon} />
								<Box className={styles.title}>
									{t(`implementationOptions.platforms.${platform.title}`)}
								</Box>
							</Link>
						))}
					</Box>
				</DashboardSectionComponent>
				<Box className={styles.gradientSeparotor} />
				<TailoredGenerationWidget />
			</Box>
			<Box className={styles.primaryGradientSeparator} />
			<Box className={styles.innerContainer}>
				<DashboardSectionComponent
					mainTitle={t("aIImageCreationCapabilities.mainTitle")}
					title={t("aIImageCreationCapabilities.title")}
					description={t("aIImageCreationCapabilities.description")}
					cards={aIImageCreationCapabilitiesCards}
					className={styles.cards}
				/>
				<Box className={styles.separotor}> </Box>

				<DashboardSectionComponent
					title={t("aIImageCustomizationCapabilities.title")}
					description={t("aIImageCustomizationCapabilities.description")}
					cards={aiImageCustomizationCapabilitiesCards}
				/>
			</Box>
		</Box>
	);
};

export default observer(Home);
