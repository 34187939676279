const NotesIcon = () => (
	<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.82943 5.83333H12.8294M7.82943 9.16667H12.8294M7.82943 12.5H11.1628M6.16276 2.5H14.4961C15.4166 2.5 16.1628 3.24619 16.1628 4.16667V15.8333C16.1628 16.7538 15.4166 17.5 14.4961 17.5H6.16276C5.24229 17.5 4.49609 16.7538 4.49609 15.8333V4.16667C4.49609 3.24619 5.24229 2.5 6.16276 2.5Z"
			stroke="var(--secondary-color)"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default NotesIcon;
