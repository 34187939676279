import { Box, Step, Typography, styled } from "@mui/material";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import clsx from "clsx";
import { ReactNode } from "react";
import stepperConnectorIcon from "../../../assets/images/svgs/stepper-connector.svg";
import styles from "./HorizontalLinearStepper.module.scss";

interface IHorizontalLinearStepper {
	steps: string[];
	activeStep?: number;
	setActiveStep?: (value: number) => void;
	size?: string;
	readonly?: boolean;
	disabledStep?: number[] | undefined;
	disableAll?: boolean | undefined;
	icon?: ReactNode;
}

const StyledStepper = styled(Stepper)(({ theme }) => ({
	"& .Mui-active .MuiStepIcon-root": {
		color: theme.palette.primary.light,
		border: `2px solid ${theme.palette.primary.light}`,
		borderRadius: "50%",
		background: theme.palette.primary.light,
	},
	"& .Mui-active .MuiTypography-root": {
		fontWeight: theme.typography.fontWeightMedium,
	},
	"& .MuiStep-root": {
		paddingTop: 4,
		paddingBottom: 4,
	},
	"& .MuiStep-root:first-child .stepConnectorIcon": {
		display: "none",
	},
	"& .Mui-completed:hover .MuiTypography-root": {
		fontWeight: theme.typography.fontWeightMedium,
	},
	"& .Mui-completed:hover": {
		background: "#F3F3F3",
		borderRadius: 8,
	},
	"& .Mui-disabled .MuiStepIcon-root": {
		color: "white",
		border: `2px solid ${theme.palette.primary.light}`,
		borderRadius: "50%",
	},
	"& .Mui-disabled .MuiStepLabel-labelContainer": {
		color: "#5B5B5B",
	},
	"& .Mui-disabled .MuiStepIcon-root text": { fill: "black", fontWeight: "600" },
	"& .Mui-active .MuiStepIcon-root text": { fontWeight: "600" },
	"& .Mui-completed.MuiStepLabel-iconContainer": {
		boxShadow: `inset 0px 0px 0px 3px ${theme.palette.primary.light}`,
		borderRadius: "50%",
	},
	"& .Mui-completed .MuiStepIcon-root": {
		color: theme.palette.primary.light,
		border: `2px solid ${theme.palette.primary.light}`,
		borderRadius: "50%",
	},
	"& .Mui-completed:hover .MuiStepIcon-root": {
		background: "black",
		fill: "#F3F3F3",
		boxShadow: `inset 0px 0px 0px 3px #F3F3F3`,
	},
	"& .MuiStepLabel-iconContainer": {
		paddingRight: 0,
		marginRight: 8,
	},
}));

const HorizontalLinearStepper = ({
	steps,
	activeStep,
	setActiveStep = () => {},
	size,
	readonly,
	disabledStep = [],
	disableAll,
	icon,
}: IHorizontalLinearStepper) => {
	const handleStepClick = (index: number) => {
		setActiveStep(index);
	};
	return (
		<StyledStepper
			className={clsx(styles.container, { [styles.readonly]: readonly, [styles.big]: size === "large" })}
			activeStep={activeStep}
		>
			{steps.map((label, index) => {
				const isDisabled = disableAll || (Array.isArray(disabledStep) && disabledStep.includes(index));
				return (
					<Step key={label} className={clsx({ [styles.disabled]: isDisabled })}>
						{icon ? (
							<Box className={styles.arrowStepConnector}>
								<img className="stepConnectorIcon" src={stepperConnectorIcon} />
								<StepButton disableRipple onClick={() => handleStepClick(index)}>
									<Typography className={styles.stepButton}>{label}</Typography>
								</StepButton>
							</Box>
						) : (
							<StepButton disableRipple onClick={() => handleStepClick(index)}>
								<Typography className={styles.stepButton}>{label}</Typography>
							</StepButton>
						)}
					</Step>
				);
			})}
		</StyledStepper>
	);
};

export default HorizontalLinearStepper;
