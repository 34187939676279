import { Box, TextField } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useState } from "react";
import CloseIcon from "../../../../../../../../assets/images/icons/CloseIcon";
import SolidGradientColorPicker from "../../../../../../../../components/common/SolidGradientColorPicker/SolidGradientColorPicker";
import { useCustomColorPicker } from "../../../../../../../../hooks/useCustomColotPicker";
import styles from "./ColorBoxPicker.module.scss";
interface Props {
	color?: string;
	onColorChange?: (color: string) => void;
	disabled?: boolean;
	withPadding?: boolean;
}

function TextColorPicker({ color, onColorChange, disabled, withPadding = true }: Props) {
	const DEFAULT_COLOR = "#FFFFFF";
	const PLACEHOLDER_COLOR = "#8800FF";
	const { colorToHex } = useCustomColorPicker();
	const [showPicker, setShowPicker] = useState(false);
	const handleIconClick = () => {
		setShowPicker(!showPicker);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value: color },
		} = e;
		onColorChange?.(color);
	};

	return (
		<Box className={styles.container}>
			<Box
				style={{
					backgroundColor: color,
					background: color,
				}}
				onClick={handleIconClick}
				className={styles.colorBox}
			>
				{showPicker && (
					<Box className={styles.colorPickerContainer}>
						<Box
							className={clsx({
								[styles.colorPicker]: true,
								[styles.staticPosition]: true,
							})}
						>
							<CloseIcon onClick={() => {}} className={styles.closeIcon} />
							<SolidGradientColorPicker
								color={color ?? DEFAULT_COLOR}
								onChange={(color: string) => {
									const hexColor = colorToHex(color);
									if (hexColor) {
										onColorChange?.(hexColor);
									}
								}}
								hideColorTypeBtns={true}
							/>
						</Box>
					</Box>
				)}
			</Box>
			<TextField
				placeholder={PLACEHOLDER_COLOR}
				fullWidth
				InputProps={{
					classes: {
						root: styles.textField,
					},
				}}
				className={clsx({
					[styles.colorTextfield]: withPadding,
				})}
				value={color}
				onChange={handleChange}
				disabled={disabled}
			/>
		</Box>
	);
}

const ObservedComponent = observer(TextColorPicker);
export default ObservedComponent;
