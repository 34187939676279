import { BrandDefinition } from "../models/brandDefinition.ts";
import { Template } from "../models/template.ts";

export enum SmartImageBackgroundOperation {
	Expand = "expand",
	Generate = "generate",
}
export interface LayerText {
	id: string;
	type: string;
	content: string;
	text_configs?: {
		strikethrough?: boolean;
		shadow?: {
			blur?: number;
			color?: string;
			offset?: {
				x: number,
				y: number
			}
		};
		highlight_color?: string;
		fit_text_box?: {
			width: number;
			height: number;
			max_font_size: number;
		}
	}
}
export interface LayerImage {
	id: string;
	type: string;
	content: string;
}

export interface CampaignCustomOptions {
	backgroundColor?: string;
	texts?: LayerText[];
	images?: LayerImage[];
	skip?: boolean;
}

export interface CampaignApiMainObject {
	url: string;
	skip: string;
	operation: string;
	backgroundPrompt?: string;
}
export interface CampaignApiParameters {
	template: Template;
	brand: BrandDefinition;
	backgroundColor: string;
	mainObject: CampaignApiMainObject;
	texts: LayerText[];
	images: LayerImage[];
}

declare global {
	interface Window {
		messages: {
			type?: string;
			status?: string;
			campaign: {
				message?: string;
				template?: any;
				brand?: BrandDefinition;
				backgroundColor?: string;
				mainObject?: { url: string; skip: string; operation: string; backgroundPrompt: string };
				texts?: [];
				images?: [];
			};
		}[];
	}
}

export enum CampaignResponseMessages {
	SomethingWentWrong = "Error in handling the campaign API request, Please try again later...",
}
