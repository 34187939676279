import { makeAutoObservable } from "mobx";
import { IRootStore } from "../../../mobx/root-store";
import { ITgDatasetStore, TgDatasetStore } from "../Datasets/store/tg-dataset-store";
import { ITgModelStore, TgModelStore } from "../Models/store/tg-model-store";
import { ITgPlaygroundStore, TgPlaygroundStore } from "../Models/store/tg-playground-store";
import { ITgProjectStore, TgProjectStore } from "../Projects/store/tg-projects-store";

export interface ITgStore {
	tgProjectStore: ITgProjectStore;
	tgModelStore: ITgModelStore;
	tgPlaygroundStore: ITgPlaygroundStore;
	tgDatasetStore: ITgDatasetStore;
	rootStore: IRootStore;
}

export class TgStore implements ITgStore {
	tgProjectStore: ITgProjectStore;
	tgModelStore: ITgModelStore;
	tgDatasetStore: ITgDatasetStore;
	tgPlaygroundStore: ITgPlaygroundStore;
	rootStore: IRootStore;

	constructor(rootStore: IRootStore) {
		this.tgProjectStore = new TgProjectStore(this);
		this.tgModelStore = new TgModelStore(this);
		this.tgPlaygroundStore = new TgPlaygroundStore(this);
		this.tgDatasetStore = new TgDatasetStore(this);
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}
}
