import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../../../../assets/images/pngs/campaign_empty_state.png";
import styles from "./CampaignEmptyState.module.scss";

const CampaignEmptyState = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor" });

	return (
		<Box className={styles.templatesEmptyState}>
			<Box className={styles.content}>
				<img className={styles.image} src={EmptyState} />
				<Typography className={styles.title}>{t("popup.emptyState.title.part1")}</Typography>
				<Typography className={styles.boldSubTitle}>{t("popup.emptyState.title.part2")}</Typography>
				<Typography className={styles.subTitle}>{t("popup.emptyState.title.part3")}</Typography>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(CampaignEmptyState);
export default ObservedComponent;
