import { ArrowBack, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { toJS } from "mobx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateOptions } from "react-router-dom";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import BriaButton from "../BriaButton/BriaButton";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import styles from "./BackButton.module.scss";

export type BackButtonProps = {
	to?: string;
	navigateOptions?: NavigateOptions;
	text?: string;
	showWarning?: boolean;
	loadingWarning?: boolean;
	onWarningConfirm?: () => Promise<void>;
	currentAppName?: string;
};

const BackButton = ({
	to,
	navigateOptions,
	text,
	showWarning = false,
	loadingWarning = false,
	onWarningConfirm = () => Promise.resolve(),
	currentAppName,
}: BackButtonProps) => {
	const navigate = useSecureNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "backBtn" });
	const [openWarningPopup, setOpenWarningPopup] = useState(false);

	const handleNavigation = () => {
		if (to === "back") {
			window.history.back();
		}
		closeWarningPopup();
		if (to !== undefined) {
			navigate(to, toJS(navigateOptions));
		} else {
			navigate(-1);
		}
	};
	const handleClick = () => {
		if (showWarning) {
			setOpenWarningPopup(true);
		} else {
			handleNavigation();
		}
	};

	const closeWarningPopup = () => {
		setOpenWarningPopup(false);
	};

	const handleConfirmWarning = async () => {
		closeWarningPopup();
		await onWarningConfirm();
		handleNavigation();
	};

	return (
		<>
			<Box className={styles.backBtnWrapper}>
				{currentAppName ? (
					<>
						<Link className={styles.appsBack} onClick={handleClick}>
							{text ?? t("text")}
							<KeyboardArrowRight />
						</Link>
						<Typography className={styles.appName}>{currentAppName}</Typography>
					</>
				) : (
					<BriaButton className={styles.backBtn} buttonType="textMedium" onClick={handleClick}>
						<ArrowBack />
						{text ?? t("text")}
					</BriaButton>
				)}
			</Box>
			<ConfirmationPopup
				onClose={closeWarningPopup}
				title={t("warningPopup.title")}
				description={t("warningPopup.description")}
				confirmButtonText={t("warningPopup.saveChanges")}
				open={openWarningPopup}
				onClick={handleConfirmWarning}
				firstButtonText={t("warningPopup.undoChanges")}
				onSecondaryClick={handleNavigation}
				loading={loadingWarning}
			/>
		</>
	);
};

export default BackButton;
