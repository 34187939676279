import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { APICardComponent } from "../../../components/API/APICardComponent.tsx";
import { APICards, APICardType } from "../../../constants/APICardsConstants.ts";

interface IProps {
	type?: APICardType;
}

const BriaAPIs = ({ type = APICardType.BaseSuiteAPICards }: IProps) => {
	return (
		<Box>
			<Grid paddingBottom={2} container spacing={4}>
				{APICards.filter((card) => card.type === type).map((card: any, index: number) => (
					<APICardComponent key={index} {...card} />
				))}
			</Grid>
		</Box>
	);
};

export default observer(BriaAPIs);
