import { Box, Slider, styled, Typography } from "@mui/material";
import classNames from "classnames";
import { FC, useCallback, useMemo } from "react";
import styles from "./BriaSlider.module.scss";

interface IProps {
	name?: string;
	value?: number;
	defaultValue?: number;
	onChange?: (event: Event, value: number | number[]) => void;
	min?: number;
	max?: number;
	step?: number;
	valueLabelDisplay?: "on" | "auto" | "off";
	fieldLabels?: string[];
	marks?: { value: number; label?: string }[];
	showMarksPoints?: boolean;
	labelsAlwaysGray?: boolean;
	className?: string;
}

const StyledSlider = styled(Slider)<{ showMarksPoints: boolean; labelsAlwaysGray: boolean }>(
	({ showMarksPoints, labelsAlwaysGray }) => ({
		"& .MuiSlider-markLabel": {
			fontWeight: "600",
			color: labelsAlwaysGray && "rgba(var(--black-color-rgb),0.5)",
		},
		'& .MuiSlider-markLabel[data-index="0"]': {
			transform: "translateX(0%)",
		},
		'& .MuiSlider-markLabel[data-index="1"]': {
			transform: "translateX(-100%)",
		},
		"& .MuiSlider-mark": {
			backgroundColor: showMarksPoints ? "white" : "transparent",
			display: "flex",
		},
	}),
);

const BriaSlider: FC<IProps> = ({
	name,
	value,
	onChange,
	min = 0,
	max = 100,
	step = 10,
	defaultValue = 60,
	valueLabelDisplay = "off",
	fieldLabels = [],
	marks,
	showMarksPoints = false,
	labelsAlwaysGray = false,
	className,
}) => {
	const points = useMemo(
		() =>
			Array.from({ length: (max - min) / step + 1 }, (_, index) => ({
				value: min + index * step,
			})),
		[min, max, step],
	);

	const handleChange = useCallback(
		(e: Event, newValue: number | number[]) => {
			if (onChange) onChange(e, newValue);
		},
		[onChange],
	);

	return (
		<Box className={classNames(styles.container, className)}>
			<StyledSlider
				name={name}
				value={value ?? defaultValue}
				onChange={handleChange}
				min={min}
				max={max}
				step={step}
				valueLabelDisplay={valueLabelDisplay}
				marks={marks || points}
				showMarksPoints={showMarksPoints}
				labelsAlwaysGray={labelsAlwaysGray}
				className={className}
			/>
			{fieldLabels && fieldLabels.length === 2 && (
				<Box className={styles.sliderLabels}>
					<Typography>{fieldLabels[0]}</Typography>
					<Typography>{fieldLabels[1]}</Typography>
				</Box>
			)}
		</Box>
	);
};

export default BriaSlider;
