import { Box, Slider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { useManualPlacementConfig } from "../../../../hooks/useManualPlacementConfig";
import { useAppStore } from "../../../../hooks/useStores";
import { croppedAreaPixelsType } from "../../../../models/image-to-image";
import styles from "./ImageCropper.module.scss";

const ImageCropper = () => {
	const { imageToImageStore, playgroundStore } = useAppStore();
	const { handleConfigChange, calculateImageDimensions } = useManualPlacementConfig();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
	const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false);
	const divRef = useRef<any>(null);
	const selectedImage =
		playgroundStore.singleSelectedImage || playgroundStore.getAvailableImages().find((image) => image.selected);
	const [imageSize, setImageSize] = useState<{ width: number | undefined; height: number | undefined }>({
		width: 0,
		height: 0,
	});

	const manualPlacement = imageToImageStore.config.manual_placement;

	useEffect(() => {
		resetConfigs();
	}, [selectedImage?.url]);

	useEffect(() => {
		resetConfigs();
		return () => {
			resetConfigs();
		};
	}, []);

	useEffect(() => {
		if (manualPlacement?.width && manualPlacement?.height) {
			const { width: imageWidth, height: imageHeight } = manualPlacement;
			setImageSize({
				width: manualPlacement.original_image_size?.[0] || imageWidth,
				height: manualPlacement.original_image_size?.[1] || imageHeight,
			});
			if (divRef.current) {
				const { clientWidth: containerWidth, clientHeight: containerHeight } = divRef.current;
				if (imageWidth > containerWidth || imageHeight > containerHeight) {
					const cropAreaRatio = Math.min(containerWidth / imageWidth, containerHeight / imageHeight);
					setCropSize({
						width: imageWidth * cropAreaRatio,
						height: imageHeight * cropAreaRatio,
					});
				} else {
					setCropSize({ width: imageWidth, height: imageHeight });
				}
			}
		}
	}, [manualPlacement?.width, manualPlacement?.height, playgroundStore.playgroundResults]);

	useEffect(() => {
		if (imageSize.width && imageSize.height && cropSize.width && cropSize.height) {
			const imageAspectRatio = imageSize.width / imageSize.height;
			const { clientWidth: containerWidth, clientHeight: containerHeight } = divRef.current;
			const cropAspectRatio = cropSize.width / cropSize.height;
			const cropAreaRatio = Math.min(containerWidth / imageSize.width, containerHeight / imageSize.height);

			let newZoom;
			if (imageAspectRatio > cropAspectRatio) {
				// Image is wider, fit height to crop
				newZoom = cropSize.width / imageSize.width;
			} else {
				// Image is taller, fit width to crop
				newZoom = cropSize.height / imageSize.height;
			}

			// Apply dynamic scaling factor
			setZoom(newZoom / (cropAreaRatio <= 1 ? cropAreaRatio : 1));
		}
	}, [cropSize]);

	const resetConfigs = useCallback(() => {
		setZoom(1);
		setCrop({ x: 0, y: 0 });
		setCropSize({ width: 0, height: 0 });
	}, []);

	const onCropComplete = useCallback(
		(croppedArea: any, croppedAreaPixels: croppedAreaPixelsType) => {
			const { x, y, width, height } = croppedAreaPixels;
			const adjustedX = x * -1;
			const adjustedY = y * -1;

			handleConfigChange("original_image_location", [adjustedX, adjustedY]);
			handleConfigChange("canvas_size", [width, height]);
			handleConfigChange("cropped_area_pixels", croppedAreaPixels);
			handleConfigChange("cropped_area", croppedArea);
		},
		[handleConfigChange],
	);

	const onMediaLoaded = () => {
		setIsMediaLoaded(true);
		calculateImageDimensions();
	};

	return (
		<Box className={styles.imageCropperWrapper}>
			<Box
				className={styles.cropperContainer}
				ref={divRef}
				style={
					{
						"--crop-area-width": `${cropSize.width}px`,
						"--crop-area-height": `${cropSize.height}px`,
					} as React.CSSProperties
				}
			>
				{isMediaLoaded && (
					<Box className={styles.imageResolution}>
						{manualPlacement?.width}x{manualPlacement?.height} px
					</Box>
				)}
				<Cropper
					image={selectedImage?.url}
					crop={crop}
					zoom={zoom}
					minZoom={0.1}
					aspect={1}
					cropSize={cropSize}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					showGrid={true}
					zoomWithScroll={true}
					restrictPosition={false}
					classes={{
						containerClassName: styles.cropperInnerContainer,
						cropAreaClassName: styles.customCropArea,
					}}
					onMediaLoaded={onMediaLoaded}
				/>
			</Box>

			<Box className={styles.imageScaleContainer}>
				<Box className={styles.imageScale}>
					<Typography className={styles.sliderLabel}>Image scale</Typography>
					<Slider
						className={styles.slider}
						value={zoom}
						onChange={(_e, value) => setZoom(value as number)}
						max={3}
						min={0.1}
						step={0.01}
					/>
					<Typography className={styles.sliderLabel}>{Math.floor(zoom * 100)}%</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default observer(ImageCropper);
