import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Alert, Box, ButtonProps, Dialog, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../BriaButton/BriaButton";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder.tsx";
import styles from "./ConfirmationPopup.module.scss";

interface IProps {
	open: boolean;
	title?: string;
	description?: string | ReactNode;
	subDescription?: string | ReactNode;
	subTitle?: string;
	confirmButtonText?: string | ReactNode;
	hideActions?: boolean;
	hideCancel?: boolean;
	onClick?: () => void;
	onClose: () => void;
	onSecondaryClick?: () => void;
	children?: ReactNode;
	disableConfirm?: boolean;
	hideCloseIcon?: boolean;
	firstButtonText?: string;
	errorMessage?: string | null;
	loading?: boolean;
	loadingBody?: boolean;
	paperClassName?: string;
	contentClassName?: string;
	backdropClassName?: string;
	maxWidth?: string;
	image?: any;
}

export default function ConfirmationPopup({
	open,
	title,
	description,
	subDescription,
	subTitle,
	confirmButtonText,
	hideActions = false,
	onClick,
	onClose,
	hideCancel,
	children,
	disableConfirm,
	firstButtonText,
	onSecondaryClick,
	errorMessage,
	loading = false,
	loadingBody = false,
	hideCloseIcon = false,
	paperClassName = "",
	contentClassName = "",
	backdropClassName = "",
	maxWidth = "552px",
	image,
}: IProps & ButtonProps) {
	const { t } = useTranslation();

	return (
		<Dialog
			classes={{
				paper: clsx(styles.paper, paperClassName, { [styles.hasImage]: !!image }),
			}}
			slotProps={{ backdrop: { className: backdropClassName } }}
			onClose={onClose}
			open={open}
			maxWidth={false}
			PaperProps={{
				style: {
					maxWidth: maxWidth, // Set your desired width in pixels
				},
			}}
		>
			<LoadingPlaceholder isLoading={loadingBody} className={clsx(styles.loaderStyle)}>
				{image && <img className={styles.image} src={image} alt="popup image" />}
				<Box className={clsx(styles.contentContainer, contentClassName)}>
					{!hideCloseIcon && <CloseIcon onClick={onClose} className={styles.closeIcon} />}
					{title && <Typography className={styles.title}>{title}</Typography>}
					{description && <Typography className={styles.description}>{description}</Typography>}
					{subDescription && <Typography className={styles.subDescription}>{subDescription}</Typography>}
					{subTitle && <Typography className={styles.subTitle}>{subTitle}</Typography>}
					{children}
					{!hideActions && (
						<Box className={styles.buttonsContainer}>
							<Box>
								{errorMessage && (
									<Alert severity="error" variant="standard" className={styles.errorMessage}>
										{errorMessage}
									</Alert>
								)}
							</Box>

							<Box className={styles.buttons}>
								{!hideCancel && (
									<BriaButton
										onClick={onSecondaryClick ?? onClose}
										className={styles.cancelBtn}
										buttonType="tertiaryMedium"
									>
										{firstButtonText ? firstButtonText : t("cancel")}
									</BriaButton>
								)}

								{typeof confirmButtonText === "string" ? (
									<BriaButton
										className={styles.confirmBtn}
										disabled={disableConfirm}
										onClick={onClick}
										buttonType="primaryMedium"
										loading={loading}
									>
										{confirmButtonText}
									</BriaButton>
								) : (
									confirmButtonText
								)}
							</Box>
						</Box>
					)}
				</Box>
			</LoadingPlaceholder>
		</Dialog>
	);
}
