import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../../../../hooks/useStores";
import StepperNavigation from "../../../../../../StepperNavigation/StepperNavigation";
import { PopUpSteps } from "../../../../../../constants/mock-data";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor";
import styles from "./InsertText.module.scss";

const InsertText = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.insertText" });
	const { isPopupView } = useContext(DesignEditorContext);
	const TEXT_INDEX = PopUpSteps.indexOf("text");
	const { designEditorStore } = useAppStore();

	return (
		<Box className={styles.container}>
			<Typography className={styles.title}> {t("insertText")} </Typography>
			<Typography className={styles.description}>{t("descriptions1")}</Typography>
			<Typography className={styles.description}> {t("descriptions2")}</Typography>
			{isPopupView && designEditorStore.activeStep === TEXT_INDEX && <StepperNavigation />}
		</Box>
	);
};

export default InsertText;
