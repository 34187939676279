import { makeAutoObservable, runInAction } from "mobx";
import { Template, defaultTemplate } from "./models/template.ts";

import { NavigateFunction } from "react-router-dom";
import { ANALYTICS_EVENTS } from "../analytics-store.tsx";
import RouterConstants from "../constants/RouterConstants.ts";
import { getSelectedOrganization } from "../helpers/localStorage.ts";
import useErrorToast from "../hooks/useErrorToast";
import { IRootStore } from "../mobx/root-store.tsx";
import { CampaignEntityStatus, CampaignViewModes, PaginatedItems } from "../models/common";
import QueryService from "../utils/QueryService";
import { Placement } from "./models/placement";
import { ILayer, IScene, IStaticImage } from "./types";

export enum CampaignPreviewBtn {
	PRIVATE = "private",
	SHARED = "shared",
}

export enum TemplatePreviewBtn {
	ORGANIZATION = "organization",
	PUBLIC = "public",
}

export enum UrlPathPrefix {
	CAMPAIGN_ORG = "campaigns/O",
	CAMPAIGN_USER = "campaigns/U",
	TEMPLATE_PUBLIC = "templates/P",
	TEMPLATE_ORG = "templates/O",
}
export enum UrlPathEntities {
	CAMPAIGNS = "campaigns",
	TEMPLATES = "templates",
}

interface VectorColors {
	colors: string[];
	colorMap: Record<string, string>;
}

export interface ICampaignStore {
	templates: PaginatedItems<Template>;
	vectorColors: VectorColors;
	prevColor: string;
	orderedLayers: ILayer[];
	placements: PaginatedItems<Placement>;
	isLoadingTemplates: boolean;
	isTemplateUpdating: boolean;
	isCreatingTemplate: boolean;
	isFirstTimeBrandApplied: boolean;
	isTemplateDuplicationInProgress: boolean;
	isLoadingPlacements: boolean;
	isError: boolean;
	formErrors: { save?: boolean; invalidName?: boolean };
	canvasColorPicker: string;
	isAdminMode: boolean;
	viewMode: CampaignViewModes.FULL_VIEW | CampaignViewModes.GRID_VIEW;
	selectedTemplate: Template | null; // DB selected Campaign or template
	selectedWizardTemplate: Template | null;
	templateForm: Template;
	isEditingAllAds: boolean;
	selectedTemplatePreviewBtn: string;
	selectedCampaignPreviewBtn: string;
	openDuplicateCampaignOrTemplateModal: boolean;
	saveNewTemplateClicked: boolean;
	templateName: string;
	templateToDuplicate: Template | null;
	originalLogoObjects: Record<number, IStaticImage[] | undefined>;
	cacheBustingVersion: number;
	isManualTemplateTabSelection: boolean;
	// This property stores the initial aspect ratio of the Canvas (width-to-height),
	// used to maintain proportional height adjustments when the width changes.
	orginalCanvasAspectRatio?: number;
	// This property to control resizing the canvas to keep the elements inside the new size properly
	originalScenesBeforeResizing: IScene[] | null;
	scenesAfterResizing: Record<string, IScene> | null;
	isResizingCanvas: boolean;
	isSavingToMyCampaign: boolean;
	isEditorPageLoading: boolean;
	fixTextBoxAnchorEl?: HTMLButtonElement;
	fixedTextBoxChecked: boolean;
	loadTemplates(type: CampaignEntityStatus, fetchOrgTemplates: boolean, getPublicTemplates: boolean): Promise<void>;
	getFilteredTemplatesBasesOnPlacementsAndName(
		placementsOptions: any,
		search_term: string,
		type: CampaignEntityStatus,
		fetchOrgTemplates: boolean,
		getPublicTemplates: boolean,
	): Promise<void>;

	updateTemplate(updatedTemplate: Template): Promise<void>;
	duplicateTemplate(template: Template, navigate: NavigateFunction): Promise<number | null>;
	createTemplate(template: Template, navigate: NavigateFunction): Promise<void>;
	loadPlacements(): Promise<void>;
	handleAdminModeChange(isAdmin: boolean): void;
	handleViewModeChange(viewMode: CampaignViewModes): void;
	handleSetSelectedTemplate(template: Template | null): void;
	handleSetSelectedWizardTemplate(template: Template | null): void;
	handleSetSelectedTemplatePreviewButton(btn: TemplatePreviewBtn.ORGANIZATION | TemplatePreviewBtn.PUBLIC): void;
	handleSetSelectedCampaignPreviewButton(btn: CampaignPreviewBtn.PRIVATE | CampaignPreviewBtn.SHARED): void;
	storeOriginalLogoObjects(sceneIndex: number, objects: IStaticImage[]): void;
	getOriginalLogoObjects(sceneIndex: number): IStaticImage[] | undefined;
	handleTemplateNavigation(template: Template, navigate: NavigateFunction): void;
	setProperty<K extends keyof ICampaignStore>(key: K, value: ICampaignStore[K]): Promise<void>;
}

export default class CampaignStore implements ICampaignStore {
	rootStore: IRootStore;
	errorToast = useErrorToast();
	isLoadingTemplates: boolean = false;
	isLoadingPlacements: boolean = false;
	isError: boolean = false;
	formErrors: { save?: boolean; invalidName?: boolean } = {};
	templates: PaginatedItems<Template> = { total: 0, items: [] };
	placements: PaginatedItems<Placement> = { total: 0, items: [] };
	orderedLayers: ILayer[] = [];
	canvasColorPicker: string = "#fff";
	vectorColors: VectorColors = { colors: [], colorMap: {} };
	prevColor: string = "";
	isAdminMode: boolean = false;
	viewMode: CampaignViewModes.FULL_VIEW | CampaignViewModes.GRID_VIEW = CampaignViewModes.FULL_VIEW;
	selectedTemplate: Template | null = null;
	selectedWizardTemplate: Template | null = null;
	isEditingAllAds: boolean = false;
	isTemplateUpdating: boolean = false;
	isTemplateDuplicationInProgress: boolean = false;
	isCreatingTemplate: boolean = false;
	templateForm: Template = defaultTemplate;
	selectedTemplatePreviewBtn: string = TemplatePreviewBtn.ORGANIZATION;
	selectedCampaignPreviewBtn: string = CampaignPreviewBtn.PRIVATE;
	isFirstTimeBrandApplied: boolean = true;
	openDuplicateCampaignOrTemplateModal: boolean = false;
	saveNewTemplateClicked: boolean = false;
	templateName: string = "";
	templateToDuplicate: Template | null = null;
	originalLogoObjects: Record<number, IStaticImage[] | undefined> = {};
	orginalCanvasAspectRatio?: number;
	cacheBustingVersion: number = Math.floor(Math.random() * 1000) + 1;
	isManualTemplateTabSelection: boolean = false;
	originalScenesBeforeResizing: IScene[] | null = null;
	scenesAfterResizing: Record<string, IScene> | null = null;
	isResizingCanvas: boolean = false;
	isSavingToMyCampaign: boolean = false;
	isEditorPageLoading: boolean = true;
	fixTextBoxAnchorEl?: HTMLButtonElement = undefined;
	fixedTextBoxChecked: boolean = false;
	private campaignQueryService: QueryService = new QueryService("/campaign");

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setProperty = async <K extends keyof CampaignStore>(key: K, value: CampaignStore[K]) => {
		runInAction(() => ((this as CampaignStore)[key] = value));
	};

	loadTemplates = async (
		type: CampaignEntityStatus,
		fetchOrgTemplates: boolean,
		getPublicTemplates: boolean,
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingTemplates = true;

			const templates: PaginatedItems<Template> = await this.campaignQueryService.get("/templatesV2", {
				params: {
					templateStatus: type,
					fetchOrgTemplates: fetchOrgTemplates,
					getPublicTemplates: getPublicTemplates,
				},
			});

			runInAction(() => {
				this.templates = templates;
				this.isError = false;
				this.isLoadingTemplates = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingTemplates = false;
				this.isError = true;
			});
		}
	};

	getFilteredTemplatesBasesOnPlacementsAndName = async (
		selected_placements_resolution: any,
		search_term: string,
		type: CampaignEntityStatus,
		fetchOrgTemplates: boolean,
		getPublicTemplates: boolean,
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingTemplates = true;

			const templates: PaginatedItems<Template> = await this.campaignQueryService.get(
				"/placementsV2/filteredPlacements",
				{
					params: {
						search_term: search_term,
						selected_placements_resolution: selected_placements_resolution,
						templateStatus: type,
						fetchOrgTemplates: fetchOrgTemplates,
						getPublicTemplates: getPublicTemplates,
					},
				},
			);

			runInAction(() => {
				this.templates = templates;
				this.isError = false;
				this.isLoadingTemplates = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingTemplates = false;
				this.isError = true;
			});
		}
	};

	updateTemplate = async (updatedTemplate: Template): Promise<void> => {
		this.isError = false;
		this.isTemplateUpdating = true;
		try {
			await this.campaignQueryService.put("/templatesV2", updatedTemplate);
			runInAction(() => {
				this.templates.items = this.templates?.items
					.map((template) => (template.id === updatedTemplate.id ? updatedTemplate : template))
					.filter((template) => template.status !== CampaignEntityStatus.DELETED);
				this.isError = false;
				this.isTemplateUpdating = false;
				this.cacheBustingVersion = Math.floor(Math.random() * 1000) + 1;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isTemplateUpdating = false;
			});
		}
	};

	duplicateTemplate = async (template: Template, navigate: NavigateFunction): Promise<number | null> => {
		this.isError = false;
		this.isTemplateDuplicationInProgress = true;
		const templateToDuplicate = {
			...template,
			created_at: new Date(),
		};
		try {
			const templateId = await this.campaignQueryService.post("/templatesV2/duplicate", templateToDuplicate);
			runInAction(() => {
				this.isError = false;
				this.isTemplateDuplicationInProgress = false;
				const templateToNavigate = {
					...templateToDuplicate,
					id: templateId,
				};
				this.handleTemplateNavigation(templateToNavigate, navigate);
				this.cacheBustingVersion = Math.floor(Math.random() * 1000) + 1;
				template.status === CampaignEntityStatus.DRAFT
					? this.rootStore.analyticsStore.logEvent(ANALYTICS_EVENTS.SAVE_NEW_CAMPAIGN)
					: this.rootStore.analyticsStore.logEvent(ANALYTICS_EVENTS.CREATE_NEW_TEMPLATE);
			});

			return templateId;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isTemplateDuplicationInProgress = false;
			});
			return null;
		}
	};

	createTemplate = async (template: Template, navigate: NavigateFunction): Promise<void> => {
		this.isError = false;
		this.isCreatingTemplate = true;
		try {
			const template_id = await this.campaignQueryService.post("/templatesV2/create", template);
			template.id = template_id;
			runInAction(() => {
				this.isError = false;
				this.isCreatingTemplate = false;
				this.handleSetSelectedTemplate(template);
				this.handleTemplateNavigation(template, navigate);
				this.cacheBustingVersion = Math.floor(Math.random() * 1000) + 1;
				template.status === CampaignEntityStatus.DRAFT
					? this.rootStore.analyticsStore.logEvent(ANALYTICS_EVENTS.SAVE_NEW_CAMPAIGN)
					: this.rootStore.analyticsStore.logEvent(ANALYTICS_EVENTS.CREATE_NEW_TEMPLATE);
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isCreatingTemplate = false;
			});
		}
	};

	loadPlacements = async (): Promise<void> => {
		try {
			this.isError = false;
			this.isLoadingPlacements = true;
			const placements: PaginatedItems<Placement> = await this.campaignQueryService.get("/placementsV2");

			runInAction(() => {
				this.placements = placements;
				this.isLoadingPlacements = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingPlacements = false;
				this.isError = true;
			});
			throw e;
		}
	};

	handleAdminModeChange = (isAdmin: boolean) => {
		runInAction(() => {
			this.isAdminMode = isAdmin;
		});
	};

	handleViewModeChange = (viewMode: CampaignViewModes) => {
		runInAction(() => {
			this.viewMode = viewMode;
		});
	};

	handleSetSelectedTemplate = (template: Template | null) => {
		runInAction(() => {
			this.selectedTemplate = template;
		});
	};

	handleSetSelectedWizardTemplate = (template: Template | null) => {
		runInAction(() => {
			this.selectedWizardTemplate = template;
		});
	};

	handleSetSelectedTemplatePreviewButton(button: TemplatePreviewBtn.ORGANIZATION | TemplatePreviewBtn.PUBLIC): void {
		this.selectedTemplatePreviewBtn = button;
	}

	handleSetSelectedCampaignPreviewButton(button: CampaignPreviewBtn.PRIVATE | CampaignPreviewBtn.SHARED): void {
		this.selectedCampaignPreviewBtn = button;
	}
	storeOriginalLogoObjects(sceneIndex: number, objects: IStaticImage[]) {
		this.originalLogoObjects[sceneIndex] = objects;
	}

	getOriginalLogoObjects(sceneIndex: number): IStaticImage[] | undefined {
		return this.originalLogoObjects[sceneIndex];
	}
	handleTemplateNavigation = (templateToLoad: Template, navigate: NavigateFunction) => {
		const selectedOrg = getSelectedOrganization();
		if (templateToLoad?.id) {
			let urlPath;

			if (templateToLoad.status === CampaignEntityStatus.DRAFT) {
				if (templateToLoad.org_id) {
					urlPath = `${UrlPathPrefix.CAMPAIGN_ORG}${templateToLoad.id}`;
				} else if (templateToLoad.user_id) {
					urlPath = `${UrlPathPrefix.CAMPAIGN_USER}${templateToLoad.id}`;
				}
			} else if (templateToLoad.status === CampaignEntityStatus.ACTIVE) {
				if (templateToLoad.org_id && templateToLoad.org_id !== selectedOrg?.organization.uid) {
					urlPath = `${UrlPathPrefix.TEMPLATE_PUBLIC}${templateToLoad.id}`;
				} else {
					urlPath = `${UrlPathPrefix.TEMPLATE_ORG}${templateToLoad.id}`;
				}
			}
			if (urlPath) {
				navigate(`${RouterConstants.DESIGN_EDITOR.editPath}/${urlPath}`);
			}
		}
	};
}
