import { Box } from "@mui/material";
import styles from "./HeaderLayout.module.scss";

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import BackButton from "../../components/common/BackButton/BackButton.tsx";
import BriaLogo from "../../components/common/BriaLogo/BriaLogo.tsx";
import IframeHeaderNavigationDropdown from "../../components/common/IframeHeaderNavigationDropdown/IframeHeaderNavigationDropdown.tsx";
import RouterConstants from "../../constants/RouterConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import { OrgFeatures } from "../../models/billing.ts";
import { IframePostMessageActionTypes } from "../../models/new-iframe.ts";
import iframeStore from "../../pages/IframeNew/iframe-store.tsx";
import { isAppsURL } from "../../utils/index.ts";

type Props = {
	logo?: any;
	onLogoClick: () => void;
	className?: string;
	children: ReactNode;
};

const HeaderLayout = ({ logo, className, children, onLogoClick }: Props) => {
	const { uiStore, authStore } = useAppStore();

	const onLogoClickHandler = () => {
		if (iframeStore.isIframe()) {
			iframeStore.sendActionPostMessage(IframePostMessageActionTypes.LogoClick);
		} else {
			onLogoClick();
		}
	};

	return (
		<Box
			className={clsx(className, styles.headerContainer, {
				[styles.appsHeader]:
					isAppsURL() || location.pathname.startsWith(RouterConstants.AUTOMOTIVE_EDITOR.path),
				[styles.iframe]: iframeStore.isIframe(),
			})}
		>
			<Box className={styles.logoContainer}>
				{(!iframeStore.isIframe() ||
					(!authStore.isLoadingOrgSubscriptions &&
						authStore.orgSubscription &&
						(!authStore.isFeatureEnabled(OrgFeatures.IFRAME_WHITE_LABEL) ||
							iframeStore.iframe.config.custom_style?.logo?.image))) && (
					<BriaLogo
						onLogoClick={onLogoClickHandler}
						customLogo={
							iframeStore.isIframe() &&
							!authStore.isLoadingOrgSubscriptions &&
							authStore.orgSubscription &&
							authStore.isFeatureEnabled(OrgFeatures.IFRAME_WHITE_LABEL) &&
							iframeStore.iframe.config.custom_style?.logo?.image
								? iframeStore.iframe.config.custom_style?.logo?.image
								: logo
						}
						className={styles.logo}
					/>
				)}
				{!iframeStore.isIframe() && uiStore.backButton && (
					<BackButton
						text={uiStore.backButton.text}
						navigateOptions={uiStore.backButton.navigateOptions}
						to={uiStore.backButton.to}
						showWarning={uiStore.backButton.showWarning}
						loadingWarning={uiStore.backButton.loadingWarning}
						onWarningConfirm={uiStore.backButton.onWarningConfirm}
						currentAppName={uiStore.backButton.currentAppName}
					/>
				)}
				<IframeHeaderNavigationDropdown />
			</Box>

			<Box className={styles.buttonsContainer}>{children}</Box>
		</Box>
	);
};

export default observer(HeaderLayout);
