import { makeAutoObservable, runInAction } from "mobx";
import { MutableRefObject, useRef } from "react";
import { IRootStore } from "../mobx/root-store.tsx";
import { FontTypeEnum, HorizontalEnum, LogoTypeEnum, VerticalEnum } from "../models/common.ts";
import { BASE_ITEMS, PanelType, customStepperTitle } from "./constants/app-options.ts";
import { PopUpSteps } from "./constants/mock-data.ts";
import { Template } from "./models/template.ts";
import { ILayer, IScene } from "./types";

export interface IDesignEditorStore {
	isLoading: boolean;
	isError: boolean;
	designEditorPopup: boolean;
	activeStep: number;
	newStep: number;
	campaignName: string;
	editorStepperSteps: { id: string; title: string; tabName: PanelType }[];
	editorStepperDisabledSteps: number[] | undefined;
	isTemplateEmptyState: boolean;
	hideCampaignResultContent: boolean;
	isGenerateAdsLoading: boolean;
	numberOfProcessedAdvertisements: number;
	isLoadingTemplate: boolean;
	campaignUpdatedTemplate: Template | undefined;
	hasSmartImages: boolean;
	originalInnerRectangle?: ILayer;
	originalOuterRectangle?: ILayer;
	isLoadingSmartImageStep: boolean;
	containerRef: MutableRefObject<null | HTMLDivElement>;
	activeObjectSettings: {
		colorNumber?: number;
		strokeColorNumber?: number;
		fontType?: FontTypeEnum;
		logoType?: LogoTypeEnum;
		horizontalAlignment?: HorizontalEnum;
		verticalAlignment?: VerticalEnum;
	};
	isSmartImageObjectSelected: boolean;
	imageTypeToNewUrlMap: Record<string, string | undefined>;
	originalSceneInWizard?: IScene;
	originalActiveObject?: Partial<ILayer> | null;
	currentSceneInWizard?: IScene;
	removeSmartImageFromAllScenes: boolean;
	isResettingWizardImages: boolean;
	backupOfReplacedStaticImagesLayers: Partial<ILayer>[];
	isSceneFullyLoaded: boolean;

	setProperty<K extends keyof IDesignEditorStore>(key: K, value: IDesignEditorStore[K]): Promise<void>;

	getItemPropertiesById(id: string): { name: string; stepperTitle: string };

	resetCampaignPopup(): void;

	openText2ImagePopup(isPopupView: boolean): void;
}

export default class DesignEditorStore implements IDesignEditorStore {
	rootStore: IRootStore;
	isLoading: boolean = false;
	isError: boolean = false;
	designEditorPopup: boolean = false;
	activeStep: number = 0;
	newStep: number = 0;
	campaignName: string = "";
	editorStepperSteps: { id: string; title: string; tabName: PanelType }[] = [];
	isTemplateEmptyState: boolean = true;
	hideCampaignResultContent: boolean = true;
	isGenerateAdsLoading: boolean = false;
	numberOfProcessedAdvertisements: number = 1;
	isLoadingTemplate: boolean = false;
	campaignUpdatedTemplate: Template | undefined = undefined;
	editorStepperDisabledSteps: number[] | undefined = undefined;
	hasSmartImages: boolean = false;
	originalInnerRectangle?: ILayer;
	originalOuterRectangle?: ILayer;
	isLoadingSmartImageStep: boolean = false;
	containerRef = useRef<null | HTMLDivElement>(null);
	activeObjectSettings = {
		colorNumber: undefined,
		fontType: undefined,
		logoType: undefined,
		horizontalAlignment: undefined,
		verticalAlignment: undefined,
		strokeColorNumber: undefined,
	};
	isSmartImageObjectSelected: boolean = false;
	imageTypeToNewUrlMap: Record<string, string | undefined> = {};
	originalSceneInWizard?: IScene;
	originalActiveObject?: Partial<ILayer> | null;
	currentSceneInWizard?: IScene;
	removeSmartImageFromAllScenes: boolean = false;
	backupOfReplacedStaticImagesLayers: Partial<ILayer>[] = [];
	isResettingWizardImages: boolean = false;
	isSceneFullyLoaded: boolean = true;
	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.initializeEditorStepperSteps();
		this.rootStore = rootStore;
	}

	initializeEditorStepperSteps = () => {
		this.editorStepperSteps = PopUpSteps.map((id) => {
			const { name, stepperTitle, tabName } = this.getItemPropertiesById(id);
			return { id: name, title: stepperTitle, tabName: tabName };
		});
	};

	setProperty = async <K extends keyof DesignEditorStore>(key: K, value: DesignEditorStore[K]) => {
		runInAction(() => ((this as DesignEditorStore)[key] = value));
	};

	getItemPropertiesById = (id: string) => {
		const item = BASE_ITEMS.find((item) => item.id === id);

		// Handle fallback for non-base items
		if (!item && id === PanelType.CANVASCOLOR) {
			return {
				name: PanelType.CANVASCOLOR,
				stepperTitle: customStepperTitle.canvasStepperTitle,
				tabName: PanelType.CANVASCOLOR,
			};
		}

		if (!item && id === PanelType.WIZARDIMAGES) {
			return {
				name: PanelType.WIZARDIMAGES,
				stepperTitle: customStepperTitle.wizardImagesStepperTitle,
				tabName: PanelType.WIZARDIMAGES,
			};
		}

		return {
			name: item?.name || "",
			stepperTitle: item?.stepperTitle || "",
			tabName: item?.tabName || PanelType.TEMPLATES,
		};
	};

	resetCampaignPopup = () => {
		runInAction(() => {
			this.setProperty("activeStep", 0);
			this.setProperty("campaignName", "");
			this.setProperty("isTemplateEmptyState", true);
			this.setProperty("hideCampaignResultContent", true);
			this.setProperty("isGenerateAdsLoading", false);
			this.setProperty("numberOfProcessedAdvertisements", 1);
		});
	};

	openText2ImagePopup = (isPopupView: boolean) => {
		this.rootStore.imagesStore.uploadProcessDone = false;
		this.rootStore.imagesStore.uploadToUserGallery = true;
		if (isPopupView) {
			this.rootStore.playgroundStore.clearSelectedImages();
		}
		this.rootStore.playgroundStore.openModal();
		setTimeout(() => {
			// Mohammad added this timeout because, for some reason, when the wizard popup opens
			// and when trying to open the generation popup, `isPopupView` briefly becomes false
			// and then reverts to true. This behavior causes issues with the design editor container.
			// The timeout ensures that the design editor container is hidden correctly after this transition.
			const hideDesignEditorContainer = this?.containerRef && this.containerRef?.current;
			if (hideDesignEditorContainer) this.rootStore.uiStore.hideDesignEditorContainer();
		}, 10);
	};
}
