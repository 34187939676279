import { get, ref } from "firebase/database";
import { useCallback, useEffect, useRef, useState } from "react";
import { firebaseDatabase } from "../config/firebase";
import { IMAGES_RESOLUTIONS_LIST } from "../constants/AppsConstants";
import { useAppStore } from "../hooks/useStores";
import { ConfigValue, PlatformDestination } from "../models/image-to-image";
import { checkImageResolution } from "../utils";
import { generateCropPresetsByPlatform } from "../utils/generateCropPresetsByPlatform";
import { useImageToImageConfig } from "./useImageToImageConfig";

export const useSizeConfig = () => {
	const { imageToImageStore, playgroundStore } = useAppStore();
	const [cropConfig, setCropConfig] = useState<any>([]);
	const [platformsList, setPlatformsList] = useState<any[]>([]);
	const [exceededResolutions, setExceededResolutions] = useState<string[]>([]);
	const exceededResolutionsRef = useRef<string[]>([]);
	const selectedAspectRatios = imageToImageStore.config.size?.aspect_ratio?.value || [];
	const selectedDestinations = imageToImageStore.config.size?.medium_destination?.destinations || [];
	const selectedResolutions = imageToImageStore.config.size?.increase_resolution?.value || [];
	const selectedPlatform = imageToImageStore.config.size?.medium_destination?.platform?.key || "";
	const selectedImages =
		playgroundStore.getSelectedImages() || playgroundStore.getAvailableImages().find((image) => image.selected);
	const { updateSelectedSizeConfigs } = useImageToImageConfig();

	const getCropConfig = useCallback(async () => {
		const res = await get(ref(firebaseDatabase, "crop_config/"));
		const data = generateCropPresetsByPlatform(res.val());
		setCropConfig(data);

		const platformsList: any[] = [];
		data.flatMap((platform: any) => {
			[...platform.keys()].map((key) => {
				platformsList.push({ key, value: key });
				return key;
			});
		});
		setPlatformsList(platformsList);
	}, []);

	const checkResolutions = useCallback(async () => {
		const newExceededResolutions: string[] = [];
		const resolutionFactors = IMAGES_RESOLUTIONS_LIST.filter((item) => item.key !== "X1").map((res) =>
			parseInt(res.value.replace("X", "")),
		);

		for (const image of selectedImages) {
			for (const factor of resolutionFactors) {
				const exceed = await checkImageResolution(image.url, factor);
				if (exceed) {
					IMAGES_RESOLUTIONS_LIST.forEach((res) => {
						const resFactor = parseInt(res.value.replace("X", ""));
						if (resFactor >= factor) {
							newExceededResolutions.push(res.value);
						}
					});
				}
			}
		}

		if (JSON.stringify(newExceededResolutions) !== JSON.stringify(exceededResolutionsRef.current)) {
			exceededResolutionsRef.current = newExceededResolutions;
			setExceededResolutions(newExceededResolutions);
			const currentSelectedResolutions = imageToImageStore.config.size?.increase_resolution?.value;

			handleConfigChange("increase_resolution", {
				...imageToImageStore.config.size?.increase_resolution,
				value:
					newExceededResolutions.length > 0
						? selectedResolutions.filter((item) => !newExceededResolutions.includes(item))
						: currentSelectedResolutions,
			});
		}
	}, [selectedImages, selectedResolutions]);

	const handleConfigChange = useCallback(
		(field: string, value: ConfigValue) => {
			imageToImageStore.handleConfigChange("size", {
				...imageToImageStore.config.size,
				[field]: value,
			});
			updateSelectedSizeConfigs();
		},
		[imageToImageStore, updateSelectedSizeConfigs],
	);

	const handleAspectRatioSelection = useCallback(
		(item: string) => {
			const isSelected = selectedAspectRatios.includes(item);

			const updatedAspectRatios = isSelected
				? selectedAspectRatios.filter((aspectRatio) => aspectRatio !== item)
				: [...selectedAspectRatios, item];

			handleConfigChange("aspect_ratio", {
				...imageToImageStore.config.size?.aspect_ratio,
				value: updatedAspectRatios,
				selected: updatedAspectRatios.length > 0,
			});
			updateSelectedSizeConfigs();
		},
		[selectedAspectRatios, handleConfigChange, updateSelectedSizeConfigs],
	);

	const handlePlatformSelection = useCallback(
		(item: PlatformDestination) => {
			const isSelected = selectedDestinations.some(
				(destination) => destination.width === item.width && destination.height === item.height,
			);

			const updatedDestinations = isSelected
				? selectedDestinations.filter(
						(destination) => destination.width !== item.width || destination.height !== item.height,
				)
				: [...selectedDestinations, item];

			handleConfigChange("medium_destination", {
				...imageToImageStore.config.size?.medium_destination,
				destinations: updatedDestinations,
				selected: updatedDestinations.length > 0,
			});
			updateSelectedSizeConfigs();
		},
		[selectedDestinations, handleConfigChange, updateSelectedSizeConfigs],
	);

	const handleResolutionSelection = useCallback(
		(item: string) => {
			const isSelected = selectedResolutions.includes(item);

			const updatedResolutions = isSelected
				? selectedResolutions.filter((resolution) => resolution !== item)
				: [...selectedResolutions, item];

			handleConfigChange("increase_resolution", {
				value: updatedResolutions,
				selected: updatedResolutions.length > 0,
			});
			updateSelectedSizeConfigs();
		},
		[selectedResolutions, handleConfigChange, updateSelectedSizeConfigs],
	);

	const updateSelectedCustomSize = useCallback(() => {
		const customSize = imageToImageStore.config.size?.custom_size;
		let isSelected = customSize?.selected;
		const updatedCustomSize = [];

		if (customSize?.width) {
			updatedCustomSize.push("width");
		}

		if (customSize?.height) {
			updatedCustomSize.push("height");
		}

		if (!isSelected && updatedCustomSize.length === 2) {
			isSelected = true;
		}

		if (isSelected && updatedCustomSize.length < 1) {
			isSelected = false;
		}

		handleConfigChange("custom_size", {
			...imageToImageStore.config.size?.custom_size,
			selected: isSelected,
		});
	}, [imageToImageStore.config.size?.custom_size, handleConfigChange]);

	useEffect(() => {
		getCropConfig();
	}, [getCropConfig]);

	useEffect(() => {
		checkResolutions();
	}, [selectedImages, checkResolutions]);

	return {
		cropConfig,
		platformsList,
		exceededResolutions,
		handleConfigChange,
		handleAspectRatioSelection,
		handlePlatformSelection,
		handleResolutionSelection,
		selectedAspectRatios,
		selectedDestinations,
		selectedResolutions,
		selectedPlatform,
		updateSelectedCustomSize,
		checkResolutions,
		setExceededResolutions,
	};
};
