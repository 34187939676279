import { Box, InputBaseComponentProps } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import ImageIcon from "../../../assets/images/icons/Image/ImageIcon";
import { showErrorToast } from "../../../utils/toast";
import { supportedImageFileTypes } from "../../../utils/useImageUtils";
import BriaIconButton from "../BriaIconButton/BriaIconButton";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import { VisuallyHiddenInput } from "../VisuallyHiddenInput";
import styles from "./ImageUploader.module.scss";

type Props = {
	src?: string;
	description?: string | ReactNode;
	supportedFilesText?: string | ReactNode;
	showPreview?: boolean;
	loading?: boolean;
	disabled?: boolean;
	inputProps?: InputBaseComponentProps;
	onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
	onDelete?: () => Promise<void>;
	className?: string;
	icon?: string | JSX.Element;
	largeIcon?: boolean;
	emptyStateClassName?: string;
	titleClassName?: string;
	descriptionClassName?: string;
	maxFilesLimit?: number;
	maxFileSize?: number;
	horizontalLayout?: boolean;
	inlineError?: boolean;
	children?: React.ReactNode;
};

const ImageUploader: React.FC<Props> = ({
	src,
	description,
	supportedFilesText,
	icon,
	largeIcon,
	showPreview = true,
	loading = false,
	disabled = false,
	inputProps,
	onUpload,
	onDelete,
	className,
	emptyStateClassName,
	titleClassName,
	descriptionClassName,
	maxFilesLimit = Infinity,
	maxFileSize = Infinity,
	horizontalLayout = false,
	inlineError = false,
	children,
}) => {
	const defaultAcceptTypes = supportedImageFileTypes.map((type) => `image/${type}`).join(", ");
	const [blobUrl, setBlobUrl] = useState<string>();
	const [error, setError] = useState("");
	const { t } = useTranslation("translation", { keyPrefix: "playground" });

	useEffect(() => {
		setBlobUrl(undefined);
	}, [src]);

	const handleUpload = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.[0];
			const files: File[] = Array.from(e.target.files || []);
			if (!files.length) return;

			if (files.length > maxFilesLimit) {
				if (inlineError) {
					setError(t("imageToImage.fileExceedsSize"));
				} else {
					showErrorToast(t("imageToImage.filesExceedLimit"));
				}
				return;
			}

			const oversizedFile = files.find((file) => file.size > maxFileSize);

			if (oversizedFile) {
				if (inlineError) {
					setError(t("imageToImage.fileExceedsSize"));
				} else {
					showErrorToast(t("imageToImage.fileExceedsSize"));
				}
				return;
			}

			if (file) {
				const url = URL.createObjectURL(file);
				setBlobUrl(url);
				await onUpload?.(e);
			}

			e.target.value = "";
		},
		[maxFilesLimit, maxFileSize, onUpload, t],
	);

	const handleDelete = useCallback(
		async (e: React.MouseEvent<SVGElement | HTMLButtonElement>) => {
			e.preventDefault();
			if (blobUrl) {
				URL.revokeObjectURL(blobUrl);
				setBlobUrl(undefined);
			}
			await onDelete?.();
		},
		[blobUrl, onDelete],
	);

	const renderFileInput = useCallback(
		() => (
			<VisuallyHiddenInput
				type="file"
				id="fileInput"
				inputProps={{ ...inputProps, accept: inputProps?.accept ?? defaultAcceptTypes }}
				onChange={handleUpload}
				disabled={disabled}
			/>
		),
		[handleUpload, inputProps, disabled],
	);

	const supportedFiles = useMemo(() => {
		return (inputProps?.accept || defaultAcceptTypes)
			.split("image/")
			.map((type: string) => type.trim().toUpperCase())
			.join(" ");
	}, [inputProps]);

	const showImagePreview = useMemo(
		() => showPreview && (blobUrl || src || loading),
		[showPreview, blobUrl, src, loading],
	);

	return (
		<Box
			className={clsx(className, styles.container)}
			component={"label"}
			sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
		>
			{renderFileInput()}
			{!horizontalLayout && showImagePreview ? (
				<LoadingPlaceholder className={styles.loading} isLoading={loading}>
					<Box className={styles.imgContainer}>
						<DeleteIcon className={styles.deleteIcon} onClick={handleDelete} />
						<LazyLoadImage className={styles.img} src={src || blobUrl} alt={t("imageAlt")} />
					</Box>
				</LoadingPlaceholder>
			) : (
				<Box
					className={clsx(emptyStateClassName, styles.emptyState, {
						[styles.horizontalLayout]: horizontalLayout,
					})}
				>
					<Box
						className={clsx(styles.imageWrapper, {
							[styles.imagePreview]: showImagePreview,
						})}
					>
						{horizontalLayout && showImagePreview && (
							<LoadingPlaceholder className={styles.loading} isLoading={loading}>
								<Box
									className={clsx(styles.imgContainer, {
										[styles.horizontalLayout]: horizontalLayout,
									})}
								>
									<BriaIconButton className={styles.deleteIconBtn} onClick={handleDelete}>
										<DeleteIcon />
									</BriaIconButton>
									<LazyLoadImage className={styles.img} src={src || blobUrl} alt={t("imageAlt")} />
								</Box>
							</LoadingPlaceholder>
						)}
						{!src &&
							(icon ? (
								<Box className={clsx(styles.image, { [styles.largeIcon]: largeIcon })}>{icon}</Box>
							) : (
								<ImageIcon />
							))}
					</Box>

					<Box className={styles.contentWrapper}>
						<Box className={clsx(styles.description, titleClassName)}>{description}</Box>
						<Box className={clsx(styles.support, descriptionClassName)}>
							{supportedFilesText
								? supportedFilesText
								: `${t("emptyState.supportedFiles")} ${supportedFiles}`}
						</Box>
						{inlineError && <Box className={styles.error}>{error}</Box>}
						{children}
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default observer(ImageUploader);
