const MailIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.5 5.83366C17.5 4.91318 16.7538 4.16699 15.8333 4.16699H4.16667C3.24619 4.16699 2.5 4.91318 2.5 5.83366M17.5 5.83366V14.167C17.5 15.0875 16.7538 15.8337 15.8333 15.8337H4.16667C3.24619 15.8337 2.5 15.0875 2.5 14.167V5.83366M17.5 5.83366L10 10.8337L2.5 5.83366"
			stroke="var(--secondary-color)"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default MailIcon;
