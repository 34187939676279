import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionsDotsDropdown from "../../../components/common/ActionsDotsDropdown/ActionsDotsDropdown";
import BriaRadioButton from "../../../components/common/BriaRadioButton/BriaRadioButton";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import CopyToClipboardButton from "../../../components/common/CopyToClipboardButton/CopyToClipboardButton.tsx";
import { USER_SETTINGS } from "../../../constants/UserConstants.ts";
import { useAppStore } from "../../../hooks/useStores";
import { useUserChecks } from "../../../hooks/useUserChecks";
import TableLayout from "../../../layout/TableLayout/TableLayout";
import { EventLogTypesEnum } from "../../../models/common.ts";
import { ApiKey } from "../../../models/organization";
import styles from "./ApiKeys.module.scss";

const Account = () => {
	const { authStore, appStore } = useAppStore();
	const { canUserEditOrganization } = useUserChecks();
	const [apiDeleteKey, setApiDeleteKey] = useState("");
	const { t } = useTranslation();
	const [showCreateApiPopup, setShowCreateApiPopup] = useState(false);
	const headerArray = [t("key"), t("keyType"), t("created"), t("tokenStatus"), t("actions")];
	const [selectedKeyType, setSelectedKeyType] = useState("");
	const [disableStaging, setDisableStaging] = useState(false);
	const [disableProduction, setDisableProduction] = useState(false);

	const createData = (key: string, created: string, status: string, keyType: string) => {
		const color = status === "blocked" || status === "deleted" ? "rgba(var(--primary-text-color-rgb),0.4)" : "";
		return {
			rowStyle: {
				backgroundColor: status === "blocked" || status === "deleted" ? "var(--disabled-table-row)" : "",
			},
			data: {
				key: (
					<Box className={styles.keyContainer}>
						<Typography className={`${styles.textToCopy}`}>{`******************************${key.substring(
							30,
						)}`}</Typography>
					</Box>
				),
				keyType: (
					<Box style={{ color: color }} className={styles.statusContainer}>
						{keyType}
					</Box>
				),
				created: <Box style={{ color: color }}>{created}</Box>,
				status:
					status !== "blocked" && status !== "deleted" && canUserEditOrganization() ? (
						<ActionsDotsDropdown
							label={
								<Box className={styles.statusContainer}>
									<div className={`${styles[status.toLowerCase()]} ${styles.circle}`} />
									{status}
								</Box>
							}
							items={[
								{
									text: t("delete"),
									onClick: () => setApiDeleteKey(key),
								},
							]}
						/>
					) : (
						<Box className={styles.statusContainer}>
							<div className={`${styles[status.toLowerCase()]} ${styles.circle}`} />
							{status}
						</Box>
					),
				actions: (
					<Box className={styles.keyContainer}>
						<CopyToClipboardButton
							afterCopyAction={() => {
								authStore.user?.updateSettings(USER_SETTINGS.hideFreeUserBanner, true);
								appStore.logEvent({ eventType: EventLogTypesEnum.USER_COPIED_API_KEY });
							}}
							textToCopy={key}
							buttonText={t("copyAPKey")}
						/>
					</Box>
				),
			},
		};
	};
	const [tableData, setTableData] = useState<
		Array<{
			rowStyle: any;
			data: { key: any; created: any; lastUsed: any; status: any; keyType: any; actions: any };
		}>
	>([]);

	useEffect(() => {
		const loadData = async () => {
			if (!appStore.isError && appStore.apiKeys.length === 0) {
				appStore.loadApiKeys();
			}
		};
		loadData();
	}, []);

	useEffect(() => {
		const newTableData: any = appStore.apiKeys.map((item: ApiKey) => {
			if (item.key_type === "production" && item.status !== "deleted") {
				setDisableProduction(true);
			}
			if (item.key_type === "staging" && item.status !== "deleted") {
				setDisableStaging(true);
			}
			const itemStatus = authStore.isOrgBlocked ? "blocked" : item.status;

			return createData(item.api_token, item.created, itemStatus, item.key_type);
		});
		setTableData([...newTableData]);
	}, [appStore.apiKeys]);

	const createApiKey = async () => {
		await appStore.createApiKey(selectedKeyType.toLowerCase());
		setShowCreateApiPopup(false);
	};

	const deleteApiKey = async (key: any) => {
		await appStore.updateApiKey(key, "deleted");
		setApiDeleteKey("");
	};

	return (
		<>
			<TableLayout
				title={t("apiKeys")}
				description={t("manageAccountAPIKeys")}
				primaryButtonText={t("createApiKey")}
				primaryButtonIconType="plus"
				primaryButtonClick={() => setShowCreateApiPopup(true)}
				disablePrimaryButton={!canUserEditOrganization() || authStore.isOrgBlocked}
				primaryButtonLoading={authStore.isLoadingOrgSubscriptions}
				loading={authStore.isLoadingOrgSubscriptions}
				headerArray={headerArray}
				tableData={tableData}
			/>
			<ConfirmationPopup
				onClose={() => setApiDeleteKey("")}
				title={t("deleteApiKey")}
				description={t("deleteApiKeyPopupConfirmationQuestion")}
				confirmButtonText={t("delete")}
				open={!!apiDeleteKey}
				onClick={() => deleteApiKey(apiDeleteKey)}
			/>
			<ConfirmationPopup
				onClose={() => setShowCreateApiPopup(false)}
				title={t("KeyTypeTitle")}
				description={t("SpecifyKeyType")}
				confirmButtonText={t("CreateApiKey")}
				hideCancel={true}
				open={showCreateApiPopup}
				onClick={() => createApiKey()}
				disableConfirm={disableProduction && disableStaging}
			>
				<BriaRadioButton
					buttons={[
						{ label: "Staging", disabled: disableStaging, selected: true },
						{ label: "Production", disabled: disableProduction },
					]}
					defaultValue={!disableStaging ? "Staging" : !disableProduction ? "Production" : ""}
					onClick={(value: any) => setSelectedKeyType(value)}
				/>
			</ConfirmationPopup>
		</>
	);
};

export default observer(Account);
