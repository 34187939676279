import { MoreHoriz } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyledMenuItem } from "../../../../../components/common/BriaDropDown/BriaDropDown";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import RouterConstants from "../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../hooks/useStores";
import { EditTgProjectLocationState } from "../../Editor/TgProjectEditor";
import { ViewTgProjectLocationState } from "../../Viewer/TgProjectViewer";
import { TgProject } from "../../store/tg-projects-store";
import styles from "./TgProjectActionsPopover.module.scss";

type TgProjectActionsPopoverProps = {
	project: TgProject;
};

const TgProjectActionsPopover = ({ project }: TgProjectActionsPopoverProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.dashboard.card.actions" });
	const navigate = useSecureNavigate();
	const { tgStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const [anchorEl, setAnchorEl] = useState<SVGSVGElement>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [errorDelete, setErrorDelete] = useState("");

	const handleOpenDialog = (event: React.MouseEvent<SVGSVGElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	const openProject = () => {
		const viewProjectState: ViewTgProjectLocationState = { projectToView: toJS(project) };
		navigate(`${RouterConstants.TG_PROJECTS.fullPath}/${project.id}/${RouterConstants.NEW_TG_MODELS.path}`, {
			state: viewProjectState,
		});
	};

	const deleteProject = async () => {
		try {
			setErrorDelete("");
			await tgProjectStore.deleteProject(project.id);
			handleClose();
			setOpenDeletePopup(false);
		} catch (err: any) {
			setErrorDelete(err);
		}
	};

	const editProject = () => {
		const editProjectState: EditTgProjectLocationState = { projectToEdit: toJS(project) };
		navigate(`${RouterConstants.TG_PROJECTS.fullPath}/${project.id}/edit`, { state: editProjectState });
	};

	const openCreateModel = () => {
		navigate(`${RouterConstants.TG_PROJECTS.fullPath}/${project.id}/${RouterConstants.NEW_TG_MODELS.createPath}`);
	};

	return (
		<>
			<MoreHoriz className={styles.iconButton} onClick={handleOpenDialog} />
			<Popover
				open={!!anchorEl}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<>
					<StyledMenuItem onClick={openProject}>{t("open")}</StyledMenuItem>
					<StyledMenuItem onClick={() => setOpenDeletePopup(true)}>{t("delete")}</StyledMenuItem>
					<StyledMenuItem onClick={editProject}>{t("edit")}</StyledMenuItem>
					<StyledMenuItem onClick={openCreateModel}>{t("createModel")}</StyledMenuItem>
				</>
			</Popover>
			<ConfirmationPopup
				open={openDeletePopup}
				onClick={deleteProject}
				loading={tgProjectStore.loadingDeleteProject}
				title={t("deletePopup.title")}
				description={<Trans>{t("deletePopup.description")}</Trans>}
				confirmButtonText={t("deletePopup.confirmBtn")}
				onClose={() => setOpenDeletePopup(false)}
				errorMessage={errorDelete}
			/>
		</>
	);
};

export default observer(TgProjectActionsPopover);
