import i18n from "i18next";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { FilterByOptions } from "../../components/common/BriaTable/BriaTable.tsx";
import { isProduction } from "../../config/env.ts";
import { CAMPAIGN_API_IFRAME } from "../../constants/IframeConstants.ts";
import { pagesSort } from "../../constants/newIframe.ts";
import { PaginatedItems } from "../../models/common.ts";
import { ImageToImageConfigType, PlaygroundImage } from "../../models/image-to-image.ts";
import {
	Iframe,
	IframePages,
	IframePostMessageActionTypes,
	IframePostMessageItemTypes,
	IframePostMessageTypes,
	getDefaultIframe,
} from "../../models/new-iframe.ts";
import { UserOrganization } from "../../models/organization.ts";
import { TextToImageConfigType } from "../../models/text-to-image.ts";
import QueryService from "../../utils/QueryService.ts";

interface ExportPostMessage {
	type: IframePostMessageItemTypes;
	src: string;
}

interface MetadataPostMessage {
	[key: string]: any;
}

export interface IIframeStore {
	passedImageUrl: string | null;
	queryParams: URLSearchParams;
	iframe?: Iframe;
	iframeForm: Iframe;
	paginatedIframes: PaginatedItems<Iframe>;
	loadingOrgIframes: boolean;
	showIframeAuthErrorPage: boolean;
	rowsPerPage: number;
	formErrors: { save?: boolean; invalidName?: boolean };
	getOrgIframes: (pageNumber: number, filterBy?: FilterByOptions) => Promise<PaginatedItems<Iframe>>;
	handleFormChange: <K extends keyof Iframe>(key: K, value: Iframe[K]) => void;
	getIframe: (iframeId: string) => Promise<Iframe>;
	getIframeAnonymous: (iframeId: string) => Promise<{ iframe: Iframe; fb_organization: UserOrganization }>;
	createIframe: (iframe: Iframe) => Promise<number>;
	updateIframe: (iframe: Iframe) => Promise<void>;
	deleteIframe: (iframeId: string) => Promise<void>;
	isIframe: () => boolean;
	isPreview: () => boolean;
	sendPostMessage: (type: IframePostMessageTypes, message?: any) => void;
	sendExportPostMessage: (
		action: IframePostMessageActionTypes.Save | IframePostMessageActionTypes.Download,
		exportType: IframePostMessageItemTypes,
		items: ExportPostMessage[],
		metadata?: MetadataPostMessage,
	) => void;
	sendGeneratePostMessage: (
		config: TextToImageConfigType | ImageToImageConfigType,
		results: PlaygroundImage[],
	) => void;
	sendUploadPostMessage: (images: PlaygroundImage[]) => void;

	setProperty<K extends keyof IframeStore>(key: K, value: IframeStore[K]): void;
}

export class IframeStore implements IIframeStore {
	private queryService: QueryService = new QueryService("/iframes");
	passedImageUrl: string | null;
	iframeSessionId: string;
	showIframeAuthErrorPage: boolean = false;
	isInitialized: boolean = false;
	isIframeConfigLoaded: boolean = false;
	iframe: Iframe;
	iframeForm: Iframe;
	paginatedIframes: PaginatedItems<Iframe> = { total: 0, items: [] };
	rowsPerPage: number = 20;
	loadingOrgIframes: boolean = false;
	formErrors: { save?: boolean; invalidName?: boolean } = {};
	queryParams = new URLSearchParams(location.search);

	constructor() {
		makeAutoObservable(this);
		const defaultIframe = getDefaultIframe("");
		this.iframeSessionId = uuidv4();
		this.iframeForm = defaultIframe;
		this.iframe = { ...defaultIframe, id: this.queryParams.get("iframeId") ?? "" };
		this.passedImageUrl = this.queryParams.get("imageUrl");
	}

	setProperty = async <K extends keyof IframeStore>(key: K, value: IframeStore[K]) => {
		runInAction(() => ((this as IframeStore)[key] = value));
	};

	getOrgIframes = async (pageNumber: number, filterBy?: FilterByOptions): Promise<PaginatedItems<Iframe>> => {
		try {
			this.loadingOrgIframes = true;
			const paginatedIframes: PaginatedItems<Iframe> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: pageNumber,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedIframes = paginatedIframes;
				this.loadingOrgIframes = false;
			});

			return this.paginatedIframes;
		} catch (e: any) {
			this.loadingOrgIframes = false;
			return Promise.reject(`Error loading organization's iframes: ${e.message || e.toString()}`);
		}
	};

	handleFormChange = <K extends keyof Iframe>(key: K, value: Iframe[K]) => {
		this.iframeForm = { ...this.iframeForm, [key]: value };
	};

	getIframe = async (iframeId: string): Promise<Iframe> => {
		return this.queryService.get(`/${iframeId}`);
	};

	getIframeAnonymous = async (iframeId: string): Promise<{ iframe: Iframe; fb_organization: UserOrganization }> => {
		return this.queryService.get(`/anonymous/${iframeId}`);
	};

	createIframe = async (iframe: Iframe): Promise<number> => {
		try {
			const iframeId = await this.queryService.post(`/`, iframe);
			return iframeId;
		} catch (e: any) {
			if (e.response?.status === 409) {
				this.formErrors.invalidName = true;
			}
			return Promise.reject(`Error creating new iframe: ${e.message || e.toString()}`);
		}
	};
	updateIframe = async (iframe: Iframe): Promise<void> => {
		try {
			await this.queryService.put(`/${iframe.id}`, iframe);
			runInAction(() => {
				this.paginatedIframes.items = this.paginatedIframes?.items.map((template) =>
					template.id === iframe.id ? iframe : template,
				);
			});
		} catch (e: any) {
			if (e.response?.status === 409) {
				this.formErrors.invalidName = true;
			}
			return Promise.reject(`Error updating iframe: ${e.message || e.toString()}`);
		}
	};

	deleteIframe = async (iframeId: string): Promise<void> => {
		return this.queryService.delete(`/${iframeId}`);
	};

	isIframe = (): boolean => {
		return !!this.iframe?.id;
	};

	isPreview = (): boolean => {
		return this.isIframe() && this.iframe?.id === "preview";
	};

	isCampaignApi = (): boolean => {
		return this.isIframe() && this.iframe?.id === CAMPAIGN_API_IFRAME;
	};

	sendPostMessage = (type: IframePostMessageTypes, message: any = {}) => {
		if (this.isIframe()) {
			const messageJson = {
				iframeId: this.iframe.id,
				sessionId: this.iframeSessionId,
				type,
				message,
			};

			!isProduction() && console.log("iFrame postMessage sent to *", messageJson);
			window.parent.postMessage(messageJson, "*");
		}
	};

	sendActionPostMessage = (action: IframePostMessageActionTypes, additionalData?: any) => {
		this.sendPostMessage(IframePostMessageTypes.Action, {
			page: this.getIframeAppName(iframeStore.iframe.config.landing_page),
			action,
			...additionalData,
		});
	};

	sendExportPostMessage = (
		action: IframePostMessageActionTypes.Save | IframePostMessageActionTypes.Download,
		exportType: IframePostMessageItemTypes,
		items: ExportPostMessage[],
		metadata?: MetadataPostMessage,
	) => {
		this.sendActionPostMessage(action, {
			type: exportType,
			items: items,
			metadata,
		});
	};

	sendGeneratePostMessage = (config: TextToImageConfigType | ImageToImageConfigType, results: PlaygroundImage[]) => {
		this.sendActionPostMessage(IframePostMessageActionTypes.Generate, {
			config: toJS(config),
			results: results.map((image) => ({ type: IframePostMessageItemTypes.Image, src: image.url })),
		});
	};

	sendUploadPostMessage = (images: PlaygroundImage[]) => {
		this.sendActionPostMessage(IframePostMessageActionTypes.Upload, {
			type: IframePostMessageItemTypes.Image,
			items: images.map((image) => ({ type: IframePostMessageItemTypes.Image, src: image.url })),
		});
	};

	getIframeAppName = (app: IframePages) => {
		return i18n.t(`staticDropdowns.appPages.${app}`);
	};

	sortPages = (page1: string, page2: string) => {
		return pagesSort.indexOf(page1) - pagesSort.indexOf(page2);
	};
}

const iframeStore = new IframeStore();
export default iframeStore;
