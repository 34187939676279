import { NavigateFunction, createSearchParams, matchRoutes } from "react-router-dom";
import { APPS, MAX_INC_RESOLUTION_PIXELS } from "../constants/AppsConstants.ts";
import {
	ACCENTURE_ORG_ID,
	AMANA_ORG_ID,
	APRIMO_ORG_ID,
	AWS_ORG_ID,
	BRIA_DEMOS_ORG_ID,
	BRIA_ORG_ID,
	BRIA_PUBLIC_ORG_ID,
	FOX_ORG_ID,
	LOBLAW_ORG_ID,
	MCCANN_ORG_ID,
	MIROGLIO_ORG_ID,
	NEXAR_ORG_ID,
	NVIDIA_GAMING_ORG_ID,
	PAMPERS_ORG_ID,
	PUBLICIS_LE_PONT_UNILEVER_ORG_ID,
	PUBLICIS_ORG_ID,
	PURINA_ORG_ID,
	RAKUTEN_ORG_ID,
	SAFFRON_ORG_ID,
	TAG_WORLDWIDE_ORG_ID,
	TRAILER_PARK_GROUP_ORG_ID,
	VML_ORG_ID,
	WPP_POC_ORG_ID,
} from "../constants/OrgConstants.ts";
import RouterConstants from "../constants/RouterConstants.ts";
import { USER_ORGANIZATION_ROLES } from "../constants/UserConstants.ts";
import { getSelectedOrganization } from "../helpers/localStorage.ts";
import { PlaygroundImage, PlaygroundResult } from "../models/playground.ts";
import { TextToImageConfigType } from "../models/text-to-image.ts";
import User from "../models/user.ts";
import { convertS3UriToHttpsUrl } from "../pages/Campaign/Brands/utils.tsx";

export function capitalizeFirstLetter(text: string) {
	return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
}

export const getOrganizationAdmins = (user: any) => {
	let adminsList = user.user_organizations?.filter((item: any) => {
		return (
			item.role === USER_ORGANIZATION_ROLES.ADMIN ||
			item.role === USER_ORGANIZATION_ROLES.ADMIN_HIDDEN ||
			item.role === USER_ORGANIZATION_ROLES.OWNER
		);
	});
	adminsList = adminsList?.map((item: any) => item.org_uid);
	return adminsList ?? [];
};

export const isBriaAdmin = (user: User | null) => {
	return (
		user?.user_organizations?.some(
			(item: { org_uid: string; status: string; role: string }) =>
				item.org_uid === BRIA_ORG_ID && item.role === USER_ORGANIZATION_ROLES.ADMIN,
		) ?? false
	);
};

export const isBriaMember = (user: User | null) => {
	return (
		user?.user_organizations?.some(
			(item: { org_uid: string; status: string; role: string }) => item.org_uid === BRIA_ORG_ID,
		) ?? false
	);
};

export const isNvidiaGaminOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === NVIDIA_GAMING_ORG_ID;
};

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const isPublicisOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PUBLICIS_ORG_ID;
};

export const isPublicisLePointUnileverOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PUBLICIS_LE_PONT_UNILEVER_ORG_ID;
};

export const isAmanaOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === AMANA_ORG_ID;
};

export const isBriaOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === BRIA_ORG_ID;
};

export const isAwsOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === AWS_ORG_ID;
};

export const isLoblawOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === LOBLAW_ORG_ID;
};

export const isAprimoOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === APRIMO_ORG_ID;
};

export const isRakutenOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === RAKUTEN_ORG_ID;
};

export const isVmlOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === VML_ORG_ID;
};

export const isMiroglioOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === MIROGLIO_ORG_ID;
};

export const isNexarOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === NEXAR_ORG_ID;
};

export const isSaffronOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === SAFFRON_ORG_ID;
};

export const isTrailerParkGroupOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === TRAILER_PARK_GROUP_ORG_ID;
};

export const isMccannOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === MCCANN_ORG_ID;
};

export const isBriaDemosOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === BRIA_DEMOS_ORG_ID;
};

export const isWppPocOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === WPP_POC_ORG_ID;
};

export const isFoxOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === FOX_ORG_ID;
};

export const isPurinaOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PURINA_ORG_ID;
};

export const isPampersOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === PAMPERS_ORG_ID;
};

export const isAccentureOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === ACCENTURE_ORG_ID;
};

export const isTagWorldwideOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === TAG_WORLDWIDE_ORG_ID;
};

export const isBriaPublicOrg = () => {
	const selectedOrg = getSelectedOrganization();
	return selectedOrg?.organization?.uid === BRIA_PUBLIC_ORG_ID;
};

export const navigateWithParams = (navigate: NavigateFunction, path: string) => {
	const queryParams = new URLSearchParams(location.search);
	navigate(`${path}?${queryParams.toString()}`);
};

export function formatDate(apiDateString?: Date | string): string {
	if (apiDateString) {
		const apiDate = new Date(apiDateString);
		const options: Intl.DateTimeFormatOptions = {
			day: "numeric",
			month: "short",
			year: "numeric",
		};
		return apiDate.toLocaleDateString(undefined, options);
	} else {
		return "";
	}
}

export const isModelTypeDropDownVisible = () => {
	return false;
};

export const getSafeFileName = (filename: string) => {
	return filename?.replace(/[^a-z0-9]/gi, "_");
};

export const hasOrganization = () => {
	const selectedOrg = getSelectedOrganization();
	return !!selectedOrg?.organization;
};

export const getRouteConfigs = (url: string) => {
	const routes = Object.values(RouterConstants).map((config) => ({
		path: config.fullPath ?? config.path,
	}));

	const matchedRoutes = matchRoutes(routes, url);

	if (matchedRoutes && matchedRoutes.length > 0) {
		const match = matchedRoutes[0];
		return Object.values(RouterConstants).find((config) => config.fullPath === match.route.path);
	}

	return null;
};

export const getTodayDateRange = (): [Date, Date] => {
	const now = new Date();
	const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
	const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	return [startDate, endDate];
};

export const convertDateToUtc = (date: Date) => {
	return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const removeParamFromLocationUrl = (params: string[], navigate?: NavigateFunction) => {
	const queryParams = new URLSearchParams(location.search);
	params.forEach((param) => queryParams.delete(param));
	if (navigate) {
		navigate(
			{
				search: createSearchParams(queryParams).toString(),
			},
			{ replace: true },
		);
	}
	return `${location.origin}${location.pathname}?${queryParams.toString()}`;
};

export const loadFont = (fontSrc: any) => {
	const style = document.createElement("style");
	const httpUrl = convertS3UriToHttpsUrl(fontSrc.src);
	style.appendChild(
		document.createTextNode(`
	  @font-face {
		font-family: "${fontSrc.label ?? fontSrc.name}";
		src: url("${httpUrl}") format("truetype");
	  }
	`),
	);
	document.head.appendChild(style);
};

export const sortById = (list: any[] | undefined) => {
	return list ? list.slice().sort((itemA: any, itemB: any) => itemA.id - itemB.id) : [];
};
export const isFoxApps = () => {
	return window.location.pathname.includes("fox-apps");
};

export const isCanvasRequiredForThisApp = () => {
	const appsWithCanvas = ["eraser", "gen-fill"];
	return appsWithCanvas.some((app) => window.location.pathname.includes(app));
};

export const isAppsURL = () => {
	return window.location.pathname.includes("apps");
};

export const isPlatformHubPage = () => {
	return window.location.pathname === "/apps";
};

export const convertToCamelCase = (str: string): string => {
	if (str.trim().length === 0) return str;

	const parts: string[] = str.split("_");
	let camelCase: string = parts[0].toLowerCase();

	for (let i = 1; i < parts.length; i++) {
		if (parts[i]) {
			camelCase += parts[i].charAt(0).toUpperCase() + parts[i].slice(1).toLowerCase();
		}
	}

	return camelCase;
};

export const urlToFile = async (url: string, filename: string) => {
	const response = await fetch(url);

	if (!response.ok) {
		throw new Error("Failed to fetch image");
	}

	const mimeType = response.headers.get("Content-Type") || "application/octet-stream";

	const blob = await response.blob();

	return new File([blob], filename, { type: mimeType });
};

export const createFileList = (files: File[]): FileList => {
	const dataTransfer = new DataTransfer();
	files.forEach((file) => dataTransfer.items.add(file));
	return dataTransfer.files;
};

export const truncateToTwoDecimals = (num: number) => {
	return Math.floor(num * 100) / 100;
};

export const isPlaygroundLoadingImages = (playgroundResults: PlaygroundResult[], type: string) => {
	const lastIndex = playgroundResults.reduce(
		(lastIndex, item, index) => (item.type === type ? index : lastIndex),
		-1,
	);

	if (lastIndex === -1) return null;

	const playgroundResult = playgroundResults[lastIndex];

	const hasLoadingImages = playgroundResult?.images?.some((image) => image.loading);

	return hasLoadingImages ? playgroundResult : null;
};

export const getUsedModelsNames = (selectedImages: PlaygroundImage[], playgroundResult: PlaygroundResult[]): string => {
	const extractModelName = (config: unknown): string | null => {
		return (config as TextToImageConfigType)?.model_name || null;
	};

	const getValidModelNames = (items: PlaygroundImage[]): string[] => {
		return items.map((item) => extractModelName(item.config)).filter((name): name is string => name !== null);
	};

	const modelNames =
		selectedImages.length > 0
			? getValidModelNames(selectedImages)
			: playgroundResult
					.filter((item) => item?.type === APPS.TEXT_TO_IMAGE)
					.map((item) => extractModelName(item.config))
					.filter((name): name is string => name !== null);

	return Array.from(new Set(modelNames)).join(", ");
};

export const checkImageResolution = (url: string, scaleFactor: number = 1) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = url;
		img.onload = () => {
			const resolution = img.width * img.height * Math.pow(scaleFactor, 2);
			resolve(resolution > MAX_INC_RESOLUTION_PIXELS);
		};
		img.onerror = (error) => reject(`Failed to load image: ${error}`);
	});
};

export const isCampaignEditor = window.location.pathname.includes(RouterConstants.DESIGN_EDITOR.path);
