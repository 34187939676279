import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../../../../components/common/BriaButton/BriaButton.tsx";
import { useAppStore } from "../../../../../../../../../hooks/useStores.tsx";
import { useEditor } from "../../../../../../../../hooks/useEditor.tsx";
import { Image } from "../../../../../../../../models/image.ts";
import { useSmartImageUtils } from "../../../../../../utils/smartImageUtils.ts";
import { BackgroundRoute } from "../../../index.ts";
import styles from "./ExpandBackground.module.scss";

interface Iprops {
	addImageObject: (image: Image) => void;
}
const ExpandBackground = ({ addImageObject }: Iprops) => {
	const editor = useEditor();
	const { getSmartImageSize } = useSmartImageUtils();
	const { imagesStore, designEditorStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.images" });

	async function handleExpandBackground() {
		const currentScene = editor && editor.scene.exportToJSON();
		if (!currentScene) return;
		const smartImageSize = await getSmartImageSize(currentScene);
		if (smartImageSize) {
			await imagesStore.generateOrExpandImageBackground(BackgroundRoute.Expand, smartImageSize);
			addImageObject(imagesStore.smartImagesHistory[0]);
		}
	}

	return (
		<Box className={styles.generateBackgroundContainer}>
			<BriaButton
				className={styles.primaryButton}
				buttonType="primary"
				onClick={handleExpandBackground}
				loading={imagesStore.isGeneratingImages}
				disabled={
					imagesStore.isGeneratingImages ||
					imagesStore.isUploadingSmartImageMainObject ||
					!designEditorStore.isSceneFullyLoaded
				}
			>
				{t("smartImage.generateButton")}
			</BriaButton>
		</Box>
	);
};

const ObservedComponent = observer(ExpandBackground);
export default ObservedComponent;
