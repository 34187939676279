import { useState } from "react";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../../../../../../../components/common/BriaTooltip/BriaTooltip";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import letterCase from "../../../../../../assets/svgs/letter-case.svg";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText, LayerType } from "../../../../../../types";
import styles from "./TextLetterCase.module.scss";

const TextLetterCase = () => {
	const [state, setState] = useState<{ upper: boolean }>({ upper: false });
	const editor = useEditor();
	const { EditAllAds } = useDesignEditorUtils();
	const activeObject = useActiveObject() as Required<IStaticText>;
	return (
		<BriaTooltip title={"Letter case"} placement="bottom">
			<BriaButton
				className={styles.iconButton}
				disableRipple
				onClick={() => {
					if (!state.upper) {
						setState({ upper: true });
						editor?.objects.toUppercase();
						EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.LETTER_CASE, "uppercase");
					} else {
						setState({ upper: false });
						editor?.objects.toLowerCase();
						EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.LETTER_CASE, "");
					}
				}}
			>
				<img className={styles.textControl} src={letterCase} />
			</BriaButton>
		</BriaTooltip>
	);
};

export default TextLetterCase;
