import { Tooltip, TooltipProps } from "@mui/material";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import styles from "./BriaTooltip.module.scss";

type BriaTooltipProps = {
	displayOn?: "hover" | "click";
	isHtmlTitle?: boolean;
} & TooltipProps;

const BriaTooltip = ({
	displayOn = "hover",
	placement = "top",
	enterDelay = 100,
	leaveDelay = 50,
	arrow = true,
	className,
	children,
	isHtmlTitle = true,
	title,
	...rest
}: BriaTooltipProps) => {
	const [open, setOpen] = useState(false);
	const tooltipRef = useRef<HTMLDivElement>(null);

	const handleTooltipToggle = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen((prev) => !prev);
	};

	const handleTooltipClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (displayOn === "click") {
			const handleClickOutside = (event: MouseEvent) => {
				if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
					if ((event.target as HTMLElement).tagName.toLowerCase() === "a") {
						return;
					}
					handleTooltipClose();
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [displayOn]);

	const eventHandlers =
		displayOn === "click"
			? { onClick: handleTooltipToggle }
			: {
					onMouseEnter: () => setOpen(true),
					onMouseLeave: handleTooltipClose,
			  };

	return (
		<Tooltip
			{...rest}
			title={
				isHtmlTitle ? <Box component="div" dangerouslySetInnerHTML={{ __html: title as string }}></Box> : title
			}
			placement={placement}
			enterDelay={enterDelay}
			leaveDelay={leaveDelay}
			arrow={arrow}
			open={open}
			disableHoverListener={displayOn === "click"}
			className={className}
			componentsProps={{
				...rest.componentsProps,
				tooltip: {
					...rest.componentsProps?.tooltip,
					className: clsx(rest.componentsProps?.tooltip?.className, styles.tooltip),
				},
				arrow: {
					...rest.componentsProps?.arrow,
					className: clsx(rest.componentsProps?.arrow?.className, styles.arrow),
				},
				popper: {
					...rest.componentsProps?.popper,
					className: clsx(rest.componentsProps?.popper?.className, className),
				},
			}}
		>
			<Box className={clsx(styles.tooltipChild, { [styles.clickable]: displayOn == "click" })} ref={tooltipRef} {...eventHandlers}>
				{children}
			</Box>
		</Tooltip>
	);
};

export default BriaTooltip;
