import { Box, useTheme } from "@mui/material";
import { AxiosInstance, AxiosResponse } from "axios";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import AccountIcon from "../../assets/images/icons/AccountIcon.tsx";
import { DashboardIcon } from "../../assets/images/icons/DashboardIcon";
import DiscordIcon from "../../assets/images/icons/DiscordIcon.tsx";
import MailIcon from "../../assets/images/icons/MailIcon.tsx";
import NotesIcon from "../../assets/images/icons/NotesIcon.tsx";
import SlackIcon from "../../assets/images/icons/SlackIcon.tsx";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import MenuCell from "../../components/common/MenuCell/MenuCell";
import { BriaAxios } from "../../config/axios.tsx";
import LinkConstants from "../../constants/LinkConstants";
import RouterConstants from "../../constants/RouterConstants";
import { getSelectedOrganization } from "../../helpers/localStorage.ts";
import useOrgNavigation from "../../hooks/useOrgNavigation.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import { OrgFeatures } from "../../models/billing.ts";
import { Iframe } from "../../models/iframe.ts";
import {
	isAccentureOrg,
	isAprimoOrg,
	isAwsOrg,
	isBriaMember,
	isBriaOrg,
	isFoxOrg,
	isLoblawOrg,
	isMiroglioOrg,
	isNexarOrg,
	isPublicisLePointUnileverOrg,
	isPublicisOrg,
	isRakutenOrg,
	isSaffronOrg,
	isTagWorldwideOrg,
	isTrailerParkGroupOrg,
	isVmlOrg,
} from "../../utils";
import styles from "./Sidebar.module.scss";

interface SideMenuButtonProps {
	title: string;
	url: string;
	icon?: ({ stroke }: any) => JSX.Element;
	badge?: { text: string; color?: string };
	newTab?: boolean;
}

function Sidebar() {
	const { uiStore, authStore } = useAppStore();
	const theme = useTheme();
	const { orgNavigationHandler } = useOrgNavigation();
	const [orgIframes, setOrgIframes] = useState<Iframe[]>();
	const [loadingOrgIframes, setLoadingOrgIframes] = useState(false);

	const { t } = useTranslation("translation", { keyPrefix: "sideMenu" });
	const myOrgRoute = authStore.user?.isSuperAdmin()
		? RouterConstants.USAGE_BILLING.fullPath
		: RouterConstants.AUTH_KEYS.fullPath;

	const sideMenuButtons: SideMenuButtonProps[] = [
		{
			title: t("dashboard"),
			url: RouterConstants.CONSOLE.path,
			icon: DashboardIcon,
		},
		{
			title: t("models"),
			url: RouterConstants.FOUNDATION_MODELS.fullPath,
		},
		{
			title: t("apis"),
			url: RouterConstants.IMAGE_GENERATION_APIS.fullPath,
		},
		{
			title: t("newIframe"),
			url: RouterConstants.NEW_IFRAMES_CONFIG.fullPath,
			badge: { text: t("new"), color: theme.palette.primary.main },
		},
		{
			title: t("apps"),
			url: `${RouterConstants.APPS.path}`,
		},
	];

	const showCampaign = () => {
		sideMenuButtons.push({
			title: t("campaign"),
			url: `${RouterConstants.DESIGN_EDITOR.editPath}`,
			newTab: true,
		});
	};

	useEffect(() => {
		const loadOrgIframes = async () => {
			try {
				setLoadingOrgIframes(true);
				const axiosInstance: AxiosInstance = await BriaAxios();
				const response: AxiosResponse = await axiosInstance.request({
					method: "get",
					url: `/org_iframes`,
				});
				setOrgIframes(response.data);
			} finally {
				setLoadingOrgIframes(false);
			}
		};
		if (getSelectedOrganization()) {
			loadOrgIframes();
		}
	}, []);

	if (!loadingOrgIframes && orgIframes && orgIframes.filter((iframe) => iframe.status === "Active").length > 0) {
		sideMenuButtons.splice(4, 0, {
			title: t("iframe"),
			url: RouterConstants.IFRAME_CONFIG.fullPath,
		});
	}

	sideMenuButtons.splice(5, 0, {
		title: t("newTailoredGeneration"),
		url: RouterConstants.TG_PROJECTS.fullPath,
		badge: { text: t("new"), color: theme.palette.primary.main },
	});

	if (
		isAccentureOrg() ||
		isPublicisOrg() ||
		isPublicisLePointUnileverOrg() ||
		isBriaMember(authStore.user) ||
		isTagWorldwideOrg()
	) {
		sideMenuButtons.splice(7, 0, {
			title: t("campaignBrand"),
			url: RouterConstants.TEMPLATES.fullPath,
		});
	}

	if (isFoxOrg() || isBriaOrg()) {
		sideMenuButtons.push({
			title: t("foxApps"),
			url: `${RouterConstants.FOX_APPS.path}`,
		});
	}

	if (
		isBriaOrg() ||
		isAwsOrg() ||
		isLoblawOrg() ||
		isPublicisOrg() ||
		isRakutenOrg() ||
		isAprimoOrg() ||
		isVmlOrg() ||
		isMiroglioOrg() ||
		isNexarOrg() ||
		isSaffronOrg() ||
		isTrailerParkGroupOrg()
	) {
		showCampaign();
	}

	useEffect(() => {
		if (
			!authStore.isLoadingOrgSubscriptions &&
			authStore.orgSubscription &&
			authStore.isFeatureEnabled(OrgFeatures.CAMPAIGN)
		) {
			showCampaign();
		}
	}, [authStore.isLoadingOrgSubscriptions, authStore.orgSubscription]);

	return (
		<Box className={styles.sideBarContainer} ref={uiStore.sideBar}>
			{sideMenuButtons.map((btn: any, index: any) => {
				return (
					<div key={index}>
						<NavLink
							end={true}
							to={btn.url}
							className={styles.tabContainer}
							target={btn.newTab ? "_blank" : undefined}
							onClick={(e) => {
								e.preventDefault();
								orgNavigationHandler(btn.url, undefined, btn.newTab);
							}}
						>
							{({ isActive }: { isActive: boolean }) => (
								<MenuCell
									key={index}
									icon={btn.icon && btn.icon}
									selected={isActive}
									text={btn.title}
									badge={btn.badge}
								/>
							)}
						</NavLink>
					</div>
				);
			})}
			<Box className={styles.footerButtonsContainer}>
				<NavLink
					to={myOrgRoute}
					className={styles.tabContainer}
					onClick={(e) => {
						e.preventDefault();
						orgNavigationHandler(myOrgRoute);
					}}
				>
					{({ isActive }: { isActive: boolean }) => (
						<BriaButton
							className={clsx(styles.btnStyle, {
								[styles.selectedBtnStyle]: isActive,
							})}
							buttonType="textSmall"
						>
							<AccountIcon />
							{t("myOrganization")}
						</BriaButton>
					)}
				</NavLink>
				<NavLink to={LinkConstants.TERMS_AND_CONDITIONS_LINK} className={styles.tabContainer} target="_blank">
					<BriaButton className={styles.btnStyle} buttonType="textSmall">
						<NotesIcon />
						{t("termsAndConditions")}
					</BriaButton>
				</NavLink>
				<NavLink to={LinkConstants.DISCORD_COMMUNITY} className={styles.tabContainer} target="_blank">
					<BriaButton className={styles.btnStyle} buttonType="textSmall">
						<DiscordIcon />
						{t("discordCommunity")}
					</BriaButton>
				</NavLink>
				<NavLink to={LinkConstants.SLACK_API_COMMUNITY} className={styles.tabContainer} target="_blank">
					<BriaButton className={styles.btnStyle} buttonType="textSmall">
						<SlackIcon />
						{t("slackApiCommunity")}
					</BriaButton>
				</NavLink>
				<NavLink to={LinkConstants.CONTACT_US_FORM_LINK} className={styles.tabContainer} target="_blank">
					<BriaButton className={styles.btnStyle} buttonType="textSmall">
						<MailIcon />
						{t("getHelp")}
					</BriaButton>
				</NavLink>
			</Box>
		</Box>
	);
}

export default observer(Sidebar);
