import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import BriaButton from "../../../../../../../../components/common/BriaButton/BriaButton";
import useDesignEditorUtils, { FeatureType } from "../../../../../../../CustomUtils/UseDesignEditor";
import textShadow from "../../../../../../../assets/svgs/Text Shadow.svg";
import chevronDown from "../../../../../../../assets/svgs/chevron-down.svg";
import shapeShadow from "../../../../../../../assets/svgs/shapeShadow.svg";
import { defaultShadow } from "../../../../../../../constants/contants";
import { useActiveObject } from "../../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../../hooks/useEditor";
import FabricShadow from "../../../../Toolbox/TextToolBar/FabricShadow/FabricShadow";
import styles from "./FabricElementsShadow.module.scss";
export type IProps = {
	showTextShadowIcon?: boolean;
};
const ShapesShadow = ({ showTextShadowIcon = false }: IProps) => {
	const editor = useEditor();
	const activeObject = useActiveObject() as any;
	const { EditAllAds } = useDesignEditorUtils();
	const [shadowAnchorEl, setShadowAnchorEl] = useState<HTMLButtonElement>();
	const [shadowChanged, setShadowChanged] = useState(false);

	const handleOpenShadowDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
		setShadowAnchorEl(event.currentTarget);
	};
	const handleShadowAnchorElClose = () => {
		setShadowAnchorEl(undefined);
	};

	return (
		<Box className={styles.shapeShadowButtonsContainer}>
			<Box>
				<BriaButton
					buttonType="textMedium"
					onClick={() => {
						let newShadow = null;
						if (activeObject.shadow) {
							newShadow = {
								...defaultShadow,
								enabled: false,
							};
							editor?.objects.update({
								shadow: newShadow,
							});
						} else {
							newShadow = defaultShadow;
							editor?.objects.update({
								shadow: defaultShadow,
							});
						}
						EditAllAds(activeObject?.type, activeObject.shapeType, FeatureType.SHADOW, newShadow);
						setShadowChanged(!shadowChanged);
					}}
					className={clsx({
						[styles.shapeShadowButtonWrapper]: true,
						[styles.shapeShadowSelected]: !!activeObject?.shadow,
					})}
				>
					<img src={!showTextShadowIcon ? shapeShadow : textShadow} />
				</BriaButton>
				<FabricShadow
					open={!!shadowAnchorEl}
					handleClose={handleShadowAnchorElClose}
					anchorEl={shadowAnchorEl}
				/>
			</Box>
			<BriaButton
				buttonType="textMedium"
				onClick={handleOpenShadowDialog}
				className={clsx({
					[styles.shapeShadowButtonWrapper]: true,
					[styles.selected]: !!shadowAnchorEl,
				})}
			>
				<img src={chevronDown} alt="" />
			</BriaButton>
		</Box>
	);
};

const ObservedComponent = observer(ShapesShadow);
export default ObservedComponent;
