import { styled } from "@mui/material";

interface BriaButtonGroupProps {
	spacing?: number;
}

const BriaButtonGroup = styled("div")<BriaButtonGroupProps>(({ spacing = 1 }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	gap: `${spacing * 8}px`,
}));

export default BriaButtonGroup;
