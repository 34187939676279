import { SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../../../assets/images/icons/CrownIcon";
import BriaDropDown, { DropDownItem } from "../../../../../components/common/BriaDropDown/BriaDropDown";
import RouterConstants from "../../../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../../../hooks/useStores";
import { OrgFeatures } from "../../../../../models/billing.ts";
import { TgModel } from "../../../../NewTG/Models/store/tg-model-store.tsx";
import iframeStore from "../../../iframe-store.tsx";

const IframeImageGenerationTgModels = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.imageGenerationConfig.tgModels" });
	const { tgStore, authStore } = useAppStore();
	const { iframeForm, handleFormChange } = iframeStore;
	const [tgModelsOptions, setTgModelsOptions] = useState<DropDownItem[]>([]);

	useEffect(() => {
		const createModelsOptions = async (): Promise<DropDownItem[]> => {
			return (await tgStore.tgModelStore.listAllModels("Completed")).map((tailoredModel: TgModel) => ({
				key: tailoredModel.name,
				value: tailoredModel.id.toString(),
				icon: !authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION) ? <CrownIcon /> : "",
			}));
		};

		createModelsOptions().then(setTgModelsOptions);
	}, [authStore.isLoadingOrgSubscriptions]);

	const handleTgModelChange = (e: SelectChangeEvent<string[]>) => {
		if (authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION)) {
			handleFormChange("config", {
				...iframeForm.config,
				image_generation_config: {
					...(iframeForm.config.image_generation_config ?? {}),
					enabled_tg_models: e.target.value as string[],
				},
			});
		} else {
			window.open(`${RouterConstants.CONSOLE.path}?pricing=true`);
		}
	};

	const handleDeleteTgModel = (keyToDelete: string): void => {
		const updatedTgModels = iframeForm?.config.image_generation_config?.enabled_tg_models ?? [];
		const index = updatedTgModels.indexOf(keyToDelete as string);
		if (index > -1) {
			updatedTgModels.splice(index, 1);
		}
		handleFormChange("config", {
			...iframeForm.config,
			image_generation_config: {
				...(iframeForm.config.image_generation_config ?? {}),
				enabled_tg_models: updatedTgModels,
			},
		});
	};

	return (
		<BriaDropDown
			menuMaxHeight={"300px"}
			value={iframeForm?.config.image_generation_config?.enabled_tg_models ?? []}
			onChange={handleTgModelChange}
			handleDelete={handleDeleteTgModel}
			loading={tgStore.tgModelStore.loadingModelsList || authStore.isLoadingOrgSubscriptions}
			error={tgStore.tgModelStore.modelsListError}
			placeholder={
				tgStore.tgModelStore.loadingModelsList || tgModelsOptions.length ? t("placeholder") : t("noModels")
			}
			disabled={
				!tgStore.tgModelStore.loadingModelsList &&
				(tgModelsOptions.length === 0 || authStore.isLoadingOrgSubscriptions)
			}
			items={tgModelsOptions}
			multiple
		/>
	);
};
export default observer(IframeImageGenerationTgModels);
