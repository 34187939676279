import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import CloudUploadSVG from "../../../assets/images/icons/CloudUpload.tsx";
import FileUploader from "../../../components/common/FileUploader/FileUploader";
import ImageUploader from "../../../components/common/ImageUploader/ImageUploader";
import { APPS } from "../../../constants/AppsConstants.ts";
import RouterConstants from "../../../constants/RouterConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores";
import ProductGallery from "./ProductGallery/ProductGallery";
import styles from "./ProductPlacement.module.scss";

const ProductPlacement: React.FC = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.productPlacement" });
	const { t: t1 } = useTranslation("translation", { keyPrefix: "popups.imageUploader" });
	const { productPlacementStore, playgroundStore, uiStore } = useAppStore();
	const navigate = useSecureNavigate();

	useEffect(() => {
		playgroundStore.clearResults();
		playgroundStore.handleAppChange(APPS.PRODUCT_PLACEMENT);
		if (!productPlacementStore.isLoading && productPlacementStore.images.length === 0) {
			productPlacementStore.fetchImagesFromS3();
		}

		uiStore.showBackButton(
			RouterConstants.APPS.path,
			undefined,
			t("backToAllApplication"),
			undefined,
			undefined,
			undefined,
			t(`backBtn`),
		);

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
		};
	}, []);

	const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
		await productPlacementStore.handleUploadImage(e);
		productPlacementStore.fetchImagesFromS3();
		navigate(`/${RouterConstants.PRODUCT_PLACEMENT.fullPath}`);
	};

	return (
		<Box className={styles.productPlacementContainer}>
			<Typography className={styles.title}>{t("title")}</Typography>
			{!productPlacementStore.isLoading &&
				(!productPlacementStore.images?.length ? (
					<Box className={styles.fileUploader}>
						<ImageUploader
							className={styles.imageUploader}
							emptyStateClassName={styles.emptyState}
							icon={<CloudUploadSVG/>}
							largeIcon={true}
							loading={productPlacementStore.isUploadingImage}
							description={
								<Box className={styles.imageUploaderDesc}>
									<Box className={styles.header}>{t1("emptyState.header")}</Box>
									<Box className={styles.subHeader}>
										{t1("emptyState.subHeader")}
										<span className={styles.subHeader1}>{t1("emptyState.subHeader1")}</span>
									</Box>
								</Box>
							}
							onUpload={(e) => handleUploadImage(e)}
							inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp" }}
						/>
					</Box>
				) : (
					<Box
						className={clsx({
							[styles.uploadImageButton]: true,
							[styles.disabledUploadButton]: productPlacementStore.isUploadingImage,
						})}
					>
						<FileUploader
							buttonText={t("uploadImage")}
							buttonType="primary"
							loading={productPlacementStore.isUploadingImage}
							onUpload={(e) => handleUploadImage(e)}
							inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
							disabled={productPlacementStore.isUploadingImage}
						/>
					</Box>
				))}
			<Box className={styles.GalleryHub}>
				<ProductGallery />
			</Box>
		</Box>
	);
};

export default observer(ProductPlacement);
