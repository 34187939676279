import aspectRatio1 from "../../../../../assets/images/pngs/BestPractices/aspectRatio1.png";
import aspectRatio2 from "../../../../../assets/images/pngs/BestPractices/aspectRatio2.png";
import definedCharacter1 from "../../../../../assets/images/pngs/BestPractices/definedCharacter1.png";
import definedCharacter2 from "../../../../../assets/images/pngs/BestPractices/definedCharacter2.png";
import definedCharacter3 from "../../../../../assets/images/pngs/BestPractices/definedCharacter3.png";
import diversity1 from "../../../../../assets/images/pngs/BestPractices/diversity1.png";
import diversity2 from "../../../../../assets/images/pngs/BestPractices/diversity2.png";
import diversity3 from "../../../../../assets/images/pngs/BestPractices/diversity3.png";
import focusOnSubject1 from "../../../../../assets/images/pngs/BestPractices/focusOnSubject1.png";
import focusOnSubject2 from "../../../../../assets/images/pngs/BestPractices/focusOnSubject2.png";
import minimizeMargins1 from "../../../../../assets/images/pngs/BestPractices/minimizeMargins1.png";
import minimizeMargins2 from "../../../../../assets/images/pngs/BestPractices/minimizeMargins2.png";

import { TFunction } from "i18next";

export const getBestPracticesData = (t: TFunction) => {
	return {
		sideMenuButtons: [
			{
				title: t("taps.overview.title"),
				id: t("taps.overview.id"),
			},
			{
				title: t("taps.datasetSize.title"),
				id: t("taps.datasetSize.id"),
			},
			{
				title: t("taps.diversityAndFocus.title"),
				id: t("taps.diversityAndFocus.id"),
			},
			{
				title: t("taps.imageQuality.title"),
				id: t("taps.imageQuality.id"),
			},
			{
				title: t("taps.assetsBackgrounds.title"),
				id: t("taps.assetsBackgrounds.id"),
			},
			{
				title: t("taps.captionsAndPrefix.title"),
				id: t("taps.captionsAndPrefix.id"),
			},
		],
		diversityUseCases: [
			{
				title: t("content.diversitySection.useCases.balanceContent.title"),
				description: t("content.diversitySection.useCases.balanceContent.descr"),
				images: [diversity1, diversity2, diversity3],
			},
		],
		imageQualityUseCases: [
			{
				title: t("content.imageQualitySection.useCases.resolution.title"),
				description: t("content.imageQualitySection.useCases.resolution.descr"),
			},
			{
				title: t("content.imageQualitySection.useCases.aspectRatio.title"),
				list: [
					{
						text: t("content.imageQualitySection.useCases.aspectRatio.listItem1"),
					},
					{
						text: t("content.imageQualitySection.useCases.aspectRatio.listItem2"),
						images: [aspectRatio1, aspectRatio2],
					},
				],
			},
		],
		assetsUseCases: [
			{
				title: t("content.assetsSection.useCases.focusOnSubject.title"),
				description: t("content.assetsSection.useCases.focusOnSubject.descr"),
				images: [focusOnSubject1, focusOnSubject2],
			},
			{
				title: t("content.assetsSection.useCases.minimize.title"),
				description: t("content.assetsSection.useCases.minimize.descr"),
				images: [minimizeMargins1, minimizeMargins2],
			},
			{
				title: t("content.assetsSection.useCases.definedCharacters.title"),
				description: t("content.assetsSection.useCases.definedCharacters.descr"),
				images: [definedCharacter1, definedCharacter2, definedCharacter3],
			},
		],
		captionsUseCases: [
			{
				title: t("content.captionsSection.useCases.defaultPrefix.title"),
				description: t("content.captionsSection.useCases.defaultPrefix.descr"),
			},
			{
				title: t("content.captionsSection.useCases.customizingThePrefix.title"),
				description: t("content.captionsSection.useCases.customizingThePrefix.descr"),
			},
			{
				title: t("content.captionsSection.useCases.modifyingCaptions.title"),
				list: [
					{ text: t("content.captionsSection.useCases.modifyingCaptions.listItem1") },
					{ text: t("content.captionsSection.useCases.modifyingCaptions.listItem2") },
					{ text: t("content.captionsSection.useCases.modifyingCaptions.listItem3") },
				],
			},
			{
				title: t("content.captionsSection.useCases.bestPractices.title"),
				list: [
					{ text: t("content.captionsSection.useCases.bestPractices.listItem1") },
					{ text: t("content.captionsSection.useCases.bestPractices.listItem2") },
					{ text: t("content.captionsSection.useCases.bestPractices.listItem3") },
				],
			},
		],
	};
};
