import { Box } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import RouterConstants from "../../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../../hooks/useStores";
import { ViewTgProjectLocationState } from "../../TgProjectViewer";
import styles from "./TgProjectDatasetLayout.module.scss";

export const TgProjectDatasetLayout = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.viewer.datasets.edit" });
	const navigate = useSecureNavigate();
	const { projectId } = useParams();
	const { uiStore, tgStore } = useAppStore();
	const { tgProjectStore } = tgStore;

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(
			`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_DATASETS.path}`,
			undefined,
			t(`backButton`, { projectName: tgProjectStore.projectToView.project_name || "Project" }),
		);

		(async () => {
			try {
				if (!tgProjectStore.projectToView.id && projectId) {
					const project = await tgProjectStore.getProject(Number(projectId));
					tgProjectStore.projectToView = project;
				}
			} catch (err: any) {
				if (err.response?.status === 404) {
					navigate(RouterConstants.NEW_TAILORED_GENERATION.fullPath);
				}
			}
		})();

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
		};
	}, []);

	useEffect(() => {
		if (tgProjectStore.projectToView.id) {
			const viewProjectState: ViewTgProjectLocationState = { projectToView: toJS(tgProjectStore.projectToView) };

			uiStore.showBackButton(
				`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_DATASETS.path}`,
				{ state: viewProjectState },
				t(`backButton`, { projectName: tgProjectStore.projectToView.project_name }),
			);
		}
	}, [tgProjectStore.projectToView.id]);

	return (
		<Box className={styles.container}>
			<Outlet />
		</Box>
	);
};

export default observer(TgProjectDatasetLayout);
