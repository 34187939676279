import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import RouterConstants from "../../../constants/RouterConstants.ts";
import ImageViewer from "../../../components/common/Image/Image";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import useOrgNavigation from "../../../hooks/useOrgNavigation.tsx";
import styles from "./TailoredGenerationWidget.module.scss";

const TailoredGenerationWidget = () => {
	const { t } = useTranslation("translation", { keyPrefix: "home.tailoredGenerationWidget" });
	const { orgNavigationHandler } = useOrgNavigation();

	const accessMethods = t("accessMethods", { returnObjects: true });

	return (
		<Box className={styles.container}>
			<Box className={styles.topContent}>
				<Typography className={styles.title}>{t("title")}</Typography>
				<Typography className={styles.description}>{t("description")}</Typography>
			</Box>

			<Box className={styles.bottomContent}>
				<Box className={styles.createYourModels}>
					<Typography className={styles.title}>{t("createYourModels.title")}</Typography>
					<Typography className={styles.description}>{t("createYourModels.description")}</Typography>
					<BriaButton
						onClick={() => {
							orgNavigationHandler(RouterConstants.NEW_TAILORED_GENERATION.fullPath);
						}}
						className={styles.upgradeButton}
						buttonType="secondaryMedium"
					>
						{t("createYourModels.cta")}
					</BriaButton>
				</Box>

				<Box className={styles.accessMethods}>
					<Typography className={styles.title}>{t("accessMethodsTitle")}</Typography>
					<Box className={styles.methodsWrap}>
						{Object.keys(accessMethods).map((method) => (
							<Box className={styles.titleWrap}>
								<ImageViewer name={`images/svgs/TgAccessMethods/${method}.svg`} />
								{t(`accessMethods.${method}`)}
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(TailoredGenerationWidget);
export default ObservedComponent;
