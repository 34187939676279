import { Box } from "@mui/material";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import clsx from "clsx";
import React from "react";
import styles from "./LoadingPlaceholder.module.scss";

interface LoadingPlaceholderProps extends CircularProgressProps {
	isLoading?: boolean;
	children?: React.ReactNode;
	className?: string;
	loaderClassName?: string;
	alwaysRenderChildren?: boolean;
	size?: number | string;
	type?: "overlay";
}

const LoadingPlaceholder = ({
	isLoading = false,
	children,
	className,
	loaderClassName,
	alwaysRenderChildren,
	size = 40,
	type,
	...props
}: LoadingPlaceholderProps) => {
	if (type === "overlay") {
		return (
			<Box className={clsx({ [styles.overlay]: isLoading }, className)}>
				{isLoading && (
					<CircularProgress
						{...props}
						className={clsx(styles.loadingPlaceholder, loaderClassName)}
						size={size}
					/>
				)}
				{children}
			</Box>
		);
	} else {
		if (alwaysRenderChildren) {
			return (
				<>
					{isLoading && (
						<div className={className}>
							<CircularProgress
								{...props}
								className={clsx(styles.loadingPlaceholder, loaderClassName)}
								size={size}
							/>
						</div>
					)}
					<div className={clsx(styles.childrenContainer, { [styles.hidden]: isLoading })}>{children}</div>
				</>
			);
		} else {
			return isLoading ? (
				<div className={className}>
					<CircularProgress
						{...props}
						className={clsx(styles.loadingPlaceholder, loaderClassName)}
						size={size}
					/>
				</div>
			) : (
				children
			);
		}
	}
};

export default LoadingPlaceholder;
