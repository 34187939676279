import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../../assets/images/icons/DeleteIcon";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaIconButton from "../../../../../components/common/BriaIconButton/BriaIconButton";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import BriaImage from "../../../../../components/common/Galleries/BriaImage";
import LoadingPlaceholder from "../../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import { useAppStore } from "../../../../../hooks/useStores";
import { TgDatasetImage } from "../../store/tg-dataset-store";
import TgDatasetImageCardError from "./Error/TgDatasetImageCardError";
import styles from "./TgDatasetImageCard.module.scss";

type TgDatasetImageCardProps = {
	image: TgDatasetImage;
	triggerOnChangeExisting: () => Promise<boolean>;
};

const TgDatasetImageCard = ({ image, triggerOnChangeExisting }: TgDatasetImageCardProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.editor.form.images" });
	const { tgStore } = useAppStore();
	const { tgDatasetStore } = tgStore;
	const [newCaption, setNewCaption] = useState(image.caption);
	const [isCaptionEditable, setIsCaptionEditable] = useState(false);
	const [loadingUpdateCaption, setLoadingUpdateCaption] = useState(false);
	const [loadingRegenerateCaption, setLoadingRegenerateCaption] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);

	useEffect(() => {
		setNewCaption(image.caption);
	}, [image.caption]);

	const regenerateImageCaption = async () => {
		setLoadingRegenerateCaption(true);
		try {
			const imageIndex = tgDatasetStore.datasetForm.images.findIndex((img) => img.id === image.id);
			const imageId = (await triggerOnChangeExisting())
				? tgDatasetStore.datasetForm.images[imageIndex]?.id
				: image.id;

			await tgDatasetStore.regenerateImageCaption(tgDatasetStore.datasetForm.id, imageId);
		} finally {
			setLoadingRegenerateCaption(false);
		}
	};

	const deleteImage = async () => {
		setLoadingDelete(true);
		try {
			if (image.uploading) {
				tgDatasetStore.datasetForm.images = tgDatasetStore.datasetForm.images.filter(
					(img) => img.id !== image.id,
				);
			} else {
				const imageIndex = tgDatasetStore.datasetForm.images.findIndex((img) => img.id === image.id);
				const imageId = (await triggerOnChangeExisting())
					? tgDatasetStore.datasetForm.images[imageIndex]?.id
					: image.id;

				await tgDatasetStore.deleteImage(tgDatasetStore.datasetForm.id, imageId);
			}
		} finally {
			setLoadingDelete(false);
		}
	};

	const editImageCaption = async () => {
		setIsCaptionEditable(true);
	};

	const saveImageCaption = async () => {
		setLoadingUpdateCaption(true);
		try {
			if (newCaption !== image.caption) {
				const imageIndex = tgDatasetStore.datasetForm.images.findIndex((img) => img.id === image.id);
				const imageId = (await triggerOnChangeExisting())
					? tgDatasetStore.datasetForm.images[imageIndex]?.id
					: image.id;

				await tgDatasetStore.updateImageCaption(tgDatasetStore.datasetForm.id, imageId, newCaption);
			}
		} finally {
			setLoadingUpdateCaption(false);
			setIsCaptionEditable(false);
		}
	};

	return (
		<Box className={styles.imageCard}>
			<Box className={styles.image}>
				<BriaImage
					className={styles.image}
					url={image.thumbnail_url}
					image={{ url: image.thumbnail_url, id: image.id.toString(), src: image.thumbnail_url }}
					hideFullScreenButton
				/>
			</Box>
			<Box className={styles.imageCaptionWrapper}>
				<Box className={styles.imageHeader}>
					<Typography className={styles.imageCaptionTag}>{t(`tags.${image.caption_source}`)}</Typography>
					{!image.error && (
						<BriaButtonGroup className={styles.imageButtons}>
							{isCaptionEditable ? (
								<BriaButton
									className={styles.imageButton}
									buttonType="textSmall"
									onClick={() => saveImageCaption()}
									loading={loadingUpdateCaption}
									disabled={image.uploading || tgDatasetStore.loadingDuplicateDataset}
								>
									{t("saveCaptionButton")}
								</BriaButton>
							) : (
								<BriaButton
									className={styles.imageButton}
									buttonType="textSmall"
									onClick={() => editImageCaption()}
									disabled={image.uploading || tgDatasetStore.loadingDuplicateDataset}
								>
									{t("editButton")}
								</BriaButton>
							)}
							<BriaButton
								className={styles.imageButton}
								buttonType="textSmall"
								onClick={() => regenerateImageCaption()}
								loading={loadingRegenerateCaption}
								disabled={image.uploading || tgDatasetStore.loadingDuplicateDataset}
							>
								{t("regenerateImageButton")}
							</BriaButton>
							<BriaIconButton
								className={styles.imageIconButton}
								title={t("deleteTooltip")}
								onClick={() => deleteImage()}
								loading={loadingDelete}
								disabled={image.uploading || tgDatasetStore.loadingDuplicateDataset}
							>
								<DeleteIcon />
							</BriaIconButton>
						</BriaButtonGroup>
					)}
				</Box>
				<Typography className={styles.imageCaption}>
					<Typography component="span" className={styles.imagePrefix}>
						<Typography component="span" className={styles.imagePrefixTag}>
							{t(`tags.prefix`)}
						</Typography>
						<Typography component="span" className={styles.imagePrefixText}>
							{tgDatasetStore.datasetForm.caption_prefix}
						</Typography>
					</Typography>
					<LoadingPlaceholder
						className={styles.loadingImageCaption}
						isLoading={image.uploading || tgDatasetStore.loadingRegenerate}
					>
						{isCaptionEditable ? (
							<BriaInput
								value={newCaption}
								onChange={(e) => setNewCaption(e.target.value)}
								multiline
								maxRows={2}
								fullWidth
								autoFocus
								InputProps={{ className: styles.captionInput }}
							/>
						) : (
							<Typography component="span" className={styles.imageCaptionText}>
								{image.caption}
							</Typography>
						)}
					</LoadingPlaceholder>
				</Typography>
				<TgDatasetImageCardError {...{ image }} />
			</Box>
		</Box>
	);
};

export default observer(TgDatasetImageCard);
