import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import RouterConstants from "../../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../../hooks/useSecureNavigate";
import styles from "../../TgProjectViewer.module.scss";

const TgProjectModelsEmpty = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.dashboard" });
	const { projectId } = useParams();
	const navigate = useSecureNavigate();
	return (
		<Box className={styles.emptyState}>
			<Box>{t("emptyState")}</Box>
			<BriaButton
				onClick={() => {
					return navigate(
						`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_MODELS.createPath}`,
					);
				}}
				buttonType="primaryMedium"
			>
				{t("createModel")}
			</BriaButton>
		</Box>
	);
};

export default observer(TgProjectModelsEmpty);
