import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DatabaseIcon from "../../../../../../../DesignEditor/components/Icons/DatabaseIcon";
import DuplicateIcon from "../../../../../../../DesignEditor/components/Icons/DuplicateIcon";
import DeleteIcon from "../../../../../../../assets/images/icons/DeleteIcon";
import BriaIconButton from "../../../../../../../components/common/BriaIconButton/BriaIconButton";
import ConfirmationPopup from "../../../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import RouterConstants from "../../../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../../../hooks/useStores";
import { ViewTgModelLocationState } from "../../../../../Models/Viewer/TgModelViewer";
import { TgModel } from "../../../../../Models/store/tg-model-store";
import styles from "./TgModelActions.module.scss";

type TgModelActionsProps = {
	model: TgModel;
};

const TgModelActions = ({ model }: TgModelActionsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.dashboard.table.actions" });
	const navigate = useSecureNavigate();
	const { tgStore } = useAppStore();
	const { tgModelStore } = tgStore;
	const [openDeletePopup, setOpenDeletePopup] = useState(false);

	const duplicateModel = async () => {
		tgModelStore.modelForm = {
			...model,
			id: NaN,
			name: `${t("duplicatedModelNamePrefix")} ${model.name}`,
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${model.project_id}/${RouterConstants.NEW_TG_MODELS.createPath}`,
		);
	};

	const openModelDataset = () => {
		const modelToViewState: ViewTgModelLocationState = {
			modelToView: model,
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${model.project_id}/${RouterConstants.NEW_TG_MODELS.path}/${model.id}/${RouterConstants.TG_MODEL_DATASET.path}/${model.dataset_id}`,
			{ state: modelToViewState },
		);
	};

	const deleteModel = async () => {
		try {
			await tgModelStore.deleteModel(model.id);
		} finally {
			setOpenDeletePopup(false);
		}
	};

	return (
		<>
			<Box className={styles.actions}>
				<BriaIconButton className={styles.iconButton} title={t("duplicate")} onClick={() => duplicateModel()}>
					<DuplicateIcon />
				</BriaIconButton>
				<BriaIconButton className={styles.iconButton} title={t("dataset")} onClick={() => openModelDataset()}>
					<DatabaseIcon />
				</BriaIconButton>
				<BriaIconButton
					className={styles.iconButton}
					title={t("delete")}
					disabled={model.status === "InProgress"}
					onClick={() => setOpenDeletePopup(true)}
				>
					<DeleteIcon />
				</BriaIconButton>
			</Box>
			<ConfirmationPopup
				open={openDeletePopup}
				onClick={deleteModel}
				loading={tgModelStore.loadingDeleteModel}
				title={t("deletePopup.title")}
				description={<Trans>{t("deletePopup.description")}</Trans>}
				confirmButtonText={t("deletePopup.confirmBtn")}
				onClose={() => setOpenDeletePopup(false)}
			/>
		</>
	);
};

export default observer(TgModelActions);
