import { FormControlLabel } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../../../../components/common/BriaCheckbox/BriaCheckbox";
import { useAppStore } from "../../../../../../../hooks/useStores";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText } from "../../../../../../types";
import styles from "./FixedTextBoxSizeCheckBox.module.scss";

const FixedTextBoxSizeCheckBox = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.textControllers" });
	const activeObject = useActiveObject() as Required<IStaticText>;
	const editor = useEditor();
	const { campaignStore } = useAppStore();

	useEffect(() => {
		campaignStore.setProperty("fixedTextBoxChecked", activeObject?.fixedSize);
	}, []);

	useEffect(() => {
		const watcher = async () => {
			if (activeObject && activeObject.type === "StaticText") {
				campaignStore.setProperty("fixedTextBoxChecked", activeObject?.fixedSize);
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);

	const makeTextFixedSize = useCallback(
		(value: boolean) => {
			editor?.objects.update(
				{
					fixedSize: value,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			campaignStore.setProperty("fixedTextBoxChecked", value);
			// When pressing "fix" - Width + height + max font should be reset per the current state
			activeObject.fixedWidthValue = activeObject.width;
			activeObject.fixedHeightValue = activeObject.height;
			activeObject.maximumFontSize = activeObject.fontSize;
			if (!value) {
				activeObject?.setControlsVisibility({
					mt: true,
					mb: true,
					ml: true,
					mr: true,
					bl: true,
					br: true,
					tl: true,
					tr: true,
					mtr: true,
				});
			} else {
				activeObject?.setControlsVisibility({
					mt: false,
					mb: false,
					ml: false,
					mr: false,
					bl: false,
					br: false,
					tl: false,
					tr: false,
					mtr: false,
				});
			}
			// TODO:It's not needed for now, may be we need to add it in future
			// EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.FIXEDTEXTBOX, value);
		},
		[editor],
	);
	return (
		<FormControlLabel
			className={styles.FormCheckBoxControlLabel}
			classes={{ label: styles.checkBoxLabel }}
			control={
				<BriaCheckbox
					className={styles.checkBox}
					checked={campaignStore.fixedTextBoxChecked}
					onChange={(e) => {
						makeTextFixedSize(e.target.checked);
						if (e.target.checked) {
							campaignStore.fixTextBoxAnchorEl = e.currentTarget as any;
						}
					}}
				/>
			}
			label={t("fixTextBox")}
		/>
	);
};

const ObservedComponent = observer(FixedTextBoxSizeCheckBox);
export default ObservedComponent;
