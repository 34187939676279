import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function EyeIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 21 21" {...props}>
			<path
				d="M18.8334 10.5C16.6109 14.3891 13.8334 16.3333 10.5 16.3333C7.16669 16.3333 4.38919 14.3891 2.16669 10.5C4.38919 6.61079 7.16669 4.66663 10.5 4.66663C13.8334 4.66663 16.6109 6.61079 18.8334 10.5Z"
				stroke="#5B5B5B"
				strokeOpacity={props.opacity || "0.5"}
				strokeWidth={props.strokeWidth || "1.5"}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.5 12.1666C11.4205 12.1666 12.1667 11.4204 12.1667 10.5C12.1667 9.57948 11.4205 8.83329 10.5 8.83329C9.57955 8.83329 8.83335 9.57948 8.83335 10.5C8.83335 11.4204 9.57955 12.1666 10.5 12.1666Z"
				stroke="#5B5B5B"
				strokeOpacity={props.opacity || "0.5"}
				strokeWidth={props.strokeWidth || "1.5"}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default EyeIcon;
