import React from "react";
import styles from "./StoppableButton.module.scss";
import BriaButton, { ButtonTypes } from "../BriaButton/BriaButton";

interface StoppableButtonProps {
  condition: boolean | null;
  onStopButtonClick: () => void;
  onButtonClick: () => Promise<void> | void;
  disabled?: boolean;
  buttonLabel: string | JSX.Element;
  stopButtonLabel?: string | JSX.Element;
  buttonType?: ButtonTypes;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
  className?: string;
}

const StoppableButton: React.FC<StoppableButtonProps> = ({
  condition,
  onStopButtonClick,
  onButtonClick,
  disabled = false,
  buttonLabel,
  stopButtonLabel = "Stop",
  buttonType = "primaryMedium",
  fullWidth = true,
  size = "large",
  className = "",
}) => {
  return condition ? (
    <BriaButton
      buttonType={buttonType}
      fullWidth={fullWidth}
      onClick={onStopButtonClick}
      className={`${styles.stopButton} ${className}`}
      size={size}
    >
      {stopButtonLabel}
    </BriaButton>
  ) : (
    <BriaButton
      buttonType={buttonType}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onButtonClick}
      className={`${styles.startButton} ${className}`}
      size={size}
    >
      {buttonLabel}
    </BriaButton>
  );
};

export default StoppableButton;
