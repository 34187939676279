import { Mark } from "@mui/base/useSlider";
import { Box, Divider, SelectChangeEvent, Slider } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import CloudUploadSVG from "../../../assets/images/icons/CloudUpload.tsx";
import BriaCheckbox from "../../../components/common/BriaCheckbox/BriaCheckbox";
import BriaColorPicker from "../../../components/common/BriaColorPicker/BriaColorPicker";
import { IGroupOption } from "../../../components/common/CustomSingleSelectDropdown/SingleSelectGroupedDropDown";
import GuidanceMethods from "../../../components/common/GuidanceMethods/GuidanceMethods.tsx";
import ImagePromptAdapter from "../../../components/common/ImagePromptAdapter/ImagePromptAdapter.tsx";
import ImageUploader from "../../../components/common/ImageUploader/ImageUploader";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import BriaButton, { ButtonTypes } from "../BriaButton/BriaButton.tsx";
import BriaDropDown, { DropDownItem } from "../BriaDropDown/BriaDropDown.tsx";
import BriaInput from "../BriaInput/BriaInput.tsx";
import BriaSlider from "../BriaSlider/BriaSlider.tsx";
import CheckboxInput from "../CheckboxInput/CheckboxInput.tsx";
import TextToImageModelsDropdown from "../DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown";
import styles from "./GenericInput.module.scss";

interface IProps {
	name: string;
	type: string;
	value: any;
	defaultValue?: any;
	label?: string;
	floatingLabel?: string | string[];
	placeholder?: string;
	info?: string;
	dropdownOptions?: DropDownItem<any>[];
	selectMultipleCheckbox?: boolean;
	multiple?: boolean;
	min?: number;
	max?: number;
	step?: number;
	marks?: boolean | Mark[];
	labeledMarks?: { value: number; label?: string }[];
	hidden?: boolean;
	valueLabelDisplay?: "on" | "off" | "auto";
	numberOfInputs?: number;
	customEndAdornmentText?: string;
	size?: "small" | "medium";
	minRows?: number;
	multiline?: boolean;
	className?: string;
	buttonType?: ButtonTypes;
	validationMessage?: string | null;
	onClick?: () => void;
	onChange?: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<HTMLInputElement> | Event) => void;
	onColorChange?: (value: string) => void;
	onIntArrayChange?: (index: number, value: number | null) => void;
	variant?: string;
	fieldLabels?: [];
	buttonsList?: [];
	onButtonClick?: (fieldName: string) => void;
	onTailoredModelChange?: (e: React.ChangeEvent<HTMLSelectElement>, selected: IGroupOption) => void;
	showFoundationModels: boolean;
	isVector?: boolean;
	onFoundationModelsSuccess?: (foundationModels: IGroupOption[]) => void;
	onTailoredModelsSuccess?: (tailoredModels: IGroupOption[]) => void;
	onUploadImage?: (e: React.ChangeEvent<HTMLInputElement>, name: string) => Promise<void>;
	onDeleteImage?: (name: string) => Promise<void>;
	description?: React.ReactNode;
	supportedFilesText?: string;
}

const GenericInput: React.FC<IProps> = ({
	name,
	type,
	value,
	defaultValue,
	label,
	floatingLabel,
	placeholder,
	info,
	dropdownOptions,
	selectMultipleCheckbox,
	multiple,
	min,
	max,
	step,
	marks,
	labeledMarks,
	hidden,
	valueLabelDisplay,
	numberOfInputs,
	customEndAdornmentText,
	size = "small",
	minRows,
	multiline,
	className,
	buttonType,
	onChange,
	onClick,
	onColorChange,
	onIntArrayChange,
	variant,
	fieldLabels,
	buttonsList,
	onButtonClick,
	validationMessage = null,
	onTailoredModelChange,
	showFoundationModels,
	isVector,
	onFoundationModelsSuccess,
	onTailoredModelsSuccess,
	onUploadImage,
	onDeleteImage,
	description,
	supportedFilesText,
}) => {
	const { sandboxAPIStore, playgroundStore } = useAppStore();
	const getButtonDisabledState = (buttonName: string) => {
		switch (buttonName) {
			case "reset_image":
				return (
					sandboxAPIStore.isLoading ||
					playgroundStore.playgroundResults.length === 0 ||
					sandboxAPIStore.isUploadingImage
				);
			case "erase_object":
				return (
					sandboxAPIStore.isLoading ||
					playgroundStore.playgroundResults.length === 0 ||
					sandboxAPIStore.isUploadingImage ||
					sandboxAPIStore.config.apiConfig?.parameters?.some((item) => item?.validationMessage) ||
					!sandboxAPIStore.brushConfigs.isMaskApplied
				);
			default:
				return false;
		}
	};
	const renderInput = () => {
		switch (type) {
			case "string":
			case "number":
				return (
					!hidden && (
						<InputLayout
							label={label}
							className={`${className ? styles[className] : ""}`}
							labelClassName={styles.inputLabel}
							info={info}
							validateOn={true}
							validationMessage={validationMessage}
						>
							<BriaInput
								name={name}
								type={type}
								value={value}
								placeholder={placeholder}
								size={size}
								minRows={minRows}
								multiline={multiline}
								inputProps={{ className: styles.inputBase }}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onChange?.(e);
								}}
							/>
						</InputLayout>
					)
				);
			case "dropdown":
				if (dropdownOptions?.length)
					return (
						!hidden &&
						value !== undefined && (
							<InputLayout
								label={label}
								className={`${className ? styles[className] : ""}`}
								labelClassName={styles.inputLabel}
								info={info}
								validateOn={true}
								validationMessage={validationMessage}
							>
								<BriaDropDown
									name={name}
									className={styles.dropDown}
									value={value}
									placeholder={placeholder}
									items={dropdownOptions}
									multiple={multiple}
									selectMultipleCheckbox={selectMultipleCheckbox}
									onChange={(e: any) => {
										onChange?.(e);
									}}
								/>
							</InputLayout>
						)
					);
				break;
			case "color":
				return (
					!hidden && (
						<InputLayout
							label={label}
							className={`${className ? styles[className] : ""}`}
							labelClassName={styles.inputLabel}
							info={info}
							validateOn={true}
							validationMessage={validationMessage}
						>
							<BriaColorPicker
								color={value}
								onChange={(color: string) => onColorChange?.(color)}
								showInputField
								className={styles.colorInputField}
							/>
						</InputLayout>
					)
				);
			case "slider":
				switch (variant) {
					case "labeled-slider":
						return (
							!hidden && (
								<InputLayout
									label={label}
									className={`${className ? styles[className] : ""}`}
									labelClassName={styles.inputLabel}
									info={info}
								>
									<BriaSlider
										name={name}
										value={value}
										onChange={(e: Event) => {
											onChange?.(e);
										}}
										min={min}
										max={max}
										step={step}
										marks={labeledMarks}
										valueLabelDisplay={valueLabelDisplay}
										fieldLabels={fieldLabels}
										showMarksPoints
									/>
								</InputLayout>
							)
						);
					default:
						return (
							!hidden &&
							value !== undefined && (
								<InputLayout
									label={label}
									className={`${className ? styles[className] : ""}`}
									labelClassName={styles.inputLabel}
									info={info}
								>
									<Slider
										name={name}
										value={value}
										onChange={(e: any) => {
											onChange?.(e);
										}}
										min={min}
										max={max}
										step={step}
										valueLabelDisplay={valueLabelDisplay}
										{...(marks &&
											max &&
											step && {
												marks: Array.from({ length: max }, (_, index) => ({
													value: index + step,
													label: `${index + step}`,
												})),
											})}
									/>
								</InputLayout>
							)
						);
				}
			case "intArray":
				return (
					!hidden && (
						<InputLayout
							label={label}
							className={`${className ? styles[className] : ""}`}
							labelClassName={styles.inputLabel}
							info={info}
							validateOn={true}
							validationMessage={validationMessage}
						>
							<Box className={styles.dimensions}>
								{numberOfInputs &&
									Array.from({ length: numberOfInputs }).map((_, index) => (
										<BriaInput
											key={index}
											name={`${name}${index}`}
											type="number"
											value={value ? value[index] : null}
											placeholder={placeholder && placeholder[index]}
											label={floatingLabel && floatingLabel[index]}
											size={size}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												if (onIntArrayChange) {
													const inputValue =
														e.target.value === "" ? null : Number(e.target.value);
													onIntArrayChange(index, inputValue);
												}
											}}
											customEndAdornmentText={customEndAdornmentText}
											className={styles.inputWrapper}
											inputProps={{ className: styles.inputField }}
										/>
									))}
							</Box>
						</InputLayout>
					)
				);
			case "checkbox":
				return (
					!hidden &&
					value !== undefined && (
						<CheckboxInput
							className={`${className ? styles[className] : ""} ${styles.checkboxLabel}`}
							label={label}
							info={info}
							checkbox={
								<BriaCheckbox
									name={name}
									checked={value}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										onChange?.(e);
									}}
								/>
							}
						/>
					)
				);
			case "button":
				return (
					!hidden && (
						<InputLayout label={label} className={className} labelClassName={styles.inputLabel} info={info}>
							<BriaButton
								buttonType={buttonType}
								onClick={() => {
									onClick?.();
								}}
							>
								{name}
							</BriaButton>
						</InputLayout>
					)
				);
			case "buttonsGroup":
				return (
					!hidden && (
						<Box className={className && styles[className]}>
							{buttonsList?.map((button: any) => (
								<BriaButton
									buttonType={button.type}
									disabled={getButtonDisabledState(button.name)}
									onClick={async () => {
										if (onButtonClick) {
											onButtonClick(button.name);
										}
									}}
								>
									{button.label}
								</BriaButton>
							))}
						</Box>
					)
				);
			case "tailored_models":
				if (defaultValue !== undefined) {
					return (
						<InputLayout
							label={label}
							className={`${styles.modelsDropdown} ${className}`}
							labelClassName={styles.inputLabel}
							info={info}
						>
							<TextToImageModelsDropdown
								selectedValue={defaultValue}
								handleChange={onTailoredModelChange}
								onFoundationModelsSuccess={(foundationModels) =>
									onFoundationModelsSuccess?.(foundationModels)
								}
								onTailoredModelsSuccess={(tailoredModels) => onTailoredModelsSuccess?.(tailoredModels)}
								placeholder={placeholder}
								showFoundationModels={showFoundationModels}
								isVector={isVector}
							/>
						</InputLayout>
					);
				}
				break;
			case "guidanceMethods":
				return (
					!hidden && (
						<>
							<Divider />
							<GuidanceMethods label={label} info={info} />
						</>
					)
				);
			case "imagePromptAdapter":
				return (
					!hidden && (
						<>
							<Divider />
							<ImagePromptAdapter label={label} info={info} />
						</>
					)
				);
			case "divider":
				return <Divider />;
			case "imageUploader":
				if (onUploadImage !== undefined && onDeleteImage !== undefined)
					return (
						<Box className={styles.imageUploader}>
							<InputLayout
								label={label}
								className={`${styles.modelsDropdown} ${className}`}
								labelClassName={styles.inputLabel}
								info={info}
								validateOn={true}
								validationMessage={validationMessage}
							>
								<ImageUploader
									description={description}
									supportedFilesText={supportedFilesText}
									icon={<CloudUploadSVG/>}
									horizontalLayout
									largeIcon
									showPreview={true}
									src={value}
									onUpload={(e) => onUploadImage(e, name)}
									onDelete={() => onDeleteImage(name)}
									emptyStateClassName={styles.noPadding}
									maxFileSize={sandboxAPIStore.MAX_FILE_SIZE}
									inlineError={true}
								></ImageUploader>
							</InputLayout>
						</Box>
					);
				break;
			case "secondaryButton":
				return (
					!hidden && (
						<Box className={`${className ? styles[className] : ""}`}>
							<BriaButton
								buttonType={buttonType}
								onClick={() => {
									onClick?.();
								}}
							>
								{label}
							</BriaButton>
						</Box>
					)
				);
				break;
		}
	};

	return renderInput();
};

const ObservedComponent = observer(GenericInput);
export default ObservedComponent;
